import * as dayjs from 'dayjs';
import {ProductField} from "./product-field";
import {Categorizable, ProductCategory} from "./product-category";

export class OrderLineFieldPreset {

    id: string;
    name: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    order_line_fields?: any[];
    constructor() {
    }

    static fromJSON(json: OrderLineFieldPresetJSON | string): OrderLineFieldPreset {
        if (typeof json === 'string') {
            return JSON.parse(json, OrderLineFieldPreset.reviver);
        } else {
            let productFieldPreset = Object.create(OrderLineFieldPreset.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                order_line_fields: [],
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'order_line_fields') {
                    json[key].forEach((value, _) => {
                        extra.order_line_fields.push(value);
                    });
                }

            });

            return Object.assign(productFieldPreset, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? OrderLineFieldPreset.fromJSON(value) : value;
    }
}

// A representation of ProductFieldPreset data that can be converted to
// and from JSON without being altered.
interface OrderLineFieldPresetJSON {
    id?: string;
    name?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
