<div class="container app__component">
    <h1 class="heading">
        <ng-container i18n>Contact types</ng-container>
        <small *ngIf="loading && contactTypes" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!contactTypes" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="contactTypes">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add contact type</a>
    </p>

    <app-table [autoSizeLastColumn]="true" *ngIf="contactTypes">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Features</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody [sortablejs]="contactTypes" [sortablejsOptions]="{ handle: '.sortablejs--handle' }">
            <ng-template ngFor let-contactType [ngForOf]="contactTypes">
                <tr>
                    <td>
                        <span class="sortablejs--handle  mr-2  text-muted" style="cursor: move;">=</span>
                        {{ contactType.name }}
                    </td>
                    <td>
                        <span *ngIf="contactType.is_order_invoice" class="badge  badge-secondary" i18n>Order invoice</span>
                        <span *ngIf="contactType.is_order_delivery" class="badge  badge-secondary  mr-2" i18n>Order delivery</span>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(contactType)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(contactType)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <button *ngIf="contactTypes" (click)="saveSorting()" class="btn  btn-sm  btn-primary" [disabled]="loading" i18n>Save sorting</button>
</div>
