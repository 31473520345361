import {Component, OnInit} from '@angular/core';
import {OrderEditComponent} from "../../../order-edit/order-edit.component";
import {OrdersService} from "../../../services/orders.service";
import {OrderStatesService} from "../../../services/order-states.service";
import {ProductsService} from "../../../services/products.service";
import {DebtorsService} from "../../../services/debtors.service";
import {ReferencesService} from "../../../services/references.service";
import {BranchesService} from "../../../services/branches.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OrderLineFieldPresetsService} from "../../../services/order-line-field-presets.service";
import {OrderLineFieldPreset} from "../../../services/order-line-field-preset";
import {OrderDeliveriesModalComponent} from "../order-deliveries-modal/order-deliveries-modal.component";
import {OrderLine} from "../../../services/order-line";

@Component({
    selector: 'app-edit-order',
    templateUrl: './edit-order.component.html',
    styleUrls: ['./edit-order.component.scss'],
    providers: [
        OrderLineFieldPresetsService,
        OrdersService, OrderStatesService, ProductsService, DebtorsService, ReferencesService, BranchesService]
})
export class ThorsensEditOrderComponent extends OrderEditComponent implements OnInit {

    constructor(public ordersService: OrdersService,
                public branchesService: BranchesService,
                public orderStatesService: OrderStatesService,
                public debtorsService: DebtorsService,
                public referencesService: ReferencesService,
                public productsService: ProductsService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router,
                public orderLineFieldPresetsService: OrderLineFieldPresetsService
    ) {

        super(ordersService,
            branchesService,
            orderStatesService,
            debtorsService,
            referencesService,
            productsService,
            activatedRoute,
            ngbModal,
            router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.activatedRoute.paramMap.subscribe(params => {
            // this.load(params.get('orderId'));
            this.loadPresets();
        });
    }

    orderLineFieldPresets: OrderLineFieldPreset;

    loadPresets() {
        this.orderLineFieldPresetsService.getOrderLineFieldPreset('17a262c4-060a-43c8-ba60-67d59db5c041').subscribe((result) => {
            this.orderLineFieldPresets = result.data;
        });
    }

    showDeliveries(orderLine: OrderLine): void {
        const modalRef = this.ngbModal.open(OrderDeliveriesModalComponent, {size: 'xl'});
        // @todo make this id dynamic
        modalRef.componentInstance.referenceValue = this._order.getIdentificationReference() + '-' + orderLine.getFieldValueForId('179c5dcb-6748-4a14-afb5-b1eceff8b781')

    }
}
