<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!priceGroup" i18n>Add price group</ng-container>
            <ng-container *ngIf="priceGroup" i18n>Edit price group</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="!debtors" class="text-muted" i18n>Loading...</p>

        <form *ngIf="debtors">
            <div class="form-group">
                <label for="price-group-name" i18n>Name</label>
                <input type="text" id="price-group-name" class="form-control" [(ngModel)]="_priceGroup.name" name="name">
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" class="mr-1" [(ngModel)]="_priceGroup.is_cost_price" name="is_cost_price"> <ng-container i18n>Is cost price</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" class="mr-1" [(ngModel)]="_priceGroup.is_default" name="is_default"> <ng-container i18n>Is default</ng-container>
                    </label>
                </div>
            </div>
            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" class="mr-1" [(ngModel)]="_priceGroup.is_inactive" name="is_inactive"> <ng-container i18n>Is Inactive</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <label for="price-group-currency" i18n>Currency</label>
                <select id="price-group-currency" class="form-control" [(ngModel)]="_priceGroup.currency_id" name="currency">
                    <option [value]="defaultCurrency.id"><span i18n>Default</span><p> - {{defaultCurrency.name}}</p></option>
                    <option *ngFor="let currency of currencies" [value]="currency.id">{{currency.name}}</option>
                </select>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <p class="text-muted" i18n>Debtors</p>

                    <ul>
                        <ng-template ngFor let-debtor [ngForOf]="debtors">
                            <li>
                                {{ debtor.name }}

                                <button *ngIf="!_priceGroup.hasDebtor(debtor)" (click)="_priceGroup.addDebtor(debtor)" class="btn  btn-sm  btn-primary" i18n>Add</button>
                                <button *ngIf="_priceGroup.hasDebtor(debtor)" (click)="_priceGroup.removeDebtor(debtor)" class="btn  btn-sm  btn-danger" i18n>Remove</button>
                            </li>
                        </ng-template>
                    </ul>
                </div>

                <div class="col-md-6">
                    <p class="text-muted" i18n>Product</p>

                    <p *ngIf="_priceGroup.product">{{ _priceGroup.product.name }}</p>

                    <p *ngIf="!productReferences" class="text-muted" i18n>Loading...</p>

                    <ng-container *ngIf="productReferences">
                        <label for="search-term" i18n>Search by product reference</label>

                        <div class="input-group  mb-2">
                            <input type="text" id="search-term" class="form-control" name="search-term" [(ngModel)]="searchTerm">

                            <div class="input-group-append">
                                <button (click)="search()" class="btn  btn-primary" type="button" i18n>Search</button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="loading || products">
                        <p *ngIf="loading" class="text-muted" i18n>Loading...</p>

                        <p *ngIf="products && products.length === 0" class="text-muted" i18n>No products found using search term.</p>

                        <table class="table  table-sm  table-striped  table-hover">
                            <tbody>
                            <ng-template ngFor let-searchProduct [ngForOf]="products">
                                <tr>
                                    <td>{{ searchProduct.name }}</td>
                                    <td class="text-right"><button (click)="select(searchProduct)" class="btn  sm  btn-primary" i18n>Select</button></td>
                                </tr>
                            </ng-template>

                            </tbody>
                        </table>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
