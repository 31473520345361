import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceGroupsService} from "../services/price-groups.service";
import {PriceGroup, PriceGroupRelatable} from "../services/price-group";

@Component({
    selector: 'app-price-group-select-modal',
    templateUrl: './price-group-select-modal.component.html',
    styleUrls: ['./price-group-select-modal.component.scss'],
    providers: [PriceGroupsService]
})
export class PriceGroupSelectModalComponent implements OnInit {

    public priceGroupRelatable: PriceGroupRelatable;

    priceGroups: PriceGroup[];

    priceGroup: PriceGroup = null;

    constructor(public activeModal: NgbActiveModal,
                public priceGroupsService: PriceGroupsService) {
    }

    ngOnInit() {
        this.priceGroupsService.getPriceGroups(1, -1).subscribe(response => {
            this.priceGroups = response.data;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.priceGroupRelatable.addPriceGroup(this.priceGroup);

        this.activeModal.close();
    }
}
