<ng-template ngFor let-contact [ngForOf]="contactable.contacts" let-i="index">
    <button *ngIf="allowRemove" (click)="removeContact(i)" class="btn  btn-sm  btn-danger  float-right">Remove</button>

    <div class="form-group">
        <label for="contact-{{i}}-name" i18n>Contact name</label>
        <input type="text" id="contact-{{i}}-name" class="form-control  form-control-sm" [(ngModel)]="contact.name" name="contact-{{i}}-name" [disabled]="disableName">
    </div>

    <div class="form-group" *ngIf="!hideNickname">
        <label for="contact-{{i}}-nickname" i18n>Contact nickname</label>
        <input type="text" id="contact-{{i}}-nickname" class="form-control  form-control-sm" [(ngModel)]="contact.nickname" name="contact-{{i}}-nickname">
    </div>

    <div class="form-group">
        <label for="contact-{{i}}-full_name" i18n>Contact full name</label>
        <input type="text" id="contact-{{i}}-full_name" class="form-control  form-control-sm" [(ngModel)]="contact.full_name" name="contact-{{i}}-full_name">
    </div>

    <div class="form-group">
        <label for="contact-{{i}}-address" i18n>Contact address</label>
        <input type="text" id="contact-{{i}}-address" class="form-control  form-control-sm" [(ngModel)]="contact.address" name="contact-{{i}}-address">
    </div>

    <div class="form-group">
        <label for="contact-{{i}}-zip" i18n>Contact zip</label>
        <input type="text" id="contact-{{i}}-zip" class="form-control  form-control-sm" [(ngModel)]="contact.zip" name="contact-{{i}}-zip">
    </div>

    <div class="form-group">
        <label for="contact-{{i}}-city" i18n>Contact city</label>
        <input type="text" id="contact-{{i}}-city" class="form-control  form-control-sm" [(ngModel)]="contact.city" name="contact-{{i}}-city">
    </div>

    <ng-template ngFor let-contactField [ngForOf]="contact.contact_fields" let-j="index">
        <div class="form-group">
            <label for="contact-{{i}}-contact-field-{{j}}-value">{{ contactField.name }}</label>
            <input type="text" id="contact-{{i}}-contact-field-{{j}}-value" class="form-control  form-control-sm" [(ngModel)]="contactField._joinData.value" name="contact-{{i}}-contact-field-{{j}}-value">
        </div>
    </ng-template>

    <hr>
</ng-template>
