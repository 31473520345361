<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Dates</ng-container>
        <small *ngIf="loading && dates" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!dates" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="dates">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add date</a>
    </p>

    <app-table *ngIf="dates" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Features</th>
                <th i18n>Editable</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-date [ngForOf]="dates">
                <tr>
                    <td>{{ date.name }}</td>
                    <td>
                        <span *ngIf="date.is_creation" class="badge  badge-primary  mr-2" i18n>Creation date</span>
                        <span *ngIf="date.is_due" class="badge  badge-primary  mr-2" i18n>Due date</span>
                        <span *ngIf="date.is_shipped" class="badge  badge-primary  mr-2" i18n>Shipped date</span>
                        <span *ngIf="date.is_completed" class="badge  badge-primary" i18n>Completed date</span>
                        <span *ngIf="date.is_invoiced" class="badge  badge-primary" i18n>Invoiced date</span>
                        <span *ngIf="date.is_production" class="badge  badge-primary" i18n>Production date</span>
                    </td>
                    <td>
                        <span *ngIf="date.is_editable" i18n>Yes</span>
                        <span *ngIf="!date.is_editable" i18n>No</span>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(date)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(date)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>
</div>
