<div class="TenFour-Details-Order">
    <app-loader [useOverlayLoader]="true" *ngIf="loading"></app-loader>
    <div class="row">
        <div class="col  mb-4">
            <div class="data-board" *ngIf="primaryOptions && secondaryOptions">
                <div class="row">
                    <div class="col-12  col-lg-4  col-xl-6">
                        <button *ngFor="let item of primaryOptions" class="btn  btn-sm  btn-secondary  ml-1  mb-1" (click)="item.onClick()" [ngbTooltip]="item.text" [ngStyle]="{color: item.color ? item.color : '#FFF'}">
                            <fa-icon [icon]="item.option"></fa-icon>
                        </button>
                    </div>
                    <div class="col-12  col-lg-8  col-xl-6  text-lg-right">
                        <button *ngFor="let item of secondaryOptions" class="btn  btn-sm  btn-secondary  ml-1  mb-1" (click)="item.onClick()" [ngbTooltip]="item.text" [ngStyle]="{color: item.color ? item.color : '#FFF'}">
                            <fa-icon [icon]="item.option"></fa-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="showChangeClientComponent" class="row  mt-2">
                    <div class="col-12">
                        <div class="data-board">
                            <app-change-order-client (didChangeClient)="updateClient($event)"></app-change-order-client>
                        </div>
                    </div>
                </div>

                <div *ngIf="showChangeDeliveryContactComponent" class="row  mt-2">
                    <div class="col-12">
                        <div class="data-board">
                            <app-change-order-delivery-contact (didChangeDeliveryContact)="closeDeliveryContactComponent($event)"></app-change-order-delivery-contact>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="data-board">
        <app-info-and-delivery-address-inputs></app-info-and-delivery-address-inputs>
    </div>
</div>
