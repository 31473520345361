import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelmsProductionLinesRoutingModule } from './helms-production-lines-routing.module';
import { ProductionLinesComponent } from './production-lines/production-lines.component';
import { ProductionLineSlotsComponent } from './production-line-slots/production-line-slots.component';
import {NgbAccordionModule, NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    ProductionLinesComponent,
    ProductionLineSlotsComponent
  ],
    imports: [
        CommonModule,
        HelmsProductionLinesRoutingModule,
        NgbAccordionModule,
        FontAwesomeModule,
        FormsModule,
        NgbDatepickerModule,
    ]
})
export class HelmsProductionLinesModule { }
