import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {Reference} from "../services/reference";
import {ReferencesService} from "../services/references.service";

@Component({
    selector: 'app-reference-edit-modal',
    templateUrl: './reference-edit-modal.component.html',
    styleUrls: ['./reference-edit-modal.component.scss'],
    providers: [ReferencesService]
})
export class ReferenceEditModalComponent implements OnInit {

    public reference: Reference;
    public _reference: Reference;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public referencesService: ReferencesService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._reference = this.reference ? Reference.fromJSON(this.reference) : Reference.fromJSON({});
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.reference) {
            this.referencesService.updateReference(this._reference).subscribe(reference => {
                this.saved();

                this.saving = false;

                this.activeModal.close(reference);
            });
        } else {
            this.referencesService.addReference(this._reference).subscribe(reference => {
                this.saved();

                this.saving = false;

                this.activeModal.close(reference);
            });
        }
    }
}
