import {Injectable} from '@angular/core';
import {Reservation} from './reservation';
import {Observable} from 'rxjs';
import {ApiService} from "../../api.service";
import {environment} from "../../../../environments/environment";

@Injectable()
export class ReservationsService {

    protected url = environment.apiBase + 'reservations';

    constructor(public apiService: ApiService) {
    }

    getReservation(id: string): Observable<{data: Reservation, count: number, perPage: number}> {
        return this.apiService.get<Reservation>(this.url + '/' + id, true, Reservation);
    }

    getReservations(page: number, limit: number, query?: ReservationsQueryInterface): Observable<{data: Reservation[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            parameters.push('limit=' + limit);
        }

        if (query && query.from) {
            parameters.push('from=' + query.from);
        }

        if (query && query.to) {
            parameters.push('to=' + query.to);
        }

        if (query && query.forOrder) {
            parameters.push('for-order=' + query.forOrder);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<Reservation[]>(url, true, Reservation);
    }

    addReservation(reservation: Reservation): Observable<{data: Reservation, count: number, perPage: number}> {
        return this.apiService.post<Reservation>(this.url, reservation, true, Reservation);
    }

    updateReservation(reservation: Reservation): Observable<{data: Reservation, count: number, perPage: number}> {
        return this.apiService.patch<Reservation>(this.url + '/' + reservation.id, reservation, true, Reservation);
    }

    deleteReservation(reservation: Reservation): Observable<{data: Reservation, count: number, perPage: number}> {
        return this.apiService.delete<Reservation>(this.url + '/' + reservation.id, true, Reservation);
    }
}

export interface ReservationsQueryInterface {
    from?: string;
    to?: string;
    forOrder?: string;
}
