import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, convertToParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {Token} from "./token";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Group} from "./group";
import {UsersService} from "./users.service";
import {TokenService} from "./token.service";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "./user";

@Injectable()
export class AuthService implements CanActivate {

    public isImpersonating = false;
    public impersonation_name: any;

    availablePermissions = [
        'admin', 'creditors', 'debtors', 'products_read', 'branch_admin'
    ];

    authedPermissions = [];

    private authedPermissionsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public currentUser: User;

    public authedPermissionsDataStream(): Observable<any[]> {
        return this.authedPermissionsSubject.asObservable();
    }

    public postAuthedPermissions(event: any[]): void {
        this.authedPermissionsSubject.next(event);
    }

    constructor(public http: HttpClient,
                public router: Router,
                public usersService: UsersService,
                public tokenService: TokenService) {
        /**
         * Get user permissions
         */
        let token = this.tokenService.getToken();

        if (token) {
            this.getAuthedPermissions(this.tokenService.getToken());
            if (localStorage.getItem('_user_token')) {
                this.isImpersonating = true;
                this.impersonation_name = localStorage.getItem('impersonation_name');
            }
        }
    }

    authenticateUser(email: string, password: string, two_factor_authentication_password: string) {
        let body = {
            email: email,
            password: password
        };

        if (two_factor_authentication_password) {
            body['two_factor_authentication_password'] = two_factor_authentication_password;
        }

        return this.http.post(environment.apiBase + 'login', body, {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        }).toPromise().then((response) => {
            this.login(response['data']);
        }).catch(AuthService.handleError);
    }

    login(response): Token {
        let token = Token.fromJSON(response);

        /**
         * Set token in local storage
         */
        localStorage.setItem('user_token', token.getToken());

        /**
         * Get user permissions
         */
        this.getAuthedPermissions(token);

        /**
         * Return token
         */
        return token;
    }

    logout(returnUrl?: string) {
        localStorage.removeItem('user_token');

        this.authedPermissions = [];
        this.postAuthedPermissions(this.authedPermissions);
        this.router.navigate(['login'], {queryParams: {returnUrl: returnUrl}});
    }

    restoreToken() {
        localStorage.setItem('user_token', localStorage.getItem('_user_token'));
        localStorage.removeItem('_user_token');
    }

    loggedIn(): boolean {
        let token = this.tokenService.getToken();

        return !!token;
    }

    public static handleError(error: any): Promise<any> {
        if (!environment.production) {
            console.error('An error occurred', error);
        }
        return Promise.reject(error);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const token = convertToParamMap(route.queryParams).get('token');
        if (token) {
            this.tokenService.saveTokenString(token);
        }

        if (!this.loggedIn()) {
            this.logout(state.url);
            return false;
        }
        return true;
    }

    public hasPermission(permission) {
        let index = this.authedPermissions.findIndex((element) => {
            return element['permission'] === permission;
        });

        return index >= 0;
    }

    public hasAvailablePermissions(group: Group): boolean {
        let availablePermissionsCount = 0;

        for (let permission of this.availablePermissions) {
            if (!group.hasPermission(permission)) {
                availablePermissionsCount++;
            }
        }

        return availablePermissionsCount > 0;
    }

    public getAuthedPermissions(token: Token) {
        const id = token.getUserId();

        /**
         * If the user ID is not found in the token, log out the user - Could be corrupt token
         */
        if (!token.getUserId()) {
            this.logout();
            return;
        }

        this.usersService.getUser(id, true).subscribe(response => {
            this.currentUser = response.data;
            this.authedPermissions = response.data.group['group_permissions'];
            this.postAuthedPermissions(this.authedPermissions);
        });
    }
}
