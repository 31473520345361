import {Component, Input, OnInit} from '@angular/core';
import {LanguagesService} from '../services/languages.service';

@Component({
    selector: 'app-language-indicator',
    templateUrl: './language-indicator.component.html',
    styleUrls: ['./language-indicator.component.scss']
})
export class LanguageIndicatorComponent implements OnInit {

    @Input() isLocalized = true;

    constructor(public languagesService: LanguagesService) {
    }

    ngOnInit(): void {

    }

}
