import {Component, OnInit} from '@angular/core';
import {ProductField} from "../services/product-field";
import {ProductFieldsService} from "../services/product-fields.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProductFieldEditModalComponent} from "../product-field-edit-modal/product-field-edit-modal.component";
import {LanguagesService} from "../services/languages.service";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-product-fields',
    templateUrl: './product-fields.component.html',
    styleUrls: ['./product-fields.component.sass'],
    providers: [ProductFieldsService, LanguagesService]
})
export class ProductFieldsComponent implements OnInit {

    productFields: ProductField[];

    loading = false;
    faExclamation = faExclamationCircle;
    missingLocalizations = [];

    constructor(public productFieldsService: ProductFieldsService,
                public ngbModal: NgbModal,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this.languagesService.getLanguages().subscribe(() => {
            this.load();
        });
    }

    load() {
        this.loading = true;

        this.productFieldsService.getProductFields(null, null).subscribe(response => {
            this.productFields = response.data;
            this.productFields.forEach((productField) => {
                this.missingLocalizations.push(productField._translations.checkMissingLocalizations(this.languagesService.languages));
            });

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(ProductFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(productField: ProductField, index: number) {
        let self = this;

        const modalRef = this.ngbModal.open(ProductFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productField = productField;
        modalRef.componentInstance.missingLocalizations = this.missingLocalizations[index];
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(productField: ProductField) {
        if (confirm($localize `Are you sure?\n\nThe product field will be deleted`)) {
            this.loading = true;

            this.productFieldsService.deleteProductField(productField).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }
}
