<ng-container *ngIf="offerService.readyToolExists || standalone">
    <p>Følgende maskiner er allerede bestilt eller på lager og kan leveres hurtigere:</p>

    <ng-container *ngFor="let orderGroup of orderGroups">
        <h3>{{orderGroup.name}}</h3>

        <app-table *ngIf="orderGroup.orders">
            <thead>
            <th></th>
            <th>
                <a class="table-header-link" (click)="changeFilter(1,'sort-by-reference', idValuesService.tmtNumberId, 'numeric', true)" i18n>TMT</a>
                <fa-icon *ngIf="filter.sortValue !== idValuesService.tmtNumberId" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.tmtNumberId && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.tmtNumberId && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th>
                <a i18n class="table-header-link" (click)="changeFilter(1, 'sort-by-machine-field', 'name', '', true, idValuesService.productMachineCategoryId)" i18n>Model/ Udstyr</a>
                <fa-icon *ngIf="filter.sortValue !==  'name'" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'name' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'name' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
                </th>
            <th>
                <a class="table-header-link" (click)="changeFilter(1, 'sort-by-date', idValuesService.productionDateId, null, true)" i18n>Prod. uge</a>
                <fa-icon *ngIf="filter.sortValue !== idValuesService.productionDateId" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.productionDateId && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.productionDateId && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th>Lok.</th>
            <th class="text-right">
                <a class="table-header-link" (click)="changeFilter(page, 'sort-by-field','total', null, true)" i18n>Udsalgspris i DKK</a>
                <fa-icon *ngIf="filter.sortValue !== 'total'" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'total' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'total' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
                </th>
            </thead>
            <tbody>
            <tr *ngFor="let order of orderGroup.orders">
                <td>
                    <span *ngIf="offerService.selectedOrder && order.id ===  offerService.selectedOrder.id">Valgt maskine</span>

                    <!-- @todo move into a reservation component -->
                    <ng-container *ngIf="order.reservations && order.reservations.length>0">
                        <ng-container *ngFor="let reservation of order.reservations">
                            <!--                            {{reservation|json}}-->
                            Reserveret til {{reservation.end.format('DD/MM/YYYY hh:mm')}}
                            <fa-icon *ngIf="reservation.debtor" class="ml-1"
                                     [icon]="faInfoCircle" [ngbTooltip]="reservation.debtor.name" [triggers]="'hover focus'"></fa-icon>

                            <ng-container *ngIf="reservation.allow_override">
                                <button class="btn  btn-sm  btn-primary  btn-block  text-uppercase" (click)="pickStock(order)"
                                        *ngIf="!offerService.selectedOrder || order.id !== offerService.selectedOrder.id">
                                    <small>Vælg</small></button>

                                <a href="javascript:0" (click)="endReservation(reservation)" i18n>End reservation</a>

                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!order.reservations || order.reservations.length === 0">
                        <a href="javascript:0" (click)="startReserveration(order)"
                           *ngIf="order.order_state_id === idValuesService.pipelineOrderStateId || order.order_state_id === idValuesService.inventoryOrderStateId"
                           i18n>Reserve</a>

                        <button class="btn  btn-sm  btn-primary  btn-block  text-uppercase" (click)="pickStock(order)"
                                *ngIf="!offerService.selectedOrder || order.id !== offerService.selectedOrder.id">
                            <small>Vælg</small></button>
                    </ng-container>
                </td>
                <td>
                    <span>{{ order.getReference(idValuesService.tmtNumberId) }} <span *ngIf="order?.getFieldValueForId(idValuesService.hiddenFieldValueId) == 1">!</span></span>
                    <span class="badge  badge-danger" *ngIf="order.getFieldValueForId(this.idValuesService.demoFieldValueId) ==1">Demo</span>
                </td>
                <td>
                    <ul *ngIf="order.order_lines">
                        <li *ngFor="let orderLine of order.order_lines">
                            {{orderLine.product.name}}
                        </li>
                    </ul>
                    <p>
                        {{order.getFieldValueForId(this.idValuesService.memoExternFieldValueId)}}
                    </p>
                    <p *ngIf="authService.hasPermission('admin')">
                        {{order.getFieldValueForId(this.idValuesService.memoFieldValueId)}}
                    </p>
                </td>
                <td>
                    <ng-container *ngIf="order.getDate('is_production') && order.order_state_id === idValuesService.pipelineOrderStateId">
                        Uge {{ order.getDate('is_production')._joinData.time.isoWeek()|json }}
                        {{ order.getDate('is_production')._joinData.time.isoWeekYear()|json }}
                    </ng-container>
                    <ng-container *ngIf="order.order_state_id !== idValuesService.pipelineOrderStateId">
                        Lager
                    </ng-container>
                </td>
                <td>
                    {{order.getFieldValueForId(this.idValuesService.locationNameFieldValueId)}}
                </td>
                <td class="text-right">{{order.getTotalUnadjusted()|currency:'DKK'}}
                    <span *ngIf="order.getFieldValueForId(idValuesService.conditionDiscountFieldValueId)">
                        <br/>
                        {{order.getFieldValueForId(idValuesService.conditionFieldValueId)}}rabat
                        {{order.getFieldValueForId(idValuesService.conditionDiscountFieldValueId) | currency:'DKK'}}
                    </span>
                </td>
            </tr>
            </tbody>
        </app-table>


    </ng-container>
    <app-loader *ngIf="orderGroups === undefined"></app-loader>
    <p *ngIf="!standalone">Alternativt kan du selv <a href (click)="switchMode(); $event.preventDefault()">sammensætte
        maskine til senere levering</a> -
        <ng-container *ngFor="let productionLine of offerService?.selectedTool?.product?.production_lines">
            <span *ngIf="productionLine.open_from && productionLine.open_from.isValid()"><a routerLink="/production-lines"><ng-container
                i18n>Week</ng-container>
                {{ productionLine.open_from.isoWeek() }} - {{ productionLine.open_from.isoWeekYear() }}</a></span>
            <span *ngIf="!productionLine.open_from || !productionLine.open_from.isValid()" class="text-muted">Ingen åbne produktions pladser</span>
        </ng-container>
    </p>

</ng-container>
