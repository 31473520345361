import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductCategoriesService} from "../services/product-categories.service";
import {Categorizable, ProductCategory} from "../services/product-category";

@Component({
    selector: 'app-product-category-select-modal',
    templateUrl: './product-category-select-modal.component.html',
    styleUrls: ['./product-category-select-modal.component.scss'],
    providers: [ProductCategoriesService]
})
export class ProductCategorySelectModalComponent implements OnInit {

    public categorizable: Categorizable;

    productCategories: ProductCategory[];

    public selected;

    constructor(public activeModal: NgbActiveModal,
                public productCategoriesService: ProductCategoriesService) {
    }

    ngOnInit() {
        this.productCategoriesService.getProductCategories(true, false).subscribe(response => {
            this.productCategories = response.data;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    select(productCategory: ProductCategory) {
        this.categorizable.addCategory(productCategory);

        this.selected(this.categorizable);

        this.activeModal.close();
    }
}
