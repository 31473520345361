import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {ArticlesService} from '../services/articles.service';
import {Article} from '../services/article';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss'],
    providers: [ArticlesService, AuthService]
})
export class ArticlesComponent implements OnInit {

    @Input() embedded: boolean;
    articles: Article[];

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    constructor(public articlesService: ArticlesService,
                public authService: AuthService) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number): void {
        this.loading = true;
        this.articlesService.getArticles(
            page,
            this.limit,
        ).subscribe(response => {
            this.articles = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    pageChange(page: number): void {
        this.load(page);
    }

    deleteArticle(article: Article): void {
        if (confirm($localize`Are you sure?\n\nThe article will be deleted`)) {
            this.loading = true;
            this.articlesService.deleteArticle(article).subscribe((response) => {
                this.load(this.page);
            });
        }
    }

    updateArticle(article: Article): void {
        this.loading = true;
        article.is_active = !article.is_active;
        this.articlesService.updateArticle(article).subscribe((response) => {
            this.load(this.page);
        });
    }
}
