import * as dayjs from 'dayjs';
import {Branch} from "./branch";
import {Product} from "./product";

export class ProductStock {

    id: string;
    product_id: string;
    branch_id: string;
    location_reference: string;
    quantity: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    product: Product;
    branch: Branch;

    predicted: number;

    constructor() {
    }

    static fromJSON(json: ProductStockJSON | string): ProductStock {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductStock.reviver);
        } else {
            let productStock = Object.create(ProductStock.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product: null,
                branch: null
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'product' && json[key]) {
                    extra[key] = Product.fromJSON(json[key]);
                }

                if (key === 'branch' && json[key]) {
                    extra[key] = Branch.fromJSON(json[key]);
                }
            });

            return Object.assign(productStock, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductStock.fromJSON(value) : value;
    }
}

// A representation of ProductStock data that can be converted to
// and from JSON without being altered.
interface ProductStockJSON {
    id?: string;
    product_id?: string;
    branch_id?: string;
    location_reference?: string;
    quantity?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    branch?: Branch;

    predicted?: number;
}
