import {Injectable} from '@angular/core';
import {Group} from './group';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable()
export class GroupsService {

    protected url = environment.apiBase + 'groups';

    constructor(public apiService: ApiService) {
    }

    getGroup(id: string): Observable<{data: Group, count: number, perPage: number}> {
        return this.apiService.get<Group>(this.url + '/' + id, true, Group);
    }

    getGroups(withPermissions: boolean, withUsers: boolean): Observable<{data: Group[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        if (withPermissions) {
            parameters.push('with-permissions=1');
        }

        if (withUsers) {
            parameters.push('with-users=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<Group[]>(url, true, Group);
    }

    addGroup(group: Group): Observable<{data: Group, count: number, perPage: number}> {
        return this.apiService.post<Group>(this.url, group, true, Group);
    }

    updateGroup(group: Group): Observable<{data: Group, count: number, perPage: number}> {
        return this.apiService.patch<Group>(this.url + '/' + group.id, group, true, Group);
    }

    deleteGroup(group: Group): Observable<{data: Group, count: number, perPage: number}> {
        return this.apiService.delete<Group>(this.url + '/' + group.id, true, Group);
    }
}
