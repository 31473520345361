<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Payment agreements</ng-container>
        <small *ngIf="loading && paymentAgreements" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!paymentAgreements" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="paymentAgreements">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add payment agreement</a>
    </p>

    <app-table *ngIf="paymentAgreements" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th>Name</th>
                <th class="text-right" i18n>Agreement</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-paymentAgreement [ngForOf]="paymentAgreements">
                <tr>
                    <td>
                        {{ paymentAgreement.name }}
                        <span *ngIf="paymentAgreement.is_default" class="badge  badge-primary  mr-2" i18n>Default</span>
                    </td>
                    <td class="text-right">
                        <span *ngIf="paymentAgreement.days">{{ paymentAgreement.days }} <span i18n>days</span></span>
                        <span *ngIf="paymentAgreement.is_running_month" i18n>Running month</span>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(paymentAgreement)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(paymentAgreement)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>
</div>
