import {Component, OnInit} from '@angular/core';
import {ContactField} from "../services/contact-field";
import {ContactFieldsService} from "../services/contact-fields.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactFieldEditModalComponent} from "../contact-field-edit-modal/contact-field-edit-modal.component";

@Component({
    selector: 'app-contact-fields',
    templateUrl: './contact-fields.component.html',
    styleUrls: ['./contact-fields.component.sass'],
    providers: [ContactFieldsService]
})
export class ContactFieldsComponent implements OnInit {

    contactFields: ContactField[];

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    constructor(public contactFieldsService: ContactFieldsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number) {
        this.loading = true;

        this.contactFieldsService.getContactFields(page, this.limit).subscribe(response => {
            this.contactFields = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(ContactFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load(self.page);
        }
    }

    edit(contactField: ContactField) {
        let self = this;

        const modalRef = this.ngbModal.open(ContactFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.contactField = contactField;
        modalRef.componentInstance.saved = function () {
            self.load(self.page);
        }
    }

    delete(contactField: ContactField) {
        if (confirm($localize `Are you sure?\n\nThe contact field will be deleted`)) {
            this.loading = true;

            this.contactFieldsService.deleteContactField(contactField).subscribe(_ => {
                this.loading = false;

                this.load(this.page);
            });
        }
    }

    pageChange(page: number) {
        this.load(page)
    }
}
