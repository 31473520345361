import {Component, Input, OnInit} from '@angular/core';
import {OfferGeneratorService} from '../../services/offer-generator.service';
import {Product} from '../../services/product';
import {OrderLine} from '../../services/order-line';
import {ProductsService} from '../../services/products.service';
import {Router} from '@angular/router';
import {ProductRelationsGroupsService} from '../../services/product-relations-groups.service';
import {ProductRelationsGroup} from '../../services/product-relations-group';
import {forkJoin} from 'rxjs';
import {faGears, faCheck} from '@fortawesome/free-solid-svg-icons';
import {IdValuesService} from "../../services/themes/helms/id-values.service";
import {faInfoCircle, faSortUp, faSortDown, faSort} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-custom-order',
    templateUrl: './custom-order.component.html',
    styleUrls: ['./custom-order.component.scss'],
    providers: [ProductsService, ProductRelationsGroupsService]

})
export class CustomOrderComponent implements OnInit {

    @Input() extras: Product[];
    groups: ProductRelationsGroup[];
    faGears = faGears;
    faCheck = faCheck;
    public faInfoCircle = faInfoCircle;


    constructor(
        public idValuesService: IdValuesService,
        private router: Router,
        public productsService: ProductsService,
        public offerService: OfferGeneratorService,
        public productRelationsGroupsService: ProductRelationsGroupsService) {
        this.offerService.pausenext = false;
    }

    ngOnInit(): void {

        if (this.offerService.selectedOrderId !== undefined) {
            this.offerService.selectedOrderId = null;
        }

        if (!this.offerService.selectedTool) {
            this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['tool-orders']}}]);
        } else {
            forkJoin([
                this.productsService.getProducts(1, -1, false, false, true, false, false, false, {
                    byCategories: '0ac87919-fda3-4ed6-8ff6-897b1244111a', // udstyr
                    withProductRelations: false,
                    withProductStocks: true,
                    withAdjustedPrices: true,
                    withProductFields: true,
                    byParentRelation: this.offerService.selectedTool.product.id
                }),
                this.productRelationsGroupsService.getGroupsForProduct(this.offerService.selectedTool.product, true)
            ]).subscribe(joinData => {
                this.extras = joinData[0].data;
                this.groups = joinData[1].data;

                /**
                 * Filter the products in groups out of general list
                 */
                // tslint:disable-next-line:variable-name
                const _extras = [];
                _extras.push(...this.extras);
                let i = 0;
                this.extras.forEach((extra) => {
                    this.groups.forEach((group) => {
                        group.product_relations_product_relations_groups.forEach((relation) => {
                            if (relation.product_relation.related_product_id === extra.id) {
                                _extras.splice(i, 1);
                                i--;
                            }
                        });
                    });
                    i++;
                });
                this.extras = [];
                this.extras.push(..._extras);

                /**
                 * Select standard and move them to top @todo disabled for now ?
                 */
                //     // tslint:disable-next-line:variable-name
                // _extras = [];
                // _extras.push(...this.extras);
                //
                i = 0;
                this.extras.forEach((extra) => {
                    if (extra.product_relation_parents[0].is_standard) {
                        // _extras.splice(i, 1);
                        // _extras.unshift(extra);
                        if (!this.checkIfExtraIsSelected(extra)) {
                            this.onClick(extra);
                        }
                    }
                    i++;
                });
                // this.extras = [];
                // this.extras.push(..._extras);

                // @todo is debtor id available in this step?
                // @todo with-adjusted-prices send debtor id
            });
        }
        // this.extras = this.offerService.selectedTool.product.product_relation_childrens.filter((el) => {
        //     return el.product.hasCategory({id: ''});
        // });
    }

    onClick(item: Product): void {
        if (this.checkIfExtraIsSelected(item)) {
            const index = this.offerService.selectedOptions.findIndex((el) => {
                return el.product_id === item.id;
            });
            if (index > -1) {
                this.offerService.selectedOptions.splice(index, 1);
            }
        } else {
            if (!this.offerService.selectedOptions) {
                this.offerService.selectedOptions = [];
            }
            const orderLine = new OrderLine();
            orderLine.product_id = item.id;
            orderLine.quantity = 1;
            orderLine.product = item;
            orderLine.price = item.price;
            orderLine.price_adjusted = item.adjusted_price;
            orderLine.price_adjustment_name = item.adjusted_name;

            this.offerService.selectedOptions.push(orderLine);
        }
    }

    onExclusiveClick(item: Product, group: ProductRelationsGroup): void {
        if (this.checkIfExtraIsSelected(item)) {
            const index = this.offerService.selectedOptions.findIndex((el) => {
                return el.product_id === item.id;
            });
            if (index > -1) {
                this.offerService.selectedOptions.splice(index, 1);
            }
        } else {
            /**
             * Remove other group products from selectedOptions
             */
            let i = 0;
            this.offerService.selectedOptions.forEach((el) => {
                group.product_relations_product_relations_groups.forEach((relation) => {
                    if (el.product_id === relation.product_relation.related_product_id) {
                        this.offerService.selectedOptions.splice(i, 1);
                    }
                });
                i++;
            });

            if (!this.offerService.selectedOptions) {
                this.offerService.selectedOptions = [];
            }
            const orderLine = new OrderLine();
            orderLine.product_id = item.id;
            orderLine.quantity = 1;
            orderLine.product = item;
            orderLine.price = item.price;
            orderLine.price_adjusted = item.adjusted_price;
            orderLine.price_adjustment_name = item.adjusted_name;
            this.offerService.selectedOptions.push(orderLine);
        }
    }

    onDependentClick(group: ProductRelationsGroup): void {
        if (this.checkIfExtraIsSelected(group.product_relations_product_relations_groups[0].product_relation.related_product)) {
            group.product_relations_product_relations_groups.forEach((relation) => {
                const index = this.offerService.selectedOptions.findIndex((el) => {
                    return el.product_id === relation.product_relation.related_product_id;
                });
                if (index > -1) {
                    this.offerService.selectedOptions.splice(index, 1);
                }
            });

        } else {
            if (!this.offerService.selectedOptions) {
                this.offerService.selectedOptions = [];
            }
            group.product_relations_product_relations_groups.forEach((relation) => {
                const item = relation.product_relation.related_product;
                const orderLine = new OrderLine();
                orderLine.product_id = item.id;
                orderLine.quantity = 1;
                orderLine.product = item;
                orderLine.price = item.price;
                orderLine.price_adjusted = item.adjusted_price;
                orderLine.price_adjustment_name = item.adjusted_name;
                this.offerService.selectedOptions.push(orderLine);
            });
        }
    }

    checkIfExtraIsSelected(item: Product): boolean {

        return !!(this.offerService.selectedOptions && this.offerService.selectedOptions.find((el) => {
            return el.product_id === item.id;
        }));
    }

    switchMode(): void {
        this.offerService.selectedOrderId = null;
        this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['ready-orders']}}]);
    }
}
