import {Component, OnInit} from '@angular/core';
import {faCalendarCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {NgbCalendar, NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../../../services/order';
import {OrdersService} from '../../../services/orders.service';
import {OrderLine} from '../../../services/order-line';
import {IdValuesService} from '../../../services/themes/helms/id-values.service';
import {ReservationsService} from '../../../services/themes/helms/reservations.service';
import {Reservation} from '../../../services/themes/helms/reservation';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import {MachinesService} from "../../../services/themes/helms/machines.service";

@Component({
    selector: 'app-helms-demos',
    templateUrl: './helms-demos.component.html',
    styles: [`
        .custom-day {
            text-align: center;
            padding: 0.185rem 0.25rem;
            display: inline-block;
            height: 2rem;
            width: 2rem;
        }
        .custom-day.focused {
            background-color: #e6e6e6;
        }
        .custom-day.range, .custom-day:hover {
            background-color: rgb(227, 23, 54);
            color: white;
        }
        .custom-day.faded {
            background-color: #800000;
        }
    `],
    styleUrls: ['./helms-demos.component.scss'],
    providers: [OrdersService, ReservationsService, MachinesService]
})
export class HelmsDemosComponent implements OnInit {

    hoveredDate: NgbDate | null = null;

    fromDate: NgbDate;
    toDate: NgbDate | null = null;
    disableSubmit = false;

    faCalendarCheck = faCalendarCheck;
    faTimes = faTimes;

    orders: Order[];
    loading = false;
    page = 1;
    limit = 25;
    count;
    perPage;

    selectedOrder: Order;

    reservations: Reservation[];

    isDateBetweenReservations = (date: NgbDate, current): boolean => {
        dayjs.extend(isBetween);
        const checkThisDate = dayjs(date.year + '-' + date.month + '-' + date.day);
        let hasOverlap = false;
        this.reservations.forEach((r) => {
            if (checkThisDate.isBetween(dayjs(r.start), dayjs(r.end), 'day', '[]')){
                hasOverlap = true;
            }
        });
        return hasOverlap;
    }

    constructor(public calendar: NgbCalendar,
                public modalService: NgbModal,
                public ordersService: OrdersService,
                public idValuesService: IdValuesService,
                public reservationsService: ReservationsService,
                public machinesService: MachinesService) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number): void {
        this.loading = true;

        this.ordersService.getOrders(page, this.limit, true, false, false, false, false, true, false, false, {
            forOrderLineFields: 'eb706407-1239-492f-8fcf-405cb5b7d76a'
        }).subscribe(response => {
            this.orders = response.data;
            this.count = response.count;
            this.perPage = response.perPage;
            this.loading = false;
        });
    }

    pageChange(page: number): void {
        this.load(page);
    }

    onDateSelection(date: NgbDate): void {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    isHovered(date: NgbDate): boolean {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate): boolean {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate): boolean {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    openModal(content, order: Order): void {
        this.selectedOrder = order;
        this.reservations = undefined;

        this.reservationsService.getReservations(1, 100, {forOrder: this.selectedOrder.id}).subscribe(response => {
            this.reservations = response.data;
        });

        this.modalService.open(content, {}).result.then((_) => {
        });
    }

    reserve(): void {
        const reservation = Reservation.fromJSON({
            debtor_id: '0', // @todo
            order_id: this.selectedOrder.id,
            start: dayjs(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day),
            end: dayjs(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day),
        });

        this.reservationsService.addReservation(reservation).subscribe(response => {
            this.reservations = undefined;

            this.reservationsService.getReservations(1, 100, {forOrder: this.selectedOrder.id}).subscribe(response => {
                this.reservations = response.data;
            });
        });
    }
}
