<table class="table  table-bordered  table-sm  mb-0">
    <tr>
        <td>Ordre Dato:</td>
        <td><strong>{{ order.getDate('is_creation') ? order.getDate('is_creation')._joinData.time.format('YYYY-MM-DD') : '-' }}</strong></td>
    </tr>
    <tr>
        <td>Leverings Dato:</td>
        <td><strong>{{ order.getDate('is_shipping') ? order.getDate('is_shipping')._joinData.time.format('YYYY-MM-DD') : '-' }}</strong></td>
    </tr>
    <tr>
        <td>Forfalds Dato:</td>
        <td><strong>{{ order.getDate('is_due') ? order.getDate('is_due')._joinData.time.format('YYYY-MM-DD') : '-' }}</strong></td>
    </tr>
    <tr>
        <td>Rekvisions Nr:</td>
        <td><strong>{{ order.getReference('b73d3bf9-937f-4957-be53-6755e57883cc') ? order.getReference('b73d3bf9-937f-4957-be53-6755e57883cc') : '-' }}</strong></td>
    </tr>
    <tr>
        <td>EAN Nr:</td>
        <td><strong>{{ order.getReference('94396a46-6686-47de-9be2-c15286050228') ? order.getReference('94396a46-6686-47de-9be2-c15286050228') : '-' }}</strong></td>
    </tr>
    <tr>
        <td>Betaling via bon</td>
        <td class="bg-danger"></td>
    </tr>
    <tr>
        <td>Saldo</td>
        <td>
            <span *ngIf="!creditor && !debtor" class="text-muted">Indlæser...</span>
            <strong *ngIf="order.isCreditor() && creditor">{{ creditor.balance | currency:'DKK':'':null }}</strong>
            <strong *ngIf="!order.isCreditor() && debtor">{{ debtor.balance | currency:'DKK':'':null }}</strong>
        </td>
    </tr>
</table>
