import {Component, OnInit} from '@angular/core';
import {OfferGeneratorService} from '../../services/offer-generator.service';
import {Router} from '@angular/router';
import {ProductsQueryInterface, ProductsService} from '../../services/products.service';
import {Product} from '../../services/product';
import {IdValuesService} from '../../services/themes/helms/id-values.service';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {faCamera} from '@fortawesome/free-solid-svg-icons';
import {MachinesService} from "../../services/themes/helms/machines.service";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";

@Component({
    selector: 'app-tool-orders',
    templateUrl: './tool-orders.component.html',
    styleUrls: ['./tool-orders.component.scss'],
    providers: [ProductsService, IdValuesService, MachinesService]
})
export class ToolOrdersComponent implements OnInit {

    private authedPermissions: Observable<any[]>;

    isAdmin = false;

    tools: Product[];
    public loading = false;
    activeThumbnail: any;
    faCamera = faCamera;

    constructor(public offerService: OfferGeneratorService,
                public productsService: ProductsService,
                public idValuesService: IdValuesService,
                public machinesService: MachinesService,
                public authService: AuthService,
    ) {
        this.updateNextStatus();
        this.authedPermissions = this.authService.authedPermissionsDataStream();
    }

    updateNextStatus() {
        if (!this.offerService.selectedTool) {
            this.offerService.pausenext = true;
        } else {
            this.offerService.pausenext = false;
        }
    }

    ngOnInit(): void {
        this.authedPermissions.subscribe((_) => {
            this.isAdmin = this.authService.hasPermission('admin');

            this.load();
        });

        this.offerService.updates$.subscribe(response => {
            if (response) {
                this.updateNextStatus();
            }
        });
    }

    load(): void {
        this.loading = true;

        const query: ProductsQueryInterface = {
            byCategories: this.idValuesService.productMachineCategoryId,
            withProductRelations: false,
            withProductStocks: true,
            withAdjustedPrices: true,
            withProductFields: true,
            withAttachments: true
        };

        if (this.isAdmin) {
            query.onlyActiveIgnoringStart = true;
        } else {
            query.onlyActive = true;
        }

        this.productsService.getProducts(1, -1, false, false, true, false, false, false, query).subscribe(response => {
            this.tools = response.data;
            this.loading = false;
        });
    }

    onToolClick(tool: Product): void {

        this.loading = true;
        this.productsService.getProduct(tool.id, true, false, true, true, true, true, null,
            true, true, true, true,
            {
                byCategories: this.idValuesService.productMachineCategoryId,
                withProductStocks: true,
                withAdjustedPrices: true
            }).subscribe(response => {
            // load complete machine
            this.offerService.changeSelectedTool(response.data);
            this.loading = false;
            this.offerService.nextStep();
        });

    }

    onThumbnailClick(event, tooltip: NgbTooltip, product: Product): void {
        event.stopPropagation();
        if (product.getThumbnail('product_list_preview')) {
            this.activeThumbnail = product.getThumbnail('product_list_preview');
        }
        if (tooltip.isOpen()) {
            tooltip.close();
        } else {
            tooltip.open();
        }
    }
}
