import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ProductField} from "./product-field";

@Injectable()
export class ProductFieldsService {

    protected url = environment.apiBase + 'product-fields';

    constructor(public apiService: ApiService) {
    }

    getProductField(id: string): Observable<{data: ProductField, count: number, perPage: number}> {
        return this.apiService.get<ProductField>(this.url + '/' + id, true, ProductField);
    }

    getProductFields(forProduct: string, forProductCategories: string[], all?:boolean): Observable<{data: ProductField[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        if (forProduct) {
            parameters.push('for-product=' + forProduct);
        }

        if (forProductCategories) {
            parameters.push('for-product-categories=' + forProductCategories.join(','));
        }

        if (all) {
            parameters.push('all=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<ProductField[]>(url, true, ProductField);
    }

    addProductField(productField: ProductField): Observable<{data: ProductField, count: number, perPage: number}> {
        return this.apiService.post<ProductField>(this.url, productField, true, ProductField);
    }

    updateProductField(productField: ProductField): Observable<{data: ProductField, count: number, perPage: number}> {
        return this.apiService.patch<ProductField>(this.url + '/' + productField.id, productField, true, ProductField);
    }

    deleteProductField(productField: ProductField): Observable<{data: ProductField, count: number, perPage: number}> {
        return this.apiService.delete<ProductField>(this.url + '/' + productField.id, true, ProductField);
    }
}
