export class LocaleTranslation {
    locale: string;
    title: string;
    body: string;
    name: string;
    value: string;

    constructor() {
    }

    static fromJSON(json: LocaleTranslationJSON | string): LocaleTranslation {
        if (typeof json === 'string') {
            return JSON.parse(json, LocaleTranslation.reviver);
        } else {
            const localeTranslation = Object.create(LocaleTranslation.prototype);

            const extra = {
            };

            return Object.assign(localeTranslation, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? LocaleTranslation.fromJSON(value) : value;
    }
}
// A representation of LocaleTranslation data that can be converted to
// and from JSON without being altered.
interface LocaleTranslationJSON {
    locale?: string;
    title?: string;
    body?: string;
    name?: string;
    value?: string;
}
