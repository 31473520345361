<div class="products  pb-5">
    <h1 class="heading">
        <ng-container i18n>Deliveries</ng-container>
        <small *ngIf="loading && products" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!products || (loading && products)" [useOverlayLoader]="loading && products ? true : false" i18n>
        Loading...
    </app-loader>

    <app-table *ngIf="products" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <td></td>
            <td></td>
            <ng-container *ngFor="let field of productFieldPresets.product_fields">
                <th [ngClass]="{'text-right': field.type ==='number'}">{{field.name}}</th>
            </ng-container>
            <th></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-product [ngForOf]="products">
            <tr>
                <td>
                    <ng-template ngFor let-reference [ngForOf]="product.references">
                            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  ml-2">
                                {{ reference.name }}: {{ reference._joinData.value }}
                            </span>
                    </ng-template>
                </td>
                <td>
                    <span *ngIf="!product.creditor" class="text-muted" i18n>No creditor</span>
                    <span *ngIf="product.creditor">{{ product.creditor.name }}


                        <a routerLink="/custom/fields" [queryParams]="{creditorId: product.creditor.id}" i18n>Marker</a></span>
                </td>
                <ng-container *ngFor="let field of productFieldPresets.product_fields">
                    <td [ngClass]="{'text-right': field.type ==='number'}">
                        {{field.type === 'number' ? (product.getFieldValueForId(field.id)| number:'1.2-2':'da') : product.getFieldValueForId(field.id)}}
                    </td>
                </ng-container>

                <td class="text-right">
                    <ng-template ngFor let-productRelation [ngForOf]="product.product_relation_childrens">
                        <a [routerLink]="'/custom/analysis/' + productRelation.related_product_id"
                           *ngIf="productRelation.product.hasCategory({id: '00b99997-5e9b-4ee5-8f6c-75c19d2c8b73'})"
                           i18n>Analysis</a>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
        </tbody>

        <tfoot>

        <!-- @todo Do we have a better way to fetch sum and average cols from api -->
        <ng-template ngFor let-sumField [ngForOf]="sumFields">
            <tr style="border-top: 2px solid #000000!important;">
                <th>Sum:</th>
                <th></th>
                <ng-container *ngFor="let field of productFieldPresets.product_fields">
                    <th *ngIf="field.name ==='Hkg'" class="text-right">{{sumField.sum_hkg | number:'1.2-2':'da'}}</th>
                    <th *ngIf="field.name !=='Hkg'"></th>
                </ng-container>
                <th></th>
                <th></th>
            </tr>
        </ng-template>

        <ng-template ngFor let-sumField [ngForOf]="sumFields">
            <tr style="border-top: 2px solid #000000!important;">
                <th>Gennemsnit:</th>
                <th></th>
                <ng-container *ngFor="let field of productFieldPresets.product_fields">
                    <th *ngIf="field.name ==='Hkg'" class="text-right">{{sumField.avg_hkg | number:'1.2-2':'da'}}</th>
                    <th *ngIf="field.name !=='Hkg'"></th>
                </ng-container>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        </tfoot>

    </app-table>

    <ng-container *ngIf="products && count > products.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10"
                        [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'"
                        [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
