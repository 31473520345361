import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ProductRelation} from "../services/product-relation";
import {ProductRelationsService} from "../services/product-relations.service";

@Component({
    selector: 'app-product-relation-edit-modal',
    templateUrl: './product-relation-edit-modal.component.html',
    styleUrls: ['./product-relation-edit-modal.component.scss'],
    providers: [ProductRelationsService]
})
export class ProductRelationEditModalComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal,
        public productRelationsService: ProductRelationsService) {
    }

    @Input() productRelation: ProductRelation;
    @Input() delete;

    public saving = false;
    public saved;

    ngOnInit(): void {
        /*this._productRelation = this.productRelation ? ProductRelation.fromJSON(this.productRelation) : ProductRelation.fromJSON({
        });*/
    }

    close(): void {
        this.activeModal.dismiss();
    }

    removeFromGroup(index): void {
        // this._productRelationsGroup.product_relations.splice(index, 1);
    }

    addToGroup(productRelation: ProductRelation): void {
        // this._productRelationsGroup.product_relations.push(productRelation);
        // this._productChildRelations.splice(this._productChildRelations.indexOf(productRelation), 1);
    }

    deleteRelation(): void {
        this.delete(this.productRelation);
        this.activeModal.close();
    }

    public addAsFollowProduct(): void {
        this.productRelation.follow_requirement_minimum = 1;
        this.productRelation.follow_amount = 1;
    }
}
