import {Injectable} from '@angular/core';
import {PriceGroup} from './price-group';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable()
export class PriceGroupsService {

    protected url = environment.apiBase + 'price-groups';

    constructor(public apiService: ApiService) {
    }

    getPriceGroup(id: string): Observable<{ data: PriceGroup, count: number, perPage: number }> {
        return this.apiService.get<PriceGroup>(this.url + '/' + id, true, PriceGroup);
    }

    getPriceGroups(page: number, limit: number, query?: PriceGroupsQueryInterface): Observable<{ data: PriceGroup[], count: number, perPage: number }> {
        let url = this.url;

        const parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            if (limit === -1) {
                parameters.push('all=1');
            } else {
                parameters.push('limit=' + limit);
            }
        }

        if (query && query.withDebtors) {
            parameters.push('with-debtors=1');
        }

        if (query && query.withProducts) {
            parameters.push('with-products=1');
        }

        if (query && query.withCurrencies) {
            parameters.push('with-currencies=1');
        }

        if (query && query.forProductId) {
            parameters.push('for-product-id=' + query.forProductId);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<PriceGroup[]>(url, true, PriceGroup);
    }

    addPriceGroup(priceGroup: PriceGroup): Observable<{ data: PriceGroup, count: number, perPage: number }> {
        return this.apiService.post<PriceGroup>(this.url, priceGroup, true, PriceGroup);
    }

    updatePriceGroup(priceGroup: PriceGroup): Observable<{ data: PriceGroup, count: number, perPage: number }> {
        return this.apiService.patch<PriceGroup>(this.url + '/' + priceGroup.id, priceGroup, true, PriceGroup);
    }

    updatePriceGroups(priceGroups: PriceGroup[]): Observable<{ data: PriceGroup[], count: number, perPage: number }> {
        return this.apiService.patch<PriceGroup[]>(this.url, priceGroups, true, PriceGroup);
    }

    deletePriceGroup(priceGroup: PriceGroup): Observable<{ data: PriceGroup, count: number, perPage: number }> {
        return this.apiService.delete<PriceGroup>(this.url + '/' + priceGroup.id, true, PriceGroup);
    }
}

export interface PriceGroupsQueryInterface {
    forProductId?: string;
    withDebtors?: boolean;
    withProducts?: boolean;
    withCurrencies?: boolean;
}
