import * as dayjs from 'dayjs';
import {Component, Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-datepicker-adapter',
    template: ''
})
export class NgbdDatepickerAdapter {
}

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<dayjs.Dayjs> {

    fromModel(date: dayjs.Dayjs | null): NgbDateStruct | null {
        if (date) {
            return {
                day: date.date(),
                month: date.month() + 1,
                year: date.year()
            };
        }

        return null;
    }

    toModel(date: NgbDateStruct | null): dayjs.Dayjs | null {
        return date ? dayjs.utc(`${date.year}-${date.month}-${date.day}`) : null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day : parseInt(date[0], 10),
                month : parseInt(date[1], 10),
                year : parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
    }
}
