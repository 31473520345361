import {Component, OnInit} from '@angular/core';
import {
    faFile,
    faFilePdf,
    faDownload,
    IconDefinition,
    faFileImage,
    faFileWord, faFilePowerpoint
} from '@fortawesome/free-solid-svg-icons';
import {AttachmentsService} from '../../../services/attachments.service';
import {Attachment} from '../../../services/attachment';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-helms-filecenter',
    templateUrl: './helms-filecenter.component.html',
    styleUrls: ['./helms-filecenter.component.scss'],
    providers: [AttachmentsService]
})
export class HelmsFilecenterComponent implements OnInit {

    attachments: Attachment[];
    filteredAttachments: Attachment[] = [];
    faDownload = faDownload;
    loading = false;
    page = 1;
    limit = 25;
    count;
    perPage;

    constructor(public attachmentsService: AttachmentsService) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number): void {
        this.loading = true;
        this.attachmentsService.getAttachments(page, this.limit).subscribe(response => {
            this.attachments = response.data;
            this.filteredAttachments = this.attachments;
            this.count = response.count;
            this.perPage = response.perPage;
            this.loading = false;
        });
    }

    pageChange(page: number): void {
        this.load(page);
    }

    showIcon(type: string): IconDefinition {
        switch (type) {
            case 'application/pdf':
                return faFilePdf;
            case 'image/jpeg':
            case 'image/png':
                return faFileImage;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return faFileWord;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return faFilePowerpoint;
            default:
                return faFile;
        }
    }

    humanFileName(mimeType: string): string{
        switch (mimeType) {
            case 'application/pdf':
                return 'PDF';
            case 'image/jpeg':
                return 'JPG';
            case 'image/png':
                return 'PNG';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'Word Document';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'PowerPoint';
            default:
                return 'Fil';
        }
    }

    /**
     * @see https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
     * @param bytes
     * @param si
     * @param dp
     */
    humanFileSize(bytes, si= false, dp= 1): string {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    onSearchChange(val: string): void {
        if (val){
            this.filteredAttachments = this.attachments.filter((file) => {
               if (
                   file.description.includes(val) ||
                   file.model.includes(val) ||
                   this.humanFileName(file.type).includes(val) ||
                   file.language.includes(val)){
                   return file;
               }
            });
        } else {
            this.filteredAttachments = this.attachments;
        }
    }

    downloadFile(attachment: Attachment) {
        this.attachmentsService.downloadAttachment(attachment.id).subscribe(response => {
            FileSaver.saveAs(this.b64toBlob(response.data['file'], response.data['type']), attachment.name);
        });
    }

    b64toBlob(b64Data, contentType='', sliceSize=512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }
}
