import {Component, OnInit} from '@angular/core';
import {Product} from "../services/product";
import {ProductsQueryInterface, ProductsService} from "../services/products.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {faCalendarXmark, faXmark} from '@fortawesome/free-solid-svg-icons';
import {ProductCategoriesService} from "../services/product-categories.service";

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.sass'],
    providers: [ProductsService, ProductCategoriesService]
})
export class ProductsComponent implements OnInit {

    products: Product[];
    loading = false;
    searchProductsText: string;
    searchProductsSelect: string = 'product_reference';
    page = 1;
    limit = 25;
    count;
    perPage;
    active = true;
    filterActive = false;
    filterInactive = false;
    faCalendarXmark = faCalendarXmark;
    faXmark = faXmark;
    now: Date;
    filterQuery: ProductsQueryInterface = {};
    public productCategories: any[];
    productCategoryId: any;
    constructor(public productsService: ProductsService,
                public productCategoriesService: ProductCategoriesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.now = new Date();
        this.load(this.page);

        this.productCategoriesService.getProductCategoriesAsTreeList().subscribe(response => {
            const productCategories = [];

            for (const productCategoryId of Object.keys(response.data)) {
                productCategories.push({
                    id: productCategoryId,
                    name: response.data[productCategoryId]
                });
            }

            this.productCategories = productCategories;
        });

    }


    load(page: number): void {
        this.loading = true;

        const query: ProductsQueryInterface = {};

        if (this.searchProductsText && this.searchProductsText.length > 0) {
            switch (this.searchProductsSelect) {
                case 'product_name':
                    query.forProductName = this.searchProductsText;
                    break;

                case 'product_reference':
                    query.forProductReference = 'e79ff942-6260-4166-becc-bacda11b85e3'; // @todo Produkt Nummer
                    query.forProductReferenceValue = this.searchProductsText;
                    break;
            }
        }

        if (this.filterActive) {
            query.onlyActive = true;
        }

        if (this.filterInactive) {
            query.onlyInactive = true;
        }

        if (this.productCategoryId) {
            query.byCategories = this.productCategoryId;
        }

        this.productsService.getProducts(page, this.limit, true, false, true, true, true, false, query).subscribe(response => {
            this.products = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    delete(product: Product): void {
        const message = product.is_active
            ? $localize `Are you sure?\n\nThe product will be soft deleted\n\nThis action can be reversed`
            : $localize `Are you sure?\n\nThe product will be deleted\n\nThis action CANNOT be reversed`;
        if (confirm(message)) {
            this.loading = true;
            this.productsService.deleteProduct(product).subscribe(result => {
                this.loading = false;
                this.load(this.page);
            });
        }
    }

    pageChange(page: number): void {
        this.load(page);

        this.pushState();
    }

    pushState(): void {
        if (!this.page) {
            return;
        }

        history.pushState('', '', '/products?page=' + this.page);
    }

    refreshList(): void {
        // Toggle
        this.active = !this.active;
        this.loading = true;

        if (this.active) {
            this.load(1);
        } else {
            const query: ProductsQueryInterface = {};

            if (this.searchProductsText && this.searchProductsText.length > 0) {
                switch (this.searchProductsSelect) {
                    case 'product_name':
                        query.forProductName = this.searchProductsText;
                        break;

                    case 'product_reference':
                        query.forProductReference = 'e79ff942-6260-4166-becc-bacda11b85e3'; // @todo Produkt Nummer
                        query.forProductReferenceValue = this.searchProductsText;
                        break;
                }
            }
            query.onlyInactive = true;

            this.productsService.getProducts(1, this.limit, true, false, true, true, true, false, query).subscribe(response => {
                this.products = response.data;

                this.count = response.count;
                this.perPage = response.perPage;

                this.loading = false;
            });
        }
    }
}
