import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);
import {Product} from "../../services/product";
import {ProductionLineWeek} from "./production-line-week";


export class ProductionLine {

    id: string;
    created: dayjs.Dayjs;
    modified: dayjs.Dayjs;
    open_from: dayjs.Dayjs;
    name: string;
    slots_week: number;
    slots_count: number;
    slots_free_count: number;
    slots_sold_count: number;
    products?: Product[];
    production_line_weeks?: ProductionLineWeek[];

    constructor() {
    }

    static fromJSON(json: ProductionLineInterface | string): ProductionLine {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductionLine.reviver);
        } else {
            let model = new ProductionLine();

            let extra = {
                created: dayjs(json.created),
                modified: dayjs(json.modified),
                open_from: dayjs(json.open_from).isValid() ? dayjs(json.open_from) : null,
                products: [],
                production_line_weeks: []
            };

            Object.keys(json).forEach((key, _) => {
                if (key === 'products') {
                    json[key].forEach((value, _) => {
                        extra.products.push(Product.fromJSON(value));
                    });
                }

                if (key === 'production_line_weeks') {
                    json[key].forEach((value, _) => {
                        extra.production_line_weeks.push(ProductionLineWeek.fromJSON(value));
                    });
                }
            });

            return Object.assign(model, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductionLine.fromJSON(value) : value;
    }
}

export interface ProductionLineInterface extends ProductionLine {
}
