import {Injectable} from '@angular/core';
import {PriceAdjustment} from './price-adjustment';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable()
export class PriceAdjustmentsService {

    protected url = environment.apiBase + 'price-adjustments';

    constructor(public apiService: ApiService) {
    }

    getPriceAdjustment(id: string, withPriceAdjustmentRelations: boolean): Observable<{data: PriceAdjustment, count: number, perPage: number}> {
        let url = this.url + '/' + id;

        let parameters = [];

        if (withPriceAdjustmentRelations) {
            parameters.push('with-price-adjustment-relations=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<PriceAdjustment>(url, true, PriceAdjustment);
    }

    getPriceAdjustments(): Observable<{data: PriceAdjustment[], count: number, perPage: number}> {
        return this.apiService.get<PriceAdjustment[]>(this.url, true, PriceAdjustment);
    }

    addPriceAdjustment(priceAdjustment: PriceAdjustment): Observable<{data: PriceAdjustment, count: number, perPage: number}> {
        return this.apiService.post<PriceAdjustment>(this.url, priceAdjustment, true, PriceAdjustment);
    }

    updatePriceAdjustment(priceAdjustment: PriceAdjustment): Observable<{data: PriceAdjustment, count: number, perPage: number}> {
        return this.apiService.patch<PriceAdjustment>(this.url + '/' + priceAdjustment.id, priceAdjustment, true, PriceAdjustment);
    }

    deletePriceAdjustment(priceAdjustment: PriceAdjustment): Observable<{data: PriceAdjustment, count: number, perPage: number}> {
        return this.apiService.delete<PriceAdjustment>(this.url + '/' + priceAdjustment.id, true, PriceAdjustment);
    }
}
