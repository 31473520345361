import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderEditComponent} from '../../../order-edit/order-edit.component';
import {OrdersService} from '../../../services/orders.service';
import {OrderStatesService} from '../../../services/order-states.service';
import {ProductsService} from '../../../services/products.service';
import {DebtorsService} from '../../../services/debtors.service';
import {ReferencesService} from '../../../services/references.service';
import {BranchesService} from '../../../services/branches.service';
import {
    faArrowAltCircleLeft,
    faNetworkWired,
    faRedoAlt,
    faSearchDollar,
    faWindowMaximize,
    faCommentAlt,
    faFileExcel,
    faMapMarkerAlt,
    faStar,
    faCalculator,
    faAlignJustify,
    faGift,
    faExchangeAlt,
    faTruck,
    faCopy,
    faHatCowboy,
    faMagic,
    faPercentage,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {Order} from '../../../services/order';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {Reference} from '../../../services/reference';
import {Subject, Subscription} from 'rxjs';
import {OrderLine} from '../../../services/order-line';

interface IOptions {
    option: IconDefinition;
    color?: string;
    onClick?: any;
}

@Component({
    selector: 'app-step-2-order-overview',
    templateUrl: './step-2-order-overview.component.html',
    styleUrls: ['./step-2-order-overview.component.scss'],
    providers: [OrdersService, OrderStatesService, ProductsService, DebtorsService, ReferencesService, BranchesService],
})
export class TenFourStep2OrderOverviewComponent extends OrderEditComponent implements OnInit {

    order: Order | null | undefined;

    showAdditionalData = false;

    showProductsSearchResult = false;
    productsSearchReference: null | Reference = null;
    productsModelChanged: Subject<string> = new Subject<string>();
    productsSubscription: Subscription;
    orderlinesFromProductsSearch: OrderLine[] = [];
    debounceTime = 500;

    dummyData = [];
    options: IOptions[] = [
        // @todo DISABLED UNTIL FUNCTIONAL
        //{
        //    option: faArrowAltCircleLeft,
        //},
        //{
        //    option: faNetworkWired
        //},
        //{
        //    option: faRedoAlt
        //},
        //{
        //    option: faSearchDollar
        //},
        //{
        //    option: faWindowMaximize
        //},
        //{
        //    option: faCommentAlt
        //},
        //{
        //    option: faFileExcel,
        //    color: 'lightgreen'
        //},
        //{
        //    option: faMapMarkerAlt
        //},
        //{
        //    option: faStar
        //},
        //{
        //    option: faCalculator
        //},
        //{
        //    option: faAlignJustify
        //},
        //{
        //    option: faGift
        //},
        //{
        //    option: faExchangeAlt
        //},
        //{
        //    option: faTruck
        //},
        //{
        //    option: faCopy
        //},
        //{
        //    option: faHatCowboy,
        //    onClick: () => this.showAdditionalData = !this.showAdditionalData,
        //},
        //{
        //    option: faMagic
        //},
        //{
        //    option: faPercentage
        //}
    ];

    constructor(public ordersService: TenFourOrderService,
                public branchesService: BranchesService,
                public orderStatesService: OrderStatesService,
                public debtorsService: DebtorsService,
                public referencesService: ReferencesService,
                public productsService: ProductsService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router) {
        super(
            ordersService,
            branchesService,
            orderStatesService,
            debtorsService,
            referencesService,
            productsService,
            activatedRoute,
            ngbModal,
            router
        );
    }

    ngOnInit(): void {
        this.ordersService.currentOrder.subscribe((order) => {
            this.order = order;
        });
    }

    closeAdditionalData(event: boolean): void {
        this.showAdditionalData = false;
    }

}
