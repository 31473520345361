import {Component, OnInit} from '@angular/core';
import {Order} from "../../../services/order";
import {TenFourOrderService} from "../../../services/themes/10-4/ten-four-order.service";
import {OrderTypesService} from "../../../services/order-types.service";
import {OrderType} from "../../../services/order-type";
import {ICard} from "../../../widgets/card-select/card-select.component";
import {faPeopleCarry, faRulerCombined} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-select-new-order-type',
    templateUrl: './select-new-order-type.component.html',
    styleUrls: ['./select-new-order-type.component.scss'],
    providers: [OrderTypesService]
})
export class SelectNewOrderTypeComponent implements OnInit {

    order: Order;
    orderTypes: OrderType[];

    dataForCards: ICard[];

    constructor(private orderService: TenFourOrderService, private orderTypesService: OrderTypesService) {
        this.dataForCards = [
            {
                name: 'Afhenter',
                icon: faPeopleCarry,
            },
            {
                name: 'Trælast',
                icon: faRulerCombined,
            }
        ];
    }

    ngOnInit(): void {
        this.orderService.currentOrder.subscribe((response) => {
            this.order = response;
        });
        this.orderTypesService.getOrderTypes().subscribe((response) => {
            this.orderTypes = response.data;
            this.dataForCards = this.dataForCards.map((c) => {
               const matchedOrderType = this.orderTypes.find((ot) => ot.name === c.name);
               if (matchedOrderType){
                   return {
                       ...c,
                       emitValue: matchedOrderType.id,
                   };
               } else {
                   return c;
               }
            });
        });
    }

    setNewOrderType(val: string): void {
        const selectedOrderType = this.orderTypes.find((ot) => ot.id === val);
        this.order.order_type = selectedOrderType;
        this.orderService.setOrder(this.order);
    }

}
