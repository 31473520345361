<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!productCategory" i18n>Add product category</ng-container>
            <ng-container *ngIf="productCategory" i18n>Edit product category</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-loader *ngIf="loading" class="text-muted" i18n>Loading...</app-loader>

    <div class="modal-body" *ngIf="!loading">
        <app-language-picker [missingLocalizations]="missingLocalizations"></app-language-picker>
        <form>
            <div class="form-group">
                <label for="product-category-name" i18n>Name</label><app-language-indicator></app-language-indicator>
                <input type="text" id="product-category-name" class="form-control" [(ngModel)]="name[languagesService.index]" name="name">
            </div>

            <div class="form-group">
                <label for="product-category-parent_id" i18n>Parent category</label>

                <select id="product-category-parent_id" class="form-control" [(ngModel)]="_productCategory.parent_id" name="parent_id">
                    <option [ngValue]="null" i18n>-- No parent --</option>
                    <option *ngFor="let productCategory of productCategoriesList | keyvalue:keepOriginalOrder" [ngValue]="productCategory.key">{{ productCategory.value }}</option>
                </select>
            </div>

            <hr>

            <ng-template ngFor let-reference [ngForOf]="_productCategory.references">
                <div class="form-group">
                    <label for="product-category-reference-{{ reference.id }}-value">{{ reference.name }}</label>
                    <input type="text" id="product-category-reference-{{ reference.id }}-value" class="form-control" [(ngModel)]="reference._joinData.value" name="{{ reference.id }}-value">
                </div>
            </ng-template>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
