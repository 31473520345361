import {Component, OnInit} from '@angular/core';
import {PriceGroup} from '../services/price-group';
import {PriceGroupsService} from '../services/price-groups.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceGroupEditModalComponent} from '../price-group-edit-modal/price-group-edit-modal.component';
import {faCalendarXmark, faXmark} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-price-groups',
    templateUrl: './price-groups.component.html',
    styleUrls: ['./price-groups.component.sass'],
    providers: [PriceGroupsService]
})
export class PriceGroupsComponent implements OnInit {

    faXmark = faXmark;

    priceGroups: PriceGroup[];

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    constructor(public priceGroupsService: PriceGroupsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number) {
        this.loading = true;

        this.priceGroupsService.getPriceGroups(this.page, this.limit, {withDebtors: true, withProducts: true}).subscribe(response => {
            this.priceGroups = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    add() {
        const self = this;

        const modalRef = this.ngbModal.open(PriceGroupEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function() {
            self.load(self.page);
        };
    }

    edit(priceGroup: PriceGroup) {
        const self = this;

        const modalRef = this.ngbModal.open(PriceGroupEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.priceGroup = priceGroup;
        modalRef.componentInstance.saved = function() {
            self.load(self.page);
        };
    }

    delete(priceGroup: PriceGroup) {
        if (confirm($localize `Are you sure?\n\nThe price group will be deleted`)) {
            this.loading = true;

            this.priceGroupsService.deletePriceGroup(priceGroup).subscribe(_ => {
                this.loading = false;

                this.load(this.page);
            });
        }
    }

    saveSorting() {
        this.loading = true;

        let sorting = 0;
        const priceGroups = [];

        for (const priceGroup of this.priceGroups) {
            priceGroups.push(PriceGroup.fromJSON({
                id: priceGroup.id,
                sorting
            }));

            sorting++;
        }

        this.priceGroupsService.updatePriceGroups(priceGroups).subscribe(_ => {
            this.loading = false;
        });
    }
}
