<h5>
    {{referenceValue}}
</h5>

<app-table *ngIf="products" [autoSizeLastColumn]="true">
    <thead>
    <tr>
        <th i18n>References</th>
        <th i18n>Categories</th>
        <ng-container *ngFor="let field of productFieldPresets.product_fields">
            <th [ngClass]="{'text-right': field.type ==='number'}">{{field.name}}:</th>
        </ng-container>
        <th></th>
        <th></th>
    </tr>
    </thead>

    <tbody>
    <ng-template ngFor let-product [ngForOf]="products">
        <tr>
            <td>
                <ng-template ngFor let-reference [ngForOf]="product.references">
                            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  ml-2">
                                {{ reference.name }}: {{ reference._joinData.value }}
                            </span>
                </ng-template>
            </td>
            <td>
                <ng-template ngFor let-productCategory [ngForOf]="product.product_categories">
                            <span class="badge  badge-secondary  ml-2">
                                {{ productCategory.name }}
                            </span>
                </ng-template>
            </td>

            <ng-container *ngFor="let field of productFieldPresets.product_fields">
                <td [ngClass]="{'text-right': field.type ==='number'}">
                    {{field.type === 'number' ? (product.getFieldValueForId(field.id)| number:'1.2-2':'da') : product.getFieldValueForId(field.id)}}
                </td>
            </ng-container>

            <td>
                <ng-template ngFor let-productRelation [ngForOf]="product.product_relation_childrens">
                    <a [routerLink]="'/custom/analysis/' + productRelation.related_product_id" i18n>Analysis</a>
                </ng-template>
            </td>
        </tr>
    </ng-template>

    </tbody>
</app-table>

<ng-container *ngIf="products && count > products.length">
    <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10"
                    [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'"
                    [disabled]="loading"></ngb-pagination>
</ng-container>
