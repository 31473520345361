<p *ngIf="!_order" class="text-muted" i18n>Loading...</p>

<div class="order-edit  pb-5" *ngIf="_order">
    <h1 class="float-right  text-muted">#{{_order.getIdentificationReference()}}</h1>
    <h1 class="heading" style="width: auto;">
        <ng-container i18n>Order details</ng-container>
    </h1>
    <div class="row  mb-4">
        <div class="col-12  col-md-6">
            <div class="data-board">
                <h5 i18n>Debtor</h5>
                <p>

                    <strong>{{_order?.debtor?.name}}</strong><br/>
                    <ng-container *ngFor="let contact of _order.contacts">
                        <ng-container *ngFor="let field of contact.contact_fields">
                            <span *ngIf="field._joinData.value">{{field._joinData.value}}<br/></span>
                        </ng-container>
                    </ng-container>
                </p>
            </div>
        </div>
        <div class="col-12  col-md-3  offset-md-3">
            <!--            <p>-->
            <!--                <small>-->
            <!--                    Ordre dato: <strong>24/02-2020</strong><br/>-->
            <!--                    Ref: <strong>#32627623</strong>-->
            <!--                </small>-->
            <!--            </p>-->
            <!--            <div class="data-board" style="height: auto;">-->
            <!--                <h5>Fakturerings adresse</h5>-->
            <!--                <p>-->
            <!--                    <small>-->
            <!--                        <strong>Acme Corperation ApS</strong><br/>-->
            <!--                        Adressevej 23<br/>-->
            <!--                        9800 Bynavn-->
            <!--                    </small>-->
            <!--                </p>-->
            <!--            </div>-->
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p></p>
        </div>
    </div>

    <app-table [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th>#</th>
            <ng-container *ngFor="let field of orderLineFieldPresets.order_line_fields">
                <th [ngClass]="{'text-right': field.type ==='number'}">{{field.name}}:</th>
            </ng-container>
            <td></td>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-orderLine [ngForOf]="_order.order_lines" let-i="index">
            <tr>
                <td>{{i}}</td>
                <ng-container *ngFor="let field of orderLineFieldPresets.order_line_fields">
                    <td [ngClass]="{'text-right': field.type ==='number'}">
                        {{field.type === 'number' ? (orderLine.getFieldValueForId(field.id)| number:'1.2-2':'da') : orderLine.getFieldValueForId(field.id)}}
                    </td>
                </ng-container>
                <td>
                    <a [routerLink]="'/custom/orders/deliveries/'+this._order.getIdentificationReference() + '-' + orderLine.getFieldValueForId('179c5dcb-6748-4a14-afb5-b1eceff8b781')">
                        <ng-container i18n>Show deliveries</ng-container>
                    </a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>
</div>
