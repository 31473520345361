import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {PaymentAgreementsService} from "../services/payment-agreements.service";
import {PaymentAgreement} from "../services/payment-agreement";

@Component({
    selector: 'app-payment-agreement-edit-modal',
    templateUrl: './payment-agreement-edit-modal.component.html',
    styleUrls: ['./payment-agreement-edit-modal.component.scss'],
    providers: [PaymentAgreementsService]
})
export class PaymentAgreementEditModalComponent implements OnInit {

    public paymentAgreement: PaymentAgreement;
    public _paymentAgreement: PaymentAgreement;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public paymentAgreementsService: PaymentAgreementsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._paymentAgreement = this.paymentAgreement ? PaymentAgreement.fromJSON(this.paymentAgreement) : PaymentAgreement.fromJSON({
            days: 8,
            is_running_month: false,
            is_default: false
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.paymentAgreement) {
            this.paymentAgreementsService.updatePaymentAgreement(this._paymentAgreement).subscribe(paymentAgreement => {
                this.saved();

                this.saving = false;

                this.activeModal.close(paymentAgreement);
            });
        } else {
            this.paymentAgreementsService.addPaymentAgreement(this._paymentAgreement).subscribe(paymentAgreement => {
                this.saved();

                this.saving = false;

                this.activeModal.close(paymentAgreement);
            });
        }
    }
}
