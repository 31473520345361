import * as dayjs from 'dayjs';
import {Debtor, DebtorRelatable} from "./debtor";
import {Product, ProductRelatable} from "./product";
import {Currency} from "./currency";

export class PriceGroup implements DebtorRelatable, ProductRelatable {

    id: string;
    currency_id: string;
    name: string;
    product_id: string;
    sorting: number;
    is_cost_price: boolean;
    is_default: boolean;
    is_inactive: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    debtors: Debtor[];
    product: Product;
    currency: Currency;

    constructor() {
    }

    static fromJSON(json: PriceGroupJSON | string): PriceGroup {
        if (typeof json === 'string') {
            return JSON.parse(json, PriceGroup.reviver);
        } else {
            const priceGroup = Object.create(PriceGroup.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                debtors: [],
                product: null,
                currency: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (key === 'debtors') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Debtor.fromJSON(value));
                    });
                }

                if (key === 'product' && json[key]) {
                    extra[key] = Product.fromJSON(json[key]);
                }
                // @todo works not
                if (key === 'currency' && json[key]) {
                    extra[key] = Currency.fromJSON(json[key]);
                }
            });

            return Object.assign(priceGroup, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? PriceGroup.fromJSON(value) : value;
    }

    public addDebtor(debtor: Debtor): void {
        if (this.hasDebtor(debtor)) {
            return;
        }

        this.debtors.push(Debtor.fromJSON({
            id: debtor.id,
            name: debtor.name
        }));
    }

    public removeDebtor(debtor: Debtor): void {
        let index = this.debtors.findIndex((element) => {
            return element.id === debtor.id;
        });

        if (index >= 0) {
            this.debtors.splice(index, 1);
        }
    }

    public hasDebtor(debtor: Debtor): boolean {
        return this.debtors.findIndex((element) => {
            return element.id === debtor.id;
        }) >= 0;
    }

    addProduct(product: Product): void {
        this.product_id = product.id;
        this.product = product;
    }

    removeProduct(): void {
        this.product_id = null;
        this.product = null;
    }
}

// A representation of PriceGroup data that can be converted to
// and from JSON without being altered.
interface PriceGroupJSON {
    id?: string;
    name?: string;
    sorting?: number;
    is_cost_price?: boolean;
    is_default?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    debtors?: Debtor[];
}

export interface PriceGroupRelatable {
    addPriceGroup(priceGroup: PriceGroup): void
    removePriceGroup(priceGroup: PriceGroup): void
}
