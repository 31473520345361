import {Injectable} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {Product} from './product';
import {OrderLine} from './order-line';
import {BehaviorSubject} from 'rxjs';
import {Order} from "./order";
import {OrdersService} from "./orders.service";
import {OrderField} from "./order-field";

@Injectable({
    providedIn: 'root',
})
export class OfferGeneratorService {

    public selectedOrderId?: string | undefined; // undefined is custom mode, null is not selected yet
    public selectedTool?: OrderLine;
    public selectedWheel?: OrderLine;
    public selectedOptions?: OrderLine[] = [];
    public selectedExtraTools?: OrderLine[] = [];
    public newOrderId?: string;
    public selectedOrder?: Order;

    private updateSubject = new BehaviorSubject<Date | undefined>(undefined);
    updates$ = this.updateSubject.asObservable();

    public dummySteps = [
        {
            title: 'Vælg Maskine',
            url: ['/', 'offers', {outlets: {offer_outlet: ['tool-orders']}}]
        },
        {
            title: 'Tilpas',
            url: ['/', 'offers', {outlets: {offer_outlet: ['ready-orders']}}]
        },
        {
            title: 'Vælg Hjul',
            url: ['/', 'offers', {outlets: {offer_outlet: ['wheel-select']}}]
        },
        {
            title: 'Vælg Redskaber',
            url: ['/', 'offers', {outlets: {offer_outlet: ['tool-select']}}]
        },
        {
            title: 'Oversigt',
            url: ['/', 'offers', {outlets: {offer_outlet: ['preview-order']}}]
        },
        {
            title: 'Bekræft Bestilling',
            url: ['/', 'offers', {outlets: {offer_outlet: ['confirm-order']}}]
        },
    ];
    public readyToolExists = false;
    public currentStepIndex: number;
    public offerSteps: any;
    public pausenext = false;
    public completed: boolean;

    // Holding area for tilbud data
    public comment: string;
    public orderFields: OrderField[];

    constructor(private router: Router, public ordersService: OrdersService) {

        if (!this.currentStepIndex) {
            this.currentStepIndex = 0;
        }

        // This helps us detect we are on the correct step still after using the back and forward arrows in the browser
        router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                if (event.navigationTrigger === 'popstate' && this.offerSteps) {

                    const newIndex = this.offerSteps.findIndex((step) => this.router.createUrlTree(step.url).toString() === event.url);

                    if ((newIndex === this.currentStepIndex) || (newIndex === -1)) {
                        // Do nothing
                    } else if (newIndex > this.currentStepIndex) {
                        this.currentStepIndex = this.currentStepIndex + 1;
                    } else {
                        this.currentStepIndex = this.currentStepIndex - 1;
                    }
                }
            }
        });
    }

    public changeSelectedTool(newTool: Product, orderline?:OrderLine): void {

        this.reset(false);
        this.readyToolExists = false;
        let toolOrderLine = new OrderLine();
        if (orderline) {
            toolOrderLine = orderline;
        }

        toolOrderLine.product_id = newTool.id;
        toolOrderLine.product = newTool;
        toolOrderLine.quantity = 1;
        toolOrderLine.price = orderline ? orderline.price : newTool.price;
        toolOrderLine.price_adjusted = orderline ? orderline.price_adjusted : newTool.adjusted_price;
        toolOrderLine.price_adjustment_name = orderline ? orderline.price_adjustment_name : newTool.adjusted_name;
        this.selectedTool = toolOrderLine;
        newTool.product_stocks.forEach((item) => {
            if (item.quantity > 0) {
                this.readyToolExists = true;
            }
        });
        this.selectedOrderId = null;
        this.updateSubject.next(new Date());
    }

    public changeSelectedWheel(wheel: Product, orderline?: OrderLine): void {

        let wheelOrderLine = new OrderLine();
        if (orderline) {
            wheelOrderLine = orderline;
        }
        wheelOrderLine.product_id = wheel.id;
        wheelOrderLine.product = wheel;
        wheelOrderLine.quantity = 1;
        wheelOrderLine.price = orderline ? orderline.price : wheel.price;
        wheelOrderLine.price_adjusted = orderline ? orderline.price_adjusted : wheel.adjusted_price;
        wheelOrderLine.price_adjustment_name = orderline ? orderline.price_adjustment_name : wheel.adjusted_name;
        this.selectedWheel = wheelOrderLine;
        this.updateSubject.next(new Date());
    }

    public changeRoute(newRoute: []): void {
        this.router.navigate(newRoute);
    }

    public nextStep(): void {
        this.currentStepIndex = this.currentStepIndex + 1;
        this.router.navigate(this.offerSteps[this.currentStepIndex].url);
    }

    reset(navigate = true): void {
        this.completed = false;
        this.pausenext = true;
        this.selectedOrderId = null;
        this.selectedTool = null;
        this.selectedWheel = null;
        this.selectedOrder = null;
        this.selectedOptions = [];
        this.selectedExtraTools = [];
        if (navigate) {
            this.currentStepIndex = 0;
            this.router.navigate(this.offerSteps[this.currentStepIndex].url);
        }
    }

    setComplete(): void {
        this.selectedOrderId = null;
        this.selectedTool = null;
        this.selectedWheel = null;
        this.selectedOptions = [];
        this.selectedExtraTools = [];
        this.completed = true;
    }

    // Udstyr


    // Redskaber
    onAddClick(tool: Product, oldorderLine?:OrderLine): void {
        let selectedExtraTool: boolean | OrderLine = false;
        if (this.selectedExtraTools) {
            selectedExtraTool = this.selectedExtraTools.find((el) => {
                return el.product_id === tool.id;
            });
        }
        if (selectedExtraTool) {
            selectedExtraTool.quantity = selectedExtraTool.quantity + 1;
        } else {
            let orderLine = new OrderLine();
            if (oldorderLine) {
                orderLine= oldorderLine;
            }
            orderLine.product_id = tool.id;
            orderLine.product = tool;
            orderLine.quantity = 1;
            orderLine.price_adjusted = oldorderLine ? oldorderLine.price_adjusted : tool.adjusted_price;
            orderLine.price_adjustment_name = oldorderLine ? oldorderLine.price_adjustment_name : tool.adjusted_name;
            orderLine.price = oldorderLine ? oldorderLine.price : tool.price;
            this.selectedExtraTools.push(orderLine);
        }
        this.updateSubject.next(new Date());
    }

    onRemoveClick(tool: Product): void {
        let selectedExtraTool: boolean | OrderLine = false;
        if (this.selectedExtraTools) {
            selectedExtraTool = this.selectedExtraTools.find((el) => {
                return el.product_id === tool.id;
            });
        }
        if (selectedExtraTool) {
            if (selectedExtraTool.quantity === 1) {
                this.selectedExtraTools = this.selectedExtraTools.filter((el) => {
                    return el.product_id !== tool.id;
                });
            } else {
                selectedExtraTool.quantity = selectedExtraTool.quantity - 1;
            }
        }
        this.updateSubject.next(new Date());
    }

    quantityForTool(tool: Product): number {
        const selectedExtraTool = this.selectedExtraTools.find((el) => {
            return el.product_id === tool.id;
        });
        if (selectedExtraTool) {
            return selectedExtraTool.quantity;
        }
        return 0;
    }

    public loadSelectedOrder(): void {
        this.ordersService.getOrder(this.selectedOrderId, true, true, true, true, true, true, true, true, {}).subscribe((respoinse) => {
            this.selectedOrder = respoinse.data;
        });
    }
}
