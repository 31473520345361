<div class="orders">
    <h1 class="heading" i18n>Offer</h1>

    <app-helms-orders [embedded]="true"
                      [limitInfo]="true"
                      [showRekvNr]="true"
                      [disableFilterUI]="true"
                      [orderState]="['44019a09-dca4-435b-bf53-7e77a59694f2'].join(',')"

    [orderType]="[
    '77ddc44f-a56a-4c35-b7f8-f67874cdfa17',
    '5b394b80-e003-44bf-a5cb-a805121caa9b',
    'be969ca9-d5f0-4eec-bdc7-dab679ea7979',
    'cf0d5dfd-bcaf-41a4-b24c-723c82fa8ebf'].join(',')"
    ></app-helms-orders>

</div>

