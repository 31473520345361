import {Component, Input, OnInit} from '@angular/core';
import {OrdersQueryInterface, OrdersService} from '../../../services/orders.service';
import {AuthService} from '../../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faInfoCircle, faSortUp, faSortDown, faSort} from '@fortawesome/free-solid-svg-icons';
import {Order} from '../../../services/order';
import {IdValuesService} from '../../../services/themes/helms/id-values.service';
import {ActivatedRoute} from '@angular/router';
import {MachinesService} from '../../../services/themes/helms/machines.service';
import {Router} from '@angular/router';
import {OrderStatesService} from '../../../services/order-states.service';
import {OrderTypesService} from '../../../services/order-types.service';
import {OrderState} from '../../../services/order-state';
import {OrderType} from '../../../services/order-type';
import {Dayjs} from 'dayjs';
import {Date} from '../../../services/date';
import * as dayjs from 'dayjs';
import {HelmsOrdersComponent} from '../helms-orders/helms-orders.component';

@Component({
    selector: 'app-helms-offers',
    templateUrl: './helms-offers.component.html',
    providers: [OrdersService, AuthService, IdValuesService, MachinesService, OrderStatesService, OrderTypesService]

})
export class HelmsOffersComponent {
    constructor(public idValuesService: IdValuesService) {
    }
}
