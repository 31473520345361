<div class="login  forgot-password">
    <div class="login__wrapper">
        <div class="login__header">
            <ng-container *ngIf="!environment.customer">
                <h1 class="logo  logo--text  logo--centerized">Winston</h1>
            </ng-container>
            <ng-container *ngIf="environment.customer">
                <img class="logo  logo--centerized" [src]="logo"/>
            </ng-container>
        </div>

        <div *ngIf="hasError" class="alert  alert-danger">
            Noget gik galt! Prøv venligst igen.
        </div>

        <div *ngIf="hasValidated" class="alert  alert-success">
            Din adgangskode er nu opdateret! Du kan nu <a [routerLink]="'/login'">logge ind</a> med din nye adgangskode
        </div>

        <ng-container *ngIf="!hasValidated">

            <h3 class="heading  heading--minor">Nulstil din adgangskode</h3>

            <form (ngSubmit)="onSubmit()" #basketContactForm="ngForm" id="address" novalidate>

                <div class="form-group">
                    <label for="password">Din nye adgangskode:</label>
                    <input type="password" class="form-control" [ngClass]="{ 'is-invalid': hasError }" id="password"
                           autocomplete="password" [(ngModel)]="user.password" name="password" [disabled]="hasValidated"
                           required>
                    <div class="invalid-feedback">
                        {{basketContactForm.form.getError('invalid', 'password')}}
                    </div>
                </div>

                <div class="form-group">
                    <label for="password_control">Gentag adgangskode:</label>
                    <input type="password" class="form-control" [ngClass]="{ 'is-invalid': hasError }"
                           id="password_control" autocomplete="password" [(ngModel)]="user.password_control"
                           name="password_control" [disabled]="hasValidated" required>
                    <div class="invalid-feedback">
                        {{basketContactForm.form.getError('invalid', 'password_control')}}
                    </div>

                </div>

                <button type="submit" class="login__button  login__button--reset  btn  btn-primary  btn-lg"
                        [disabled]="hasValidated">Nulstil
                </button>

            </form>

        </ng-container>


    </div>
</div>
