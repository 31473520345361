import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {Product} from "../services/product";
import {ProductsService} from "../services/products.service";
import {Order} from "../services/order";
import {OrderLine} from "../services/order-line";
import {forkJoin} from "rxjs";

@Component({
    selector: 'app-order-line-edit-modal',
    templateUrl: './order-line-edit-modal.component.html',
    styleUrls: ['./order-line-edit-modal.component.scss'],
    providers: [ProductsService]
})
export class OrderLineEditModalComponent implements OnInit {

    public loading = false;
    public adding = false;

    public saved;

    public order: Order;
    public orderLine: OrderLine;
    public _orderLine: OrderLine;

    products: Product[];

    constructor(public activeModal: NgbActiveModal,
                public productsService: ProductsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._orderLine = this.orderLine ? OrderLine.fromJSON(this.orderLine) : OrderLine.fromJSON({product_id: null, quantity: 1, price: 0});

        // @todo pagination could be a problem here, but we might need to do a search instead of dropdown later on anyway

        this.productsService.getProducts(1, 100, false, true, false, false, false, true).subscribe(response => {
            this.products = response.data;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    add() {
        if (this.orderLine) {
            // edit functionality goes here

            /**
             * Callback
             */
            this.saved();
        } else {
            /**
             * Go through relation product relation childrens and if follow product, then get prices and other product info from API
             */
            let productRelationCalls = [];

            for (let productRelation of this._orderLine.product.product_relation_childrens) {
                if (productRelation.isFollowProduct()) {
                    productRelationCalls.push(this.productsService.getProduct(productRelation.product.id, false, true, false, false, false, false, this.order.debtor_id ? this.order.debtor_id : 'null', true, true, false, true));
                }
            }

            if (productRelationCalls.length > 0) {
                this.adding = true;

                forkJoin(productRelationCalls).subscribe(joinDatum => {
                    for (let joinData of joinDatum) {
                        for (let productRelation of this._orderLine.product.product_relation_childrens) {
                            if (productRelation.product.id === joinData['data'].id) {
                                productRelation.product = joinData['data'];
                            }
                        }
                    }

                    this.order.addOrderLine(this._orderLine);

                    this.adding = false;

                    /**
                     * Close modal
                     */
                    this.activeModal.close();

                    /**
                     * Callback
                     */
                    this.saved();
                });
            } else {
                this.order.addOrderLine(this._orderLine);

                /**
                 * Close modal
                 */
                this.activeModal.close();

                /**
                 * Callback
                 */
                this.saved();
            }
        }
    }

    productSelected(_: string) {
        let index = this.products.findIndex((element) => {
            return element['id'] === this._orderLine.product_id;
        });

        this._orderLine.cost_price = null;
        this._orderLine.price = null;
        this._orderLine.product = null;

        if (index >= 0) {
            this._orderLine.product = this.products[index];

            this.loading = true;

            this.productsService.getProduct(this._orderLine.product.id, false, true, false, false, false, false, this.order.debtor_id ? this.order.debtor_id : 'null', true, true, false, true).subscribe(response => {
                let product = response.data;

                if (product.adjusted_price) {
                    this._orderLine.cost_price = product.cost_price;
                    this._orderLine.price = product.adjusted_price;
                }

                this._orderLine.product = product;

                this.loading = false;
            });
        }
    }

    quantityChanged() {
        for (let alternativePriceUnit of this._orderLine.product.alternative_price_units) {
            alternativePriceUnit.quantity = alternativePriceUnit.getUnits(this._orderLine.quantity);
        }

        this._orderLine.alternative_price_unit = null;
        this._orderLine.alternative_price_unit_id = null;
        this._orderLine.alternative_price_unit_factor = null;
    }
}
