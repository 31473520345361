<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!unit" i18n>Add unit</ng-container>
            <ng-container *ngIf="unit" i18n>Edit unit</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-loader *ngIf="!languagesService.languages" class="text-muted" i18n>Loading...</app-loader>

    <div class="modal-body" *ngIf="languagesService.languages">
        <app-language-picker [missingLocalizations]="missingLocalizations"></app-language-picker>

        <form>
            <div class="form-group">
                <label for="unit-name" i18n>Name</label><app-language-indicator></app-language-indicator>
                <input type="text" id="unit-name" class="form-control" [(ngModel)]="name[languagesService.index]" name="name">
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_unit.is_default" name="is_default"> <ng-container i18n>Default unit</ng-container>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
