import * as dayjs from 'dayjs';
import {Product} from "./product";

export class AlternativePriceUnit {

    id: string;
    product_id: string;
    unit: string;
    factor: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    product: Product;

    quantity: number; // used to hold amount if using alternative price units

    constructor() {
    }

    static fromJSON(json: AlternativePriceUnitJSON | string): AlternativePriceUnit {
        if (typeof json === 'string') {
            return JSON.parse(json, AlternativePriceUnit.reviver);
        } else {
            let alternativePriceUnit = Object.create(AlternativePriceUnit.prototype);

            let extra = {
                factor: json.factor ? +json.factor : null,
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product: null
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'product' && json[key]) {
                    extra[key] = Product.fromJSON(json[key]);
                }
            });

            return Object.assign(alternativePriceUnit, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? AlternativePriceUnit.fromJSON(value) : value;
    }

    public getQuantity(): number {
        return Math.ceil(this.quantity * this.factor);
    }

    public getUnits(quantity: number): number {
        return quantity / this.factor;
    }

    public getPrice(price: number): number {
        return price * this.factor;
    }
}

// A representation of AlternativePriceUnit data that can be converted to
// and from JSON without being altered.
interface AlternativePriceUnitJSON {
    id?: string;
    product_id?: string;
    unit?: string;
    factor?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    product?: Product;
}
