<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Product fields</ng-container>
        <small *ngIf="loading && productFields" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!productFields" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="productFields">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add product field</a>
    </p>

    <app-table *ngIf="productFields" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th i18n>Name</th>
            <th style="width: 60px;"></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-productField [ngForOf]="productFields" let-i="index">
            <tr>
                <td>
                    <fa-icon [ngbTooltip]="'Localizations missing'" *ngIf="!missingLocalizations[i].complete" [icon]="faExclamation"></fa-icon>
                    {{ productField.name }}
                    <span class="badge  badge-secondary" style="transform: translateY(-1px);">{{ productField.type }}</span>
                </td>
                <td class="text-right">
                    <a href="javascript:" (click)="edit(productField, i)" i18n>Edit</a>
                    <a href="javascript:" (click)="delete(productField)" class="text-danger" i18n>Delete</a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>
</div>
