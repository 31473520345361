<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!orderField" i18n>Add order line field</ng-container>
            <ng-container *ngIf="orderField" i18n>Edit order line field</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-loader *ngIf="!languagesService.languages" class="text-muted" i18n>Loading...</app-loader>

    <div class="modal-body" *ngIf="languagesService.languages">
        <app-language-picker [missingLocalizations]="missingLocalizations"></app-language-picker>

        <form>
            <div class="form-group">
                <label for="order-line-field-name" i18n>Name</label><app-language-indicator></app-language-indicator>
                <input type="text" id="order-line-field-name" class="form-control" [(ngModel)]="name[languagesService.index]" name="name">
            </div>

            <div class="form-group">
                <label for="order-line-field-type" i18n>Type</label>
                <select id="order-line-field-type" class="form-control" [(ngModel)]="_orderField.type" name="type">
                    <option [ngValue]="'text'" i18n>Text</option>
                    <option [ngValue]="'checkbox'" i18n>Checkbox</option>
                </select>
            </div>
            <div class="form-group">
                <div class="checkbox">
                    <input type="checkbox" id="order-line-field-localized" [(ngModel)]="_orderField.is_localized" name="localized">
                    <label for="order-line-field-localized" i18n>Localized</label>
                </div>
            </div>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
