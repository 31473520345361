<div class="container app__component">
    <h1 class="heading">
        <ng-container i18n>Order Types</ng-container>
        <small *ngIf="loading && orderTypes" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!orderTypes" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="orderTypes">
        <a class="btn btn-primary button button--add-to-table" href="javascript:" (click)="add()" i18n>Add order Type</a>
    </p>

    <app-table [autoSizeLastColumn]="true" *ngIf="orderTypes">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody [sortablejs]="orderTypes" [sortablejsOptions]="{ handle: '.sortablejs--handle' }">
            <ng-template ngFor let-orderType [ngForOf]="orderTypes">
                <tr>
                    <td>
                        <span class="sortablejs--handle  mr-2  text-muted" style="cursor: move;">=</span>
                        {{ orderType.name }}
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(orderType)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(orderType)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <button *ngIf="orderTypes" (click)="saveSorting()" class="btn  btn-sm  btn-primary" [disabled]="loading" i18n>Save sorting</button>
</div>
