import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {OrderLineField} from "./order-line-field";

@Injectable()
export class OrderLineFieldsService {

    protected url = environment.apiBase + 'order-line-fields';

    constructor(public apiService: ApiService) {
    }

    getOrderLineField(id: string): Observable<{data: OrderLineField, count: number, perPage: number}> {
        return this.apiService.get<OrderLineField>(this.url + '/' + id, true, OrderLineField);
    }

    getOrderLineFields(): Observable<{data: OrderLineField[], count: number, perPage: number}> {
        return this.apiService.get<OrderLineField[]>(this.url, true, OrderLineField);
    }

    addOrderLineField(orderLineField: OrderLineField): Observable<{data: OrderLineField, count: number, perPage: number}> {
        return this.apiService.post<OrderLineField>(this.url, orderLineField, true, OrderLineField);
    }

    updateOrderLineField(orderLineField: OrderLineField): Observable<{data: OrderLineField, count: number, perPage: number}> {
        return this.apiService.patch<OrderLineField>(this.url + '/' + orderLineField.id, orderLineField, true, OrderLineField);
    }

    deleteOrderLineField(orderLineField: OrderLineField): Observable<{data: OrderLineField, count: number, perPage: number}> {
        return this.apiService.delete<OrderLineField>(this.url + '/' + orderLineField.id, true, OrderLineField);
    }
}
