<h3>Delivery:</h3>

<app-table *ngIf="product" [autoSizeLastColumn]="true">
    <thead>
    <tr>
        <th i18n>References</th>
        <th i18n>Categories</th>
        <ng-container *ngFor="let field of productFieldPresets.product_fields">
            <th>{{field.name}}:</th>
        </ng-container>
        <th></th>
        <th></th>
    </tr>
    </thead>

    <tbody>
    <tr>
        <td>
            <ng-template ngFor let-reference [ngForOf]="product.references">
                            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  ml-2">
                                {{ reference.name }}: {{ reference._joinData.value }}
                            </span>
            </ng-template>
        </td>
        <td>
            <ng-template ngFor let-productCategory [ngForOf]="product.product_categories">
                            <span class="badge  badge-secondary  ml-2">
                                {{ productCategory.name }}
                            </span>
            </ng-template>
        </td>
        <ng-container *ngFor="let field of productFieldPresets.product_fields">
            <td>{{product.getFieldValueForId(field.id)}}</td>
        </ng-container>
    </tr>
    </tbody>
</app-table>

<ng-container
    *ngIf="product && product.product_relation_childrens && product && product.product_relation_childrens.length > 0">

    <h3 i18n>Analysis</h3>

    <app-table [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th i18n>References</th>
            <ng-container *ngFor="let field of analysisFieldPresets.product_fields">
                <th>{{field.name}}:</th>
            </ng-container>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-productRelation [ngForOf]="product.product_relation_childrens">
            <tr>
                <td>
                    <ng-template ngFor let-reference [ngForOf]="productRelation.product.references">
                            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  ml-2">
                                {{ reference.name }}: {{ reference._joinData.value }}
                            </span>
                    </ng-template>
                </td>
                <ng-container *ngFor="let field of analysisFieldPresets.product_fields">
                    <td>{{productRelation.product.getFieldValueForId(field.id)}}</td>
                </ng-container>
            </tr>
        </ng-template>
        </tbody>
    </app-table>
</ng-container>


