import * as dayjs from 'dayjs';
import {ProductImportMappingColumn} from "./product-import-mapping-column";

export class ProductImportMapping {

    id: string;
    name: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    product_import_mapping_columns: ProductImportMappingColumn[];

    constructor() {
    }

    static fromJSON(json: ProductImportMappingJSON | string): ProductImportMapping {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductImportMapping.reviver);
        } else {
            let productImportMapping = Object.create(ProductImportMapping.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product_import_mapping_columns: []
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'product_import_mapping_columns') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductImportMappingColumn.fromJSON(value));
                    });
                }
            });

            return Object.assign(productImportMapping, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductImportMapping.fromJSON(value) : value;
    }
}

// A representation of ProductImportMapping data that can be converted to
// and from JSON without being altered.
interface ProductImportMappingJSON {
    id?: string;
    name?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    product_import_mapping_columns?: ProductImportMappingColumn[];
}
