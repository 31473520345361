import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Debtor, DebtorRelatable} from "../services/debtor";
import {DebtorsService} from "../services/debtors.service";
import {Contact} from "../services/contact";

@Component({
    selector: 'app-debtor-select-modal',
    templateUrl: './debtor-select-modal.component.html',
    styleUrls: ['./debtor-select-modal.component.scss'],
    providers: [DebtorsService]
})
export class DebtorSelectModalComponent implements OnInit {

    public debtorRelatable: DebtorRelatable;

    debtors: Debtor[];

    debtor: Debtor = null;

    referenceContact: Contact = null;
    deliveryContact: Contact = null;

    saved;
    parent;

    constructor(public activeModal: NgbActiveModal,
                public debtorsService: DebtorsService) {
    }

    ngOnInit() {
        this.debtorsService.getDebtors(1, -1, false, true, true, true, true).subscribe(response => {
            this.debtors = response.data;
        });
    }

    debtorChanged() {
        this.deselectReferenceContact();
        this.deselectDeliveryContact();

        if (this.debtor.contacts.length > 0) {
            this.referenceContact = this.debtor.contacts[0];
        }
    }

    selectReferenceContact(contact: Contact) {
        this.referenceContact = contact;
    }

    selectDeliveryContact(contact: Contact) {
        this.deliveryContact = contact;
    }

    deselectReferenceContact() {
        this.referenceContact = null;
    }

    deselectDeliveryContact() {
        this.deliveryContact = null;
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.debtorRelatable.addDebtor(this.debtor);

        this.saved({
            Contact: this.referenceContact,
            Delivery: this.deliveryContact
        });

        this.activeModal.close();
    }
}
