import * as dayjs from 'dayjs';

export class DebtorsUser {

    id: string;
    debtor_id: string;
    user_id: string;
    is_supervisor: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: DebtorsUserJSON | string): DebtorsUser {
        if (typeof json === 'string') {
            return JSON.parse(json, DebtorsUser.reviver);
        } else {
            let debtorsUser = Object.create(DebtorsUser.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(debtorsUser, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? DebtorsUser.fromJSON(value) : value;
    }
}

// A representation of DebtorsUser data that can be converted to
// and from JSON without being altered.
interface DebtorsUserJSON {
    id?: string;
    debtor_id?: string;
    user_id?: string;
    is_supervisor?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
