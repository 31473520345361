import * as dayjs from 'dayjs';
import {PriceAdjustmentRelation} from "./price-adjustment-relation";

export class PriceAdjustment {

    id: string;
    name: string;
    pct: number;
    amount: number;
    sort  : number;
    for_cost_price: boolean;
    is_override: boolean;
    date_start: dayjs.Dayjs;
    date_end: dayjs.Dayjs;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    price_adjustment_relations: PriceAdjustmentRelation[];

    constructor() {
    }

    static fromJSON(json: PriceAdjustmentJSON | string): PriceAdjustment {
        if (typeof json === 'string') {
            return JSON.parse(json, PriceAdjustment.reviver);
        } else {
            let priceAdjustment = Object.create(PriceAdjustment.prototype);

            let extra = {
                pct: json.pct,
                date_start: json.date_start ? dayjs(json.date_start) : null,
                date_end: json.date_end ? dayjs(json.date_end) : null,
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                price_adjustment_relations: []
            }

            Object.keys(json).forEach((key, index) => {
                if (key === 'price_adjustment_relations') {
                    json[key].forEach((value, index) => {
                        extra.price_adjustment_relations.push(PriceAdjustmentRelation.fromJSON(value));
                    });
                }
            });

            return Object.assign(priceAdjustment, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? PriceAdjustment.fromJSON(value) : value;
    }

    addPriceAdjustmentRelation(): void {
        this.price_adjustment_relations.push(PriceAdjustmentRelation.fromJSON({}));
    }

    hasPriceAdjustmentRelation(priceAdjustmentRelation: PriceAdjustmentRelation): boolean {
        let index = this.price_adjustment_relations.findIndex((element) => {
            return element['id'] === priceAdjustmentRelation.id;
        });

        return index >= 0;
    }

    isActive(): boolean {
        let now = dayjs();

        if (this.date_start && now < this.date_start) {
            return false;
        }

        if (this.date_end && now > this.date_end) {
            return false;
        }

        return true;
    }
}

// A representation of PriceAdjustment data that can be converted to
// and from JSON without being altered.
interface PriceAdjustmentJSON {
    id?: string;
    name?: string;
    pct?: number;
    amount?: number;
    sort?: number;
    for_cost_price?: boolean;
    is_override?: boolean;
    date_start?: dayjs.Dayjs;
    date_end?: dayjs.Dayjs;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
