<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Price groups</ng-container>
        <small *ngIf="loading && priceGroups" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!priceGroups" class="text-muted" i18n>Loading...</app-loader>

    <ng-container *ngIf="priceGroups">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add price group</a>
    </ng-container>

    <table *ngIf="priceGroups" class="table  table-sm  table-striped  table-hover">
        <thead>
            <tr>
                <th style="width: 250px;" i18n>Name</th>
                <th i18n>Debtor</th>
                <th i18n>Product</th>
                <th style="width: 60px;"></th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody [sortablejs]="priceGroups" [sortablejsOptions]="{ handle: '.sortablejs--handle' }">
            <ng-template ngFor let-priceGroup [ngForOf]="priceGroups">
                <tr>
                    <td>
                        <span class="sortablejs--handle  mr-2  text-muted" style="cursor: move;">=</span>
                        {{ priceGroup.name }}
                        <span *ngIf="priceGroup.is_cost_price" class="badge  badge-primary  ml-2" i18n>Cost price</span>
                        <span *ngIf="priceGroup.is_default" class="badge  badge-primary  ml-2" i18n>Default</span>

                        <fa-icon [icon]="faXmark" [ngbTooltip]="'Inactive'" *ngIf="priceGroup.is_inactive"></fa-icon>

                    </td>
                    <td>
                        <ng-template ngFor let-debtor [ngForOf]="priceGroup.debtors">
                            <span class="badge  badge-secondary  mr-2">{{ debtor.name }}</span>
                        </ng-template>

                        <p *ngIf="priceGroup.debtors.length == 0" class="text-muted" i18n>No debtor</p>
                    </td>
                    <td>
                        <p *ngIf="priceGroup.product">{{ priceGroup.product.name }}</p>

                        <p *ngIf="!priceGroup.product" class="text-muted" i18n>No product</p>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(priceGroup)" class="mr-3" i18n>Edit</a>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="delete(priceGroup)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>

    <button (click)="saveSorting()" class="btn  btn-sm  btn-primary  float-right" [disabled]="loading" i18n>Save sorting</button>

    <ng-container *ngIf="priceGroups && count > priceGroups.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
