import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-corporate',
    templateUrl: './corporate.component.html',
    styleUrls: ['./corporate.component.scss'],
    providers: []
})
export class CorporateComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

        //

    }
}
