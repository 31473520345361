import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Branch} from '../services/branch';
import {AuthService} from "../services/auth.service";
import {BranchesService} from "../services/branches.service";

@Component({
    selector: 'app-branch-edit-modal',
    templateUrl: './branch-edit-modal.component.html',
    styleUrls: ['./branch-edit-modal.component.scss'],
    providers: [BranchesService]
})
export class BranchEditModalComponent implements OnInit {

    public branch: Branch;
    public _branch: Branch;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public branchesService: BranchesService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._branch = this.branch ? Branch.fromJSON(this.branch) : Branch.fromJSON({});
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.branch) {
            this.branchesService.updateBranch(this._branch).subscribe(branch => {
                this.saved();

                this.saving = false;

                this.activeModal.close(branch);
            });
        } else {
            this.branchesService.addBranch(this._branch).subscribe(branch => {
                this.saved();

                this.saving = false;

                this.activeModal.close(branch);
            });
        }
    }
}
