import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'app-attachment-download',
    templateUrl: './attachment-download.component.html',
    styleUrls: ['./attachment-download.component.scss']
})
export class AttachmentDownloadComponent implements OnInit {
    private attachmentId: any;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public activatedRoute: ActivatedRoute,
        public router: Router
    ) {
        this.attachmentId = activatedRoute.snapshot.params.attachmentId;
    }

    ngOnInit(): void {
        this.document.location.href = environment.apiBase + 'attachments/' + this.attachmentId + '/download';
    }
}
