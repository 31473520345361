import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Creditor} from '../services/creditor';
import {AuthService} from "../services/auth.service";
import {CreditorsService} from "../services/creditors.service";
import {ReferencesService} from "../services/references.service";
import {Reference} from "../services/reference";
import {ContactFieldPreset} from "../services/contact-field-preset";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";

@Component({
    selector: 'app-creditor-edit-modal',
    templateUrl: './creditor-edit-modal.component.html',
    styleUrls: ['./creditor-edit-modal.component.scss'],
    providers: [CreditorsService, ReferencesService, ContactFieldPresetsService]
})
export class CreditorEditModalComponent implements OnInit {

    public creditor: Creditor;
    public _creditor: Creditor;

    public contactFieldPresets: ContactFieldPreset[];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public creditorsService: CreditorsService,
                public referencesService: ReferencesService,
                public contactFieldPresetsService: ContactFieldPresetsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.referencesService.getReferences().subscribe(response => {
            this._creditor = this.creditor ? Creditor.fromJSON(this.creditor) : Creditor.fromJSON({});

            for (let reference of response.data) {
                if (reference.is_creditor_reference && !this._creditor.hasReference(reference.id)) {
                    this._creditor.references.push(Reference.fromJSON({
                        id: reference.id,
                        name: reference.name,
                        _joinData: {
                            value: ''
                        }
                    }));
                }
            }
        });

        this.contactFieldPresetsService.getContactFieldPresets(true).subscribe(response => {
            this.contactFieldPresets = response.data;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.creditor) {
            this.creditorsService.updateCreditor(this._creditor).subscribe(creditor => {
                this.saved();

                this.saving = false;

                this.activeModal.close(creditor);
            });
        } else {
            this.creditorsService.addCreditor(this._creditor).subscribe(creditor => {
                this.saved();

                this.saving = false;

                this.activeModal.close(creditor);
            });
        }

    }
}
