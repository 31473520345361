import {Component, Input, OnInit} from '@angular/core';
import {ContactFieldPreset} from "../services/contact-field-preset";
import {Contact, Contactable} from "../services/contact";
import {ContactField} from "../services/contact-field";

@Component({
    selector: 'app-contact-edit-selector',
    templateUrl: './contact-edit-selector.component.html',
    styleUrls: ['./contact-edit-selector.component.scss'],
    providers: []
})
export class ContactEditSelector implements OnInit {

    @Input() public contactable: Contactable;
    @Input() public contactFieldPresets: ContactFieldPreset[];

    constructor() {
    }

    ngOnInit() {
    }

    addContactFromContactFieldPreset(contactFieldPreset: ContactFieldPreset): void {
        let contactFields = [];

        for (let contactField of contactFieldPreset.contact_fields) {
            contactFields.push(ContactField.fromJSON({
                id: contactField.id,
                name: contactField.name,
                type: contactField.type,
                _joinData: {
                    value: ''
                }
            }));
        }

        this.contactable.contacts.push(Contact.fromJSON({
            contact_fields: contactFields
        }));
    }
}
