<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Product field presets</ng-container>
        <small *ngIf="loading && productFieldPresets" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!productFieldPresets" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="productFieldPresets">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add product field preset</a>
    </p>

    <app-table *ngIf="productFieldPresets" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Product fields</th>
                <th i18n>Product categories</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-productFieldPreset [ngForOf]="productFieldPresets">
                <tr>
                    <td>{{ productFieldPreset.name }}</td>
                    <td>
                        <ng-template ngFor let-productField [ngForOf]="productFieldPreset.product_fields">
                            <span class="badge  badge-secondary  mr-2">{{ productField.name }}</span>
                        </ng-template>
                    </td>
                    <td>
                        <ng-template ngFor let-productCategory [ngForOf]="productFieldPreset.product_categories">
                            <span class="badge  badge-secondary  mr-2">{{ productCategory.name }}</span>
                        </ng-template>

                        <span *ngIf="productFieldPreset.product_categories.length === 0" class="text-muted" i18n>All categories</span>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(productFieldPreset)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(productFieldPreset)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>
</div>
