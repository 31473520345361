import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss'],
    providers: []
})
export class InventoryComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

        //

    }
}
