import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {OrderLineFieldPreset} from "./order-line-field-preset";

@Injectable()
export class OrderLineFieldPresetsService {

    protected url = environment.apiBase + 'order-line-field-presets';

    constructor(public apiService: ApiService) {
    }

    getOrderLineFieldPreset(id: string): Observable<{ data: OrderLineFieldPreset, count: number, perPage: number }> {
        return this.apiService.get<OrderLineFieldPreset>(this.url + '/' + id, true, OrderLineFieldPreset);
    }
}
