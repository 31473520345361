import * as dayjs from 'dayjs';
import {Order} from "./order";

export class Branch {

    id: string;
    name: string;
    slug: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    orders: Order[];

    constructor() {
    }

    static fromJSON(json: BranchJSON | string): Branch {
        if (typeof json === 'string') {
            return JSON.parse(json, Branch.reviver);
        } else {
            const branch = Object.create(Branch.prototype);
            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                orders: []
            };

            Object.keys(json).forEach((key, _) => {
                if (key === 'orders') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Order.fromJSON(value));
                    });
                }
            });

            return Object.assign(branch, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? Branch.fromJSON(value) : value;
    }
}

// A representation of Branch data that can be converted to
// and from JSON without being altered.
interface BranchJSON {
    id?: string;
    name?: string;
    slug?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
