import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {TokenService} from "./token.service";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(public http: HttpClient,
                public tokenService: TokenService) {
    }

    public get<T>(url: string, authenticate: boolean, itemType: any, params?: HttpParams): Observable<{data: T, count: number, perPage: number}> {
        return this.http.get<T>(url, {headers: this.getHeaders(authenticate, null), params: params}).pipe(map(response => this.handleArrayOrNotArray(itemType, response)));
    }

    public post<T>(url: string, object: T, authenticate: boolean, itemType: any, contentType: string = 'application/json', params?: HttpParams): Observable<{data: T, count: number, perPage: number}> {
        return this.http.post(url, object, {headers: this.getHeaders(authenticate, contentType), params: params}).pipe(map(response => this.handleArrayOrNotArray(itemType, response)));
    }

    public upload<T>(url: string, object: FormData, authenticate: boolean, itemType: any, contentType: string = 'application/json', params?: HttpParams): Observable<{data: T, count: number, perPage: number}> {
        return this.http.post(url, object, {headers: this.getHeaders(authenticate, contentType), params: params}).pipe(map(response => this.handleArrayOrNotArray(itemType, response)));
    }

    public patch<T>(url: string, object: T, authenticate: boolean, itemType: any, params?: HttpParams): Observable<{data: T, count: number, perPage: number}> {
        return this.http.patch(url, object, {headers: this.getHeaders(authenticate), params: params}).pipe(map(response => this.handleArrayOrNotArray(itemType, response)));
    }

    public delete<T>(url: string, authenticate: boolean, itemType: any, params?: HttpParams): Observable<{data: T, count: number, perPage: number}> {
        return this.http.delete(url, {headers: this.getHeaders(authenticate, null), params: params}).pipe(map(response => this.handleArrayOrNotArray(itemType, response)));
    }

    protected handleArrayOrNotArray(itemType, response) {
        let data;
        if (response.data instanceof Array) {
            data = response.data.map((item: any) => {
                return itemType.fromJSON(item);
            });
        } else {
            data = itemType.fromJSON(response.data);
        }

        return {
            data: data,
            count: response.hasOwnProperty('count') ? response.count : null,
            perPage: response.hasOwnProperty('perPage') ? response.perPage : null
        }
    }

    public getHeaders(authenticate: boolean, contentType: string = 'application/json', accept: string = 'application/json'): HttpHeaders {
        let headers = {
            'Accept': accept
        }

        if (authenticate) {
            headers['Authorization'] = this.tokenService.getToken().getToken();
        }

        if (contentType) {
            headers['Content-Type'] = contentType;
        }

        return new HttpHeaders(headers);
    }

    protected static handleError(error: any): Promise<any> {
        if (!environment.production) {
            console.error('An error occurred', error);
        }
        return Promise.reject(error.message || error);
    }
}
