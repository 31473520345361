import {Component, OnInit} from '@angular/core';
import {Date} from "../services/date";
import {DatesService} from "../services/dates.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DateEditModalComponent} from "../date-edit-modal/date-edit-modal.component";

@Component({
    selector: 'app-dates',
    templateUrl: './dates.component.html',
    styleUrls: ['./dates.component.sass'],
    providers: [DatesService]
})
export class DatesComponent implements OnInit {

    dates: Date[];

    loading = false;

    constructor(public datesService: DatesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.datesService.getDates().subscribe(response => {
            this.dates = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(DateEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(date: Date) {
        let self = this;

        const modalRef = this.ngbModal.open(DateEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.date = date;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(date: Date) {
        if (confirm($localize `Are you sure?\n\nThe date will be deleted`)) {
            this.loading = true;

            this.datesService.deleteDate(date).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }
}
