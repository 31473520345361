<div class="container">
    <h1 class="heading">
        <ng-container i18n>Price adjustments</ng-container>
        <small *ngIf="loading && priceAdjustments" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!priceAdjustments" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="priceAdjustments">
        <a routerLink="add" i18n>Add price adjustment</a>
    </p>

    <table *ngIf="priceAdjustments" class="table  table-sm  table-striped  table-hover">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Adjustment percentage</th>
                <th i18n>Adjustment amount</th>
                <th i18n>Priority</th>
                <th i18n>Override</th>
                <th i18n>Start</th>
                <th i18n>End</th>
                <th style="width: 60px;"></th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-priceAdjustment [ngForOf]="priceAdjustments">
                <tr>
                    <td>
                        {{ priceAdjustment.name }}
                        <span *ngIf="priceAdjustment.for_cost_price" class="badge  badge-secondary  ml-2" i18n>Cost price</span>
                        <span *ngIf="!priceAdjustment.isActive()" class="badge  badge-danger  ml-2" i18n>Inactive</span>
                    </td>
                    <td>{{ priceAdjustment.pct|number }}%</td>
                    <td>{{ priceAdjustment.amount|number }}</td>
                    <td>{{ priceAdjustment.sort }}</td>
                    <td>
                        <span *ngIf="priceAdjustment.is_override" i18n>Yes</span>
                        <span *ngIf="!priceAdjustment.is_override" i18n>No</span>
                    </td>
                    <td>
                        <span *ngIf="!priceAdjustment.date_start" class="text-muted" i18n>-</span>
                        <span *ngIf="priceAdjustment.date_start">{{ priceAdjustment.date_start.format('YYYY-MM-DD') }}</span>
                    </td>
                    <td>
                        <span *ngIf="!priceAdjustment.date_end" class="text-muted" i18n>-</span>
                        <span *ngIf="priceAdjustment.date_end">{{ priceAdjustment.date_end.format('YYYY-MM-DD') }}</span>
                    </td>
                    <td class="text-right">
                        <a routerLink="{{ priceAdjustment.id }}" class="mr-3" i18n>Edit</a>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="delete(priceAdjustment)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
</div>
