<div class="products  container  app__component">
    <h1 class="heading  mb-0  pb-0">
        <ng-container i18n>Products</ng-container>
    </h1>

    <p *ngIf="products">
        <a class="btn btn-primary button button--add-to-table" routerLink="/products/add" i18n>Add product</a>
    </p>

    <app-table-helper *ngIf="products">
        <div class="row">
            <div class="col-md-5">
                <div class="input-group">
                    <input type="text" class="form-control" i18n-placeholder placeholder="Search" [(ngModel)]="searchProductsText">
                    <div class="input-group-append">
                        <select class="custom-select" [(ngModel)]="searchProductsSelect">
                            <option [ngValue]="'product_name'">Varenavn</option>
                            <option [ngValue]="'product_reference'">Varenummer</option>
                        </select>
                        <button (click)="page = 1; load(page); pushState()" type="button" class="btn  btn-primary">Søg
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="input-group">
                    <input type="number" min="1" class="form-control" [(ngModel)]="limit" name="limit"
                           style="display: inline; width: 75px;" [disabled]="loading">
                    <div class="input-group-append">
                        <span class="input-group-text" i18n>Per page</span>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <label for="filter-active" i18n>Only active</label>
                <input type="checkbox" id="filter-active" [(ngModel)]="filterActive">

                <label for="filter-inactive" i18n>Only inactive</label>
                <input type="checkbox" id="filter-inactive" [(ngModel)]="filterInactive">
            </div>
            <div class="col-md-1">
                <input type="button" id="filter-search" class="btn btn-primary" name="active" (click)="load(1)"
                       [value]="'Update list'" i18n>
            </div>


            <div class="form-group col-md-5">
                <label i18n>Category</label>
                <select class="form-control  mb-3" [(ngModel)]="productCategoryId" name="product_category_id"  [disabled]="productCategoryId && !products">
                    <option [ngValue]="null" i18n>-- Select product category --</option>
                    <option *ngFor="let productCategory of productCategories" [ngValue]="productCategory.id">{{ productCategory.name }}</option>
                </select>
            </div>
        </div>
    </app-table-helper>

    <app-loader *ngIf="!products || loading" class="text-muted" i18n>Loading...</app-loader>

    <app-table *ngIf="products && !loading" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th i18n>Name</th>
            <th i18n>Creditor</th>
            <th i18n>References</th>
            <th i18n>Categories</th>
            <th i18n>Prices</th>
            <th></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-product [ngForOf]="products">
            <tr>
                <td>
                    {{ product.name }}
                    <fa-icon [icon]="faCalendarXmark" [ngbTooltip]="'Product is expired or has not launched yet'"
                             *ngIf="product.date_start > now || product.date_end < now"></fa-icon>
                    <fa-icon [icon]="faXmark" [ngbTooltip]="'Inactive'" *ngIf="!product.is_active"></fa-icon>
                </td>
                <td>
                    <span *ngIf="!product.creditor" class="text-muted" i18n>No creditor</span>
                    <span *ngIf="product.creditor">{{ product.creditor.name }}</span>
                </td>
                <td>
                    <ng-template ngFor let-reference [ngForOf]="product.references">
                            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  ml-2">
                                {{ reference.name }}: {{ reference._joinData.value }}
                            </span>
                    </ng-template>
                </td>
                <td>
                    <ng-template ngFor let-productCategory [ngForOf]="product.product_categories">
                            <span class="badge  badge-secondary  ml-2">
                                {{ productCategory.name }}
                            </span>
                    </ng-template>
                </td>
                <td>
                    <span *ngIf="product.prices.length > 0">{{ product.prices.length }}</span>
                    <span *ngIf="product.prices.length === 0" class="text-muted" i18n>No prices</span>
                </td>
                <td class="text-right">
                    <a routerLink="/products/{{ product.id }}" i18n>Edit</a>
                    <a role="button" tabindex="0" (click)="delete(product)" (keyup.Enter)="delete(product)"
                       (keyup.Space)="delete(product)" class="text-danger" i18n>Delete</a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="products && count > products.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10"
                        [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'"
                        [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
