import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-buy',
    templateUrl: './buy.component.html',
    styleUrls: ['./buy.component.scss'],
    providers: []
})
export class BuyComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

        //

    }
}
