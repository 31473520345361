import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../services/auth.service';
import {OrderFieldsService} from '../services/order-fields.service';
import {OrderField} from '../services/order-field';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from '../services/locale-translation';

@Component({
    selector: 'app-order-field-edit-modal',
    templateUrl: './order-field-edit-modal.component.html',
    styleUrls: ['./order-field-edit-modal.component.scss'],
    providers: [OrderFieldsService, LanguagesService]
})
export class OrderFieldEditModalComponent implements OnInit {

    public orderField: OrderField;
    public _orderField: OrderField;

    public saving = false;
    public saved;

    public missingLocalizations;
    public name: string[] = [];

    constructor(public activeModal: NgbActiveModal,
                public orderFieldsService: OrderFieldsService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this._orderField = this.orderField ? OrderField.fromJSON(this.orderField) : OrderField.fromJSON({
            type: 'text'
        });
        this.languagesService.getLanguages().subscribe(() => {
            this.loadLanguages();
        });
    }

    loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {
            if (language.locale === this.languagesService.primaryLanguage.locale) {
                if (this._orderField.name) {
                    this.name[i] = this._orderField.name;
                } else {
                    this.name[i] = '';
                }
            } else {
                if (this._orderField._translations) {
                    const translation = this._orderField._translations[language.locale];
                    if (translation) {
                        if (translation.name) {
                            this.name[i] = translation.name;
                        } else {
                            this.name[i] = '';
                        }
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }
            }
            i++;
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        this.saveLanguages();

        if (this.orderField) {
            this.orderFieldsService.updateOrderField(this._orderField).subscribe(orderField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderField);
            });
        } else {
            this.orderFieldsService.addOrderField(this._orderField).subscribe(orderField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderField);
            });
        }
    }

    saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {
            if (this.languagesService.isSame(language, this.languagesService.primaryLanguage)) {
                this._orderField.name = this.name[i];
            } else {
                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;
            }
            this._orderField._translations = translations;
            i++;
        });
    }
}
