import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbAccordion, NgbDate, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProductionLinesService} from '../services/production-lines.service';
import {ProductionLine} from '../services/production-line';
import {ProductionLineSlot} from '../services/production-line-slot';
import {
    faArrowDownUpAcrossLine,
    faArrowsUpDown,
    faCalendar,
    faChevronLeft,
    faChevronUp,
    faClipboardList,
    faClockRotateLeft,
    faCog,
    faTimes,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import {Dayjs} from 'dayjs';
import {mergeMap} from "rxjs/operators";
import {ProductionLineWeek} from "../services/production-line-week";
import {IdValuesService} from "../../services/themes/helms/id-values.service";

@Component({
    selector: 'app-production-line-slots',
    templateUrl: './production-line-slots.component.html',
    styleUrls: ['./production-line-slots.component.scss'],
    providers: [ProductionLinesService]
})
export class ProductionLineSlotsComponent implements OnInit {


    @ViewChild('acc') acc: NgbAccordion;

    public productionLineSlots: ProductionLineSlot[];
    public productionLine: ProductionLine;

    public faChevron = faChevronUp;
    public faTimes = faTimes;
    public faChevronLeft = faChevronLeft;
    public faArrowUpDown = faArrowsUpDown;
    public faArrowDownUpAcross = faArrowDownUpAcrossLine;
    public faClockBack = faClockRotateLeft;
    public faCalendar = faCalendar;
    public faCog = faCog;
    public faClipboardList = faClipboardList;
    public faTriangleExclamation = faTriangleExclamation;

    public delayData: any = {date: Dayjs, weeks: Number};
    modalRef: NgbModalRef;
    processing: boolean;
    public swapQueue: ProductionLineSlot[] = [];

    hasError: boolean;
    errorMessage: string;

    hasModalError: boolean;
    modalErrorMessage: string;

    public groupedData: IGroupData[] = [];

    disableDays = (date: NgbDate, current): boolean => {
        const checkThisDate = dayjs(date.year + '-' + date.month + '-' + date.day);
        if (checkThisDate.day() !== 1) {
            return true;
        } else {
            return false;
        }
    }

    constructor(
        public idValuesService: IdValuesService,
        public activatedRoute: ActivatedRoute,
        public ngbModal: NgbModal,
        public router: Router,
        public productionLinesService: ProductionLinesService) {
        dayjs.extend(advancedFormat);
        dayjs.extend(isoWeek);
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.loadData(params.get('productionLineId'));
        });
    }

    loadData(id): void {
        this.productionLinesService.getProductionLineSlots(id).pipe(mergeMap((response) => {
            return forkJoin(
                [
                    this.parseGroups(response.data),
                    this.productionLinesService.getProductionLine(id)
                ]);
        })).subscribe((response) => {
            this.productionLine = response[1].data;
            this.groupedData = response[0];

            this.parseGroups(this.productionLineSlots);

            this.addMissingGroups();
        }, response => {
            alert('error');
        });
    }

    parseGroups(slots: ProductionLineSlot[]): Observable<IGroupData[]> {
        if (!slots) {
            return EMPTY;
        }

        const processed = slots
            .sort((a, b) => a.date.diff(b.date)) // sort a
            .reduce((r: Map<string, IGroupData>, e) => {
                let group;
                if (e.date.isValid()) {
                    group = e.date.format('W / YYYY');
                } else {
                    group = 'Unknown';
                }
                if (!r[group]) {
                    r[group] = {group, slots: [e]};
                } else {
                    r[group].slots.push(e);
                }
                return r;
            }, new Map());

        return of(Object.values(processed));
    }

    addMissingGroups() {
        let now = dayjs();

        for (let i = 0; i < 52; i++) {
            let groupName = now.isoWeek() + ' / ' + now.year();

            let index = this.groupedData.findIndex((element) => {
                return element['group'] === groupName;
            });

            if (index < 0) {
                let group = {
                    'group': groupName,
                    'slots': []
                };

                this.groupedData.push(group);
            }

            now = now.add(1, 'week');
        }

        this.groupedData.sort((a, b) =>  this.isGroupBeforeOtherGroup(a.group, b.group));
    }

    isGroupBeforeOtherGroup(a: string, b: string): number {
        const aElements = a.split(' / ');
        let adate = dayjs(aElements[1] + '-02-01');
        adate = adate.isoWeek(+aElements[0]);

        const bElements = b.split(' / ');
        let bdate = dayjs(bElements[1] + '-02-01');
        bdate = bdate.isoWeek(+bElements[0]);
        return adate.diff(bdate);
    }

    getProductionSlots(group: string) {
        let index = this.productionLine.production_line_weeks.findIndex((element) => {
            let date = dayjs(element.date);

            return date.isoWeek() + ' / ' + date.year() === group;
        });

        return index >= 0 ? this.productionLine.production_line_weeks[index].slots_week : this.productionLine.slots_week;
    }

    openModal(content): void {
        this.modalRef = this.ngbModal.open(content, {});
        this.modalRef.result.then((modalResult) => {
            //console.log(modalResult);
        });
    }

    delayOrders(): void {
        this.prepareProcessing();
        this.processing = true;
        this
            .productionLinesService.shiftProductionLine(this.productionLine.id, this.delayData).subscribe((respoinse) => {
            this.loadData(this.productionLine.id);
            if (this.modalRef) {
                this.modalRef.close();
            }
            this.processing = false;
        }, (response) => {
            this.processing = false;
            this.hasModalError = true;
            this.modalErrorMessage = response.error.error;
        });
    }

    productionLineSlotFromGroup(group, warning= false): ProductionLineSlot {
        let groupElements = group.group.split(' / ');

        let date = dayjs(groupElements[1] + '-02-01');

        date = date.isoWeek(groupElements[0]);

        let productionLineSlot = new ProductionLineSlot();
        productionLineSlot.date = date;
        productionLineSlot._warning = warning;
        productionLineSlot._group = group;

        return productionLineSlot;
    }

    addToSwap(productionLineSlot: ProductionLineSlot) {
        if (this.swapQueue.length >= 2) {
            if (!this.swapQueue[1].id) {
                this.swapQueue[1] = productionLineSlot;
                return;
            }

            alert('error');
        } else {
            this.swapQueue.push(productionLineSlot);
        }
    }

    performSwap() {
        this.prepareProcessing();
        this.processing = true;
        this.productionLinesService.swapProductionLine(this.productionLine.id, this.swapQueue).subscribe((response) => {
            this.swapQueue = [];
            this.loadData(this.productionLine.id);
            this.processing = false;
        }, (response) => {
            this.hasError = true;
            this.processing = false;
            this.errorMessage = response.error.error;
        });
    }

    prepareProcessing() {
        this.hasModalError = false;
        this.hasError = false;
        this.modalErrorMessage = null;
        this.errorMessage = null;
    }

    removeGroupFromQueue(group: IGroupData) {
        const index = this.swapQueue.findIndex((item) => {
            if (item._group) {
            return item._group.group === group.group;
            }

        });
        this.swapQueue.splice(index, 1);
    }

    checkQueueForGroup(group: IGroupData) {
        return this.swapQueue.find((item) => {
            if (item._group) {
            return item._group.group === group.group;
            }
        });
    }
}

export interface IGroupData {
    group: string;
    slots: ProductionLineSlot[];
}
