import * as dayjs from 'dayjs';

export class Reference {

    id: string;
    name: string;
    is_order_identification: boolean;
    is_order_reference: boolean;
    is_product_identification: boolean;
    is_product_reference: boolean;
    is_creditor_reference: boolean;
    is_debtor_reference: boolean;
    is_product_category_reference: boolean;
    is_incremental: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    _joinData: {
        created: string,
        id: string,
        modified: string,
        order_id: string,
        reference_id: string,
        value: string,
    };

    constructor() {
    }

    static fromJSON(json: ReferenceJSON | string): Reference {
        if (typeof json === 'string') {
            return JSON.parse(json, Reference.reviver);
        } else {
            let reference = Object.create(Reference.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _joinData: null
            }

            Object.keys(json).forEach((key, _) => {
                if (key === '_joinData' && json[key]) {
                    extra._joinData = {};

                    Object.keys(json[key]).forEach((keyKey, _) => {
                        extra._joinData[keyKey] = json[key][keyKey]
                    });
                }
            });

            return Object.assign(reference, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Reference.fromJSON(value) : value;
    }
}

// A representation of Reference data that can be converted to
// and from JSON without being altered.
interface ReferenceJSON {
    id?: string;
    name?: string;
    is_order_identification?: boolean;
    is_order_reference?: boolean;
    is_product_identification?: boolean;
    is_product_reference?: boolean;
    is_creditor_reference?: boolean;
    is_debtor_reference?: boolean;
    is_product_category_reference?: boolean;
    is_incremental?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    _joinData?;
}
