<div class="creditors">
    <h1 class="heading">
        <ng-container *ngIf="!_creditor" i18n>Creditor</ng-container>
        <ng-container *ngIf="_creditor">{{ _creditor.name }}</ng-container>
        <button *ngIf="_creditor && !loading && authService.hasPermission('admin')" (click)="edit()" class="btn  btn-sm  btn-primary" i18n>Edit creditor</button>
    </h1>

    <app-loader *ngIf="!_creditor" i18n>Loading...</app-loader>

    <ng-container *ngIf="_creditor">

        <ng-template ngFor let-reference [ngForOf]="_creditor.references">
        <span *ngIf="reference._joinData.value" class="badge  badge-secondary  mr-2">
            {{ reference.name }}: {{ reference._joinData.value }}
        </span>
        </ng-template>

        <hr>

        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
            <li ngbNavItem [ngbNavItem]="1">
                <a ngbNavLink i18n>Users ({{_creditor.users.length}})</a>
                <ng-template ngbNavContent>
                    <div class="mt-3">
                        <app-table>
                            <thead>
                            <tr>
                                <th i18n>User</th>
                                <th i18n>Supervisor</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                            <ng-template ngFor let-user [ngForOf]="_creditor.users">
                                <tr>
                                    <td>
                                        {{ user.name }}
                                        <span class="mr-2">&lt;{{ user.email }}&gt;</span>
                                        <span *ngIf="!user.active" class="badge  badge-danger  mr-2" i18n>Inactive</span>
                                    </td>
                                    <td>
                                        <span *ngIf="user['_joinData'].is_supervisor" class="badge  badge-primary">Supervisor</span>
                                        <span *ngIf="!user['_joinData'].is_supervisor" class="badge  badge-secondary">No</span>
                                    </td>
                                    <td>
                                        <button (click)="deleteUser(user)" class="btn  btn-sm  btn-danger  float-right" i18n>Delete</button>
                                        <button (click)="editUser(user)" class="btn  btn-sm  btn-primary  float-right" i18n>Edit</button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-container *ngIf="_creditor.users.length === 0">
                                <tr>
                                    <td colspan="3">
                                        <span class="text-muted" i18n>No users</span>
                                    </td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </app-table>
                    </div>

                    <button (click)="addUser()" class="btn  btn-sm  btn-primary  mb-3" i18n>Add creditor user</button>
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="2" *ngIf="contactFieldPresets && contactFieldPresets.length > 0">
                <a ngbNavLink i18n>Contacts ({{_creditor.contacts.length}})</a>
                <ng-template ngbNavContent>
                    <div class="mt-3">
                        <app-loader *ngIf="loading || !contactFieldPresets" i18n>Loading...</app-loader>
                        <app-table *ngIf="!loading && contactFieldPresets && contactFieldPresets.length > 0" [autoSizeLastColumn]="true">
                            <thead>
                            <tr>
                                <th>User</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                            <ng-template ngFor let-contact [ngForOf]="_creditor.contacts">
                                <tr>
                                    <td>
                                        {{ contact.name }}
                                    </td>
                                    <td>
                                        <button class="button  btn  btn-xs  btn-primary m-0" (click)="editContact(contact)"><fa-icon [icon]="editIcon"></fa-icon></button>
                                        <button class="button  btn  btn-xs  btn-danger  m-0" (click)="deleteContact(contact)"><fa-icon [icon]="deleteIcon"></fa-icon></button>
                                    </td>
                                </tr>
                            </ng-template>
                            </tbody>
                        </app-table>
                    </div>
                    <div ngbDropdown class="d-inline-block">
                        <button [disabled]="loading" class="btn  btn-primary  btn-sm  mb-3" id="dropdownBasic1" ngbDropdownToggle i18n>Add creditor contact</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button *ngFor="let preset of contactFieldPresets" (click)="addContact(preset)" ngbDropdownItem>{{preset.name}}</button>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

    </ng-container>
</div>
