<div class="login  forgot-password">
    <div class="login__wrapper">
        <div class="login__header">
            <ng-container *ngIf="!environment.customer">
                <h1 class="logo  logo--text  logo--centerized">Winston</h1>
            </ng-container>
            <ng-container *ngIf="environment.customer">
                <img class="logo  logo--centerized" [src]="logo" />
            </ng-container>
        </div>

        <div *ngIf="hasError" class="alert  alert-danger">
            Vi kan ikke finde den pågældende e-mail.
        </div>

        <div *ngIf="hasValidated" class="alert  alert-success">
            Der er nu sendt en e-mail til indtastede e-mail, med instruktioner i at nulstille din adgangskode.
        </div>

        <h3 class="heading  heading--minor">Nulstil din adgangskode</h3>

        <form (ngSubmit)="onSubmit()">

            <div class="form-group">
                <label for="user-email">Din e-mail adresse:</label>
                <input type="email" class="form-control" [ngClass]="{ 'is-invalid': hasError }" id="user-email" autocomplete="username" [(ngModel)]="user.email" name="email" [disabled]="hasValidated" required>
            </div>

            <button type="submit" class="login__button  login__button--reset  btn  btn-primary  btn-lg" [disabled]="hasValidated">Nulstil</button>

        </form>
    </div>
</div>
