<div class="order-edit  pb-5">
    <h1 *ngIf="_order && _order.id" class="float-right  text-muted">#{{ _order.getIdentificationReference() }}</h1>

    <h1>
        <ng-container *ngIf="!orderId" i18n>Add order</ng-container>
        <ng-container *ngIf="orderId" i18n>Edit order</ng-container>
    </h1>

    <p *ngIf="!_order" class="text-muted" i18n>Loading...</p>

    <div *ngIf="_order">
        <div class="row">
            <div class="col-md-6">
                <h3 i18n>Branch</h3>

                <div class="form-group">
                    <select class="form-control" name="branch_id" [(ngModel)]="_order.branch_id" [disabled]="_order.isFrozen()">
                        <option *ngFor="let branch of branches" [ngValue]="branch.id">{{ branch.name }}</option>
                    </select>
                </div>

                <h3 i18n>State</h3>

                <div class="form-group">
                    <select class="form-control" name="order_state_id" [(ngModel)]="_order.order_state_id" [disabled]="_order.isFrozen()">
                        <option *ngFor="let orderState of orderStates" [ngValue]="orderState.id">{{ orderState.name }}</option>
                    </select>
                </div>

                <ng-container *ngIf="_order.debtor">
                    <hr>

                    <h3 i18n>Payment agreement</h3>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="order-payment-agreement-days" i18n>Days</label>
                                <input type="number" min="0" id="order-payment-agreement-days" class="form-control" [(ngModel)]="_order.payment_agreement_days" (change)="_order.onPaymentAgreementDaysChange()" name="payment_agreement_days">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" [(ngModel)]="_order.payment_agreement_is_running_month" (change)="_order.onPaymentAgreementIsRunningMonthChange()" name="payment_agreement_is_running_month"> <ng-container i18n>Running month</ng-container>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <hr>

                <ng-container i18n>Created</ng-container>: <b>{{ _order.created.format('YYYY-MM-DD HH:mm:ss') }}</b><br>
                <ng-container i18n>Modified</ng-container>: <b>{{ _order.modified.format('YYYY-MM-DD HH:mm:ss') }}</b><br>

                <hr>

                <ng-template ngFor let-date [ngForOf]="_order.dates">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label" for="order-date-{{ date._joinData.id }}-time">{{ date.name }}</label>
                        <div class="col-sm-8">
                            <input (click)="dateTime.toggle()" type="text" id="order-date-{{ date._joinData.id }}-time" class="form-control  form-control-sm" [(ngModel)]="date._joinData.time" ngbDatepicker #dateTime="ngbDatepicker" name="{{ date._joinData.id }}-time" [disabled]="!date.is_editable">
                        </div>
                    </div>
                </ng-template>
            </div>

            <div class="col-md-6">
                <h2 i18n>Debtor</h2>

                <div class="row">
                    <div class="col-md-6">
                        <p *ngIf="!_order.debtor_id" class="text-muted" i18n>Debtor not selected</p>

                        <p *ngIf="_order.debtor"><ng-container i18n>Name</ng-container>: <b>{{ _order.debtor.name }}</b></p>
                    </div>

                    <div class="col-md-6">
                        <div *ngIf="!_order.isFrozen()" class="text-right">
                            <button *ngIf="_order.debtor_id" (click)="setDebtor()" class="btn  btn-sm  btn-secondary" [disabled]="saving" i18n>Change debtor</button>
                            <button *ngIf="!_order.debtor_id" (click)="setDebtor()" class="btn  btn-sm  btn-primary" [disabled]="saving" i18n>Set debtor</button>
                        </div>
                    </div>
                </div>

                <hr>

                <h2 i18n>Creditor</h2>

                <div class="row">
                    <div class="col-md-6">
                        <p *ngIf="!_order.creditor_id" class="text-muted" i18n>Creditor not selected</p>

                        <p *ngIf="_order.creditor"><ng-container i18n>Name</ng-container>: <b>{{ _order.creditor.name }}</b></p>
                    </div>

                    <div class="col-md-6">
                        <div *ngIf="!_order.isFrozen()" class="text-right">
                            <button *ngIf="_order.creditor_id" (click)="setCreditor()" class="btn  btn-sm  btn-secondary" [disabled]="saving" i18n>Change creditor</button>
                            <button *ngIf="!_order.creditor_id" (click)="setCreditor()" class="btn  btn-sm  btn-primary" [disabled]="saving" i18n>Set creditor</button>
                        </div>
                    </div>
                </div>

                <hr>

                <h2 i18n>Contact</h2>

                <app-contacts-editor [contactable]="_order" [allowRemove]="false" [disableName]="true" [hideNickname]="true"></app-contacts-editor>

                <ng-container *ngIf="orderId">
                    <hr>

                    <button (click)="copy()" class="btn  btn-sm  btn-primary" [disabled]="copying">
                        <ng-container *ngIf="copying" i18n>Copying...</ng-container>
                        <ng-container *ngIf="!copying" i18n>Copy order</ng-container>
                    </button>

                    <span class="text-muted  mx-3">|</span>

                    <button (click)="printModal('offer')" class="btn  btn-sm  btn-primary  mr-2" i18n>Offer</button>
                    <button (click)="printModal('order')" class="btn  btn-sm  btn-primary  mr-2" i18n>Order</button>
                    <button (click)="printModal('shipping')" class="btn  btn-sm  btn-primary  mr-2" i18n>Shipping</button>
                    <button (click)="printModal('invoice')" class="btn  btn-sm  btn-primary" i18n>Invoice</button>

                    <span class="text-muted  mx-3">|</span>

                    <button (click)="createInvoice()" class="btn  btn-sm  btn-primary" [disabled]="_order.hasDateWithType('is_invoiced')" i18n>Create invoice</button>
                </ng-container>
            </div>
        </div>

        <hr>

        <h2 i18n>Order</h2>

        <app-orderlines [order]="_order" [showExtendedCostPrice]="false"></app-orderlines>
<!--        <table class="table  table-sm  table-striped  table-hover">-->
<!--            <thead>-->
<!--                <tr>-->
<!--                    <th i18n>Product</th>-->
<!--                    <th style="width: 100px;" i18n>Quantity</th>-->
<!--                    <th style="width: 150px;" i18n>Alternative Unit</th>-->
<!--                    <th class="text-right" style="width: 100px;" i18n>Price</th>-->
<!--                    <th class="text-right" style="width: 100px;" i18n>Discount</th>-->
<!--                    <th class="text-right" style="width: 100px;" i18n>Cost</th>-->
<!--                    <th class="text-right" style="width: 100px;" i18n>Incl. VAT</th>-->
<!--                    <th class="text-right" style="width: 125px;" i18n>VAT</th>-->
<!--                    <th class="text-right" style="width: 90px;" i18n>Total</th>-->
<!--                    <th class="text-right" style="width: 150px;" i18n>Total Incl. VAT</th>-->
<!--                    <th style="width: 60px;"></th>-->
<!--                </tr>-->
<!--            </thead>-->

<!--            <tbody>-->
<!--                <tr *ngIf="_order.order_lines.length === 0">-->
<!--                    <td colspan="11" class="text-center  text-muted" i18n>No order lines</td>-->
<!--                </tr>-->

<!--                <ng-template ngFor let-orderLine [ngForOf]="_order.order_lines" let-i="index">-->
<!--                    <tr>-->
<!--                        <td>{{ orderLine.product.name }}</td>-->
<!--                        <td><input type="number" class="form-control  form-control-sm" [(ngModel)]="orderLine.quantity" (ngModelChange)="quantityChanged(orderLine)" name="quantity-{{ i }}" [disabled]="_order.isFrozen() || orderLine.is_follow_product || loading || saving"></td>-->
<!--                        <td>-->
<!--                            <ng-container *ngIf="orderLine.alternative_price_unit">-->
<!--                                <input type="number" class="form-control  form-control-sm" style="display: inline-block; width: 80px;" [(ngModel)]="orderLine.alternative_price_unit.quantity" (ngModelChange)="orderLine.onAlternativePriceUnitAmountChanged(orderLine.alternative_price_unit)" name="alternative-price-unit-quantity-{{ i }}" [disabled]="_order.isFrozen() || loading || saving">-->
<!--                                {{ orderLine.alternative_price_unit.unit }}-->
<!--                                <span class="text-muted">({{ orderLine.alternative_price_unit.factor }})</span>-->

<!--                                <span class="float-right">{{ orderLine.alternative_price_unit.getPrice(orderLine.price) | currency:'DKK':'':null }}</span>-->
<!--                            </ng-container>-->
<!--                        </td>-->
<!--                        <td class="text-right"><input type="number" class="form-control  form-control-sm" [(ngModel)]="orderLine.price" (ngModelChange)="orderLine.onPriceChange($event)" name="price-{{ i }}" [disabled]="_order.isFrozen() || loading || saving"></td>-->
<!--                         <td class="text-right"><input type="number" class="form-control  form-control-sm" [(ngModel)]="orderLine.discount_percentage" (ngModelChange)="orderLine.onDiscountChange($event)" min="0" max="100" name="discount" [disabled]="_order.isFrozen() || loading || saving"></td>-->
<!--                        <td class="text-right  text-muted">{{ orderLine.cost_price | currency:'DKK':'':null }}</td>-->
<!--                        <td class="text-right  text-muted">{{ orderLine.getPriceWithVAT() | currency:'DKK':'':null }}</td>-->
<!--                        <td class="text-right  text-muted">-->
<!--                            {{ orderLine.getVATAmount() | currency:'DKK':'':null }}-->
<!--                            ({{ orderLine.product.vat_type.rate | number }}%)-->
<!--                        </td>-->
<!--                        <td class="text-right">{{ orderLine.getTotal() | currency:'DKK':'':null }}</td>-->
<!--                        <td class="text-right  text-muted">{{ orderLine.getTotalWithVAT() | currency:'DKK':'':null }}</td>-->
<!--                        <td class="text-right"><a *ngIf="!_order.isFrozen() && !loading && !saving && !orderLine.is_follow_product" href="javascript:" (click)="_order.order_lines.splice(i, 1)" class="text-danger" i18n>Delete</a></td>-->
<!--                    </tr>-->
<!--                </ng-template>-->
<!--            </tbody>-->

<!--            <tfoot *ngIf="_order.order_lines.length > 0">-->
<!--                <tr>-->
<!--                    <td colspan="7"></td>-->
<!--                    <td colspan="2" i18n>Total</td>-->
<!--                    <td class="text-right">{{ _order.getTotal() | currency:'DKK':'':null }}</td>-->
<!--                    <td class="text-right"></td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td colspan="7"></td>-->
<!--                    <td colspan="2" i18n>VAT</td>-->
<!--                    <td class="text-right">{{ _order.getVATTotal() | currency:'DKK':'':null }}</td>-->
<!--                    <td class="text-right"></td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th colspan="7"></th>-->
<!--                    <th colspan="2" i18n>Total incl. VAT</th>-->
<!--                    <th class="text-right">{{ _order.getTotalWithVAT() | currency:'DKK':'':null }}</th>-->
<!--                    <th class="text-right"></th>-->
<!--                </tr>-->
<!--            </tfoot>-->
<!--        </table>-->

        <div class="text-right">
            <button *ngIf="!_order.isFrozen()" (click)="addOrderLine()" class="btn  btn-sm  btn-primary" [disabled]="saving" i18n>Add order line</button>
        </div>

        <hr>

        <div class="text-right">
            <button *ngIf="!_order.isFrozen()" (click)="save()" class="btn  btn-success" [disabled]="!_order.branch_id || saving">
                <ng-container *ngIf="saving" i18n>Saving...</ng-container>
                <ng-container *ngIf="!saving" i18n>Save</ng-container>
            </button>
        </div>
    </div>
</div>
