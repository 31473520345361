import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Debtor} from '../../../services/debtor';
import {Subject, Subscription} from 'rxjs';
import {Reference} from '../../../services/reference';
import {Creditor} from '../../../services/creditor';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {DebtorsService} from '../../../services/debtors.service';
import {CreditorsService} from '../../../services/creditors.service';
import {debounceTime} from 'rxjs/operators';
import {ReferencesService} from '../../../services/references.service';
import {Order} from '../../../services/order';
import {faExchangeAlt, faMapPin, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {Contact} from '../../../services/contact';
import {ContactTypesService} from "../../../services/contact-types.service";

@Component({
    selector: 'app-change-order-client',
    templateUrl: './change-order-client.component.html',
    styleUrls: ['./change-order-client.component.scss'],
    providers: [ContactTypesService]
})
export class ChangeOrderClientComponent implements OnInit {

    loadingClientChanges = false;

    debtorSearchResult: null | Debtor[]  = null;
    debtorModelChanged: Subject<string> = new Subject<string>();
    debtorSearchReference: null | Reference = null;
    debtorSubscription: Subscription;

    creditorSearchResult: null | Creditor[]  = null;
    creditorModelChanged: Subject<string> = new Subject<string>();
    creditorSearchReference: null | Reference = null;
    creditorSubscription: Subscription;

    showClientSearchResults = false;
    searchInputHasValue = false;

    debounceTime = 500;

    selectIcon = faExchangeAlt;

    isCreditor: boolean;

    order: Order | null | undefined;

    @Output() didChangeClient = new EventEmitter<Order>();
    @Input() clientType: 'debtor' | 'creditor' | undefined;
    @Input() doNotFilterSelected: boolean | undefined;
    @Input() placeholder: string | undefined;
    @Input() customListIcon: IconDefinition | undefined;
    @Input() selectedIcon: IconDefinition | undefined;

    constructor(
        public orderService: TenFourOrderService,
        public debtorService: DebtorsService,
        public creditorService: CreditorsService,
        public referencesService: ReferencesService,
        public contactTypesService: ContactTypesService) {
    }

    ngOnInit(): void {
        this.orderService.currentOrder.subscribe(response => {
            this.order = response;
            this.isCreditor = (this.clientType ? this.clientType === 'creditor' : this.orderService.isCreditor);
        });

        this.referencesService.getReferences().subscribe(response => {
            for (const reference of response.data) {
                if (reference.is_debtor_reference) {
                    this.debtorSearchReference = reference;
                }
                if (reference.is_creditor_reference){
                    this.creditorSearchReference = reference;
                }
            }
        });

        this.debtorSubscription = this.debtorModelChanged.pipe(
            debounceTime(this.debounceTime),
        ).subscribe((val) => {
            this.searchDebtors(val);
        });

        this.creditorSubscription = this.creditorModelChanged.pipe(
            debounceTime(this.debounceTime),
        ).subscribe((val) => {
            this.searchCreditors(val);
        });
    }

    searchDebtors(val: string): void {
        if (val.length > 0) {
            this.debtorService.getDebtors(1, -1, true, true, true, true, true, {
                byReferenceId: this.debtorSearchReference.id,
                byRefrenceValue: val
            }).subscribe(response => {
                this.debtorSearchResult = this.doNotFilterSelected ? response.data : this.filterSameDebtor(response.data);
            });
        } else {
            this.debtorSearchResult = null;
        }
    }

    searchCreditors(val: string): void {
        if (val.length > 0) {
            this.creditorService.getCreditors(1, -1, true, true, {
                byReferenceId: this.creditorSearchReference.id,
                byRefrenceValue: val
            }).subscribe(response => {
                this.creditorSearchResult = this.doNotFilterSelected ? response.data : this.filterSameCreditor(response.data);
            });
        } else {
            this.creditorSearchResult = null;
        }
    }

    /**
     * Set a new client, if a contact is provided, we use that, otherwise we pick the first with order invoice type
     *
     * @param newClient
     * @param contact
     */
    setClient(newClient: Debtor | Creditor, contact: Contact | null): void {
        let defaultContact: Contact;

        if (contact) {
            defaultContact = contact;
        } else {
            let invoiceContacts = newClient.contacts.filter((contact) => {
                return contact.isOrderInvoiceType();
            });

            if (invoiceContacts.length > 0) {
                defaultContact = invoiceContacts[0];
            }
        }

        const blankContact = Contact.fromJSON({
            nickname: '',
            full_name: '',
            address: '',
            zip: '',
            city: ''
        });

        this.order = this.orderService.setNewContact(this.order, (defaultContact ? defaultContact : blankContact), 'Contact');
        this.order = this.orderService.setNewContact(this.order, blankContact, 'Delivery');

        if (newClient instanceof Creditor){
            this.changeCreditor(newClient);
        } else if (newClient instanceof Debtor) {
            this.changeDebtor(newClient);
        }

        this.didChangeClient.emit(this.order);
    }

    public changeCreditor(newCreditor: Creditor): void {
        this.order.addCreditor(newCreditor);
    }

    public changeDebtor(newDebtor: Debtor): void {
        this.order.addDebtor(newDebtor);
    }

    onClientInputChange(val: string): void {
        this.debtorSearchResult = null;
        this.creditorSearchResult = null;
        if (val.length > 0){
            this.showClientSearchResults = true;
        } else {
            this.showClientSearchResults = false;
        }
        if (this.isCreditor){
            this.creditorModelChanged.next(val);
        } else {
            this.debtorModelChanged.next(val);
        }
    }

    filterSameDebtor(data: Debtor[]): Debtor[]{
        return data.filter((item) => {
            return item.id !== this.order.debtor_id;
        });
    }
    filterSameCreditor(data: Creditor[]): Creditor[]{
        return data.filter((item) => {
            return item.id !== this.order.creditor_id;
        });
    }

}
