<div class="container app__component">
    <h1 class="heading">
        <span i18n>Branches</span>
        <small *ngIf="loading && branches" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!branches" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="branches">
        <a class="btn btn-primary button button--add-to-table" href="javascript:" (click)="add()" i18n>Add branch</a>
    </p>

    <app-table *ngIf="branches" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-branch [ngForOf]="branches">
                <tr>
                    <td>{{ branch.name }} <small><code>{{ branch.slug }}</code></small></td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(branch)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(branch)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>
</div>
