import {Component, OnInit} from '@angular/core';
import {Order} from "../../../services/order";
import {OrdersService} from "../../../services/orders.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OrdersComponent} from "../../../orders/orders.component";

@Component({
    selector: 'thorsens-app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    providers: [OrdersService]
})
export class ThorsensOrdersComponent extends OrdersComponent {

}
