import * as dayjs from 'dayjs';

export class ContactType {

    id: string;
    name: string;
    contact_field_preset_id: string;
    sorting: number;
    is_order_invoice: boolean;
    is_order_delivery: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: ContactTypeJSON | string): ContactType {
        if (typeof json === 'string') {
            return JSON.parse(json, ContactType.reviver);
        } else {
            let contactType = Object.create(ContactType.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(contactType, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ContactType.fromJSON(value) : value;
    }
}

// A representation of ContactType data that can be converted to
// and from JSON without being altered.
interface ContactTypeJSON {
    id?: string;
    name?: string;
    contact_field_preset_id?: string;
    sorting?: number;
    is_order_invoice?: boolean;
    is_order_delivery?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
