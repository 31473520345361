<div class="container  mb-5">
    <h1 class="heading">
        Audit log
        <small *ngIf="loading && auditLogs" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!auditLogs" class="text-muted" i18n>Loading...</app-loader>

    <app-table-helper *ngIf="auditLogs">
        <form class="form-inline">
            <div class="form-group  mr-3  mb-2">
                <input type="number" min="1" class="form-control  form-control-sm" [(ngModel)]="limit" name="limit" style="display: inline; width: 75px;" [disabled]="loading">
            </div>

            <div class="form-group  mr-3  mb-2">
                <input (click)="dateStart.toggle()" type="text" class="form-control  form-control-sm" name="date_start" i18n-placeholder placeholder="Start date" [(ngModel)]="start" ngbDatepicker #dateStart="ngbDatepicker" [disabled]="loading">
            </div>

            <div class="form-group  mr-3  mb-2">
                <input (click)="dateEnd.toggle()" type="text" class="form-control  form-control-sm" name="date_end" i18n-placeholder placeholder="End date" [(ngModel)]="end" ngbDatepicker #dateEnd="ngbDatepicker" [disabled]="loading">
            </div>

            <button (click)="load(page)" type="submit" class="btn  btn-sm  btn-primary  mb-2" [disabled]="loading">
                <ng-container *ngIf="loading" i18n>Searching...</ng-container>
                <ng-container *ngIf="!loading" i18n>Search</ng-container>
            </button>
        </form>
    </app-table-helper>


    <app-table *ngIf="auditLogs">
        <thead>
            <tr>
                <th i18n>Model</th>
                <th i18n>Operation</th>
                <th i18n>Entity</th>
                <th i18n>User</th>
                <th class="text-right" i18n>Date</th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-auditLog [ngForOf]="auditLogs">
                <tr>
                    <td>{{ auditLog.getModel() }}</td>
                    <td>
                        <span *ngIf="auditLog.operation === 'login'" class="badge  badge-secondary" i18n>Login</span>
                        <span *ngIf="auditLog.operation === 'token'" class="badge  badge-secondary" i18n>Token refresh</span>

                        <span *ngIf="auditLog.operation === 'order_state_update'" class="badge  badge-warning" i18n>Order state</span>

                        <span *ngIf="auditLog.operation === 'create'" class="badge  badge-success" i18n>Created</span>
                        <span *ngIf="auditLog.operation === 'update'" class="badge  badge-primary" i18n>Edited</span>
                        <span *ngIf="auditLog.operation === 'delete'" class="badge  badge-danger" i18n>Deleted</span>
                    </td>
                    <td>
                        {{ auditLog.getIdentification() }}
                        <small><code>{{ auditLog.foreign_key }}</code></small>
                    </td>
                    <td>{{ auditLog.user.name}}</td>
                    <td class="text-right">{{ auditLog.created.format('YYYY-MM-DD HH:mm:ss') }}</td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="auditLogs && count > auditLogs.length">
        <ngb-pagination class="d-flex  justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
