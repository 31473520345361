import * as dayjs from 'dayjs';
import {Translation} from './translation';

export class ProductField {

    id: string;
    name: string;
    type: string;
    is_localized: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    _joinData;
    _translations: Translation;

    constructor() {
    }

    static fromJSON(json: ProductFieldJSON | string): ProductField {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductField.reviver);
        } else {
            const productField = Object.create(ProductField.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _joinData: null,
                _translations: null,
            };

            Object.keys(json).forEach((key, index) => {
                if (key === '_joinData' && json[key]) {
                    extra._joinData = {};

                    Object.keys(json[key]).forEach((keyKey, index) => {
                        extra._joinData[keyKey] = json[key][keyKey];
                    });
                }
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(productField, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductField.fromJSON(value) : value;
    }
}

// A representation of ProductField data that can be converted to
// and from JSON without being altered.
interface ProductFieldJSON {
    id?: string;
    name?: string;
    type?: string;
    is_localized?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    _joinData?;
}
