import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin} from "rxjs";
import {PriceAdjustment} from "../services/price-adjustment";
import {ProductCategorySelectModalComponent} from "../product-category-select-modal/product-category-select-modal.component";
import {PriceAdjustmentsService} from "../services/price-adjustments.service";
import {PriceAdjustmentRelation} from "../services/price-adjustment-relation";
import {DebtorSelectModalComponent} from "../debtor-select-modal/debtor-select-modal.component";
import {ProductSelectModalComponent} from "../product-select-modal/product-select-modal.component";
import {CreditorSelectModalComponent} from "../creditor-select-modal/creditor-select-modal.component";
import {PriceGroupSelectModalComponent} from "../price-group-select-modal/price-group-select-modal.component";

@Component({
    selector: 'app-price-adjustment-edit',
    templateUrl: './price-adjustment-edit.component.html',
    styleUrls: ['./price-adjustment-edit.component.scss'],
    providers: [PriceAdjustmentsService]
})
export class PriceAdjustmentEditComponent implements OnInit {

    priceAdjustmentId;

    _priceAdjustment: PriceAdjustment;

    saving = false;

    constructor(public priceAdjustmentsService: PriceAdjustmentsService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router) {
        this.priceAdjustmentId = activatedRoute.snapshot.params.priceAdjustmentId;
    }

    ngOnInit(): void {
        if (this.priceAdjustmentId) {
            forkJoin([
                this.priceAdjustmentsService.getPriceAdjustment(this.priceAdjustmentId, true)
            ]).subscribe(joinData => {
                this._priceAdjustment = PriceAdjustment.fromJSON(joinData[0].data);
            });
        } else {
            this._priceAdjustment = PriceAdjustment.fromJSON({});
        }
    }

    public addPriceAdjustmentRelation() {
        this._priceAdjustment.addPriceAdjustmentRelation()
    }

    public addProduct(priceAdjustmentRelation: PriceAdjustmentRelation) {
        const modalRef = this.ngbModal.open(ProductSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.productRelatable = priceAdjustmentRelation;
    }

    public addCategory(priceAdjustmentRelation: PriceAdjustmentRelation) {
        const modalRef = this.ngbModal.open(ProductCategorySelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.categorizable = priceAdjustmentRelation;
    }

    public addDebtor(priceAdjustmentRelation: PriceAdjustmentRelation) {
        const modalRef = this.ngbModal.open(DebtorSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.debtorRelatable = priceAdjustmentRelation;
    }

    public addCreditor(priceAdjustmentRelation: PriceAdjustmentRelation) {
        const modalRef = this.ngbModal.open(CreditorSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.creditorRelatable = priceAdjustmentRelation;
    }

    public addPriceGroup(priceAdjustmentRelation: PriceAdjustmentRelation) {
        const modalRef = this.ngbModal.open(PriceGroupSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.priceGroupRelatable = priceAdjustmentRelation;
    }

    public save() {
        let isAffectingAll = false;

        for (const priceAdjustmentRelation of this._priceAdjustment.price_adjustment_relations) {
            if (priceAdjustmentRelation.product_id || priceAdjustmentRelation.product_category_id || priceAdjustmentRelation.debtor_id || priceAdjustmentRelation.creditor_id || priceAdjustmentRelation.price_group_id) {
                continue;
            }

            isAffectingAll = true;
        }

        if (!isAffectingAll || confirm($localize `Are you sure?\n\nOne or more added price adjustment relations has no associations and will affect all prices`)) {
            this.saving = true;

            if (this.priceAdjustmentId) {
                this.priceAdjustmentsService.updatePriceAdjustment(this._priceAdjustment).subscribe(response => {
                    this.saving = false;

                    this.router.navigateByUrl('/price-adjustments/' + response.data.id);
                }, error => {
                    this.saving = false;

                    alert(error.error.error);
                });
            } else {
                this.priceAdjustmentsService.addPriceAdjustment(this._priceAdjustment).subscribe(response => {
                    this.saving = false;

                    this.router.navigateByUrl('/price-adjustments/' + response.data.id);
                });
            }
        }
    }
}
