import {Component, OnInit} from '@angular/core';
import {Reference} from "../services/reference";
import {ReferencesService} from "../services/references.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReferenceEditModalComponent} from "../reference-edit-modal/reference-edit-modal.component";

@Component({
    selector: 'app-references',
    templateUrl: './references.component.html',
    styleUrls: ['./references.component.sass'],
    providers: [ReferencesService]
})
export class ReferencesComponent implements OnInit {

    references: Reference[];

    loading = false;

    constructor(public referencesService: ReferencesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.referencesService.getReferences().subscribe(response => {
            this.references = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(ReferenceEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(reference: Reference) {
        let self = this;

        const modalRef = this.ngbModal.open(ReferenceEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.reference = reference;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(reference: Reference) {
        if (confirm($localize `Are you sure?\n\nThe reference will be deleted`)) {
            this.loading = true;

            this.referencesService.deleteReference(reference).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
