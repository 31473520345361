import {Pipe, PipeTransform} from "@angular/core";

// import {$localize} from '@angular/localize/init';

@Pipe({name: 'dynamicTranslate'})
export class DynamicTranslatePipe implements PipeTransform {
    transform(value) {
        switch (value) {
            case 'Hkg after reduction':
                return $localize`:@@Hkg after reduction:Hkg after reduction`;
            case 'Analysis %':
                return $localize`:@@Analysis %:Analysis %`;
            case 'Max allowed %':
                return $localize`:@@Max allowed %:Max allowed %`;
            case 'Reduce by %':
                return $localize`:@@Reduce by %:Reduce by %`;
        }
        return value;
    }
}
