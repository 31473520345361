import {Component, Input, OnInit} from '@angular/core';
import {Contactable} from "../services/contact";

@Component({
    selector: 'app-contacts-editor',
    templateUrl: './contacts-editor.component.html',
    styleUrls: ['./contacts-editor.component.scss'],
    providers: []
})
export class ContactsEditor implements OnInit {

    @Input() public contactable: Contactable;
    @Input() public allowRemove: boolean = true
    @Input() public disableName: boolean;
    @Input() public hideNickname: boolean;

    constructor() {
    }

    ngOnInit() {
    }

    removeContact(index: number): void {
        this.contactable.contacts.splice(index, 1);
    }
}
