import {Injectable} from '@angular/core';
import {Branch} from './branch';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable()
export class BranchesService {

    protected url = environment.apiBase + 'branches';

    constructor(public apiService: ApiService) {
    }

    getBranch(id: string): Observable<{data: Branch, count: number, perPage: number}> {
        return this.apiService.get<Branch>(this.url + '/' + id, true, Branch);
    }

    getBranches(query?: BranchesQueryInterface): Observable<{data: Branch[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        if (query && query.withStatistics) {
            parameters.push('with-statistics=' + query.withStatistics);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<Branch[]>(url, true, Branch);
    }

    addBranch(branch: Branch): Observable<{data: Branch, count: number, perPage: number}> {
        return this.apiService.post<Branch>(this.url, branch, true, Branch);
    }

    updateBranch(branch: Branch): Observable<{data: Branch, count: number, perPage: number}> {
        return this.apiService.patch<Branch>(this.url + '/' + branch.id, branch, true, Branch);
    }

    deleteBranch(branch: Branch): Observable<{data: Branch, count: number, perPage: number}> {
        return this.apiService.delete<Branch>(this.url + '/' + branch.id, true, Branch);
    }
}

export interface BranchesQueryInterface {
    withStatistics?: string;
}
