import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {ApiService} from "../../../services/api.service";
import {Observable} from "rxjs";
import {Field} from "./field";

@Injectable()
export class FieldsService {

    protected url = environment.apiBase + 'fields';

    constructor(public apiService: ApiService) {
    }


    getFields(creditorId, year?: number, byFieldReference?: string, getSum?: boolean, forBreed?:string): Observable<{ data: Field[], count: number, perPage: number }> {
        let url = this.url;

        const parameters = [];
        if (creditorId) {
            parameters.push('creditor-id=' + creditorId);
        }

        if (year) {
            parameters.push('year=' + year);
        }

        if (forBreed) {
            parameters.push('forBreed=' + forBreed);
        }

        if (byFieldReference) {
            parameters.push('by-field-reference=' + byFieldReference);
        }

        if (getSum) {
            parameters.push('total-sum=1');
        }
        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<Field[]>(url, true, Field);
    }
}
