import {Component, OnInit} from '@angular/core';
import {Debtor} from "../services/debtor";
import {DebtorsQueryInterface, DebtorsService} from "../services/debtors.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DebtorEditModalComponent} from "../debtor-edit-modal/debtor-edit-modal.component";
import {faCheck, faPencilAlt, faTimes, faBan} from '@fortawesome/free-solid-svg-icons';
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'app-debtors',
    templateUrl: './debtors.component.html',
    styleUrls: ['./debtors.component.scss'],
    providers: [DebtorsService]
})
export class DebtorsComponent implements OnInit {

    debtors: Debtor[];

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    faPencilAlt = faPencilAlt;
    faTimes = faTimes;
    filter = {queryType: 'debtor_name', query: null, includeInactive: false};
    faCheck = faCheck;
    faBan = faBan;

    constructor(
        public authService: AuthService,
        public debtorsService: DebtorsService,
        public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number) {
        this.loading = true;

        const query: DebtorsQueryInterface = {};
        query.includeInactive = this.filter.includeInactive;

        if (this.filter && this.filter.query) {
            switch (this.filter.queryType) {
                case 'debtor_name':
                    query.byName = this.filter.query;
                    break;

                case 'debtor_reference':
                    query.byReferenceId = '0284656a-dd5e-43aa-905f-2c90e9645cf8'; // @todo Nav ID
                    query.byRefrenceValue = this.filter.query;
                    break;
            }
        }

        this.debtorsService.getDebtors(page, this.limit, true, true, true, true, true, query).subscribe(response => {
            this.debtors = response.data;
            this.count = response.count;
            this.perPage = response.perPage;
            this.loading = false;
        });
    }

    pageChange(page: number) {
        this.load(page);
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(DebtorEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load(self.page);
        }
    }

    edit(debtor: Debtor) {
        let self = this;

        const modalRef = this.ngbModal.open(DebtorEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.debtor = debtor;
        modalRef.componentInstance.saved = function () {
            self.load(self.page);
        }
    }

    delete(debtor: Debtor) {
        if (confirm($localize`Are you sure?\n\nThe debtor will be deleted`)) {
            this.loading = true;

            this.debtorsService.deleteDebtor(debtor).subscribe(_ => {
                this.loading = false;

                this.load(this.page);
            });
        }
    }
}
