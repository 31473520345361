import * as dayjs from 'dayjs';
import {Group} from "./group";

export class User {

    id: string;
    group_id: string;
    external_reference: string;
    name: string;
    email: string;
    password: string;
    password_control: string;
    two_factor_authentication: boolean;
    two_factor_authentication_secret: string;
    two_factor_authentication_password: string;
    active: boolean;
    token_not_after: dayjs.Dayjs;
    token_not_before: dayjs.Dayjs;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    group: Group;

    constructor() {
    }

    static fromJSON(json: UserJSON|string): User {
        if (typeof json === 'string') {
            return JSON.parse(json, User.reviver);
        } else {
            let user = Object.create(User.prototype);

            let extra = {
                token_not_after: json.token_not_after ? dayjs(json.token_not_after) : null,
                token_not_before: json.token_not_before ? dayjs(json.token_not_before) : null,
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                group: null
            }

            Object.keys(json).forEach((key, index) => {
                if (key === 'group' && json[key]) {
                    extra.group = Group.fromJSON(json[key]);
                }
            });

            return Object.assign(user, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? User.fromJSON(value) : value;
    }
}

// A representation of User data that can be converted to
// and from JSON without being altered.
interface UserJSON {
    id?: string;
    group_id?: string;
    external_reference?: string;
    name?: string;
    email?: string;
    password?: string;
    password_control?: string;
    two_factor_authentication?: boolean;
    active?: boolean;
    token_not_after?: dayjs.Dayjs;
    token_not_before?: dayjs.Dayjs;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
