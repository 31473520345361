<ng-container *ngIf="order">
    <!--
    <pre>{{order.order_fields|json}}</pre>
    -->
    <app-create-order *ngIf="!order.id"></app-create-order>
    <ng-container *ngIf="order.id">
        <app-step-stones
            [stepStones]="orderSteps"
            [activeStepIndex]="orderService.currentStepIndex"
            [showStepStones]="true"
            [showStepStoneNavigation]="false"
            (shouldChangeStepIndex)="onChangeStep($event)"
        ></app-step-stones>
        <h1 class="heading">#{{order.getIdentificationReference()}}</h1>
        <div class="row">
            <div class="col-12  col-sm-6  col-xl-3  mb-4">
                <div class="data-board">
                    <app-client-contact-info [order]="order" [orderType]="orderType"></app-client-contact-info>
                </div>
            </div>
            <div class="col-12  col-sm-6  col-xl-3  mb-4">
                <div class="data-board">
                    <app-dates-and-info [order]="order" [debtor]="debtor" [creditor]="creditor"></app-dates-and-info>
                </div>
            </div>
            <div class="col-12  col-sm-6  col-xl-3  mb-4">
                <div class="data-board">
                    <p *ngIf="!contactFieldPreset">Indlæser...</p>
                    <app-client-contact-meta [order]="order" [contactFields]="contactFieldPreset"></app-client-contact-meta>
                </div>
            </div>
            <div class="col-12  col-sm-6  col-xl-3  mb-4">
                <div class="data-board">
                    <app-total-prices [order]="order"></app-total-prices>
                </div>
            </div>
        </div>
        <router-outlet name="order_outlet"></router-outlet>
        <app-step-stones [canGoToNextStep]="true" [stepStones]="orderSteps" [activeStepIndex]="orderService.currentStepIndex" [showStepStones]="false" [showStepStoneNavigation]="true" (shouldChangeStepIndex)="onChangeStep($event)"></app-step-stones>
    </ng-container>
</ng-container>
<app-loader *ngIf="!order"></app-loader>
