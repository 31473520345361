import {Component, OnInit} from '@angular/core';
import {OrderState} from '../services/order-state';
import {OrderStatesService} from '../services/order-states.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderStateEditModalComponent} from '../order-state-edit-modal/order-state-edit-modal.component';
import {LanguagesService} from "../services/languages.service";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-order-states',
    templateUrl: './order-states.component.html',
    styleUrls: ['./order-states.component.sass'],
    providers: [OrderStatesService, LanguagesService]
})
export class OrderStatesComponent implements OnInit {

    orderStates: OrderState[];

    loading = false;
    faExclamation = faExclamationCircle;
    missingLocalizations = [];


    constructor(public orderStatesService: OrderStatesService,
                public ngbModal: NgbModal,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this.languagesService.getLanguages().subscribe(() => {
            this.load();
        });
    }

    load() {
        this.loading = true;

        this.orderStatesService.getOrderStates().subscribe(response => {
            this.orderStates = response.data;
            this.orderStates.forEach((state) => {
                this.missingLocalizations.push(state._translations.checkMissingLocalizations(this.languagesService.languages));
            });
            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(OrderStateEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(orderState: OrderState, index: number) {
        let self = this;

        const modalRef = this.ngbModal.open(OrderStateEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.orderState = orderState;
        modalRef.componentInstance.missingLocalizations = this.missingLocalizations[index];
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(orderState: OrderState) {
        if (confirm($localize `Are you sure?\n\nThe order state will be deleted`)) {
            this.loading = true;

            this.orderStatesService.deleteOrderState(orderState).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }

    saveSorting() {
        this.loading = true;

        let sorting = 0;
        let orderStates = [];

        for (let orderState of this.orderStates) {
            orderStates.push(OrderState.fromJSON({
                id: orderState.id,
                sorting: sorting
            }));

            sorting++;
        }

        this.orderStatesService.updateOrderStates(orderStates).subscribe(_ => {
            this.loading = false;
        });
    }
}
