<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Select price group</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!priceGroups" class="text-muted" i18n>Loading...</p>

            <div *ngIf="priceGroups">
                <div class="form-group">
                    <label for="price-group" i18n>Price group</label>
                    <select id="price-group" class="form-control  form-control-sm" [(ngModel)]="priceGroup" name="price-group">
                        <option [ngValue]="null" i18n>-- Select price group --</option>
                        <option *ngFor="let priceGroup of priceGroups" [ngValue]="priceGroup">{{ priceGroup.name }}</option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="!priceGroups" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!priceGroups || !priceGroup" i18n>Select</button>
    </div>
</div>
