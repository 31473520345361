import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Empty} from "./empty";

@Injectable()
export class PrintService {

    protected url = environment.apiBase + 'print-content';

    constructor(public apiService: ApiService) {
    }

    getContentPdf(url: string, footerHtml: string = ''): Observable<{ data: Empty, count: number, perPage: number }> {
        return this.apiService.post<Empty>(this.url, {
            footerHtml, url
        }, true, Empty);
    }
}
