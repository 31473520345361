<div class="TenFour-Order-View">
    <div class="row">
        <div class="col  mb-4">
            <div class="data-board">
                <app-products-search-and-add>
                    <button *ngFor="let item of options" class="btn  btn-sm  btn-secondary  ml-1  mb-1" (click)="onClick()" ngbTooltip="This explains the button!" [ngStyle]="{color: item.color ? item.color : '#FFF'}">
                        <fa-icon [icon]="item.option"></fa-icon>
                    </button>
                </app-products-search-and-add>
            </div>
        </div>
    </div>
    <app-layout-splitter [isSplitScreen]="showAdditionalData" (shouldCloseAdditionalContent)="closeAdditionalData($event)">
        <app-orderlines [order]="order" mainContent>
        </app-orderlines>
        <app-orderlines [order]="order" secondaryContent *ngIf="showAdditionalData === true">
        </app-orderlines>
    </app-layout-splitter>
</div>
