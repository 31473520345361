import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ProductCategory} from "./product-category";
import {Empty} from "./empty";

@Injectable()
export class ProductCategoriesService {

    protected url = environment.apiBase + 'product-categories';

    constructor(public apiService: ApiService) {
    }

    getProductCategory(id: string): Observable<{data: ProductCategory, count: number, perPage: number}> {
        return this.apiService.get<ProductCategory>(this.url + '/' + id, true, ProductCategory);
    }

    getProductCategories(asThreaded: boolean, withReferences: boolean): Observable<{data: ProductCategory[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        if (asThreaded) {
            parameters.push('as-threaded=1');
        }

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<ProductCategory[]>(url, true, ProductCategory);
    }

    getProductCategoriesAsTreeList(): Observable<any> {
        return this.apiService.get<Empty[]>(this.url + '?as-tree-list=1', true, Empty);
    }

    addProductCategory(productCategory: ProductCategory): Observable<{data: ProductCategory, count: number, perPage: number}> {
        return this.apiService.post<ProductCategory>(this.url, productCategory, true, ProductCategory);
    }

    updateProductCategory(productCategory: ProductCategory): Observable<{data: ProductCategory, count: number, perPage: number}> {
        return this.apiService.patch<ProductCategory>(this.url + '/' + productCategory.id, productCategory, true, ProductCategory);
    }

    deleteProductCategory(productCategory: ProductCategory): Observable<{data: ProductCategory, count: number, perPage: number}> {
        return this.apiService.delete<ProductCategory>(this.url + '/' + productCategory.id, true, ProductCategory);
    }
}
