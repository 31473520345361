import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ContactField} from "./contact-field";

@Injectable()
export class ContactFieldsService {

    protected url = environment.apiBase + 'contact-fields';

    constructor(public apiService: ApiService) {
    }

    getContactField(id: string): Observable<{ data: ContactField, count: number, perPage: number }> {
        return this.apiService.get<ContactField>(this.url + '/' + id, true, ContactField);
    }

    getContactFields(page: number, limit: number): Observable<{ data: ContactField[], count: number, perPage: number }> {
        let url = this.url;

        let parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            parameters.push('limit=' + limit);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<ContactField[]>(url, true, ContactField);
    }

    addContactField(contactField: ContactField): Observable<{ data: ContactField, count: number, perPage: number }> {
        return this.apiService.post<ContactField>(this.url, contactField, true, ContactField);
    }

    updateContactField(contactField: ContactField): Observable<{ data: ContactField, count: number, perPage: number }> {
        return this.apiService.patch<ContactField>(this.url + '/' + contactField.id, contactField, true, ContactField);
    }

    deleteContactField(contactField: ContactField): Observable<{ data: ContactField, count: number, perPage: number }> {
        return this.apiService.delete<ContactField>(this.url + '/' + contactField.id, true, ContactField);
    }
}
