import * as dayjs from 'dayjs';

export class OrderType {

    id: string;
    name: string;
    sorting: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: OrderTypeJSON | string): OrderType {
        if (typeof json === 'string') {
            return JSON.parse(json, OrderType.reviver);
        } else {
            let orderType = Object.create(OrderType.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(orderType, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? OrderType.fromJSON(value) : value;
    }
}

// A representation of OrderType data that can be converted to
// and from JSON without being altered.
interface OrderTypeJSON {
    id?: string;
    name?: string;
    sorting?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
