import {Injectable} from '@angular/core';
import {Creditor} from './creditor';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Empty} from "./empty";
import {CreditorsUser} from "./creditors-user";
import {User} from "./user";
import {Contact} from "./contact";

@Injectable()
export class CreditorsService {

    protected url = environment.apiBase + 'creditors';

    constructor(public apiService: ApiService) {
    }

    getCreditor(id: string, withReferences: boolean, withContacts: boolean, withUsers: boolean, withBalance: boolean): Observable<{data: Creditor, count: number, perPage: number}> {
        let url = this.url + '/' + id;

        let parameters = [];

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (withContacts) {
            parameters.push('with-contacts=1');
        }

        if (withUsers) {
            parameters.push('with-users=1');
        }

        if (withBalance) {
            parameters.push('with-balance=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<Creditor>(url, true, Creditor);
    }

    getCreditors(page:number, limit:number, withReferences: boolean, withContacts: boolean, query?: CreditorsQueryInterface): Observable<{data: Creditor[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            if (limit === -1) {
                parameters.push('all=1');
            } else {
                parameters.push('limit=' + limit);
            }
        }

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (withContacts) {
            parameters.push('with-contacts=1');
        }

        if (query && query.byReferenceId && query.byRefrenceValue) {
            parameters.push('by-ref-id=' + query.byReferenceId);
            parameters.push('by-ref-value=' + query.byRefrenceValue);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<Creditor[]>(url, true, Creditor);
    }

    addCreditor(creditor: Creditor): Observable<{data: Creditor, count: number, perPage: number}> {
        return this.apiService.post<Creditor>(this.url, creditor, true, Creditor);
    }

    updateCreditor(creditor: Creditor): Observable<{data: Creditor, count: number, perPage: number}> {
        return this.apiService.patch<Creditor>(this.url + '/' + creditor.id, creditor, true, Creditor);
    }

    deleteCreditor(creditor: Creditor): Observable<{data: Creditor, count: number, perPage: number}> {
        return this.apiService.delete<Creditor>(this.url + '/' + creditor.id, true, Creditor);
    }

    addCreditorsUser(creditorId: string, creditorsUser: User): Observable<{data: Empty, count: number, perPage: number}> {
        return this.apiService.post<Empty>(this.url + '/' + creditorId + '/users', creditorsUser, true, Empty);
    }

    updateCreditorsUser(creditorsUser: CreditorsUser): Observable<{data: CreditorsUser, count: number, perPage: number}> {
        return this.apiService.patch<CreditorsUser>(this.url + '/users/' + creditorsUser.id, creditorsUser, true, CreditorsUser);
    }

    deleteCreditorsUser(creditorsUser: CreditorsUser): Observable<{data: CreditorsUser, count: number, perPage: number}> {
        return this.apiService.delete<CreditorsUser>(this.url + '/users/' + creditorsUser.id, true, CreditorsUser);
    }

    addCreditorsContact(creditorId: string, contact: Contact): Observable<{data: Empty, count: number, perPage: number}> {
        return this.apiService.post<Contact>(this.url + '/' + creditorId + '/contacts', contact, true, Contact);
    }

    updateCreditorsContact(creditorContactId: string, contact: Contact): Observable<{data: Contact, count: number, perPage: number}> {
        return this.apiService.patch<Contact>(this.url + '/contacts/' + creditorContactId, contact, true, Contact);
    }

    deleteCreditorsContact(creditorContactId: string): Observable<{data: Contact, count: number, perPage: number}> {
        return this.apiService.delete<Contact>(this.url + '/contacts/' + creditorContactId, true, Contact);
    }
}

export interface CreditorsQueryInterface {
    byReferenceId?: string;
    byRefrenceValue?: any;
}
