import {Creditor} from "../../../services/creditor";
import {ProductField} from "../../../services/product-field";

export class Field {

    field_reference: string;
    creditor: Creditor;
    number_deliveries: number;
    sum_hkg: number;
    avg_hkg: number;
    product_fields: ProductField[];

    public fieldValueCache: any = {};
    public fieldMetaCache: any = {};

    constructor() {
    }

    static fromJSON(json: OrderJSON | string): Field {
        if (typeof json === 'string') {
            return JSON.parse(json, Field.reviver);
        } else {
            let order = new Field();

            let extra = {
                product_fields: [],
            };
            Object.keys(json).forEach((key, _) => {

                if (key === 'creditor' && json[key]) {
                    extra[key] = Creditor.fromJSON(json[key]);
                }
                if (key === 'product_fields') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductField.fromJSON(value));
                    });
                }
            });
            return Object.assign(order, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Field.fromJSON(value) : value;
    }

    public getFieldValueForId(id): null | any {
        if (this.fieldValueCache[id]) {
            return this.fieldValueCache[id];
        }
        if (this.product_fields.length > 0) {
            const result: any = this.product_fields.find((item) => {
                return item.id === id;
            });
            if (result) {
                this.fieldValueCache[id] = result._joinData.value;
                return result._joinData.value;
            }
        }
        this.fieldValueCache[id] = null;
        return null;
    }
}

interface OrderJSON extends Field {
}
