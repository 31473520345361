<div class="container app__component">
    <h1 class="heading">
        <ng-container i18n>Users</ng-container>
        <small *ngIf="loading && users" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!users" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="users">
        <a class="btn btn-primary button button--add-to-table" href="javascript:" (click)="add()" i18n>Add user</a>
    </p>


    <app-table-helper *ngIf="users">
        <form (ngSubmit)="changeQuery()">
            <div class="row">
                <div class="col-6">
                    <div class="input-group">
                        <input type="text" class="form-control" i18n-placeholder placeholder="Search" name="search"
                               [(ngModel)]="query.search">
                    </div>
                </div>
                <div class="col-6">
                    <button type="submit" class="btn  btn-primary">Søg</button>
                </div>
            </div>
        </form>
    </app-table-helper>

    <app-table [autoSizeLastColumn]="true" *ngIf="users">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Group</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-user [ngForOf]="users" let-index="index">
                <tr>
                    <td>
                        {{ user.name }}
                        <span class="mr-2">&lt;{{ user.email }}&gt;</span>
                        <span *ngIf="user.external_reference" class="badge  badge-secondary  mr-2">{{ user.external_reference }}</span>
                        <span *ngIf="!user.active" class="badge  badge-danger  mr-2" i18n>Inactive</span>
                        <span *ngIf="user.two_factor_authentication" (click)="disable2FA(user)" class="badge  badge-success  mr-2" style="cursor: pointer;" i18n>2FA</span>
                    </td>
                    <td>{{ user.group.name }}</td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(user)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(user)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="users && count > users.length">
        <ngb-pagination class="d-flex  justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
