import * as _ from "lodash";

/**
 *
 */
export class RecursiveHelper {

    static recursiveFunction(collection: Object, callback: (path: string, value: string, key: string) => any, path: string[] = [], joiner?:string) {
        if (!joiner) {
            joiner = '.';
        }
        _.each(collection, (value, key) => {
            if (typeof value === 'object') {
                path.push(key);
                RecursiveHelper.recursiveFunction(value, callback, path, joiner);
                path.pop();
            } else if (typeof value === 'string') {
                callback(path.join(joiner), value, key);
            }
        });
    }
}
