import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionLine} from "../services/production-line";
import {ProductionLinesService} from "../services/production-lines.service";
import * as dayjs from "dayjs";
import {ProductionLineWeek} from "../services/production-line-week";
import {Product} from "../../services/product";
import {ProductsService} from "../../services/products.service";
import {IdValuesService} from "../../services/themes/helms/id-values.service";

@Component({
    selector: 'app-production-line-edit-modal',
    templateUrl: './production-line-edit-modal.component.html',
    styleUrls: ['./production-line-edit-modal.component.scss'],
    providers: [ProductionLinesService, ProductsService]
})
export class ProductionLineEditModalComponent implements OnInit {

    public productionLine: ProductionLine;
    public _productionLine: ProductionLine;

    public saving = false;
    public deleting = false;
    public saved;

    public addingProduct = false;

    searchTerm: string = '';

    loading: boolean = false;

    products: Product[];

    public addingProductionLineWeek = false;

    public productionLineWeekDate = dayjs().add(1, 'week');
    public productionLineWeekSlotsWeek = 0;

    constructor(public activeModal: NgbActiveModal,
                public productionLinesService: ProductionLinesService,
                public productsService: ProductsService,
                public ngbModal: NgbModal,
                public idValuesService: IdValuesService,
    ) {
    }

    ngOnInit() {
        this._productionLine = this.productionLine ? ProductionLine.fromJSON(this.productionLine) : new ProductionLine();
    }

    showProductForm() {
        this.addingProduct = !this.addingProduct;
    }

    searchProduct() {
        this.loading = true;
        this.products = undefined;

        this.productsService.getProducts(1, 100, false, false, false, false, false, false, {
        //    byReferenceId: 'e79ff942-6260-4166-becc-bacda11b85e3',
         //   byRefrenceValue: this.searchTerm,
            forProductName: this.searchTerm,
            byCategories: this.idValuesService.productMachineCategoryId
        }).subscribe(response => {
            this.products = response.data;

            this.loading = false;
        });
    }

    addProduct(product: Product) {
        this.addingProduct = false;

        this._productionLine.products.push(product);
    }

    removeProduct(index: number) {
        if (confirm($localize `Are you sure?\n\nThe product will be removed`)) {
            this._productionLine.products.splice(index, 1);
        }
    }

    showProductionLineWeekForm() {
        this.addingProductionLineWeek = !this.addingProductionLineWeek;
    }

    addProductionLineWeek() {
        this.addingProductionLineWeek = false;

        let productionLineWeek = new ProductionLineWeek();

        productionLineWeek.production_line_id = this._productionLine.id;
        productionLineWeek.date = this.productionLineWeekDate.format('YYYY-MM-DD');
        productionLineWeek.slots_week = this.productionLineWeekSlotsWeek;

        this._productionLine.production_line_weeks.push(productionLineWeek);
    }

    removeProductionLineWeek(index: number) {
        if (confirm($localize `Are you sure?\n\nThe week will be removed`)) {
            this._productionLine.production_line_weeks.splice(index, 1);
        }
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.productionLine) {
            this.productionLinesService.updateProductionLine(this._productionLine).subscribe(productionLine => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productionLine);
            });
        } else {
            this.productionLinesService.addProductionLine(this._productionLine).subscribe(productionLine => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productionLine);
            });
        }
    }

    delete() {
        if (confirm($localize `Are you sure?\n\nThe production line will be deleted`)) {
            this.deleting = true;

            this.productionLinesService.deleteProductionLine(this._productionLine).subscribe(productionLine => {
                this.saved();

                this.deleting = false;

                this.activeModal.close(productionLine);
            });
        }

    }
}
