<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!this.group" i18n>Add group</ng-container>
            <ng-container *ngIf="this.group" i18n>Edit group</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label i18n>Name</label>
                        <input type="text" class="form-control" [(ngModel)]="_group.name" name="name">
                    </div>

                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="_group.default" name="default"> <ng-container i18n>Default group for new users</ng-container>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <table class="table  table-sm  table-borderless">
                        <thead>
                            <tr>
                                <th i18n>Granted permissions</th>
                                <th class="text-right"></th>
                            </tr>
                        </thead>

                        <tbody *ngIf="_group.group_permissions.length > 0">
                            <ng-template ngFor let-groupPermission [ngForOf]="_group.group_permissions">
                                <tr>
                                    <td><span class="badge  badge-primary">{{ groupPermission.permission }}</span></td>
                                    <td class="text-right">
                                        <a href="javascript:" (click)="_group.removeGroupPermission(groupPermission)" class="text-danger" i18n>Delete</a>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>

                        <tbody *ngIf="_group.group_permissions.length === 0">
                            <tr>
                                <td colspan="2" class="text-center  text-muted"><small i18n>No permissions granted</small></td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="authService.hasAvailablePermissions(_group)">
                        <hr>

                        <table class="table  table-sm  table-borderless">
                            <thead>
                            <tr>
                                <th i18n>Available permissions</th>
                                <th class="text-right"></th>
                            </tr>
                            </thead>

                            <tbody>
                            <ng-template ngFor let-permission [ngForOf]="authService.availablePermissions">
                                <tr *ngIf="!_group.hasPermission(permission)">
                                    <td><span class="badge  badge-primary">{{ permission }}</span></td>
                                    <td class="text-right">
                                        <a href="javascript:" (click)="_group.addGroupPermission(permission)" class="text-success" i18n>Add</a>
                                    </td>
                                </tr>
                            </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
