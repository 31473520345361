<table class="table  table-bordered  table-sm  mb-0">
    <tr>
        <td>Brutto:</td>
        <td><strong>{{ order.getTotal() | currency:'DKK':'':null }}</strong></td>
    </tr>
    <tr>
        <td>Rabat:</td>
        <td><strong>{{ order.getTotalDiscount() | currency:'DKK':'':null }}</strong></td>
    </tr>
    <tr>
        <td>Netto:</td>
        <td><strong>{{ order.getTotal() - order.getTotalDiscount() | currency:'DKK':'':null }}</strong></td>
    </tr>
    <tr>
        <td>DB:</td>
        <td>
            <div class="row">
                <div class="col">
                    <strong>{{ order.getTotalProfit() | currency:'DKK':'':null }}</strong>
                </div>
                <div class="col">
                    <strong>{{ order.getTotalProfitAdjustedCost() | currency:'DKK':'':null }}</strong>
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td>DG:</td>
        <td>
            <div class="row">
                <div class="col">
                    <strong>{{ order.getTotal() > 0 ? (order.getTotalProfitPercentage().toFixed(1) + ' %') : '-' }}</strong>
                </div>
                <div class="col">
                    <strong>{{ order.getTotal() > 0 ? (order.getTotalProfitAdjustedCostPercentage().toFixed(1) + ' %') : '-' }}</strong>
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td>Kost:</td>
        <td>
            <div class="row">
                <div class="col">
                    <strong>{{ order.getTotalCost() | currency:'DKK':'':null }}</strong>
                </div>
                <div class="col">
                    <strong>{{ order.getTotalCostAdjusted() | currency:'DKK':'':null }}</strong>
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td>Vægt</td>
        <td class="bg-danger"></td>
    </tr>
</table>
