import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faCheck, faCheckSquare, faSquare} from "@fortawesome/free-solid-svg-icons";
import {Order} from "../../../services/order";
import {TenFourOrderService} from "../../../services/themes/10-4/ten-four-order.service";

@Component({
    selector: 'app-select-new-order-client',
    templateUrl: './select-new-order-client.component.html',
    styleUrls: ['./select-new-order-client.component.scss']
})
export class SelectNewOrderClientComponent implements OnInit {

    @Output() didChangeClient = new EventEmitter<Order>();
    @Input() useClientType: 'debtor' | 'creditor';

    selectIcon = faSquare;
    selectedIcon = faCheckSquare;

    constructor(public orderService: TenFourOrderService) {
    }

    ngOnInit(): void {
    }

    onChangeClient(newOrder: Order): void {
        this.didChangeClient.emit(newOrder);
    }

}
