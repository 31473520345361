<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Order "{{ type }}" print</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p *ngIf="!pdf" class="text-muted" i18n>Loading...</p>

        <ng-container *ngIf="pdf">
            <object id="pdf" [data]="'data:application/pdf;base64, ' + pdf | safeEmbeddedImage" style="width: 100%; height: 600px;"></object>
        </ng-container>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="print()" i18n>Print</button>
    </div>
</div>
