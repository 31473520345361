<div *ngIf="_product">
    <h1>
        {{_product.name}}
    </h1>

    <div class="text-left">
        <a [routerLink]="['/', 'settings', {outlets: {settings_outlet: ['products']}}]" i18n>Back</a>
    </div>
    <div class="text-right">
        <button (click)="save()" class="btn  btn-success" [disabled]="saving || copying">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>

    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'product'}" id="product" (click)="changeTab('product')"
               i18n>Product</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'child'}" id="child" (click)="changeTab('child')"
               i18n>Child relations</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'parent'}" id="parent" (click)="changeTab('parent')"
               i18n>Parent relations</a>
        </li>
    </ul>

    <div class="tab-content">
        <ng-template [ngIf]="activeTab === 'product' && _product">
            <app-product-edit
                [_product]="_product"
                [productId]="productId"
                [productFields]="productFields"
                [fieldTranslations]="fieldTranslations"
                [_products_product_fields]="_products_product_fields"
                [missingLocalizations]="missingLocalizations"
                [creditors]="creditors"
                [vatTypes]="vatTypes"
                [units]="units"
                [priceGroups]="priceGroups"
                [name]="name"
                [saving]="saving"
            >
            </app-product-edit>
        </ng-template>
        <ng-template [ngIf]="activeTab === 'child' && _product">
            <app-product-child-relations-edit [_product]="_product" [saving]="saving"></app-product-child-relations-edit>
        </ng-template>
        <ng-template [ngIf]="activeTab === 'parent' && _product">
            <app-product-parent-relations-edit [_product]="_product" [saving]="saving"></app-product-parent-relations-edit>
        </ng-template>
    </div>

    <div class="text-right mt-3">
        <button *ngIf="authService.hasPermission('admin')" (click)="copy()" class="btn  btn-sm  btn-link  mr-3" style="vertical-align: -1px;" [disabled]="saving || copying">
            <ng-container *ngIf="copying" i18n>Copying product...</ng-container>
            <ng-container *ngIf="!copying" i18n>Copy product</ng-container>
        </button>

        <button (click)="save()" class="btn  btn-success" [disabled]="saving || copying">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
<app-loader *ngIf="!_product"></app-loader>


