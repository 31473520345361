import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../services/auth.service';
import {ProductField} from '../services/product-field';
import {ProductFieldsService} from '../services/product-fields.service';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from '../services/locale-translation';

@Component({
    selector: 'app-product-field-edit-modal',
    templateUrl: './product-field-edit-modal.component.html',
    styleUrls: ['./product-field-edit-modal.component.scss'],
    providers: [ProductFieldsService, LanguagesService]
})
export class ProductFieldEditModalComponent implements OnInit {

    public productField: ProductField;
    // tslint:disable-next-line:variable-name
    public _productField: ProductField;

    public saving = false;
    public saved;

    public missingLocalizations;
    public name: string[] = [];

    constructor(public activeModal: NgbActiveModal,
                public productFieldsService: ProductFieldsService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this._productField = this.productField ? ProductField.fromJSON(this.productField) : ProductField.fromJSON({
            type: 'text'
        });
        if (!this.productField) {
            this.missingLocalizations = null;
        }
        this.languagesService.getLanguages().subscribe(() => {
            this.loadLanguages();
        });
    }

    loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {
            if (language.locale === this.languagesService.primaryLanguage.locale) {
                if (this._productField.name) {
                    this.name[i] = this._productField.name;
                } else {
                    this.name[i] = '';
                }
            } else {
                if (this._productField._translations) {
                    const translation = this._productField._translations[language.locale];
                    if (translation) {
                        if (translation.name) {
                            this.name[i] = translation.name;
                        } else {
                            this.name[i] = '';
                        }
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }
            }
            i++;
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        this.saveLanguages();

        if (this.productField) {
            this.productFieldsService.updateProductField(this._productField).subscribe(productField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productField);
            });
        } else {
            this.productFieldsService.addProductField(this._productField).subscribe(productField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productField);
            });
        }
    }

    saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {
            if (this.languagesService.isSame(language, this.languagesService.primaryLanguage)) {
                this._productField.name = this.name[i];
            } else {
                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;
            }
            this._productField._translations = translations;
            i++;
        });
    }
}
