<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Contact field presets</ng-container>
        <small *ngIf="loading && contactFieldPresets" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!contactFieldPresets" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="contactFieldPresets">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add contact field preset</a>
    </p>

    <app-table *ngIf="contactFieldPresets" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Contact fields</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-contactFieldPreset [ngForOf]="contactFieldPresets">
                <tr>
                    <td>{{ contactFieldPreset.name }}</td>
                    <td>
                        <ng-template ngFor let-contactField [ngForOf]="contactFieldPreset.contact_fields">
                            <span class="badge  badge-secondary  mr-2">{{ contactField.name }}</span>
                        </ng-template>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(contactFieldPreset)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(contactFieldPreset)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>
</div>
