import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PaymentAgreementsService} from "../services/payment-agreements.service";
import {PaymentAgreement} from "../services/payment-agreement";
import {PaymentAgreementEditModalComponent} from "../payment-agreement-edit-modal/payment-agreement-edit-modal.component";

@Component({
    selector: 'app-payment-agreements',
    templateUrl: './payment-agreements.component.html',
    styleUrls: ['./payment-agreements.component.scss'],
    providers: [PaymentAgreementsService]
})
export class PaymentAgreementsComponent implements OnInit {

    paymentAgreements: PaymentAgreement[];

    loading = false;

    constructor(public paymentAgreementsService: PaymentAgreementsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.paymentAgreementsService.getPaymentAgreements().subscribe(response => {
            this.paymentAgreements = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(PaymentAgreementEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(paymentAgreement: PaymentAgreement) {
        let self = this;

        const modalRef = this.ngbModal.open(PaymentAgreementEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.paymentAgreement = paymentAgreement;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(paymentAgreement: PaymentAgreement) {
        if (confirm($localize `Are you sure?\n\nThe payment agreement will be deleted`)) {
            this.loading = true;

            this.paymentAgreementsService.deletePaymentAgreement(paymentAgreement).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
