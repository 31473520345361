import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';

export interface ICard {
    name: string;
    emitValue?: any;
    icon?: IconDefinition;
}

@Component({
    selector: 'app-card-select',
    templateUrl: './card-select.component.html',
    styleUrls: ['./card-select.component.scss']
})
export class CardSelectComponent implements OnInit {

    @Input() cardData: ICard[];
    @Input() activeValue: any;
    @Output() valueEmitter = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onCardClick(val: any): void {
        this.valueEmitter.emit(val);
    }

}
