import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VatTypesService} from "../services/vat-types.service";
import {VatType} from "../services/vat-type";
import {VatTypeEditModalComponent} from "../vat-type-edit-modal/vat-type-edit-modal.component";

@Component({
    selector: 'app-vat-types',
    templateUrl: './vat-types.component.html',
    styleUrls: ['./vat-types.component.scss'],
    providers: [VatTypesService]
})
export class VatTypesComponent implements OnInit {

    vatTypes: VatType[];

    loading = false;

    constructor(public vatTypesService: VatTypesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.vatTypesService.getVatTypes().subscribe(response => {
            this.vatTypes = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(VatTypeEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(vatType: VatType) {
        let self = this;

        const modalRef = this.ngbModal.open(VatTypeEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.vatType = vatType;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(vatType: VatType) {
        if (confirm($localize `Are you sure?\n\nThe VAT type will be deleted`)) {
            this.loading = true;

            this.vatTypesService.deleteVatType(vatType).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
