import {Component, OnInit} from '@angular/core';
import {faTrophy} from '@fortawesome/free-solid-svg-icons';
import {IdValuesService} from "../../../services/themes/helms/id-values.service";
import {DebtorsService} from "../../../services/debtors.service";
import {Debtor} from "../../../services/debtor";
import * as dayjs from "dayjs";
import {AuthService} from "../../../services/auth.service";
import {ApiService} from "../../../services/api.service";
import {environment} from "../../../../environments/environment";
import {HttpParams} from "@angular/common/http";

@Component({
    selector: 'app-helms-scoreboard',
    templateUrl: './helms-scoreboard.component.html',
    styleUrls: ['./helms-scoreboard.component.scss'],
    providers: [IdValuesService, DebtorsService]
})
export class HelmsScoreboardComponent implements OnInit {

    public faTrophy = faTrophy;

    public now = dayjs();
    public lastMonth = dayjs().subtract(1, 'month');

    public topSellersThisYear: Debtor[];
    public topSellersThisMonth: Debtor[];
    public topSellersLastMonth: Debtor[];

    public monthStrings = [
        'Januar',
        'Februar',
        'Marts',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'December',
    ];
    private authedPermissions: any;
    public isAdmin: boolean;

    constructor(public idValuesService: IdValuesService,
                public authService: AuthService,
                public apiService: ApiService,
                public debtorsService: DebtorsService) {
        this.authedPermissions = this.authService.authedPermissionsDataStream();

    }

    ngOnInit(): void {

        this.authedPermissions.subscribe((_) => {
            this.isAdmin = this.authService.hasPermission('admin');
        });

        //  // Top sellers this year
        //  this.debtorsService.getDebtors(1, 3, false, false, false, false, false, {
        //      withStatistics: this.idValuesService.orderedOrderStateId,
        //      statisticsYear: this.now.year()
        //  }).subscribe(response => {
        //      this.topSellersThisYear = response.data;
        //  });


        let params: HttpParams = new HttpParams();
        params = params.set('statistics-year', this.now.format('YYYY'));
        params = params.set('statistics-month', this.now.format('MM'));
        this.apiService.get<Debtor[]>(environment.apiBase + 'statistics/dealers', true, Debtor, params).subscribe((response) => {
            this.topSellersThisMonth = response.data;
        });


        // Top sellers this month
        //   this.debtorsService.getDebtors(1, 5, false, false, false, false, false, {
        //       withStatistics: this.idValuesService.orderedOrderStateId,
        //       statisticsYear: this.now.year(),
        //       statisticsMonth: this.now.month() + 1
        //   }).subscribe(response => {
        //       this.topSellersThisMonth = response.data;
        //   });

        //   // Top sellers last month
        //   this.debtorsService.getDebtors(1, 5, false, false, false, false, false, {
        //       withStatistics: this.idValuesService.orderedOrderStateId,
        //       statisticsYear: this.lastMonth.year(),
        //       statisticsMonth: this.lastMonth.month() + 1
        //   }).subscribe(response => {
        //       this.topSellersLastMonth = response.data;
        //   });
    }

}
