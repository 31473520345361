<div class="row">
    <div class="col-3" style="padding: 0 0 0 30px;">
        <div class="nav-sidebar">
            <small class="text-muted">Bruger: <span *ngIf="this.authService.currentUser">{{this.authService.currentUser.name}}</span></small>

            <h2 class="mt-3  mb-0" style="color: #0B3793;">Menu</h2>

            <hr class="mt-0">

            <ul class="list-unstyled">
                <li><a routerLink="/orders/add">Ny ordre</a></li>
                <li><a href="">Rediger</a></li>
            </ul>

            <ul class="list-unstyled">
                <li><a href="">Godkend</a></li>
                <li><a href="">Godkend alle</a></li>
                <li><a href="">Del-levering</a></li>
            </ul>

            <ul class="list-unstyled">
                <li><a href="">Spørge ordre</a></li>
                <li><a href="">Ordre historie</a></li>
                <li><a href="">SMS</a></li>
                <li><a href="">Spørge pris</a></li>
                <li><a href="">Kørsel</a></li>
                <li><a href="">Udlejning</a></li>
                <li><a href="">Godkend webordre</a></li>
                <li><a href="">Egne følgesedler (afd)</a></li>
            </ul>

            <ul class="list-unstyled">
                <li><a href="">Ændre nøgle/password</a></li>
                <li><a href="">Ret notat</a></li>
                <li><a href="">Afslut leveringsadresse</a></li>
                <li><a href="">Ret kundepris</a></li>
            </ul>
        </div>
    </div>

    <div class="col">
        <app-ten-four-orders></app-ten-four-orders>
    </div>
</div>
