<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!paymentAgreement" i18n>Add payment agreement</ng-container>
            <ng-container *ngIf="paymentAgreement" i18n>Edit payment agreement</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="payment-agreement-name" i18n>Name</label>
                <input type="text" id="payment-agreement-name" class="form-control" [(ngModel)]="_paymentAgreement.name" name="name">
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="payment-agreement-days" i18n>Days</label>
                        <input type="number" min="0" id="payment-agreement-days" class="form-control" [(ngModel)]="_paymentAgreement.days" (change)="_paymentAgreement.onDaysChange()" name="days">
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="_paymentAgreement.is_running_month" (change)="_paymentAgreement.onIsRunningMonthChange()" name="is_running_month"> <ng-container i18n>Running month</ng-container>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <hr>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_paymentAgreement.is_default" name="is_default"> <ng-container i18n>Default payment agreement</ng-container>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
