import {Component, OnInit} from '@angular/core';
import {ProductsComponent} from '../../../products/products.component';
import {ProductsService} from '../../../services/products.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderLine} from '../../../services/order-line';
import {OrderDeliveriesModalComponent} from '../order-deliveries-modal/order-deliveries-modal.component';
import {ThorsensDeliveryDetailsComponent} from '../thorsens-delivery-details/thorsens-delivery-details.component';
import {Product} from '../../../services/product';
import {ProductFieldPresetsService} from '../../../services/product-field-presets.service';
import {ProductFieldPreset} from '../../../services/product-field-preset';
import {ActivatedRoute, Router} from "@angular/router";
import {FieldsService} from "../services/fields.service";
import {Field} from "../services/field";
import {ProductCategoriesService} from "../../../services/product-categories.service";

@Component({
    selector: 'app-deliveries',
    templateUrl: './deliveries.component.html',
    styleUrls: ['./deliveries.component.scss'],
    providers: [ProductsService, ProductFieldPresetsService, FieldsService]

})
export class DeliveriesComponent extends ProductsComponent implements OnInit {

    productFieldPresets: ProductFieldPreset;
    sumFields: Field[] | null = null;

    constructor(
        public fieldsService: FieldsService,
        public router: Router, private route: ActivatedRoute,
        public productsService: ProductsService,
        public ngbModal: NgbModal,
        public productFieldPresetsService: ProductFieldPresetsService,
        public productCategoriesService: ProductCategoriesService,
    ) {
        super(productsService, productCategoriesService, ngbModal);

        this.filterQuery = {
            withProductFields: true,
            withProductRelations: true,

        };

        //@todo maybe load the correct preset depending on category and link to index with one category instead of both
        this.productFieldPresetsService.getProductFieldPreset('c5bc3f6c-7170-4ea4-b666-d01196b46d05').subscribe((result) => {
            this.productFieldPresets = result.data;
        });

        this.route.queryParamMap.subscribe(parmas => {

            if (parmas.get('limit')) {
                this.limit = parseInt(parmas.get('limit'));
            } else {
                this.limit = 20;
            }

            if (parmas.get('categoryId')) {
                this.filterQuery.byCategories = parmas.get('categoryId');
            } else {
                this.filterQuery.byCategories = 'a2c21341-b893-4719-8d3e-4ffc0390675c,4a9b67a5-f00f-487d-964b-e050e02fe539';
            }

            if (parmas.get('creditorId')) {
                this.filterQuery.byCreditorId = parmas.get('creditorId');
            } else {
                this.filterQuery.byCreditorId = null;
            }

            if (parmas.get('fieldId') && parmas.get('fieldValue')) {
                this.filterQuery.byFieldId = parmas.get('fieldId');
                this.filterQuery.byFieldValue = parmas.get('fieldValue');

                this.fieldsService.getFields(this.filterQuery.byCreditorId, 2020, this.filterQuery.byFieldValue, true).subscribe((response) => {
                    this.sumFields = response.data;
                });

            } else {
                this.filterQuery.byFieldId = null;
                this.filterQuery.byFieldValue = null;
                this.sumFields = [];
            }
            this.load(1);
        });
    }

    ngOnInit(): void {
        this.load(1);
    }

    load(page: number): void {
        this.loading = true;

        this.productsService.getProducts(page, this.limit, true, false, true, true, true, false, this.filterQuery).subscribe(response => {
            this.products = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }


    showDetails(product: Product): void {
        const modalRef = this.ngbModal.open(ThorsensDeliveryDetailsComponent, {size: 'xl'});
        modalRef.componentInstance.productId = product.id;
    }
}
