import * as dayjs from 'dayjs';
import {ProductField} from "./product-field";
import {Categorizable, ProductCategory} from "./product-category";

export class ProductFieldPreset implements Categorizable {

    id: string;
    name: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    product_fields: ProductField[];
    product_categories: ProductCategory[];

    constructor() {
    }

    static fromJSON(json: ProductFieldPresetJSON | string): ProductFieldPreset {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductFieldPreset.reviver);
        } else {
            let productFieldPreset = Object.create(ProductFieldPreset.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product_fields: [],
                product_categories: []
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'product_fields') {
                    json[key].forEach((value, _) => {
                        extra.product_fields.push(ProductField.fromJSON(value));
                    });
                }

                if (key === 'product_categories') {
                    json[key].forEach((value, _) => {
                        extra.product_categories.push(ProductCategory.fromJSON(value));
                    });
                }
            });

            return Object.assign(productFieldPreset, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductFieldPreset.fromJSON(value) : value;
    }

    addProductField(productField: ProductField): void {
        this.product_fields.push(ProductField.fromJSON({
            id: productField.id,
            name: productField.name
        }));
    }

    removeProductField(productField: ProductField): void {
        let index = this.product_fields.findIndex((element) => {
            return element['id'] === productField.id;
        });

        if (index >= 0) {
            this.product_fields.splice(index, 1);
        }
    }

    hasProductField(productField: ProductField): boolean {
        let index = this.product_fields.findIndex((element) => {
            return element['id'] === productField.id;
        });

        return index >= 0;
    }

    addCategory(productCategory: ProductCategory): void {
        this.product_categories.push(ProductCategory.fromJSON({
            id: productCategory.id,
            name: productCategory.name
        }));
    }

    removeCategory(productCategory: ProductCategory): void {
        let index = this.product_categories.findIndex((element) => {
            return element['id'] === productCategory.id;
        });

        if (index >= 0) {
            this.product_categories.splice(index, 1);
        }
    }

    hasCategory(productCategory: ProductCategory): boolean {
        let index = this.product_categories.findIndex((element) => {
            return element['id'] === productCategory.id;
        });

        return index >= 0;
    }
}

// A representation of ProductFieldPreset data that can be converted to
// and from JSON without being altered.
interface ProductFieldPresetJSON {
    id?: string;
    name?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
