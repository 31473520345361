import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

    public environment;

    constructor(public authService: AuthService) {
        this.environment = environment;
    }

    ngOnInit(): void {
    }
}
