<div class="orders">
    <h1 class="heading" i18n>Factory</h1>

    <form (ngSubmit)="search()" class="d-print-none">
        <div class="row  mt-1  mb-4">
            <label class="col-12  col-lg-2">
                <span i18n>Production week</span>:<br/>

                <input type="text" class="form-control" name="datestart" i18n-placeholder placeholder="Select date"
                       (click)="dateStart.toggle()" [(ngModel)]="date" ngbDatepicker
                       [markDisabled]="disableDays" outsideDays="hidden" [showWeekNumbers]="true"
                       [ngModelOptions]="{standalone: true}"
                       #dateStart="ngbDatepicker">
            </label>

            <label class="col-12  col-lg-2">
                <span i18n>TMT Number</span>:<br/>

                <input type="text" class="form-control" name="tmt" [(ngModel)]="tmtNummer">
            </label>


            <label class="col-12  col-lg-4">
                <span i18n>Search</span>:<br/>
                <div class="d-flex">
                    <button type="submit" class="btn  btn-primary" i18n>Search</button>
                </div>
            </label>
        </div>
    </form>

    <div class="row" *ngIf="date">
        <div class="col-12">
            <p class="h3">
            <span i18n>Week</span> {{date.format('W')}} / {{date.format('YYYY')}}
            </p>
        </div>
    </div>
    <div class="row">
        <ng-container *ngFor="let order of orders">
            <div class="col-12  col-md-6">
                <small class="font-weight-bold"><span i18n>TMT</span> #</small><br/>
                <span>{{ order.getReference(idValuesService.tmtNumberId) }}</span>
                <hr/>
                <app-helms-order-edit [orderId]="order.id" [factoryView]="true"></app-helms-order-edit>
            </div>
        </ng-container>
    </div>
</div>

