import {Component, Input, OnInit} from '@angular/core';
import {LanguagesService} from '../services/languages.service';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-language-picker',
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnInit {

    @Input() missingLocalizations;
    faExclamation = faExclamationCircle;

    constructor(public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
    }

    public changeLanguage(language): void {
        this.languagesService.setLanguage(language);
    }

}
