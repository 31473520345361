import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ContactFieldPreset} from "./contact-field-preset";

@Injectable()
export class ContactFieldPresetsService {

    protected url = environment.apiBase + 'contact-field-presets';

    constructor(public apiService: ApiService) {
    }

    getContactFieldPreset(id: string, withContactFields: boolean): Observable<{data: ContactFieldPreset, count: number, perPage: number}> {
        let url = this.url + '/' + id;

        let parameters = [];

        if (withContactFields) {
            parameters.push('with-contact-fields=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<ContactFieldPreset>(url, true, ContactFieldPreset);
    }

    getContactFieldPresets(withContactFields: boolean): Observable<{data: ContactFieldPreset[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        if (withContactFields) {
            parameters.push('with-contact-fields=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<ContactFieldPreset[]>(url, true, ContactFieldPreset);
    }

    addContactFieldPreset(contactFieldPreset: ContactFieldPreset): Observable<{data: ContactFieldPreset, count: number, perPage: number}> {
        return this.apiService.post<ContactFieldPreset>(this.url, contactFieldPreset, true, ContactFieldPreset);
    }

    updateContactFieldPreset(contactFieldPreset: ContactFieldPreset): Observable<{data: ContactFieldPreset, count: number, perPage: number}> {
        return this.apiService.patch<ContactFieldPreset>(this.url + '/' + contactFieldPreset.id, contactFieldPreset, true, ContactFieldPreset);
    }

    deleteContactFieldPreset(contactFieldPreset: ContactFieldPreset): Observable<{data: ContactFieldPreset, count: number, perPage: number}> {
        return this.apiService.delete<ContactFieldPreset>(this.url + '/' + contactFieldPreset.id, true, ContactFieldPreset);
    }
}
