import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Categorizable, ProductCategory} from '../services/product-category';
import {ProductCategoryEditModalComponent} from '../product-category-edit-modal/product-category-edit-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductCategoriesService} from '../services/product-categories.service';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {LanguagesService} from '../services/languages.service';

@Component({
    selector: 'app-product-categories-tree',
    templateUrl: './product-categories-tree.component.html',
    styleUrls: ['./product-categories-tree.component.sass'],
    providers: [ProductCategoriesService, LanguagesService]
})
export class ProductCategoriesTreeComponent implements OnInit {

    @Input() productCategories: ProductCategory[];
    @Input() missingLocalizations;
    // So the under categories know where to start
    @Input() missingLocalizationsIndex = 0;

    @Input() selectingForCategorizable: Categorizable;

    @Output() shouldUpdate = new EventEmitter<any>();

    @Output() didDelete = new EventEmitter<any>();
    @Output() didSelect = new EventEmitter<any>();
    @Output() didDeselect = new EventEmitter<any>();

    faExclamation = faExclamationCircle;
    loading = false;

    constructor(public productCategoriesService: ProductCategoriesService,
                public ngbModal: NgbModal,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
    }

    select(productCategory: ProductCategory): void {
        this.didSelect.emit(productCategory);
    }

    deselect(productCategory: ProductCategory): void {
        this.didDeselect.emit(productCategory);
    }

    edit(productCategory: ProductCategory, index: number): void {
        const self = this;

        const modalRef = this.ngbModal.open(ProductCategoryEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productCategory = productCategory;
        modalRef.componentInstance.missingLocalizations = this.missingLocalizations[index];
        modalRef.componentInstance.saved = () => {
            self.shouldUpdate.emit();
        };
    }

    delete(productCategory: ProductCategory): void {
        const self = this;

        if (confirm($localize `Are you sure?\n\nThe product category will be deleted`)) {
            self.didDelete.emit();

            this.productCategoriesService.deleteProductCategory(productCategory).subscribe(result => {

                self.shouldUpdate.emit();
            });
        }
    }
}
