<div class="orders    app__component" [ngClass]="{'container':!embedded}">
    <h1 class="heading" i18n *ngIf="!embedded">Orders</h1>

    <app-loader *ngIf="!orders || (loading && orders)" [useOverlayLoader]="loading && orders ? true : false" i18n>Loading...</app-loader>

    <p *ngIf="!embedded && orders && authService.hasPermission('admin')">
        <a class="btn  btn-primary  button  button--add-to-table" routerLink="/orders/add" i18n>Add order</a>
    </p>

    <app-table-helper *ngIf="orders && !embedded">

        <div class="form-group">
            <select class="form-control  form-control-sm" name="order-type-filter" style="width: 150px;" [(ngModel)]="typeFilter" (change)="filterChanged()" [disabled]="loading">
                <option [ngValue]="null" i18n>Any</option>
                <option [ngValue]="'debtor'" i18n>Debtor only</option>
                <option [ngValue]="'creditor'" i18n>Creditor only</option>
            </select>
        </div>

        <div>
            <input type="number" min="1" class="form-control  form-control-sm" [(ngModel)]="limit" name="limit" style="display: inline; width: 75px;" [disabled]="loading">
            <button (click)="load(page)" class="btn  btn-sm  btn-secondary  ml-2" style="transform: translateY(-1px);" [disabled]="loading" i18n>Load</button>
        </div>
    
    </app-table-helper>

    <app-table *ngIf="orders" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Number</th>
                <th i18n>State</th>
                <th i18n>Branch</th>
                <th i18n *ngIf="!embedded">Debtor</th>
                <th i18n *ngIf="!embedded">Creditor</th>
                <th class="text-right" i18n>Order lines</th>
                <th class="text-right" i18n>Revenue</th>
                <th class="text-right" i18n>Date</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-order [ngForOf]="orders">
                <tr>
                    <td>{{ order.getIdentificationReference() }}</td>
                    <td>
                        {{ order.order_state.name }}
                        <span *ngIf="order.isFrozen()" class="badge  badge-danger" i18n>Frozen</span>
                    </td>
                    <td>{{ order.branch.name }}</td>
                    <td *ngIf="!embedded">
                        <span *ngIf="order.debtor">{{ order.debtor.name }}</span>
                        <span *ngIf="!order.debtor" class="text-muted" i18n>-</span>
                    </td>
                    <td *ngIf="!embedded">
                        <span *ngIf="order.creditor">{{ order.creditor.name }}</span>
                        <span *ngIf="!order.creditor" class="text-muted" i18n>-</span>
                    </td>
                    <td class="text-right">{{ order.order_lines.length }}</td>
                    <td class="text-right">{{ order.getTotal() | currency:'DKK':'':null }}</td>
                    <td class="text-right">{{ order.created.format('YYYY-MM-DD') }}</td>
                    <td>
                        <a routerLink="/orders/{{ order.id }}">{{ order.isFrozen() ? 'View' : 'Edit' }}</a>
                        <a (click)="deleteOrder(order)" *ngIf="!order.isFrozen()" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="orders && count > orders.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
