<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!productFieldPreset" i18n>Add product field preset</ng-container>
            <ng-container *ngIf="productFieldPreset" i18n>Edit product field preset</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p *ngIf="!productFields || !productCategories" class="text-muted" i18n>Loading...</p>

        <form *ngIf="productFields && productCategories">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="product-field-preset-name" i18n>Name</label>
                        <input type="text" id="product-field-preset-name" class="form-control" [(ngModel)]="_productFieldPreset.name" name="name">
                    </div>
                </div>

                <div class="col-md-6">
                    <b i18n>Product categories</b>

                    <app-product-categories-tree [missingLocalizations]="[]" [productCategories]="productCategories" [selectingForCategorizable]="_productFieldPreset" (didSelect)="this._productFieldPreset.addCategory($event)" (didDeselect)="this._productFieldPreset.removeCategory($event)"></app-product-categories-tree>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="col-md-6">
                    <b i18n>Product fields</b>

                    <p *ngIf="_productFieldPreset.product_fields.length >= productFields.length" class="text-muted" i18n>All product fields selected</p>

                    <ul class="list-unstyled">
                        <ng-template ngFor let-productField [ngForOf]="productFields">
                            <li *ngIf="!_productFieldPreset.hasProductField(productField)">
                                {{ productField.name }}

                                <a href="javascript:" (click)="_productFieldPreset.addProductField(productField)" i18n>Add</a>
                            </li>
                        </ng-template>
                    </ul>
                </div>

                <div class="col-md-6">
                    <b i18n>Selected product fields</b>

                    <p *ngIf="_productFieldPreset.product_fields.length === 0" class="text-muted" i18n>No product fields selected</p>

                    <ul class="list-unstyled" [sortablejs]="_productFieldPreset.product_fields" [sortablejsOptions]="{ handle: '.sortablejs--handle' }">
                        <ng-template ngFor let-productField [ngForOf]="_productFieldPreset.product_fields">
                            <li>
                                <span class="sortablejs--handle  mr-2  text-muted" style="cursor: move;">=</span>

                                {{ productField.name }}

                                <a href="javascript:" (click)="_productFieldPreset.removeProductField(productField)" class="text-danger" i18n>Remove</a>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
