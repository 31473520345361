<div class="data-board">
    <div class="LayoutSplitter">
        <div [className]="'LayoutSplitter__item' + (isSplitScreen ? '  LayoutSplitter__item--splitted' : '')">
            <ng-content select="[mainContent]"></ng-content>
        </div>
        <div #secondaryContent [className]="'LayoutSplitter__item' + (isSplitScreen ? '  LayoutSplitter__item--splitted' : '')">
            <div class="data-board  data-board--with-auto-height  data-board--with-button" *ngIf="isSplitScreen">
                <button class="data-board__close-button" (click)="closeAdditionalContent(true)">
                    <fa-icon [icon]="faIcon"></fa-icon>
                </button>
                <ng-content select="[secondaryContent]"></ng-content>
            </div>
        </div>
    </div>
</div>
