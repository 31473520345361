<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!vatType" i18n>Add VAT type</ng-container>
            <ng-container *ngIf="vatType" i18n>Edit VAT type</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="vat-type-name" i18n>Name</label>
                <input type="text" id="vat-type-name" class="form-control" [(ngModel)]="_vatType.name" name="name">
            </div>

            <div class="form-group">
                <label for="vat-type-rate" i18n>Rate</label>
                <input type="number" min="0" id="vat-type-rate" class="form-control" [(ngModel)]="_vatType.rate" name="rate">
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_vatType.is_default" name="is_default"> <ng-container i18n>Default VAT type</ng-container>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
