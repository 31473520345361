import {Component, OnInit} from '@angular/core';
import {ProductFieldPreset} from "../services/product-field-preset";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProductFieldPresetEditModalComponent} from "../product-field-preset-edit-modal/product-field-preset-edit-modal.component";
import {ProductFieldPresetsService} from "../services/product-field-presets.service";

@Component({
    selector: 'app-product-field-presets',
    templateUrl: './product-field-presets.component.html',
    styleUrls: ['./product-field-presets.component.sass'],
    providers: [ProductFieldPresetsService]
})
export class ProductFieldPresetsComponent implements OnInit {

    productFieldPresets: ProductFieldPreset[];

    loading = false;

    constructor(public productFieldPresetsService: ProductFieldPresetsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.productFieldPresetsService.getProductFieldPresets(true, true).subscribe(response => {
            this.productFieldPresets = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(ProductFieldPresetEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(productFieldPreset: ProductFieldPreset) {
        let self = this;

        const modalRef = this.ngbModal.open(ProductFieldPresetEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productFieldPreset = productFieldPreset;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(productFieldPreset: ProductFieldPreset) {
        if (confirm($localize `Are you sure?\n\nThe product field preset will be deleted`)) {
            this.loading = true;

            this.productFieldPresetsService.deleteProductFieldPreset(productFieldPreset).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }
}
