<div class="debtors">
    <h1 class="heading">
        <ng-container i18n>Debtors</ng-container>
        <small *ngIf="loading && debtors" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!debtors" i18n>Loading...</app-loader>

    <p *ngIf="debtors">
        <a *ngIf="authService.hasPermission('admin')" class="btn  btn-primary" (click)="add()" i18n>Add debtor</a>
    </p>

    <app-table-helper *ngIf="debtors">


        <div class="form-row w-100">
            <div class="col-8">
                <div class="input-group">
                    <input type="text" class="form-control" i18n-placeholder placeholder="Search"
                           [(ngModel)]="filter.query">
                    <div class="input-group-append">
                        <select class="custom-select" [(ngModel)]="filter.queryType">
                            <option [ngValue]="'debtor_name'">Forhandlernavn</option>
                            <option [ngValue]="'debtor_reference'">Forhandlernummer</option>
                        </select>

                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="filter-active" [(ngModel)]="filter.includeInactive">
                    <label class="form-check-label" for="filter-active" i18n>Include inactive</label>
                </div>
            </div>
            <div class="col-2">
                <div class="input-group">
                    <button (click)="page = 1; load(page);" type="button" class="btn  btn-primary">Søg
                    </button>
                </div>
            </div>
        </div>

    </app-table-helper>

    <app-table *ngIf="debtors" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>References</th>
                <th i18n>Payment agreement</th>
                <th i18n>Contacts</th>
                <th i18n>Active</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-debtor [ngForOf]="debtors">
                <tr>
                    <td>{{ debtor.name }}</td>
                    <td>
                        <ng-template ngFor let-reference [ngForOf]="debtor.references">
                            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  ml-2">
                                {{ reference.name }}: {{ reference._joinData.value }}
                            </span>
                        </ng-template>
                    </td>
                    <td>{{ debtor?.payment_agreement?.name }}</td>
                    <td>{{ debtor?.contacts?.length }}</td>
                    <td>  <fa-icon [icon]="faCheck" class="text-success" *ngIf="debtor.is_active"></fa-icon>
                        <fa-icon [icon]="faBan" class="text-danger" *ngIf="!debtor.is_active"></fa-icon></td>
                    <td class="text-right">
                        <a class="btn  btn-xs  btn-primary mr-1" routerLink="/debtors/{{ debtor.id }}">
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </a>
                        <button *ngIf="authService.hasPermission('admin')" (click)="delete(debtor)" class="btn  btn-xs  btn-danger">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="debtors && count > debtors.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
