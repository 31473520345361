import * as dayjs from 'dayjs';
import {User} from './user';
import {Attachment} from './attachment';
import {Translation} from './translation';

export class Article {

    id: string;
    body: string;
    title: string;
    // tslint:disable-next-line:variable-name
    user_id: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    // tslint:disable-next-line:variable-name
    is_active: boolean;
    user: User;
    attachments: Attachment[];
    // @todo fix
    // tslint:disable-next-line:variable-name
    _translations: Translation;

    constructor() {
    }

    static fromJSON(json: ArticleJSON | string): Article {
        if (typeof json === 'string') {
            return JSON.parse(json, Article.reviver);
        } else {
            const article = Object.create(Article.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                user: null,
                attachments: [],
                _translations: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (key === 'user' && json[key]) {
                    extra[key] = User.fromJSON(json[key]);
                }
                if (key === 'attachments') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Attachment.fromJSON(value));
                    });
                }
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(article, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? Article.fromJSON(value) : value;
    }
}
// A representation of Article data that can be converted to
// and from JSON without being altered.
interface ArticleJSON {
    id?: string;
    body?: string;
    title?: string;
    user_id?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    is_active?: boolean;
}
