import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from "../services/user";
import {UsersService} from "../services/users.service";

@Component({
    selector: 'app-user-select-modal',
    templateUrl: './user-select-modal.component.html',
    styleUrls: ['./user-select-modal.component.scss'],
    providers: [UsersService]
})
export class UserSelectModalComponent implements OnInit {

    excludeUsers = [];

    users: User[];

    user: User = null;

    saved;

    constructor(public activeModal: NgbActiveModal,
                public usersService: UsersService) {
    }

    ngOnInit() {
        this.usersService.getUsers(false).subscribe(response => {
            this.users = response.data;
        });
    }

    isExcluded(user) {
        return this.excludeUsers.findIndex((e) => {
            return e.id === user.id;
        }) >= 0;
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saved(this.user);

        this.activeModal.close();
    }
}
