import {Injectable} from '@angular/core';
import {User} from './user';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Empty} from "./empty";
import {Token} from "./token";
import {HttpParams} from "@angular/common/http";

@Injectable()
export class UsersService {

    protected url = environment.apiBase + 'users';

    constructor(public apiService: ApiService) {
    }

    getUser(id: string, withGroup: boolean): Observable<{ data: User, count: number, perPage: number }> {
        let url = this.url + '/' + id;

        if (withGroup) {
            url += '?with-group=1'
        }

        return this.apiService.get<User>(url, true, User);
    }

    getUsers(withGroup: boolean, page = 1, limit = 25, query?:any): Observable<{ data: User[], count: number, perPage: number }> {
        const url = this.url;

        let params: HttpParams = new HttpParams();

        if (page) {
            params = params.set('page', page);
        }

        if (limit) {
            params = params.set('limit', limit);
        }

        if (withGroup) {
            params = params.set('with-group', '1');
        }

        if (query) {
            if (query && query.search) {
                params = params.set('search', query.search);
            }
        }

        return this.apiService.get<User[]>(url, true, User, params);
    }

    addUser(user: User): Observable<{ data: User, count: number, perPage: number }> {
        return this.apiService.post<User>(this.url, user, true, User);
    }

    updateUser(user: User): Observable<{ data: User, count: number, perPage: number }> {
        return this.apiService.patch<User>(this.url + '/' + user.id, user, true, User);
    }

    deleteUser(user: User): Observable<{ data: User, count: number, perPage: number }> {
        return this.apiService.delete<User>(this.url + '/' + user.id, true, User);
    }

    generateTwoFactorAuthenticationSecret() {
        return this.apiService.post<Empty>(this.url + '/2fa-secret', null, true, Empty);
    }

    getToken(user: User): Observable<{ data: Token, count: number, perPage: number }> {
        return this.apiService.get<Token>(this.url + '/' + user.id + '/token', true, Token);
    }
}
