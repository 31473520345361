<div class="row">
    <div class="col-12  col-lg-6  seperator">
        <h5 class="heading">Ordre Information:</h5>

        <!--
        <pre>{{_order.order_fields|json}}</pre>
        -->

        <div class="row">
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    Attention:<br />
                    <input type="text" class="form-control" />
                </label>
            </div>
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    Rekv. nr.:<br />
                    <input type="text" class="form-control" />
                </label>
            </div>
            <div [class]="_order.dates && _order.dates.length > 1 ? 'col-12  col-lg-6' : 'col-12'" *ngFor="let date of _order.dates">
                <label class="d-block">
                    {{date.name}}:<br />
                    <fa-icon [icon]="dateIcon" [class]="'text-muted  input-icon'"></fa-icon>
                    <input (click)="dateTime.toggle()" type="text" id="order-date-{{ date._joinData.id }}-time" class="form-control" [(ngModel)]="date._joinData.time" ngbDatepicker #dateTime="ngbDatepicker" name="{{ date._joinData.id }}-time" [disabled]="!date.is_editable">
                </label>
            </div>
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    Betal bet.:<br />
                    <select class="form-control">
                        <option>Betalingsmåde 1</option>
                        <option>Betalingsmåde 2</option>
                        <option>Betalingsmåde 3</option>
                    </select>
                </label>
            </div>
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    Lev. bet.:<br />
                    <select class="form-control">
                        <option>Levering 1</option>
                        <option>Levering 2</option>
                        <option>Levering 3</option>
                    </select>
                </label>
            </div>
            <div class="col-12">
                <label class="d-block">
                    Lager:<br />
                    <select class="form-control">
                        <option>Lager 1</option>
                        <option>Lager 2</option>
                        <option>Lager 3</option>
                    </select>
                </label>
            </div>
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    ENO:<br />
                    <select class="form-control">
                        <option>Ja</option>
                        <option>Nej</option>
                    </select>
                </label>
            </div>
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    Netto:<br />
                    <select class="form-control">
                        <option>Ja</option>
                        <option>Nej</option>
                    </select>
                </label>
            </div>
        </div>
    </div>
    <div class="col-12  col-lg-6">
        <h5 class="heading">Leverings Adresse:</h5>
        <div class="row">
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    Kontakt Person:<br />
                    <input type="text" class="form-control" [(ngModel)]="deliveryContact.full_name" />
                </label>
            </div>
            <div class="col-12  col-lg-6">
                <label class="d-block">
                    Adresse:<br />
                    <input type="text" class="form-control" [(ngModel)]="deliveryContact.address" />
                </label>
            </div>
            <div class="col-12">
                <label class="d-block">
                    Post Nr. & By
                    <div class="input-group">
                        <input type="text" class="col-3 form-control" [(ngModel)]="deliveryContact.zip">
                        <input type="text" class="col-9 form-control" [(ngModel)]="deliveryContact.city">
                    </div>
                </label>
            </div>
            <ng-container *ngFor="let contactField of deliveryContact.contact_fields">
                <div class="col-12  col-lg-6">
                    <label class="d-block">
                        {{contactField.name}}:<br />
                        <input type="text" class="form-control" [(ngModel)]="contactField._joinData.value" />
                    </label>
                </div>
            </ng-container>
        </div>
    </div>
</div>
