import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {ContactField} from "../services/contact-field";
import {ContactFieldsService} from "../services/contact-fields.service";

@Component({
    selector: 'app-contact-field-edit-modal',
    templateUrl: './contact-field-edit-modal.component.html',
    styleUrls: ['./contact-field-edit-modal.component.scss'],
    providers: [ContactFieldsService]
})
export class ContactFieldEditModalComponent implements OnInit {

    public contactField: ContactField;
    public _contactField: ContactField;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public contactFieldsService: ContactFieldsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._contactField = this.contactField ? ContactField.fromJSON(this.contactField) : ContactField.fromJSON({
            type: 'text'
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.contactField) {
            this.contactFieldsService.updateContactField(this._contactField).subscribe(contactField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(contactField);
            });
        } else {
            this.contactFieldsService.addContactField(this._contactField).subscribe(contactField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(contactField);
            });
        }
    }
}
