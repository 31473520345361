<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>References</ng-container>
        <small *ngIf="loading && references" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!references" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="references">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add reference</a>
    </p>

    <app-table *ngIf="references" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Features</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-reference [ngForOf]="references">
                <tr>
                    <td>{{ reference.name }}</td>
                    <td>
                        <span *ngIf="reference.is_order_identification" class="badge  badge-primary  mr-2" i18n>Order identification</span>
                        <span *ngIf="reference.is_order_reference" class="badge  badge-primary  mr-2" i18n>Order reference</span>
                        <span *ngIf="reference.is_product_identification" class="badge  badge-primary  mr-2" i18n>Product identification</span>
                        <span *ngIf="reference.is_product_reference" class="badge  badge-primary  mr-2" i18n>Product reference</span>
                        <span *ngIf="reference.is_creditor_reference" class="badge  badge-primary  mr-2" i18n>Creditor reference</span>
                        <span *ngIf="reference.is_debtor_reference" class="badge  badge-primary  mr-2" i18n>Debtor reference</span>
                        <span *ngIf="reference.is_product_category_reference" class="badge  badge-primary  mr-2" i18n>Product category reference</span>
                        <span *ngIf="reference.is_incremental" class="badge  badge-primary" i18n>Incremental</span>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(reference)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(reference)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>
</div>
