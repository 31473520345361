<div class="order-edit  pb-5">
    <a [routerLink]="'/articles'" class="btn btn-primary mb-2" i18n>Back</a>
    <h1>
        <ng-container *ngIf="isDraft" i18n>Add article</ng-container>
        <ng-container *ngIf="!isDraft" i18n>Edit article</ng-container>
        <small *ngIf="loading" class="text-muted" i18n>Loading...</small>
    </h1>

    <p *ngIf="!article" class="text-muted" i18n>Loading...</p>

    <div *ngIf="article && languagesService.languages">
        <app-language-picker [missingLocalizations]="missingLocalizations"></app-language-picker>
        <div class="row">
            <div class="col-md-6">
                <h3><span i18n>Title</span><app-language-indicator></app-language-indicator></h3>
                <div class="form-group">
                    <input type="text" class="form-control" id="title" required maxlength="191"
                           [(ngModel)]="title[languagesService.index]" name="title">
                </div>

                <app-quill-editor [article]="article" [body]="body[languagesService.index]" (bodyChange)="bodyChange($event)"></app-quill-editor>

                <h3><span i18n>Date</span> <app-language-indicator [isLocalized]="false"></app-language-indicator></h3>
                <div class="form-group">
                    <input type="date" [(ngModel)]="date" class="form-control" id="date" name="date">
                </div>

                <h3><span i18n>Image</span> / <ng-container>PDF</ng-container> <app-language-indicator [isLocalized]="false"></app-language-indicator></h3>
                <div class="form-group">
                    <input *ngIf="!file" type="file" class="form-control" id="image" name="image" (change)="onChange($event)">
                    <ng-container *ngIf="file">{{file.name}}</ng-container>
                    <button *ngIf="file" (click)="onUpload()" class="btn btn-success" i18n>Upload</button>
                </div>

                <h3 i18n>Publish</h3>
                <div class="form-group">
                    <input type="checkbox" id="publish" [(ngModel)]="article.is_active" name="publish">
                </div>
            </div>
        </div>

        <div class="text-right">
            <button (click)="save()" class="btn  btn-success" id="save" [disabled]="saving">
                <ng-container *ngIf="saving" i18n>Saving...</ng-container>
                <ng-container *ngIf="!saving" i18n>Save</ng-container>
            </button>
        </div>
    </div>
</div>
