<app-table [autoSizeFirstColumn]="true" *ngIf="tools">
    <thead>
        <tr>
            <th></th>
            <th></th>
            <th>Model</th>
            <th class="text-right">Pris I DKK</th>
            <th>Lager</th>
            <th>På vej</th>
            <th>Næste åbne produktions uge</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let tool of tools" (click)="onToolClick(tool)" class="clickable">
            <td><input type="radio" [checked]="offerService.selectedTool && offerService.selectedTool.product.id === tool.id"/></td>
            <td class="text-center" style="width: 1rem;">
                <button class="icon-button" placement="right" [ngbTooltip]="htmlContent" triggers="manual" #t1="ngbTooltip"
                        (click)="onThumbnailClick($event, t1, tool)"
                            *ngIf="tool.attachments && tool.attachments.length > 0"
                >
                    <fa-icon [icon]="faCamera"></fa-icon>
                </button>
            </td>
            <td>
                {{tool.name}}
                <a *ngIf="tool.getFieldMetaForId(idValuesService.linkFieldValueId)" href="{{tool.getFieldMetaForId(idValuesService.linkFieldValueId)}}" target="_blank">{{tool.getFieldValueForId(idValuesService.linkFieldValueId)}}</a>
            </td>
            <td class="text-right">{{tool.price|currency:'DKK'}}</td>


            <td>

                <ng-container *ngIf="authService.hasPermission('admin')">
                    <ng-container *ngFor="let stock of tool.product_stocks |filter:'location_reference':'lager-2'">
                        {{stock.quantity}}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!authService.hasPermission('admin')">
                    <ng-container *ngFor="let stock of tool.product_stocks |filter:'location_reference':'lager-1'">
                        {{stock.quantity}}
                    </ng-container>
                </ng-container>

                </td>
            <td>
                <ng-container *ngIf="authService.hasPermission('admin')">
                    <ng-container *ngFor="let stock of tool.product_stocks |filter:'location_reference':'pipeline-2'">
                        {{stock.quantity}}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!authService.hasPermission('admin')">
                    <ng-container *ngFor="let stock of tool.product_stocks |filter:'location_reference':'pipeline-1'">
                        {{stock.quantity}}
                    </ng-container>
                </ng-container>
            </td>
            <td>
                <ng-container *ngFor="let productionLine of tool.production_lines">
                    <span *ngIf="productionLine.open_from && productionLine.open_from.isValid()"><a target="_blank" routerLink="/production-lines"><ng-container i18n>Week</ng-container> {{ productionLine.open_from.isoWeek() }} - {{ productionLine.open_from.isoWeekYear() }}</a></span>
                    <span *ngIf="!productionLine.open_from || !productionLine.open_from.isValid()" class="text-muted">Leveringsuge ukendt</span>
                </ng-container>
            </td>
        </tr>
    </tbody>
</app-table>
<app-loader *ngIf="!tools || loading" [useOverlayLoader]="true"></app-loader>
<ng-template #htmlContent>
    <img src="{{activeThumbnail}}" class="img-fluid"/>
</ng-template>
