import {Component, OnInit} from '@angular/core';
import {Group} from "../services/group";
import {GroupsService} from "../services/groups.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GroupEditModalComponent} from "../group-edit-modal/group-edit-modal.component";

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.sass'],
    providers: [GroupsService]
})
export class GroupsComponent implements OnInit {

    groups: Group[];

    loading = false;

    constructor(public groupsService: GroupsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.groupsService.getGroups(true, true).subscribe(response => {
            this.groups = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(GroupEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(group: Group) {
        let self = this;

        const modalRef = this.ngbModal.open(GroupEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.group = group;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(group: Group) {
        if (confirm($localize `Are you sure?\n\nThe group will be deleted`)) {
            this.loading = true;

            this.groupsService.deleteGroup(group).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
