import {Component, OnInit} from '@angular/core';
import {ProductionLinesService} from "../services/production-lines.service";
import {ProductionLine} from "../services/production-line";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProductionLineEditModalComponent} from "../production-line-edit-modal/production-line-edit-modal.component";
import {AuthService} from "../../services/auth.service";
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

@Component({
    selector: 'app-production-lines',
    templateUrl: './production-lines.component.html',
    styleUrls: ['./production-lines.component.scss'],
    providers: [ProductionLinesService]
})
export class ProductionLinesComponent implements OnInit {

    public productionLines: ProductionLine[];

    constructor(public productionLinesService: ProductionLinesService,
                public authService: AuthService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.productionLinesService.getProductionLines().subscribe((response) => {
            this.productionLines = response.data;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(ProductionLineEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(productionLine: ProductionLine) {
        let self = this;

        const modalRef = this.ngbModal.open(ProductionLineEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productionLine = productionLine;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }
}
