import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass'],
    providers: []
})
export class LoginComponent implements OnInit {

    email = '';
    password = '';
    two_factor_authentication_password = '';

    logginIn = false;
    hasError = false;
    enviroment = environment;
    logo = 'assets/themes/' + this.enviroment.customer + '/images/logo.png';
    challenge = false;

    public returnUrl: string = null;

    constructor(public router: Router,
                private route: ActivatedRoute,
                public authService: AuthService) {
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(parmas => {
            this.returnUrl = parmas.get('returnUrl');
        })

        if (this.authService.loggedIn()) {
            if (this.returnUrl) {
                this.router.navigateByUrl(this.returnUrl);
            } else {
                this.router.navigateByUrl('/');
            }
        }
    }

    onSubmit() {
        this.logginIn = true;
        this.hasError = false;

        this.authService.authenticateUser(this.email, this.password, this.two_factor_authentication_password).then(_ => {
            if (this.returnUrl) {
                this.router.navigateByUrl(this.returnUrl);
            } else {
                this.router.navigateByUrl('/');
            }
        }).catch(error => {
            if (error.status === 401) {
                this.challenge = true;
            } else {
                this.hasError = true;

                console.error(error);
            }

            this.logginIn = false;
        });
    }
}
