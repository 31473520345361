import { Component, OnInit } from '@angular/core';
import {
    faSmile,
    faMapSigns,
    faTimes,
    faMap,
    faFileAlt,
    faBinoculars,
    faAddressCard,
    faUser,
    faInfoCircle,
    faPenSquare,
    IconDefinition, faCheck, faPeopleArrows, faExchangeAlt, faMapPin, faTrash
} from '@fortawesome/free-solid-svg-icons';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {Debtor} from '../../../services/debtor';
import {Creditor} from '../../../services/creditor';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DebtorsService} from '../../../services/debtors.service';
import {ReferencesService} from '../../../services/references.service';
import {Reference} from '../../../services/reference';
import {OrdersService} from '../../../services/orders.service';
import {OrderStatesService} from '../../../services/order-states.service';
import {ProductsService} from '../../../services/products.service';
import {BranchesService} from '../../../services/branches.service';
import {CreditorsService} from '../../../services/creditors.service';
import {Order} from '../../../services/order';
import {Router} from "@angular/router";

interface IOptions {
    option: IconDefinition;
    text?: string;
    color?: string;
    onClick?: any;
}

@Component({
    selector: 'app-step-1-order-details',
    templateUrl: './step-1-order-details.component.html',
    styleUrls: ['./step-1-order-details.component.scss'],
    providers: [OrdersService, OrderStatesService, ProductsService, DebtorsService, CreditorsService, ReferencesService, BranchesService],
})
export class TenFourStep1OrderDetailsComponent implements OnInit {

    order: Order | null | undefined;

    showChangeClientComponent = false;
    showChangeDeliveryContactComponent = false;

    loading = false;

    primaryOptions: IOptions[];
    secondaryOptions: IOptions[];

    constructor(public router: Router,
                public ordersService: TenFourOrderService,
                public debtorService: DebtorsService,
                public creditorService: CreditorsService) {
    }

    ngOnInit(): void {
        this.ordersService.currentOrder.subscribe((order) => {
            this.order = order;
            this.primaryOptions = this.order ? [
                {
                    option: faSmile,
                    text: this.order.isCreditor() ? 'Ændre Kreditor' : 'Ændre Debitor',
                    onClick: () => {
                            this.showChangeDeliveryContactComponent = false;
                            this.showChangeClientComponent = !this.showChangeClientComponent;
                        },
                    color: 'yellow'
                },
                {
                    option: faMapSigns,
                    text: 'Skift leverings adresse',
                    onClick: () => {
                        this.showChangeClientComponent = false;
                        this.showChangeDeliveryContactComponent = !this.showChangeDeliveryContactComponent;
                    },
                    color: 'limegreen',
                },
                {
                    option: faTrash,
                    text: 'Slet Ordre',
                    onClick: () => {
                        if (confirm($localize `Are you sure?\n\nThe order will be deleted`)){
                            this.loading = true;
                            this.ordersService.deleteOrder(this.order).subscribe((response) => {

                                this.router.navigateByUrl('/orders');

                            });
                        }
                    },
                    color: '#F32013'
                },
                // @todo DISABLED UNTIL FUNCTIONAL
                //{
                //    option: faMap
                //},
                //{
                //    option: faFileAlt
                //},
            ] : [];

            this.secondaryOptions = this.order ? [
                // @todo DISABLED UNTIL FUNCTIONAL
                // {
                //     option: faBinoculars,
                //     text: 'This explains the button!'
                // },
                // {
                //     option: faAddressCard
                // },
                // {
                //     option: faUser
                // },
                // {
                //     option: faInfoCircle
                // },
                // {
                //     option: faPenSquare
                // },
            ] : [];
        });
    }

    updateClient(updatedOrder: Order): void {
        this.loading = true;
        this.ordersService.updateOrder(updatedOrder).subscribe((response) => {
           this.loading = false;
           this.showChangeClientComponent = false;
           this.ordersService.setOrder(response.data);
        });
    }

    closeDeliveryContactComponent(event: boolean): void {
        this.showChangeDeliveryContactComponent = false;
    }

}
