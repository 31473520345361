<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container i18n>Order line ajour</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="loading" class="text-muted" i18n>Loading...</p>

            <div *ngIf="!loading">
                <div class="row">
                    <div class="col-md">
                        <h3>
                            <span i18n>Price</span>
                            <small class="float-right">DKK {{ _orderLine.price | currency:'DKK':'':null }}</small>
                        </h3>

                        <p>
                            <b i18n>Adjusted price</b>:
                            DKK {{ product.adjusted_price | currency:'DKK':'':null }}
                            <button *ngIf="_orderLine.price != product.adjusted_price" (click)="_orderLine.setPriceFromProduct(product)" class="btn  btn-sm  btn-primary  float-right" i18n>Set price</button>
                        </p>

                        <table class="table  table-sm  table-bordered  table-striped  table-hover">
                            <thead>
                                <tr>
                                    <th i18n>Price group</th>
                                    <th i18n>Price</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template ngFor let-price [ngForOf]="product.prices">
                                    <ng-container *ngIf="!price.price_group.is_cost_price">
                                        <tr>
                                            <td>{{ price.price_group.name }} <span *ngIf="price.price_group.is_default" class="badge  badge-primary ml-2" i18n>Default</span></td>
                                            <td>{{ price.price }}</td>
                                            <td>
                                                <button *ngIf="_orderLine.price != price.price" (click)="_orderLine.setPrice(+price.price)" class="btn  btn-sm  btn-primary  float-right" i18n>Set price</button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-md">
                        <h3>
                            <span i18n>Cost price</span>
                            <small class="float-right">DKK {{ _orderLine.cost_price | currency:'DKK':'':null }}</small>
                        </h3>

                        <p>
                            <b i18n>Cost price</b>:
                            DKK {{ product.cost_price | currency:'DKK':'':null }}
                            <button *ngIf="_orderLine.cost_price != product.cost_price" (click)="_orderLine.setCostPrice(product.cost_price)" class="btn  btn-sm  btn-primary  float-right" i18n>Set cost price</button>
                        </p>

                        <table class="table  table-sm  table-bordered  table-striped  table-hover">
                            <thead>
                                <tr>
                                    <th i18n>Price group</th>
                                    <th i18n>Price</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template ngFor let-price [ngForOf]="product.prices">
                                    <ng-container *ngIf="price.price_group.is_cost_price">
                                        <tr>
                                            <td>{{ price.price_group.name }}<span *ngIf="price.price_group.is_default" class="badge  badge-primary ml-2" i18n>Default</span></td>
                                            <td>{{ price.price }}</td>
                                            <td>
                                                <button *ngIf="_orderLine.cost_price != price.price" (click)="_orderLine.setCostPrice(+price.price)" class="btn  btn-sm  btn-primary  float-right" i18n>Set cost price</button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn  btn-secondary" (click)="close()" i18n>Close</button>
        <button type="button" class="btn  btn-primary" (click)="save()" [disabled]="loading" i18n>Save</button>
    </div>
</div>
