import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {VatType} from "./vat-type";

@Injectable()
export class VatTypesService {

    protected url = environment.apiBase + 'vat-types';

    constructor(public apiService: ApiService) {
    }

    getVatType(id: string): Observable<{data: VatType, count: number, perPage: number}> {
        return this.apiService.get<VatType>(this.url + '/' + id, true, VatType);
    }

    getVatTypes(): Observable<{data: VatType[], count: number, perPage: number}> {
        return this.apiService.get<VatType[]>(this.url, true, VatType);
    }

    addVatType(vatType: VatType): Observable<{data: VatType, count: number, perPage: number}> {
        return this.apiService.post<VatType>(this.url, vatType, true, VatType);
    }

    updateVatType(vatType: VatType): Observable<{data: VatType, count: number, perPage: number}> {
        return this.apiService.patch<VatType>(this.url + '/' + vatType.id, vatType, true, VatType);
    }

    deleteVatType(vatType: VatType): Observable<{data: VatType, count: number, perPage: number}> {
        return this.apiService.delete<VatType>(this.url + '/' + vatType.id, true, VatType);
    }
}
