import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-no-search-input-or-results',
    templateUrl: './no-search-input-or-results.component.html',
    styleUrls: ['./no-search-input-or-results.component.scss']
})
export class NoSearchInputOrResultsComponent implements OnInit {

    @Input() noResults: boolean;
    @Input() noInput: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
