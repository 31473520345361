<h3><span i18n>Body</span>
    <app-language-indicator></app-language-indicator>
</h3>
<div class="form-group">
    <div id="toolbar-container">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>

        <button class="ql-script" value="sub"></button>
        <button class="ql-script" value="super"></button>
        <button class="ql-header" value="1"></button>
        <button class="ql-header" value="2"></button>
        <button class="ql-blockquote"></button>
        <button class="ql-code-block"></button>

        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-show-html"></button>
        <button class="ql-clean"></button>

        <button id="custom-button" (click)="openAttachemtnModal()">Attachment</button>
    </div>

    <div id="body"></div>
</div>

<ng-template #metaContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Image meta</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form>
            <h3 i18n>Alt</h3>
            <div class="form-group">
                <input type="text" class="form-control" id="alt" [(ngModel)]="imageAlt" name="alt">
            </div>
            <h3 i18n>Class</h3>
            <div class="form-group">
                <input type="text" class="form-control" id="class" [(ngModel)]="imageClass" name="class">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="this.updateImage()">Save</button>
    </div>
</ng-template>

<ng-template #sourceContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-source">Edit source</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form>
            <h3 i18n>Source</h3>
            <div class="form-group">
                <textarea type="text" id="source" [(ngModel)]="body" name="source" cols="45" rows="15"></textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="this.updateSource()">Save</button>
    </div>
</ng-template>


<ng-template #attachemntPicker let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Edit source</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <app-attachments [attachments]="article.attachments" [embedded]="true"
                         (pickedAttachment)="pickedAttachemnt($event)"></app-attachments>
    </div>
    <div class="modal-footer">
    </div>
</ng-template>
