import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../services/users.service';
import {User} from '../services/user';
import {GroupsService} from "../services/groups.service";
import {Group} from "../services/group";
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'app-user-edit-modal',
    templateUrl: './user-edit-modal.component.html',
    styleUrls: ['./user-edit-modal.component.scss'],
    providers: [UsersService, GroupsService]
})
export class UserEditModalComponent implements OnInit {

    public user: User;
    public _user: User;

    public groups: Group[];

    public isManaged = false;
    public isProfileEdit = false;
    public randomPassword = false;

    public creditorId;
    public debtorId;

    public saving = false;
    public saved;

    constructor(
        public authService: AuthService,
        public activeModal: NgbActiveModal,
                public usersService: UsersService,
                public groupsService: GroupsService) {
    }

    ngOnInit() {
        this._user = this.user ? Object.assign({}, this.user) : User.fromJSON({active: true});


        /**
         * If the user is managed we dont need to get the groups
         */
        if (!this.isManaged && !this.isProfileEdit) {
            this.groupsService.getGroups(false, false).subscribe(response => {
                this.groups = response.data;

                if (!this.user) {
                    for (let group of this.groups) {
                        if (group.default) {
                            this._user.group_id = group.id;

                            break;
                        }
                    }
                }
            });
        } else {
            this.isManaged = true;
            this.groups = null;
        }
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.user) {
            this.usersService.updateUser(this._user).subscribe(user => {
                this.saved();

                this.saving = false;

                this.activeModal.close();
            }, error => {
                console.log(error.error.error); // potential for setting error indicators in the UI

                this.saving = false;
            });
        } else {
            if (this.creditorId || this.debtorId) {
                this.saving = false;

                this.saved(this._user);

                this.activeModal.close();
            } else {
                this.usersService.addUser(this._user).subscribe(response => {
                    this.saved(response.data);

                    this.saving = false;

                    this.activeModal.close();
                }, error => {
                    console.log(error.error.error); // potential for setting error indicators in the UI

                    this.saving = false;
                });
            }
        }
    }
}
