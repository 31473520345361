<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!productRelationsGroup" i18n>Add product relations group</ng-container>
            <ng-container *ngIf="productRelationsGroup" i18n>Edit product relations group</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="product-relations-group-name" i18n>Name</label>
                <input type="text" id="product-relations-group-name" class="form-control"
                       [(ngModel)]="productRelationsGroup.name" name="name">
            </div>

            <div class="form-group">
                <label for="product-relations-group-type" i18n>Type</label>

                <select class="form-control form-control-sm" id="product-relations-group-type" name="product-relations-group-type"
                       [(ngModel)]="groupType" (change)="setGroupType(groupType)">
                    <option *ngFor="let type of types" [ngValue]="type">{{type}}</option>
                </select>
            </div>

            <div>
                <label for="product-child-relations" i18n>Add Product Relation</label>
                <br>
                <select id="product-child-relations" name="product-relations-group-child"
                        [(ngModel)]="selectedProductRelation">
                    <ng-template ngFor let-child [ngForOf]="_productChildRelations" let-i="index">
                        <option [ngValue]="child">{{child.related_product.name}}</option>
                    </ng-template>
                </select>
                <button (click)="addToGroup(selectedProductRelation)" class="btn  btn-sm  btn-danger ml-3 mb-1" i18n>
                    Add selected
                </button>
            </div>

            <hr>

            <ng-template ngFor let-productRelation [ngForOf]="productRelationsGroup.product_relations_product_relations_groups" let-i="index">
                <div class="card-body">
                    <a routerLink="/products/{{ productRelation.product_relations_id }}">{{ productRelation.product_relation.related_product.name }}</a>
                    <button (click)="removeFromGroup(i)" class="btn  btn-sm  btn-danger ml-4" i18n>Remove</button>
                </div>
            </ng-template>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deleteGroup()" [disabled]="saving" i18n>Delete</button>
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
    </div>
</div>
