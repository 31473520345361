import {Component, Input, OnInit} from '@angular/core';
import {Order} from "../../../services/order";
import {Contact} from "../../../services/contact";
import {TenFourOrderService} from "../../../services/themes/10-4/ten-four-order.service";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-info-and-delivery-address-inputs',
    templateUrl: './info-and-delivery-address-inputs.component.html',
    styleUrls: ['./info-and-delivery-address-inputs.component.scss']
})
export class InfoAndDeliveryAddressInputsComponent implements OnInit {

    deliveryContact: Contact;
    contactContact: Contact;

    _order: Order | null | undefined;

    dateIcon = faCalendar;

    constructor(public orderService: TenFourOrderService) {
    }

    ngOnInit(): void {
        this.orderService.currentOrder.subscribe(response => {
            this._order = response;
            if (this._order && this._order.contacts){
                this._order.contacts.map((c) => {
                    if (c.name === 'Delivery'){
                        this.deliveryContact = c;
                    }
                    if (c.name === 'Contact'){
                        this.contactContact = c;
                    }
                });
                if (!this.deliveryContact){
                    this.deliveryContact = Contact.fromJSON({});
                }
            }
        });
    }
}
