<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container i18n>Upload attachment</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!productCategories" class="text-muted" i18n>Loading...</p>

            <ng-container *ngIf="productCategories">
                <select class="form-control  mb-3" [(ngModel)]="productCategoryId" name="product_category_id" (change)="productCategoryChanged()" [disabled]="productCategoryId && !products">
                    <option [ngValue]="null" i18n>-- Select product category --</option>
                    <option *ngFor="let productCategory of productCategories" [ngValue]="productCategory.id">{{ productCategory.name }}</option>
                </select>

                <ng-container *ngIf="productCategoryId">
                    <p *ngIf="!products" class="text-muted" i18n>Loading...</p>

                    <ng-container *ngIf="products">
                        <select class="form-control  mb-3" [(ngModel)]="productId" name="product_id">
                            <option [ngValue]="null" i18n>-- Select product --</option>
                            <option *ngFor="let product of products" [ngValue]="product.id">{{ product.name }}</option>
                        </select>
                    </ng-container>
                </ng-container>

                    <ng-container *ngIf="productId">
                    </ng-container>
                        <div class="custom-file">
                            <input type="file" id="attachment-file" class="custom-file-input" (change)="fileChanged($event)">
                            <label class="custom-file-label" for="attachment-file" i18n>Choose file</label>
                        </div>

                        <div class="form-group">
                            <label i18n>Description</label>
                            <input type="text" class="form-control" [(ngModel)]="attachment.description" name="description">
                        </div>

                        <div class="form-group">
                            <label i18n>Language</label>
                            <input type="text" class="form-control" [(ngModel)]="attachment.language" name="language">
                        </div>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!file || saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
