import {Component, OnInit} from '@angular/core';
import {ProductFieldPresetsService} from '../../../services/product-field-presets.service';
import {FieldsService} from '../services/fields.service';
import {Field} from '../services/field';
import {ActivatedRoute, Router} from '@angular/router';
import {CreditorsService} from "../../../services/creditors.service";
import {Creditor} from "../../../services/creditor";
import {ProductFieldPreset} from "../../../services/product-field-preset";
import {zip} from "rxjs";

@Component({
    selector: 'app-fields',
    templateUrl: './fields.component.html',
    styleUrls: ['./fields.component.scss'],
    providers: [FieldsService, CreditorsService, ProductFieldPresetsService]

})
export class FieldsComponent implements OnInit {

    filter: any = {year: 2020, breed: null};
    fields: Field[] | null = null;
    sumFields: Field[] | null = null;
    loading = false;
    creditorId;
    creditor: Creditor;
    productFieldPresets: ProductFieldPreset;
    breeds: string[] = [];

    constructor(
        public creditorsService: CreditorsService,
        public productFieldPresetsService: ProductFieldPresetsService,
        public router: Router, private route: ActivatedRoute, public fieldsService: FieldsService) {
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(parmas => {
            this.creditorId = parmas.get('creditorId');

            //@todo fetch preset for
            this.productFieldPresetsService.getProductFieldPreset('f4dad477-c4f0-4052-876e-bceb72961332').subscribe((result) => {
                this.productFieldPresets = result.data;
            });

            this.loadFields();
        });
    }


    loadFields(): void {

        this.loading = true;
        this.fieldsService.getFields(this.creditorId, this.filter.year, null, false, this.filter.breed).subscribe((response) => {
            this.loading = false;
            this.fields = response.data;

            const breeds = this.fields.map((i) => {
                const test = i.product_fields.find((f) => {
                    return f._joinData.product_field_id === '1d48724c-6365-4c25-8b9f-ff9f4a633ea2'; //Sort felt id
                });
                return test._joinData.value;
            });

            this.breeds = [...new Set(breeds)];
        });
        this.fieldsService.getFields(this.creditorId, this.filter.year, null, true).subscribe((response) => {
            this.sumFields = response.data;
        });
        if (this.creditorId) {

            this.creditorsService.getCreditor(this.creditorId, false, false, false, false).subscribe((response) => {
                this.creditor = response.data;
            });
        }
    }
}
