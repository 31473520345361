<div class="" [ngClass]="{ 'mb-5 container': !embedded}">
    <h1 class="heading" *ngIf="!embedded">
        <span i18n>Attachments</span>
        <small *ngIf="loading && attachments" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!attachments" class="text-muted" i18n>Loading...</app-loader>

    <app-table-helper *ngIf="attachments && !embedded">
        <form class="form-inline">
            <div class="form-group  mr-3  mb-2">
                <input type="number" min="1" class="form-control  form-control-sm" [(ngModel)]="limit" name="limit" style="display: inline; width: 75px;" [disabled]="loading">
            </div>

            <button (click)="load(page)" type="submit" class="btn  btn-sm  btn-primary  mb-2" [disabled]="loading">
                <ng-container *ngIf="loading" i18n>Searching...</ng-container>
                <ng-container *ngIf="!loading" i18n>Search</ng-container>
            </button>
        </form>

        <button (click)="add()" class="btn  btn-sm  btn-primary  float-right" [disabled]="loading" i18n>Upload</button>
    </app-table-helper>

    <app-table *ngIf="attachments">
        <thead>
            <tr>
                <th i18n>Product</th>
                <th i18n>Name</th>
                <th i18n>Description</th>
                <th i18n>Language</th>
                <th i18n>Thumbnail</th>
                <th i18n>Meta</th>
                <th class="text-right" i18n>Created</th>
                <th class="text-right"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-attachment [ngForOf]="attachments">
                <tr>
                    <td>{{ attachment.product?.name }}</td>
                    <td>{{ attachment.name }}</td>
                    <td>{{ attachment.description }}</td>
                    <td>{{ attachment.language }}</td>
                    <td>
                        <img
                            class="img-fluid"
                            src="{{attachment.thumbnailUrl('product_list_preview')}}" *ngIf="attachment.thumbnailUrl('product_list_preview')">
                       </td>
                    <td>
                        <code>{{ attachment.type }}</code><br>
                        <code>{{ attachment.size }} bytes</code>
                    </td>
                    <td class="text-right">{{ attachment.created.format('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td class="text-right">
                        <button (click)="pickAttachment(attachment)" i18n>Pick</button>
                        <a (click)="delete(attachment)" href="javascript:" class="text-danger" id="delete" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="attachments && count > attachments.length && !embedded">
        <ngb-pagination class="d-flex  justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
