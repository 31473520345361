<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Select creditor</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!creditors" class="text-muted" i18n>Loading...</p>

            <div *ngIf="creditors">
                <div class="form-group">
                    <label for="creditor" i18n>Creditor</label>
                    <select id="creditor" class="form-control  form-control-sm" [(ngModel)]="creditor" name="creditor" (change)="creditorChanged()">
                        <option [ngValue]="null" i18n>-- Select creditor --</option>
                        <option *ngFor="let creditor of creditors" [ngValue]="creditor">{{ creditor.name }}</option>
                    </select>
                </div>
            </div>

            <ng-container *ngIf="creditor">
                <hr>

                <ng-template ngFor let-creditorContact [ngForOf]="creditor.contacts">
                    <button *ngIf="referenceContact !== creditorContact" (click)="selectReferenceContact(creditorContact)" class="btn  btn-sm  btn-secondary  ml-2  float-right">Select contact</button>
                    <button *ngIf="referenceContact === creditorContact" (click)="deselectReferenceContact()" class="btn  btn-sm  btn-primary  ml-2  float-right">Deselect contact</button>

                    <button *ngIf="deliveryContact !== creditorContact" (click)="selectDeliveryContact(creditorContact)" class="btn  btn-sm  btn-secondary  ml-2  float-right">Select delivery</button>
                    <button *ngIf="deliveryContact === creditorContact" (click)="deselectDeliveryContact()" class="btn  btn-sm  btn-primary  ml-2  float-right">Deselect delivery</button>

                    <h3>{{ creditorContact.name }} <small>{{ creditorContact.nickname }}</small></h3>

                    <p>
                        Full name: {{ creditorContact.full_name }}<br>
                        Address: {{ creditorContact.address }}<br>
                        Zip: {{ creditorContact.zip }}<br>
                        City: {{ creditorContact.city }}<br>
                        <ng-template ngFor let-contactField [ngForOf]="creditorContact.contact_fields">
                            {{ contactField.name }}: {{ contactField._joinData.value }}<br>
                        </ng-template>
                    </p>

                    <hr>
                </ng-template>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="!creditors" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!creditors || !creditor" i18n>Select</button>
    </div>
</div>
