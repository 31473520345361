<div class="row" mainContent>
    <app-loader [useOverlayLoader]="true" *ngIf="disableOrderlinesManipulation"></app-loader>
    <div class="col">
        <app-table [autoSizeFirstColumn]="true" [autoSizeLastColumn]="true">
            <thead>
            <tr>
                <td></td>
                <th>Vare</th>
                <th>Tekst</th>
                <th>Antal / Enhed</th>
                <th></th>
                <th class="text-right">á Pris</th>
                <th class="text-right">Pris m. rabat</th>
                <th>Pris m. rabat %</th>
                <th>Mængde rabat %</th>
                <th>Rabat %</th>
                <th class="text-right">Pris e.</th>
                <th class="text-right">Total</th>
                <th class="text-right">Kost</th>
                <th class="text-right" *ngIf="showExtendedCostPrice">Kost 2</th>
                <th>DG</th>
                <th></th>
            </tr>
            </thead>
            <tbody *ngIf="order" [sortablejs]="order.order_lines" [sortablejsOptions]="options">
                <tr *ngFor="let item of order.order_lines; index as i;">
                    <ng-container *ngIf="!item._isEditing">
                        <td><span class="sortablejs-handle">=</span></td>
                        <td>
                            <span *ngIf="item.product.references.length >= 1">{{item.product.references[0]._joinData.value}}</span>
                        </td>
                        <td>
                            <a routerLink="/products/{{ item.product.id }}">{{item.product.name}}</a>
                        </td>
                        <td>
                            {{item.quantity}}
                            {{item.product?.unit?.name ?? 'Stk' }}
                        </td>
                        <td>
                            <ng-container *ngIf="item.alternative_price_unit">
                                {{ item.alternative_price_unit.quantity.toFixed(2) }}
                                {{ item.alternative_price_unit.unit }}
                                <span class="text-muted">({{ item.alternative_price_unit.factor }})</span>
                            </ng-container>
                        </td>
                        <td class="text-right">{{ item.price | currency:'DKK':'':null }}</td>
                        <td class="text-right">{{ item.price_adjusted | currency:'DKK':'':null }}</td>
                        <td>
                            <span *ngIf="item.price_adjustment_percentage">{{ item.price_adjustment_percentage }} % ({{ item.price_adjustment_name ?? 'Ukendt' }})</span>
                            <span *ngIf="!item.price_adjustment_percentage" class="text-muted">Ingen</span>
                        </td>
                        <td>
                            <span *ngIf="item.quantity_discount_percentage">{{ item.quantity_discount_percentage }} %</span>
                            <span *ngIf="!item.quantity_discount_percentage" class="text-muted">Ingen</span>
                        </td>
                        <td>
                            <span *ngIf="item.discount_percentage">{{ item.discount_percentage }} %</span>
                            <span *ngIf="!item.discount_percentage" class="text-muted">Ingen</span>
                        </td>
                        <td class="text-right">{{ item.price_final | currency:'DKK':'':null }}</td>
                        <td class="text-right">{{ item.getTotal() | currency:'DKK':'':null }}</td>
                        <td class="text-right">{{ item.cost_price ? (item.cost_price | currency:'DKK':'':null) : '-' }}</td>
                        <td class="text-right">{{ item.cost_price_adjusted ? (item.cost_price_adjusted | currency:'DKK':'':null) : '-' }}</td>
                        <td>{{ item.price_final && item.cost_price ? (item.getProfitPercentage().toFixed(1) + ' %') : '-' }}</td>
                        <td>
                            <button class="btn  btn-xs  btn-primary  mt-1  ml-1" (click)="editOrderLine(item.id)"><fa-icon [icon]="faPen"></fa-icon></button>
                            <!-- @todo DISABLED UNTIL FUNCTIONAL <button class="btn  btn-xs  btn-success  ml-1"><fa-icon [icon]="faHat"></fa-icon></button> -->
                            <button class="btn  btn-xs  btn-danger  mt-1  ml-1  mr-1" (click)="deleteOrderLine(item)"><fa-icon [icon]="faTimes"></fa-icon></button>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="item._isEditing">
                        <td><span class="sortablejs-handle  mr-2  text-muted" style="cursor: move;">=</span></td>
                        <td><span *ngIf="item.product.references.length >= 1">{{item.product.references[0]._joinData.value}}</span></td>
                        <td>
                            <a routerLink="/products/{{ item.product.id }}">{{item.product.name}}</a>
                            <ng-container *ngIf="!order.isCompleted() && !order.isFrozen()">
                                <span (click)="openOrderLineAjourModal(item)" class="badge  badge-secondary" style="cursor: pointer;">*</span>
                            </ng-container>
                        </td>
                        <td>
                            <div class="input-group  input-group-sm  mb-3">
                                <input type="number" class="form-control" [(ngModel)]="item.quantity" (ngModelChange)="quantityChanged(item)" name="quantity-{{ i }}" [disabled]="order.isFrozen() || item.is_follow_product">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{item.product?.unit?.name ?? 'Stk.' }}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="item.alternative_price_unit">
                                <div class="input-group  input-group-sm  mb-3">
                                    <input type="number" class="form-control" [(ngModel)]="item.alternative_price_unit.quantity" (ngModelChange)="item.onAlternativePriceUnitAmountChanged(item.alternative_price_unit)" name="alternative-price-unit-quantity-{{ i }}" [disabled]="order.isFrozen() || item.is_follow_product">
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            {{ item.alternative_price_unit.unit }}
                                            ({{ item.alternative_price_unit.factor }})
                                            |
                                            {{ item.alternative_price_unit.getPrice(item.price) | currency:'DKK':'':null }}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </td>
                        <td><input type="number" class="form-control  form-control-sm" [(ngModel)]="item.price" name="price-{{ i }}" [disabled]="order.isFrozen()"></td>
                        <td class="text-right">{{ item.price_adjusted | currency:'DKK':'':null }}</td>
                        <td>{{ item.price_adjustment_percentage }} % ({{ item.price_adjustment_name ?? 'Ukendt' }})</td>
                        <td>{{ item.quantity_discount_percentage }} %</td>
                        <td><input type="number" class="form-control  form-control-sm" [(ngModel)]="item.discount_percentage" min="0" max="100" name="discount" [disabled]="order.isFrozen()"></td>
                        <td class="text-right">{{ item.price_final | currency:'DKK':'':null }}</td>
                        <td class="text-right">{{ item.getTotal() | currency:'DKK':'':null }}</td>
                        <td class="text-right">{{ item.cost_price ? (item.cost_price | currency:'DKK':'':null) : '-' }}</td>
                        <td class="text-right" *ngIf="showExtendedCostPrice">{{ item.cost_price_adjusted ? (item.cost_price_adjusted | currency:'DKK':'':null) : '-' }}</td>
                        <td>{{ item.price_final && item.cost_price ? (item.getProfitPercentage().toFixed(1) + ' %') : '-' }}</td>
                        <td class="centerized">
                            <button class="btn  btn-xs  btn-primary  mt-1  ml-1" (click)="updateOrderLine(item)"><fa-icon [icon]="faSave"></fa-icon></button>
                            <!-- @todo DISABLED UNTIL FUNCTIONAL <button class="btn  btn-xs  btn-success  ml-1"><fa-icon [icon]="faHat"></fa-icon></button> -->
                            <button class="btn  btn-xs  btn-danger  mt-1  ml-1  mlr-1" (click)="deleteOrderLine(item)"><fa-icon [icon]="faTimes"></fa-icon></button>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </app-table>
    </div>
</div>
