import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UnitsService} from '../services/units.service';
import {Unit} from '../services/unit';
import {UnitEditModalComponent} from '../unit-edit-modal/unit-edit-modal.component';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {LanguagesService} from "../services/languages.service";

@Component({
    selector: 'app-units',
    templateUrl: './units.component.html',
    styleUrls: ['./units.component.scss'],
    providers: [UnitsService, LanguagesService]
})
export class UnitsComponent implements OnInit {

    units: Unit[];

    loading = false;
    faExclamation = faExclamationCircle;
    missingLocalizations = [];

    constructor(public unitsService: UnitsService,
                public ngbModal: NgbModal,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this.languagesService.getLanguages().subscribe(() => {
            this.load();
        });
    }

    load(): void {
        this.loading = true;
        this.unitsService.getUnits().subscribe(response => {
            this.units = response.data;
            this.units.forEach((unit) => {
                this.missingLocalizations.push(unit._translations.checkMissingLocalizations(this.languagesService.languages));
            });
            this.loading = false;
        });
    }

    add(): void {
        let self = this;

        const modalRef = this.ngbModal.open(UnitEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        };
    }

    edit(unit: Unit, index: number): void {
        let self = this;

        const modalRef = this.ngbModal.open(UnitEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.unit = unit;
        // @todo send kun den relevante
        modalRef.componentInstance.missingLocalizations = this.missingLocalizations[index];
        modalRef.componentInstance.saved = function () {
            self.load();
        };
    }

    delete(unit: Unit): void {
        if (confirm($localize `Are you sure?\n\nThe unit will be deleted`)) {
            this.loading = true;

            this.unitsService.deleteUnit(unit).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
