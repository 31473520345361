import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-finance',
    templateUrl: './finance.component.html',
    styleUrls: ['./finance.component.scss'],
    providers: []
})
export class FinanceComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

        //

    }
}
