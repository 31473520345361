<ng-container *ngIf="order && order.hasClient() && contact && contact.full_name !== ''">
    <div class="row  mb-">
        <div class="col"><strong>{{'' //@TODO Type f.eks Tømmergård/Byggecenter}}</strong></div>
    </div>
    <div class="row  mb-">
        <div class="col">{{order.isCreditor() ? 'Kreditor' : 'Debtor'}}: <strong>{{order.isCreditor() ? this.order.creditor.name : this.order.debtor.name}}</strong></div>
    </div>
    <ng-container *ngFor="let contact of order.contacts">
        <ng-container *ngIf="contact.name === 'Contact'">
            <h5>{{contact.full_name}}</h5>
            <p>
                {{contact.address}}<br />
                {{contact.zip}} {{contact.city}}
            </p>
            <hr />
            <p>
                <ng-container *ngFor="let contactField of contact.contact_fields">
                    <div class="row">
                        <div class="col-6">
                            {{contactField.name}}:
                        </div>
                        <div class="col-6">
                            <strong>{{contactField._joinData.value}}</strong>
                        </div>
                    </div>
                </ng-container>
            </p>
        </ng-container>
    </ng-container>
</ng-container>
<ng-container *ngIf="order && order.hasClient() && contact && contact.full_name === ''">
    <app-loader *ngIf="!_client"></app-loader>
    <h5 *ngIf="_client">{{_client.name}}</h5>
</ng-container>
<app-loader *ngIf="!order || !order.hasClient()"></app-loader>
