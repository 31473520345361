<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!user" i18n>Add user</ng-container>
            <ng-container *ngIf="user" i18n>Edit user</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p *ngIf="!isManaged && !groups" class="text-muted" i18n>Loading...</p>

        <form *ngIf="isManaged || groups">
            <div class="form-group">
                <label for="user-name" i18n>Name</label>
                <input type="text" id="user-name" class="form-control" [(ngModel)]="_user.name" name="name" required>
            </div>

            <div class="form-group">
                <label for="user-email" i18n>Email</label>
                <input type="email" id="user-email" class="form-control" [(ngModel)]="_user.email" name="email"
                       required>
            </div>

            <div *ngIf="!isManaged" class="form-group">
                <label for="user-group-id" i18n>Group</label>

                <select id="user-group-id" class="form-control" name="group_id" [(ngModel)]="_user.group_id" required>
                    <!-- @todo hide is_managed groups ? -->
                    <option *ngFor="let group of groups" [ngValue]="group.id">{{ group.name }}</option>
                </select>
            </div>

            <p *ngIf="isManaged && !isProfileEdit" class="text-muted" i18n>User's group and permissions will be managed
                automatically.</p>

            <hr class="my-4">

            <ng-container *ngIf="!randomPassword">
                <div class="form-group">
                    <label for="user-password" i18n>Password</label>
                    <input type="password" id="user-password" autocomplete="off" class="form-control"
                           [(ngModel)]="_user.password" name="password" required>
                </div>

                <div class="form-group">
                    <label for="user-password_control" i18n>Password control</label>
                    <input type="password" id="user-password_control" autocomplete="off" class="form-control"
                           [(ngModel)]="_user.password_control" name="password_control" required>
                </div>
            </ng-container>

            <p *ngIf="randomPassword" class="text-muted" i18n>Password will be generated and sent to the user's email
                address.</p>

            <ng-container *ngIf="!isProfileEdit">
                <hr class="my-4">

                <div class="form-group">
                    <label for="user-external_reference" i18n>External Reference</label>
                    <input type="text" id="user-external_reference" class="form-control"
                           [(ngModel)]="_user.external_reference" name="external_reference">
                </div>

                <ng-container *ngIf="user">
                    <hr class="my-4">

                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="_user.active" name="active">
                                <ng-container i18n>Active</ng-container>
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="user-token-not-before" i18n>Token not before</label>

                        <div class="input-group">
                            <input type="text" id="user-token-not-before" class="form-control" name="token_not_before"
                                   i18n-placeholder placeholder="Token not before" [(ngModel)]="_user.token_not_before" ngbDatepicker
                                   #tokenNotBefore="ngbDatepicker">

                            <div class="input-group-append">
                                <button (click)="tokenNotBefore.toggle()" class="btn  btn-outline-secondary"
                                        type="button" i18n>Date picker
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="user-token-not-after" i18n>Token not after</label>

                        <div class="input-group">
                            <input type="text" id="user-token-not-after" class="form-control" name="token_not_after"
                                   i18n-placeholder placeholder="Token not after" [(ngModel)]="_user.token_not_after" ngbDatepicker
                                   #tokenNotAfter="ngbDatepicker">

                            <div class="input-group-append">
                                <button (click)="tokenNotAfter.toggle()" class="btn  btn-outline-secondary"
                                        type="button" i18n>Date picker
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="(!isManaged && !groups) || saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
