import {Component, Input, OnInit} from '@angular/core';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {Order} from '../../../services/order';

@Component({
    selector: 'app-create-order',
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {

    order: Order | null | undefined;
    saving = false;

    constructor(public orderService: TenFourOrderService) {
    }

    ngOnInit(): void {
        this.orderService.currentOrder.subscribe((response) => {
            this.order = response;
        });
    }
    setNewOrder(newOrder: Order): void {
        this.order = newOrder;
    }
    createOrder(): void {
        this.saving = true;
        /* @todo skal udledes af login brugeren istedet for at være hardcodet: */
        this.order.branch_id = '452eaeb9-f18d-4e16-94a6-92bbfb2c2ae5';
        this.orderService.addOrder(this.order).subscribe((response) => {
            this.saving = false;
            this.orderService.setOrderId(response.data.id);
            this.orderService.refreshOrder();
        });
    }

}
