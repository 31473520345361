import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {INav} from '../navigation/navigation.component';
import {environment} from '../../../environments/environment';
import {Observable} from "rxjs";
import {
    faBox,
    faCog,
    faLock,
    faLockOpen,
    faTruck,
    faUser,
    faUserFriends,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    private authedPermissions: Observable<any[]>;

    public headerClass = '';

    constructor(public authService: AuthService, public router: Router) {
        this.authedPermissions = this.authService.authedPermissionsDataStream();
    }

    menuData: INav[] = null;

    ngOnInit(): void {
        this.authedPermissions.subscribe((event) => {
            this.setupMenus();
        });

        this.router.events.subscribe((val) => {
            this.headerClass = '';

            switch (this.router.url) {
                case '/custom/salgsordre':
                    this.headerClass = 'gradient-sale';
                    break;

                case '/custom/koebsordre':
                    this.headerClass = 'gradient-buy';
                    break;

                case '/custom/opslag':
                    this.headerClass = 'gradient-lookup';
                    break;

                case '/custom/kartotek':
                    this.headerClass = 'gradient-inventory';
                    break;

                case '/custom/udskrifter':
                    this.headerClass = 'gradient-print';
                    break;

                case '/custom/finans':
                    this.headerClass = 'gradient-finance';
                    break;

                case '/custom/koncern':
                    this.headerClass = 'gradient-corporate';
                    break;
            }
        });


    }

    setupMenus(): void {
        // @todo make this cleaner and more extensible
        // @todo implement stateservice and listen for state changes
        switch (environment.customer) {
            case '10-4':
                this.setTenFourMenu();
                break;
            case 'thorsens':
                this.setThorsenMenu();
                break;
            case 'helms' :
                this.setHelmsMenu();
                break;
            default:
                this.setDefaultMenu();
                break;
        }
    }

    setDefaultMenu(): void {
        this.menuData = [
            {
                title: $localize `Orders`,
                url: '/orders',
                className: 'navigation__item--orders',
                permission: 'loggedIn',
                icon: faTruck
            },
            {
                title: $localize `Creditors`,
                url: '/creditors',
                className: 'navigation__item--creditors',
                permission: 'loggedIn',
                icon: faUsers
            },
            {
                title: $localize `Debtors`,
                url: '/debtors',
                className: 'navigation__item--debtors',
                permission: 'loggedIn',
                icon: faUserFriends
            },
            {
                title: $localize `Products`,
                url: '/products',
                className: 'navigation__item--products',
                permission: 'loggedIn',
                icon: faBox
            },
            {
                title: $localize `Articles`,
                url: '/articles',
                className: 'navigation__item--articles',
                permission: 'loggedIn',
                icon: faBox
            },
            {
                title: $localize  `Settings`,
                url: '/settings',
                permission: 'admin',
                className: 'navigation__item--settings',
                icon: faCog
            },
            {
                title: this.authService.loggedIn() ? $localize `Logout` : $localize `Login`,
                url: !this.authService.loggedIn() ? '/login' : undefined,
                onClick: this.authService.loggedIn() ? () => this.authService.logout() : undefined,
                className: this.authService.loggedIn() ? 'navigation__item--logout' : 'navigation__item--login',
                icon: this.authService.loggedIn() ? faLock : faLockOpen,
            },
        ];
    }

    setTenFourMenu(): void {
        this.menuData = [
            {
                title: 'Salgsordre', // idaweben
                url: 'custom/salgsordre',
                permission: 'admin,debtors',
            },
            {
                title: 'Købsordre', // kobweben
                url: 'custom/koebsordre',
                permission: 'admin, debtors'
            },
            {
                title: 'Opslag', // idaintra
                url: 'custom/opslag',
                permission: 'admin,debtors',
            },
            {
                title: 'Kartotek', // karweben
                url: 'custom/kartotek',
                permission: 'admin, debtors',
            },
            {
                title: 'Udskrifter', // skrweben
                url: 'custom/udskrifter',
                permission: 'admin, debtors',
            },
            {
                title: 'Finans', // finweben
                url: 'custom/finans',
                permission: 'admin, debtors',
            },
            {
                title: 'Koncern', // konweben
                url: 'custom/koncern',
                permission: 'admin, debtors',
            },
            {
                title: $localize `Settings`,
                url: '/settings',
                permission: 'admin',
                icon: faCog,
            },
            {
                title: this.authService.loggedIn() ? 'Log ud' : 'Log ind',
                url: !this.authService.loggedIn() ? '/login' : undefined,
                onClick: this.authService.loggedIn() ? () => this.authService.logout() : undefined,
                icon: this.authService.loggedIn() ? faLock : faLockOpen,
            },
        ];
    }

    setHelmsMenu(): void {
        this.menuData = [
            {
                title: 'Bestilling',
                url: '/offers',
                permission: 'admin,debtors',
                children: [
                    {
                        title: 'Bestilling',
                        url: '/offers',
                        permission: 'admin, debtors'
                    },
                    {
                        title: 'Redskabsbestilling',
                        url: '/tool-offers',
                        permission: 'admin, debtors'
                    },{
                        title: 'Lagerliste',
                        url: '/custom/inventory',
                        permission: 'admin, debtors'
                    },
                ]
            },
            {
                title: 'Mine ordrer',
                url: '/custom/my-orders',
                permission: 'debtors',
            },
            {
                title: 'Mine tilbud',
                url: '/custom/offers',
                permission: 'debtors',
            },
            {
                title: 'Ordrer',
                url: '/orders',
                permission: 'admin,branch_admin',
            },
            //  {
            //      title: 'Lager',
            //      url: '/shipment',
            //      className: 'navigation__item--shipment',
            //  },
            //  {
            //      title: 'Diverse',
            //      url: '/shipment',
            //      className: 'navigation__item--shipment',
            //  },

            {
                title: 'Diverse',
                children: [
                    // {
                    //     title: 'Lager',
                    //     url: '/custom/inventory',
                    //     permission: 'admin',
                    // },
                    {
                        title: 'På vej',
                        url: '/custom/arrivals',
                        permission: 'admin'
                    },
                    {
                        title: 'Fabrik',
                        url: '/custom/factory',
                        permission: 'admin'
                    },
                    {
                        title: 'Sammenligning',
                        url: '/custom/compare',
                        permission: 'admin, debtors'
                    },
                    {
                        title: 'Produktions linjer',
                        url: '/production-lines',
                        permission: 'admin, debtors',
                    },
                    {
                        title: 'Demo Bestilling',
                        url: '/custom/demos',
                        permission: 'admin, debtors',
                    },
                    {
                        title: 'Fil Center',
                        url: '/custom/file-center',
                        permission: 'admin, debtors',
                    },
                    {
                        title: 'Salgsstatistik',
                        url: '/custom/statistics',
                        permission: 'admin, creditors',
                    }
                ]
            },
            {
                title: 'Settings',
                url: '/settings',
                permission: 'admin',
                icon: faCog,
                children: [
                    {
                        title: 'Settings',
                        url: '/settings',
                        permission: 'admin',
                        icon: faCog,
                    },
                    {
                        title: 'Forhandlere',
                        url: '/debtors',
                        permission: 'admin',
                    },
                ]
            },
            {
                title: this.authService.loggedIn() ? 'Log ud' : 'Log ind',
                url: !this.authService.loggedIn() ? '/login' : undefined,
                onClick: this.authService.loggedIn() ? () => this.authService.logout() : undefined,
                icon: this.authService.loggedIn() ? faLock : faLockOpen,
            },
        ];
    }

    setThorsenMenu(): void {
        this.menuData = [
            {
                title: $localize `Orders`,
                url: '/orders',
                permission: 'admin,debtors'
            },
            {
                title: $localize `Deliveries`,
                url: '/products',
                queryParams: {categoryId: 'a2c21341-b893-4719-8d3e-4ffc0390675c'},
                permission: 'admin, creditors',
            },
            {
                title: $localize `Farm lands`,
                url: '/custom/fields',
                queryParams: {categoryId: 'a2c21341-b893-4719-8d3e-4ffc0390675c'},
                permission: 'admin, creditors',
            },
            {
                title: $localize `Creditors`,
                url: '/creditors',
                permission: 'admin,creditors',
            },
            {
                title: $localize `Debtors`,
                url: '/debtors',
                permission: 'admin,debtors',
            },
            {
                title: $localize `:@@profile:Profile`,
                url: '/profile',
                permission: 'admin,debtors,creditors',
            },
            {
                title: $localize `:@@settings:Settings`,
                url: '/settings',
                permission: 'admin',
            },
            {
                title: this.authService.loggedIn() ? $localize`Logout` : $localize`Login`,
                url: !this.authService.loggedIn() ? '/login' : undefined,
                onClick: this.authService.loggedIn() ? () => this.authService.logout() : undefined,
                // className: this.authService.loggedIn() ? 'navigation__item--logout' : 'navigation__item--login',
            },
        ];
    }

    headerLogo(): string {

        if (environment.customer === 'thorsens') {
            return '<img src="../../../assets/themes/thorsens/images/logo.png" class="header__logo-img " />';
        } else if (environment.customer === 'helms') {
            return '<img src="../../../assets/themes/helms/assets/png/schaffer-logo.png" class="header__logo-img  img-responsive" />';
        } else {
            return 'Winston';
        }

    }
}
