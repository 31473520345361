<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Add product quantity discount</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!priceGroups || !priceAdjustments" class="text-muted" i18n>Loading...</p>

            <ng-container *ngIf="priceGroups && priceAdjustments">
                <form class="form">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="product-quantity-discount-minimum" i18n>Minimum</label>
                            <input type="number" id="product-quantity-discount-minimum" class="form-control" [(ngModel)]="_productQuantityDiscount.min" name="product-quantity-discount-minimum" required>
                        </div>

                        <div class="form-group col-md-12">
                            <label for="product-quantity-discount-pct" i18n>Percentage</label>
                            <input type="number" id="product-quantity-discount-pct" class="form-control" [(ngModel)]="_productQuantityDiscount.pct" name="product-quantity-discount-pct" required>
                        </div>

                        <div class="form-group  col-md-12">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" [(ngModel)]="_productQuantityDiscount.requires_min_multiple" name="requires_min_multiple"> <ng-container i18n>Requires amount is multiple of minimum</ng-container>
                                </label>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <label for="product-quantity-discount-price-group-id" i18n>Price group</label>

                            <select id="product-quantity-discount-price-group-id" class="form-control" [(ngModel)]="_productQuantityDiscount.price_group_id" name="product-quantity-discount-price_group_id">
                                <option [ngValue]="null" i18n>-- Select price group --</option>
                                <option *ngFor="let priceGroup of priceGroups" [ngValue]="priceGroup.id">{{ priceGroup.name }}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-12">
                            <label for="product-quantity-discount-price-adjustment-id" i18n>Price adjustments</label>

                            <select id="product-quantity-discount-price-adjustment-id" class="form-control" [(ngModel)]="_productQuantityDiscount.price_adjustment_id" name="product-quantity-discount-price_adjustment_id">
                                <option [ngValue]="null" i18n>-- Select price adjustment --</option>
                                <option *ngFor="let priceAdjustment of priceAdjustments" [ngValue]="priceAdjustment.id">{{ priceAdjustment.name }}</option>
                            </select>
                        </div>
                    </div>
                </form>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!priceGroups || !priceAdjustments || !_productQuantityDiscount.min || !_productQuantityDiscount.pct">
            <span *ngIf="!productQuantityDiscount" i18n>Add</span>
            <span *ngIf="productQuantityDiscount" i18n>Edit</span>
        </button>
    </div>
</div>
