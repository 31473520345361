import {Pipe, PipeTransform} from '@angular/core';
import {Price} from "../services/price";

@Pipe({name: 'priceFilter'})
export class PriceFilterPipe implements PipeTransform {
    transform(allHeroes: Price[], key, value: any, override: boolean) {
        return allHeroes.filter(hero => {

            if (override) {
                return true;
            }

            if (this.getPropByString(hero, key) === value) {
                return true;
            }
        });
    }

    private getPropByString(obj, propString) {
        if (!propString) {
            return obj;
        }

        let prop, props = propString.split('.');

        for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
            prop = props[i];

            const candidate = obj[prop];
            if (candidate !== undefined) {
                obj = candidate;
            } else {
                break;
            }
        }
        return obj[props[i]];
    }
}
