import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ProductImportMapping} from "./product-import-mapping";

@Injectable()
export class ProductImportMappingsService {

    protected url = environment.apiBase + 'product-import-mappings';

    constructor(public apiService: ApiService) {
    }

    getProductImportMapping(id: string): Observable<{data: ProductImportMapping, count: number, perPage: number}> {
        return this.apiService.get<ProductImportMapping>(this.url + '/' + id, true, ProductImportMapping);
    }

    getProductImportMappings(): Observable<{data: ProductImportMapping[], count: number, perPage: number}> {
        return this.apiService.get<ProductImportMapping[]>(this.url, true, ProductImportMapping);
    }

    addProductImportMapping(productImportMapping: ProductImportMapping): Observable<{data: ProductImportMapping, count: number, perPage: number}> {
        return this.apiService.post<ProductImportMapping>(this.url, productImportMapping, true, ProductImportMapping);
    }

    updateProductImportMapping(productImportMapping: ProductImportMapping): Observable<{data: ProductImportMapping, count: number, perPage: number}> {
        return this.apiService.patch<ProductImportMapping>(this.url + '/' + productImportMapping.id, productImportMapping, true, ProductImportMapping);
    }

    deleteProductImportMapping(productImportMapping: ProductImportMapping): Observable<{data: ProductImportMapping, count: number, perPage: number}> {
        return this.apiService.delete<ProductImportMapping>(this.url + '/' + productImportMapping.id, true, ProductImportMapping);
    }
}
