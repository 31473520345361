import * as dayjs from 'dayjs';
import {Product, ProductRelatable} from "./product";

export class ProductRelation implements ProductRelatable {

    id: string;
    product_id: string;
    related_product_id: string;
    note: string;
    is_standard: boolean;
    follow_requirement_minimum: number;
    follow_requirement_repeat: number;
    follow_amount: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    product: Product;
    // tslint:disable-next-line:variable-name
    related_product: Product;
    _joinData: any;

    childRelation: boolean;

    constructor() {
    }

    static fromJSON(json: ProductRelationJSON | string): ProductRelation {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductRelation.reviver);
        } else {
            let productRelation = Object.create(ProductRelation.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product: null,
                related_product: null
            }

            Object.keys(json).forEach((key, _) => {
                if ((key === 'product' || key === 'related_product') && json[key]) {
                    extra.product = Product.fromJSON(json[key]);
                }
                if ((key === 'product' || key === 'related_product') && json[key]) {
                    extra.related_product = Product.fromJSON(json[key]);
                }
            });

            return Object.assign(productRelation, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductRelation.fromJSON(value) : value;
    }

    addProduct(product: Product): void {
        if (this.childRelation) {
            this.related_product_id = product.id;
        } else {
            this.product_id = product.id;
        }

        this.product = product;
        this.related_product = product;
    }

    removeProduct(): void {
        if (this.childRelation) {
            this.related_product_id = null;
        } else {
            this.product_id = null;
        }

        this.product = null;
        this.related_product = null;
    }

    isFollowProduct(): boolean {
        return this.follow_requirement_minimum > 0;
    }

    setNotFollowProduct(): void {
        this.follow_requirement_minimum = null;
        this.follow_requirement_repeat = null;
        this.follow_amount = null;
    }

    getFollowProductQuantity(quantity: number): number {
        if (quantity < this.follow_requirement_minimum) {
            return 0;
        }

        return this.follow_amount + (this.follow_amount * Math.floor(quantity / this.follow_requirement_repeat));
    }
}

// A representation of ProductRelation data that can be converted to
// and from JSON without being altered.
interface ProductRelationJSON {
    id?: string;
    product_id?: string;
    related_product_id?: string;
    note?: string;
    is_standard?: boolean;
    follow_requirement_minimum?: number;
    follow_requirement_repeat?: number;
    follow_amount?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    product?: Product;

    childRelation?: boolean;
}
