import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-helper',
  templateUrl: './table-helper.component.html',
  styleUrls: ['./table-helper.component.scss']
})
export class TableHelperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
