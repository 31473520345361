import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Creditor, CreditorRelatable} from "../services/creditor";
import {CreditorsService} from "../services/creditors.service";
import {Contact} from "../services/contact";

@Component({
    selector: 'app-creditor-select-modal',
    templateUrl: './creditor-select-modal.component.html',
    styleUrls: ['./creditor-select-modal.component.scss'],
    providers: [CreditorsService]
})
export class CreditorSelectModalComponent implements OnInit {

    public creditorRelatable: CreditorRelatable;

    creditors: Creditor[];

    creditor: Creditor = null;

    referenceContact: Contact = null;
    deliveryContact: Contact = null;

    saved;
    parent;

    constructor(public activeModal: NgbActiveModal,
                public creditorsService: CreditorsService) {
    }

    ngOnInit() {
        this.creditorsService.getCreditors(1,-1, false, true).subscribe(response => {
            this.creditors = response.data;
        });
    }

    creditorChanged() {
        this.deselectReferenceContact();
        this.deselectDeliveryContact();

        if (this.creditor.contacts.length > 0) {
            this.referenceContact = this.creditor.contacts[0];
        }
    }

    selectReferenceContact(contact: Contact) {
        this.referenceContact = contact;
    }

    selectDeliveryContact(contact: Contact) {
        this.deliveryContact = contact;
    }

    deselectReferenceContact() {
        this.referenceContact = null;
    }

    deselectDeliveryContact() {
        this.deliveryContact = null;
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.creditorRelatable.addCreditor(this.creditor);

        this.saved({
            Contact: this.referenceContact,
            Delivery: this.deliveryContact
        });

        this.activeModal.close();
    }
}
