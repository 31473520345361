import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Attachment} from '../services/attachment';
import {ImageHandler, Options} from 'ngx-quill-upload';
import ImageResize from 'quill-image-resize-module';
import Quill from 'quill';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Article} from '../services/article';
import {ArticlesService} from '../services/articles.service';
Quill.register('modules/imageHandler', ImageHandler);
Quill.register('modules/ImageResize', ImageResize);
@Component({
    selector: 'app-quill-editor',
    templateUrl: './quill-editor.component.html',
    styleUrls: ['./quill-editor.component.scss'],
    providers: [ArticlesService]
})
export class QuillEditorComponent implements OnInit {
    private modalRef: NgbModalRef;
    constructor(public modalService: NgbModal,
                public articlesService: ArticlesService) {
    }

    @Input() article: Article;
    @Input() body = '';
    @Output() bodyChange = new EventEmitter<string>();
    quill: Quill;
    modules = null;
    file: File = null;
    @ViewChild('metaContent') private metaContent;
    @ViewChild('sourceContent') private sourceContent;
    @ViewChild('attachemntPicker') private attachemntPicker;
    activeItem: Element;
    imageAlt: string;
    imageClass: string;
    source: string;

    ngOnInit(): void {
        this.setupEditor();
    }

    public setupEditor(): void {
        // Prepare attachment values for later
        const attachments = [];
        const attachmentNames = [];
        this.article.attachments.forEach((value) => {
            attachments.push(value.source);
            attachmentNames.push(value.name);
        });
        // Define modules
        this.modules = {
            toolbar: '#toolbar-container',
            imageHandler: {
                upload: (file) => {
                    return new Promise<string>((resolve, reject) => {
                        let attachment = new Attachment();
                        attachment.description = 'Content image';
                        attachment.language = 'dk';
                        this.articlesService.attachment(attachment, file, this.article.id).subscribe((result) => {
                            attachment = Attachment.fromJSON(result.data);
                            resolve(attachment.source);
                        }, error => {
                            console.log(error);
                            reject(error);
                        });
                    });
                },
                accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
            } as Options,
            ImageResize: {
                modules: ['Resize', 'DisplaySize', 'Toolbar']
            }
        };
        // Create quill editor
        this.quill = new Quill('#body', {
            modules: this.modules,
            theme: 'snow',
        });
        document.querySelector('.ql-editor').setAttribute('style', 'background-color: white;');

        // Update source and listeners on images every time editor is updated
        this.quill.on('editor-change', () => {
            this.updateListeners();
            this.body = document.querySelector('.ql-editor').innerHTML;
            this.bodyChange.emit(this.body);
        });

        // Replace existing span with img
        // const placeholderPickerItems = document.querySelectorAll('.ql-insert-attachment1 .ql-picker-item');
        // let i = 0;
        // placeholderPickerItems.forEach((item) => {
        //     const newItem = document.createElement('img');
        //     newItem.setAttribute('data-value', attachments[i]);
        //     newItem.setAttribute('src', attachments[i]);
        //     newItem.setAttribute('alt', attachmentNames[i]);
        //     newItem.setAttribute('width', '120');
        //     newItem.classList.add('ql-picker-item');
        //     item.parentNode.replaceChild(newItem, item);
        //     i++;
        // });

        // Fill out labels
        // document.querySelector('.ql-insert-attachment1 .ql-picker-label').innerHTML = 'Insert attachmentssss';
        // @todo Find better icon?
        document.querySelector('.ql-show-html').innerHTML =
            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-text" viewBox="0 0 16 16">\n' +
            '  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>\n' +
            '  <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>\n' +
            '</svg>';

        // Add click event listener that adds image to editor
        // const items = document.querySelectorAll('.ql-insert-attachment .ql-picker-item');
        // items.forEach((value) => {
        //     value.addEventListener('click', () => {
        //         tslint:disable-next-line:max-line-length
        // document.querySelector('.ql-editor').innerHTML = document.querySelector('.ql-editor').innerHTML + '<img src="' + value.attributes.getNamedItem('data-value').value + '">';
        // });
        // });

        // Open the source modal on click
        const source = document.querySelector('.ql-show-html');
        source.addEventListener('click', (event) => {
            this.modalService.open(this.sourceContent);
        });

        // Load the editor with contents of body
        document.querySelector('.ql-editor').innerHTML = this.body;

        this.updateListeners();
    }

    public onChange(event): void {
        this.file = event.target.files[0];
    }

    public updateListeners(): void {
        const images = document.querySelectorAll('.ql-editor img');
        images.forEach((item) => {
            if (item.eventListeners('click').length === 0) {
                item.addEventListener('click', (event) => {
                    this.activeItem = item;
                    this.imageAlt = item.getAttribute('alt');
                    this.imageClass = item.classList.toString();

                    // Setup listener on image resize element as it is created on item click
                    setTimeout(() => {
                        const imageResize = document.querySelector('#body > div:nth-child(5)');
                        if (imageResize) {
                            imageResize.addEventListener('dblclick', (ev: Event) => {
                                // Press the enter key to prevent deleting the image while typing in textarea
                                const keyEvent = new KeyboardEvent('keyup', {
                                    altKey: false,
                                    code: 'KeyEnter',
                                    ctrlKey: false,
                                    isComposing: false,
                                    key: '13'
                                });
                                imageResize.dispatchEvent(keyEvent);

                                this.modalService.open(this.metaContent);
                            });
                        }
                    }, 1);

                });
            }
        });
    }

    public updateImage(): void {
        if (this.imageAlt === null) {
            this.imageAlt = '';
        }
        if (this.imageClass === null) {
            this.imageClass = '';
        }
        this.activeItem.setAttribute('alt', this.imageAlt);
        const existing = [];
        this.activeItem.classList.forEach((item) => {
            existing.push(item);
        });
        this.activeItem.classList.remove(...existing);

        const classes = this.imageClass.split(' ');
        classes.forEach((item) => {
            if (item) {
                this.activeItem.classList.add(item);
            }
        });
        this.modalService.dismissAll();
    }

    public updateSource(): void {
        document.querySelector('.ql-editor').innerHTML = this.body;
        this.modalService.dismissAll();
    }

    openAttachemtnModal() {
        this.modalRef = this.modalService.open(this.attachemntPicker, {size: 'lg'});
    }

    pickedAttachemnt($event: Attachment) {
        const insertIndex = this.quill.getSelection() ? this.quill.getSelection().index : this.quill.getLength();
        if ($event.type === 'application/pdf') {
            this.quill.insertText(insertIndex, $event.name, 'link', '/download/' + $event.id);
        } else {
            this.quill.insertEmbed(insertIndex, 'image', $event.thumbnailUrl('product_list_preview'));
        }
        this.modalRef.close();
    }
}
