import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from "../services/product";
import {PriceGroupsService} from "../services/price-groups.service";
import {PriceGroup} from "../services/price-group";
import {Price} from "../services/price";

@Component({
    selector: 'app-product-price-add-modal',
    templateUrl: './product-price-add-modal.component.html',
    styleUrls: ['./product-price-add-modal.component.scss'],
    providers: [PriceGroupsService]
})
export class ProductPriceAddModalComponent implements OnInit {

    public product: Product;

    priceGroups: PriceGroup[];

    price: Price;

    constructor(public activeModal: NgbActiveModal,
                public priceGroupsService: PriceGroupsService) {
    }

    ngOnInit() {
        this.priceGroupsService.getPriceGroups(1, -1).subscribe(response => {
            let unusedPriceGroups = [];

            for (let priceGroup of response.data) {
                if (!this.product.hasPriceWithGroup(priceGroup)) {
                    unusedPriceGroups.push(priceGroup);
                }
            }

            this.priceGroups = unusedPriceGroups.filter((e) => {
                return !e.is_inactive;
            });
        });

        this.price = Price.fromJSON({});
    }

    add() {
        this.price.price_group_id = this.price.price_group.id;

        this.product.addPrice(this.price);

        this.close();
    }

    close() {
        this.activeModal.dismiss();
    }
}
