<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!this.creditor" i18n>Add creditor</ng-container>
            <ng-container *ngIf="this.creditor" i18n>Edit creditor</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="!_creditor || !contactFieldPresets" i18n>Loading...</p>

        <form *ngIf="_creditor && contactFieldPresets">
            <div class="form-group">
                <label for="creditor-name" i18n>Name</label>
                <input type="text" id="creditor-name" class="form-control" [(ngModel)]="_creditor.name" name="name">
            </div>

            <hr>

            <ng-template ngFor let-reference [ngForOf]="_creditor.references">
                <div class="form-group">
                    <label for="creditor-reference-{{ reference.id }}-value">{{ reference.name }}</label>
                    <input type="text" id="creditor-reference-{{ reference.id }}-value" class="form-control" [(ngModel)]="reference._joinData.value" name="{{ reference.id }}-value">
                </div>
            </ng-template>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!contactFieldPresets || saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
