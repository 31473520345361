import * as dayjs from 'dayjs';
import {Component, OnInit} from '@angular/core';
import {AuditLogsService} from "../services/audit-logs.service";
import {AuditLog} from "../services/audit-log";

@Component({
    selector: 'app-audit-logs',
    templateUrl: './audit-logs.component.html',
    styleUrls: ['./audit-logs.component.sass'],
    providers: [AuditLogsService]
})
export class AuditLogsComponent implements OnInit {

    auditLogs: AuditLog[];

    loading = false;

    start = dayjs().subtract(7, 'day');
    end = dayjs();

    page = 1;

    limit = 25;
    count;
    perPage;

    constructor(public auditLogsService: AuditLogsService) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number) {
        this.loading = true;

        this.auditLogsService.getAuditLogs(page, this.limit, this.start, this.end, true).subscribe(response => {
            this.auditLogs = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    pageChange(page: number) {
        this.load(page)
    }
}
