import {Component, Input, OnInit} from '@angular/core';
import {OfferGeneratorService} from "../../services/offer-generator.service";
import {Product} from "../../services/product";
import {ProductRelation} from "../../services/product-relation";
import {Router} from "@angular/router";
import {ProductsService} from "../../services/products.service";
import {faCamera} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-wheel-select',
    templateUrl: './wheel-select.component.html',
    styleUrls: ['./wheel-select.component.scss'],
    providers: [ProductsService]
})
export class WheelSelectComponent implements OnInit {
    faCamera = faCamera;

    @Input() wheels: Product[];

    public activeThumbnail: string;

    constructor(
        private router: Router,
        public productsService: ProductsService,
        public offerService: OfferGeneratorService) {
    }

    ngOnInit(): void {
        if (!this.offerService.selectedTool) {
            this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['tool-orders']}}]);
        } else {
            this.productsService.getProducts(1, -1, false, false, true, false, false, false, {
                byCategories: '86252329-c3cd-4632-aa50-dc5966a7566a', // wheels
                withProductRelations: false,
                withProductStocks: true,
                withAdjustedPrices: true,
                byParentRelation: this.offerService.selectedTool.product.id,
                withAttachments: true,
            }).subscribe((response) => {
                this.wheels = response.data;
                if (this.offerService.selectedWheel) {
                    this.offerService.pausenext = false;
                } else {
                    this.offerService.pausenext = true;
                    // @todo better way to handle defaults ?
                    this.wheels.forEach((wheel) => {
                        if (wheel.product_relation_parents && wheel.product_relation_parents[0].is_standard) {
                            this.onClick(wheel);
                        }
                    });
                }
            });
        }
    }

    onClick(wheel: Product): void {
        this.offerService.changeSelectedWheel(wheel);
        this.offerService.pausenext = false;
    }

    // onThumbnailClick(event, tooltip, wheel: Product){
    //     event.stopPropagation();
    //     wheel.thumbnail ? this.activeThumbnail = wheel.thumbnail : this.activeThumbnail = undefined;
    //     if (tooltip.isOpen()) {
    //         tooltip.close();
    //     } else {
    //         tooltip.open();
    //     }
    // }

    onThumbnailClick(event, tooltip, extraTool: Product) {
        event.stopPropagation();

        this.productsService.getProduct(extraTool.id, false, false, false, false, false, false, null, false, false, false, false, {withAttachments: true}).subscribe((response) => {
            if (response.data.attachments && response.data.attachments.length > 0) {
                this.activeThumbnail = response.data.getThumbnail('product_list_preview');
            }
        });
        if (tooltip.isOpen()) {
            tooltip.close();
        } else {
            tooltip.open();
        }
    }
}
