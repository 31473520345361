import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {VatTypesService} from "../services/vat-types.service";
import {VatType} from "../services/vat-type";

@Component({
    selector: 'app-vat-type-edit-modal',
    templateUrl: './vat-type-edit-modal.component.html',
    styleUrls: ['./vat-type-edit-modal.component.scss'],
    providers: [VatTypesService]
})
export class VatTypeEditModalComponent implements OnInit {

    public vatType: VatType;
    public _vatType: VatType;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public vatTypesService: VatTypesService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._vatType = this.vatType ? VatType.fromJSON(this.vatType) : VatType.fromJSON({is_default: false});
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.vatType) {
            this.vatTypesService.updateVatType(this._vatType).subscribe(vatType => {
                this.saved();

                this.saving = false;

                this.activeModal.close(vatType);
            });
        } else {
            this.vatTypesService.addVatType(this._vatType).subscribe(vatType => {
                this.saved();

                this.saving = false;

                this.activeModal.close(vatType);
            });
        }
    }
}
