import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ContactType} from "./contact-type";

@Injectable()
export class ContactTypesService {

    protected url = environment.apiBase + 'contact-types';

    constructor(public apiService: ApiService) {
    }

    getContactType(id: string): Observable<{ data: ContactType, count: number, perPage: number }> {
        return this.apiService.get<ContactType>(this.url + '/' + id, true, ContactType);
    }

    getContactTypes(): Observable<{ data: ContactType[], count: number, perPage: number }> {
        return this.apiService.get<ContactType[]>(this.url, true, ContactType);
    }

    addContactType(contactType: ContactType): Observable<{ data: ContactType, count: number, perPage: number }> {
        return this.apiService.post<ContactType>(this.url, contactType, true, ContactType);
    }

    updateContactType(contactType: ContactType): Observable<{ data: ContactType, count: number, perPage: number }> {
        return this.apiService.patch<ContactType>(this.url + '/' + contactType.id, contactType, true, ContactType);
    }

    updateContactTypes(contactTypes: ContactType[]): Observable<{ data: ContactType[], count: number, perPage: number }> {
        return this.apiService.patch<ContactType[]>(this.url, contactTypes, true, ContactType);
    }

    deleteContactType(contactType: ContactType): Observable<{ data: ContactType, count: number, perPage: number }> {
        return this.apiService.delete<ContactType>(this.url + '/' + contactType.id, true, ContactType);
    }
}
