<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!productField" i18n>Add product field</ng-container>
            <ng-container *ngIf="productField" i18n>Edit product field</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-loader *ngIf="!languagesService.languages" class="text-muted" i18n>Loading...</app-loader>


    <div class="modal-body" *ngIf="languagesService.languages">
        <app-language-picker [missingLocalizations]="missingLocalizations"></app-language-picker>

        <form>
            <div class="form-group">
                <label for="product-field-name" i18n>Name</label><app-language-indicator></app-language-indicator>
                <input type="text" id="product-field-name" class="form-control" [(ngModel)]="name[languagesService.index]" name="name">
            </div>

            <div class="form-group">
                <label for="product-field-type" i18n>Type</label>
                <select id="product-field-type" class="form-control" [(ngModel)]="_productField.type" name="type">
                    <option [ngValue]="'text'" i18n>Text</option>
                    <option [ngValue]="'checkbox'" i18n>Checkbox</option>
                    <option [ngValue]="'link'" i18n>Link</option>
                </select>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <input type="checkbox" id="product-field-localized" [(ngModel)]="_productField.is_localized" name="localized">
                    <label for="product-field-localized" i18n>Localized</label>
                </div>
            </div>

            <!--<div class="form-group">
                <div class="checkbox">
                    <input type="checkbox" id="product-field-translatable" [(ngModel)]="_productField.translatable" name="translatable">
                    <label for="product-field-type" i18n>Translatable</label>
                </div>
            </div>-->
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
