<ng-container *ngIf="offerService.selectedTool">
    <app-loader *ngIf="!extras"></app-loader>
    <app-table [autoSizeFirstColumn]="true" *ngIf="extras">
        <thead>
        <th></th>
        <th>Ekstraudstyr</th>
        <th class="text-center">Standard</th>
        <th class="text-right">Pris i DKK</th>
        </thead>
        <tbody>
        <tr *ngFor="let product of extras" (click)="onClick(product)" class="clickable">
            <td>
                <input type="checkbox" [checked]="checkIfExtraIsSelected(product)" [disabled]="product.product_relation_parents[0].is_standard"/>
            </td>
            <td>
                <fa-icon *ngIf="product.getFieldValueForId(idValuesService.beskrivelse)" class="mr-1"
                         [icon]="faInfoCircle" [ngbTooltip]="product.getFieldValueForId(idValuesService.beskrivelse)" [triggers]="'hover focus'"></fa-icon>

                <span>
                {{product.name}}
                </span>

            </td>
            <td class="text-center">
                <fa-icon *ngIf="product.product_relation_parents[0].is_standard" [icon]="faCheck"
                         [ngbTooltip]="'Standard'"></fa-icon>
            </td>
            <td class="text-right">
                {{product.price|currency:'DKK'}}
            </td>
        </tr>
        </tbody>
    </app-table>

    <ng-template ngFor let-group [ngForOf]="groups" let-i="index">
        <p class="h3">{{group.name}}</p>
        <ng-container *ngIf="group.is_mutually_exclusive">
            <app-table [autoSizeFirstColumn]="true" *ngIf="groups">
                <thead>
                <th></th>
                <th>Ekstraudstyr</th>
                <th class="text-center">Standard</th>
                <th class="text-right">Pris i DKK</th>
                </thead>
                <tbody>
                <tr *ngFor="let productRelation of group.product_relations_product_relations_groups"
                    (click)="onExclusiveClick(productRelation.product_relation.related_product, group)"
                    class="clickable">
                    <td>
                        <input type="radio"
                               [checked]="checkIfExtraIsSelected(productRelation.product_relation.related_product)"/>
                    </td>
                    <td>
                        {{productRelation.product_relation.related_product.name}}
                    </td>
                    <td class="text-center">
                        <fa-icon *ngIf="productRelation.product_relation.is_standard" [icon]="faCheck"
                                 [ngbTooltip]="'Standard'"></fa-icon>
                    </td>
                    <td class="text-right">
                        {{productRelation.product_relation.related_product.price|currency:'DKK'}}
                    </td>
                </tr>
                </tbody>
            </app-table>
        </ng-container>
        <ng-container *ngIf="group.is_dependent">
            <input (click)="onDependentClick(group)" type="checkbox"
                   [checked]="checkIfExtraIsSelected(group.product_relations_product_relations_groups[0].product_relation.related_product)"/>

            <app-table [autoSizeFirstColumn]="true" *ngIf="groups">
                <thead>
                <th></th>
                <th>Ekstraudstyr</th>
                <th class="text-center">Standard</th>
                <th class="text-right">Pris i DKK</th>
                </thead>
                <tbody>
                <tr *ngFor="let productRelation of group.product_relations_product_relations_groups">
                    <td>
                        <input type="checkbox"
                               [checked]="checkIfExtraIsSelected(productRelation.product_relation.related_product)"
                               disabled/>
                    </td>
                    <td>
                        {{productRelation.product_relation.related_product.name}}
                    </td>
                    <td class="text-center">
                        <fa-icon *ngIf="productRelation.product_relation.is_standard" [icon]="faCheck"
                                 [ngbTooltip]="'Standard'"></fa-icon>
                    </td>
                    <td class="text-right">
                        {{productRelation.product_relation.related_product.price|currency:'DKK'}}
                    </td>
                </tr>
                </tbody>
            </app-table>
        </ng-container>
        <ng-container *ngIf="!group.is_mutually_exclusive && !group.is_dependent">
            <app-table [autoSizeFirstColumn]="true" *ngIf="groups">
                <thead>
                <th></th>
                <th>Ekstraudstyr</th>
                <th class="text-center">Standard</th>
                <th class="text-right">Pris i DKK</th>
                </thead>
                <tbody>
                <tr *ngFor="let productRelation of group.product_relations_product_relations_groups"
                    (click)="onClick(productRelation.product_relation.related_product)" class="clickable">
                    <td>
                        <input type="checkbox"
                               [checked]="checkIfExtraIsSelected(productRelation.product_relation.related_product)"/>
                    </td>
                    <td>
                        {{productRelation.product_relation.related_product.name}}
                    </td>
                    <td class="text-center">
                        <fa-icon *ngIf="productRelation.product_relation.is_standard" [icon]="faCheck"
                                 [ngbTooltip]="'Standard'"></fa-icon>
                    </td>
                    <td class="text-right">
                        {{productRelation.product_relation.related_product.price|currency:'DKK'}}
                    </td>
                </tr>
                </tbody>
            </app-table>
        </ng-container>
    </ng-template>
    <p *ngIf="offerService.readyToolExists">Ombestemt dig? Vælg istedet for en <a href
                                                                                  (click)="switchMode(); $event.preventDefault()">allerede
        leverings klar maskine</a></p>
</ng-container>
