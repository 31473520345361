import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {ProductionLine} from './production-line';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../services/api.service';
import {ProductionLineSlot} from './production-line-slot';

@Injectable()
export class ProductionLinesService {

    protected url = environment.apiBase + 'production-lines';

    constructor(public apiService: ApiService) {
    }

    getProductionLine(id: string): Observable<{ data: ProductionLine, count: number, perPage: number }> {
        return this.apiService.get<ProductionLine>(this.url + '/' + id, true, ProductionLine);
    }

    addProductionLine(productionLine: ProductionLine): Observable<{data: ProductionLine, count: number, perPage: number}> {
        return this.apiService.post<ProductionLine>(this.url, productionLine, true, ProductionLine);
    }

    updateProductionLine(productionLine: ProductionLine): Observable<{data: ProductionLine, count: number, perPage: number}> {
        return this.apiService.patch<ProductionLine>(this.url + '/' + productionLine.id, productionLine, true, ProductionLine);
    }

    deleteProductionLine(productionLine: ProductionLine): Observable<{data: ProductionLine, count: number, perPage: number}> {
        return this.apiService.delete<ProductionLine>(this.url + '/' + productionLine.id, true, ProductionLine);
    }

    shiftProductionLine(id: string, data: any): Observable<{ data: ProductionLine, count: number, perPage: number }> {
        return this.apiService.post<ProductionLine>(this.url + '/' + id + '/delay', data, true, ProductionLine);
    }

    swapProductionLine(id, data: any): Observable<{ data: ProductionLine, count: number, perPage: number }> {
        return this.apiService.post<ProductionLine>(this.url + '/' + id + '/production-line-slots/swap', data, true, ProductionLine);
    }

    getProductionLines(): Observable<{ data: ProductionLine[], count: number, perPage: number }> {
        const url = this.url;
        const params: HttpParams = new HttpParams();

        // if (query && query.noCache) {
        //     params = params.set('nocache', '1');
        // }

        // return this.httpClient.get<any>(apiUrl, {
        //     params: params,
        //     headers: this.httpHeaders
        // }).pipe(map((response) => {
        //     return Category.fromJSON(response.data)
        // }))

        return this.apiService.get<ProductionLine[]>(url, true, ProductionLine);
    }

    getProductionLineSlots(productionLineId: string): Observable<{ data: ProductionLineSlot[], count: number, perPage: number }> {
        const url = this.url + '/' + productionLineId + '/production-line-slots?all=1';
        return this.apiService.get<ProductionLineSlot[]>(url, true, ProductionLineSlot);
    }

    emulateProductionLineSlot(productId: string): Observable<{ data: ProductionLineSlot, count: number, perPage: number }> {
        const url = this.url + '/emulate';
        return this.apiService.post<any>(url, {product_id: productId}, true, ProductionLineSlot);
    }
}
