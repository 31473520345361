import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Debtor} from '../services/debtor';
import {AuthService} from "../services/auth.service";
import {DebtorsService} from "../services/debtors.service";
import {PaymentAgreementsService} from "../services/payment-agreements.service";
import {PaymentAgreement} from "../services/payment-agreement";
import {ContactFieldPreset} from "../services/contact-field-preset";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";
import {Creditor} from "../services/creditor";
import {Reference} from "../services/reference";
import {ReferencesService} from "../services/references.service";
import {BranchesService} from "../services/branches.service";
import {Branch} from "../services/branch";
import {ProductCategoriesService} from "../services/product-categories.service";
import {ProductCategory} from "../services/product-category";
import {LanguagesService} from "../services/languages.service";
import {Language} from "../services/language";
import {ProductCategorySelectModalComponent} from "../product-category-select-modal/product-category-select-modal.component";
import {AttachmentUploadModalComponent} from "../attachment-upload-modal/attachment-upload-modal.component";
import {Attachment} from "../services/attachment";

@Component({
    selector: 'app-debtor-edit-modal',
    templateUrl: './debtor-edit-modal.component.html',
    styleUrls: ['./debtor-edit-modal.component.scss'],
    providers: [DebtorsService, PaymentAgreementsService, ContactFieldPresetsService, ReferencesService, BranchesService, ProductCategoriesService, LanguagesService]
})
export class DebtorEditModalComponent implements OnInit {

    public debtor: Debtor;
    public _debtor: Debtor;

    public paymentAgreements: PaymentAgreement[];
    public contactFieldPresets: ContactFieldPreset[];
    public branches: Branch[];
    public productCategories: ProductCategory[];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public debtorsService: DebtorsService,
                public paymentAgreementsService: PaymentAgreementsService,
                public contactFieldPresetsService: ContactFieldPresetsService,
                public authService: AuthService,
                public referencesService: ReferencesService,
                public branchesService: BranchesService,
                public productCategoriesService: ProductCategoriesService,
                public languagesService: LanguagesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit() {
        this.referencesService.getReferences().subscribe(response => {
            this._debtor = this.debtor ? Debtor.fromJSON(this.debtor) : Debtor.fromJSON({});

            for (let reference of response.data) {
                if (reference.is_debtor_reference && !this._debtor.hasReference(reference.id)) {
                    this._debtor.references.push(Reference.fromJSON({
                        id: reference.id,
                        name: reference.name,
                        _joinData: {
                            value: ''
                        }
                    }));
                }
            }

            this.paymentAgreementsService.getPaymentAgreements().subscribe(response => {
                this.paymentAgreements = response.data;
                if (!this.debtor) {
                    for (let paymentAgreement of this.paymentAgreements) {
                        if (paymentAgreement.is_default) {
                            this._debtor.payment_agreement_id = paymentAgreement.id;

                            break;
                        }
                    }
                }
            });
        });

        this.contactFieldPresetsService.getContactFieldPresets(true).subscribe(response => {
            this.contactFieldPresets = response.data;
        });

        this.branchesService.getBranches().subscribe((response) => {
            this.branches = response.data;
        });

        this.languagesService.getLanguages().subscribe((response) => {
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;
        if (this.debtor) {
            this.debtorsService.updateDebtor(this._debtor).subscribe(debtor => {
                this.saved();

                this.saving = false;

                this.activeModal.close(debtor);
            });
        } else {
            this.debtorsService.addDebtor(this._debtor).subscribe(debtor => {
                this.saved();

                this.saving = false;

                this.activeModal.close(debtor);
            });
        }
    }

    removeLine(index): void {
        this._debtor.product_categories.splice(index, 1);
    }

    selectCategory(): void {
        const modalRef = this.ngbModal.open(ProductCategorySelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.debtor = this._debtor;
        modalRef.componentInstance.categorizable = this._debtor;
        modalRef.componentInstance.selected = (categorizable) => {
          //  console.log(this._debtor);
          //  console.log(categorizable);
        };
    }

    public addAttachment(): void {
        const modalRef = this.ngbModal.open(AttachmentUploadModalComponent, {size: 'lg'});
        modalRef.componentInstance.productId = this._debtor.id;
        modalRef.componentInstance.model = 'ERP.Debtors';

        modalRef.result.then((attachment: Attachment) => {
            this._debtor.attachments.push(attachment);
        });
    }
}
