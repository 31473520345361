<div class="container">
    <h1 class="heading">
        <ng-container i18n>Currencies</ng-container>
        <small *ngIf="loading && currencies" class="text-muted" i18n>Loading...</small>
    </h1>

    <p *ngIf="!currencies" class="text-muted" i18n>Loading...</p>

    <p *ngIf="currencies">
        <a href="javascript:" (click)="add()" i18n>Add unit</a>
    </p>

    <table *ngIf="currencies" class="table  table-sm  table-striped  table-hover">
        <thead>
        <tr>
            <th i18n>Name</th>
            <th i18n>Symbol</th>
            <th i18n>Price</th>
            <th style="width: 60px;"></th>
            <th style="width: 60px;"></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-currency [ngForOf]="currencies">
            <tr>
                <td>
                    {{ currency.name }}
                </td>
                <td>
                    {{ currency.symbol }}
                </td>
                <td>
                    {{ currency.price }}
                </td>
                <td class="text-right">
                    <a href="javascript:" (click)="edit(currency)" class="mr-3" i18n>Edit</a>
                </td>
                <td class="text-right">
                    <a href="javascript:" (click)="delete(currency)" class="text-danger" i18n>Delete</a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </table>
</div>
