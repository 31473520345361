<div class="debtors">
    <h1 class="heading">
        <ng-container *ngIf="!_debtor" i18n>Debtor</ng-container>
        <ng-container *ngIf="_debtor">{{ _debtor.name }}</ng-container>
        <button *ngIf="_debtor && authService.hasPermission('admin')" [disabled]="loading" (click)="edit()" class="btn  btn-sm  btn-primary" i18n>Edit debtor</button>
    </h1>

    <app-loader *ngIf="!_debtor" i18n>Loading...</app-loader>

    <ng-container *ngIf="_debtor">
        <p *ngIf="authService.hasPermission('admin')">
            <span i18n>Payment agreement</span>:
            {{ _debtor?.payment_agreement?.name }}
        </p>

        <ng-template ngFor let-reference [ngForOf]="_debtor.references">
            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  mr-2">
                {{ reference.name }}: {{ reference._joinData.value }}
            </span>
        </ng-template>
        <div>
            <p><span i18n>Product categories</span>:</p>
            <ng-template ngFor let-category [ngForOf]="_debtor.product_categories">
                <span class="badge  badge-secondary  mr-2">
                    {{ category.name }}
                </span>
            </ng-template>
        </div>

        <hr>

        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
            <li ngbNavItem [ngbNavItem]="1">
                <a ngbNavLink i18n>Users ({{_debtor.users.length}})</a>
                <ng-template ngbNavContent>
                    <div class="mt-3">
                        <app-loader *ngIf="loading" i18n>Loading...</app-loader>
                        <app-table *ngIf="!loading">
                            <thead>
                                <tr>
                                    <th i18n>User</th>
                                    <th i18n>Supervisor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template ngFor let-user [ngForOf]="_debtor.users">
                                    <tr>
                                        <td>
                                            {{ user.name }}
                                            <span class="mr-2">&lt;{{ user.email }}&gt;</span>
                                            <span *ngIf="!user.active" class="badge  badge-danger  mr-2" i18n>Inactive</span>
                                        </td>
                                        <td>
                                            <span *ngIf="user['_joinData'].is_supervisor" class="badge  badge-primary">Supervisor</span>
                                            <span *ngIf="!user['_joinData'].is_supervisor" class="badge  badge-secondary">No</span>
                                        </td>
                                        <td>
                                            <button (click)="deleteUser(user)" class="btn  btn-sm  btn-danger  float-right  ml-2" i18n>Delete</button>
                                            <button (click)="editUser(user)" class="btn  btn-sm  btn-secondary  float-right" i18n>Edit</button>
                                            <button (click)="impersonateUser(user)" class="btn  btn-sm  btn-success  float-right  ml-2" i18n>Impersonate</button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-container *ngIf="_debtor.users.length === 0">
                                    <tr>
                                        <td colspan="3">
                                            <span class="text-muted" i18n>No users</span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </app-table>
                    </div>
                    <button [disabled]="loading" (click)="addUser()" class="btn  btn-sm  btn-primary  mb-3" i18n>Add new user</button>
                    <button [disabled]="loading" (click)="addExistingUser()" class="btn  btn-sm  btn-primary  ml-2  mb-3" i18n>Add existing user</button>
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="2" *ngIf="contactFieldPresets && contactFieldPresets.length > 0">
                <a ngbNavLink i18n>Contacts ({{_debtor.contacts.length}})</a>
                <ng-template ngbNavContent>
                    <div class="mt-3">
                        <app-loader *ngIf="loading || !contactFieldPresets" i18n>Loading...</app-loader>
                        <app-table *ngIf="!loading && contactFieldPresets && contactFieldPresets.length > 0" [autoSizeLastColumn]="true">
                            <thead>
                                <tr>
                                    <th i18n>Name</th>
                                    <th i18n>Type</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-template ngFor let-contact [ngForOf]="_debtor.contacts">
                                    <tr>
                                        <td>
                                            {{ contact.name }}
                                        </td>
                                        <td>
                                            <span *ngIf="contact.contact_type">{{ contact.contact_type.name }}</span>
                                            <span *ngIf="!contact.contact_type" class="text-muted">-</span>
                                        </td>
                                        <td>
                                            <button class="button  btn  btn-xs  btn-primary m-0 mr-2" (click)="editContact(contact)"><fa-icon [icon]="editIcon"></fa-icon>&nbsp;<ng-container i18n>Edit contact</ng-container></button>
                                            <button class="button  btn  btn-xs  btn-danger  m-0" (click)="deleteContact(contact)"><fa-icon [icon]="deleteIcon"></fa-icon>&nbsp;<ng-container i18n>Delete contact</ng-container></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </app-table>
                    </div>

                    <button (click)="addContact()"  class="button  btn  btn-sm   btn-primary" i18n>Add contact</button>

                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </ng-container>
</div>
