import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export class ProductionLineWeek {

    id: string;
    production_line_id: string;
    slots_week: number;
    date: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: ProductionLineWeekInterface | string): ProductionLineWeek {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductionLineWeek.reviver);
        } else {
            let model = new ProductionLineWeek();

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            };

            return Object.assign(model, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductionLineWeek.fromJSON(value) : value;
    }

    public getWeek(): number {
        return dayjs(this.date).isoWeek();
    }

    public getYear(): number {
        return dayjs(this.date).year();
    }
}

export interface ProductionLineWeekInterface extends ProductionLineWeek {
}
