<ul ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-template ngFor let-language [ngForOf]="languagesService.languages" let-i="index">
        <li [ngbNavItem]="language.locale">
            <a class="nav-link" (click)="changeLanguage(language)" [ngClass]="{'active': languagesService.selectedLanguage.locale === language.locale}">
                {{language.name}}
                <ng-container *ngIf="missingLocalizations">
                    <fa-icon i18n [ngbTooltip]="'Localization missing'" *ngIf="!missingLocalizations.languages[i]" [icon]="faExclamation"></fa-icon>
                </ng-container>
            </a>
        </li>
    </ng-template>
</ul>
