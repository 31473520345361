import { Injectable } from '@angular/core';
import {Order} from '../../order';
import {NavigationStart, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {OrdersService} from '../../orders.service';
import {ApiService} from '../../api.service';
import {Debtor} from '../../debtor';
import {Creditor} from '../../creditor';
import {Contact} from '../../contact';

@Injectable({
  providedIn: 'root'
})
export class TenFourOrderService extends OrdersService {

    public currentStepIndex = 0;
    public orderSteps: any;
    public client: Debtor | Creditor;
    public dummySteps = [];
    public isCreditor: boolean;

    // Current order Observables
    private orderId;
    private orderIdSubject = new BehaviorSubject<string | undefined>(undefined);
    private orderSubject = new BehaviorSubject<Order | undefined>(undefined);

    currentOrderId = this.orderIdSubject.asObservable();
    currentOrder = this.orderSubject.asObservable();

    constructor(public apiService: ApiService, private router: Router) {

        super(apiService);

        // This helps us detect we are on the correct step still after using the back and forward arrows in the browser
        router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                if (event.navigationTrigger === 'popstate') {

                    const newIndex = this.dummySteps.findIndex((step) => this.router.createUrlTree(step.url).toString() === event.url);

                    if ((newIndex === this.currentStepIndex) || (newIndex === -1)) {
                        // Do nothing
                    }
                    else if (newIndex > this.currentStepIndex){
                        this.currentStepIndex = this.currentStepIndex + 1;
                    }
                    else {
                        this.currentStepIndex = this.currentStepIndex - 1;
                    }
                }
            }
        });
    }

    public changeRoute(newRoute: []): void{
        this.router.navigate(newRoute);
    }

    public nextStep(): void{
        this.currentStepIndex = this.currentStepIndex + 1;
        this.router.navigate(this.orderSteps[this.currentStepIndex].url);
    }

    public setOrderId(id: string | null): void {
        this.orderId = id ? id : 'add';
        this.dummySteps = [
            {
                title: 'Ordre Information',
                url: ['/', 'orders', this.orderId, {outlets: {order_outlet: ['order-details']}}]
            },
            {
                title: 'Ordre Liste',
                url: ['/', 'orders', this.orderId, {outlets: {order_outlet: ['order-overview']}}]
            },
        ];
        // Push the new orderid out to subscribers
        this.orderIdSubject.next(this.orderId);

    }

    public refreshOrder(): void {
        if (this.orderId) {
            this.getOrder(this.orderId, true, true, true, true, true, true, true, true).subscribe(response => {
                this.setOrder(response.data);
            });
        }
    }

    public setOrder(order: Order | null): void {
        if (order){
            if (order.isCreditor()){
                this.client = order.creditor;
            } else {
                this.client = order.debtor;
            }
        }
        this.orderSubject.next(order);
    }

    public resetOrderAndSteps(): void {
        this.setOrder(null);
        this.currentStepIndex = 0;
    }

    public setNewContact(order: Order | null, newContact: Contact, name: 'Contact' | 'Delivery'): Order {
        let index = order.contacts.findIndex((contact) => {
            return contact.name === name;
        })

        if (index >= 0) {
            // Update the existing contact
            let oldId = order.contacts[index].id;

            order.contacts[index] = newContact;
            order.contacts[index].id = oldId;
        } else {
            // Set a new contact and adjust the index to the new contact
            order.contacts.push(newContact);

            index = order.contacts.length - 1;

            order.contacts[index].id = null;
        }

        order.contacts[index].name = name;

        if (order.contacts[index] && order.contacts[index]._joinData) {
            delete order.contacts[index]._joinData.contact_id;
            delete order.contacts[index]._joinData.id;
            delete order.contacts[index]._joinData.order_id;
            delete order.contacts[index]._joinData.debtor_id;
            delete order.contacts[index]._joinData.creditor_id;
        }

        return order;
    }
}
