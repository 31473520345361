<div [className]="'app' + (viewPrint ? '  app--print-view' : '')" [ngStyle]="{'margin-bottom':authService.isImpersonating ?'5rem':'0'}">
    <app-header></app-header>
    <div class="app__body">
        <router-outlet></router-outlet>
    </div>
    <div *ngIf="authService.isImpersonating" class="fixed-bottom p-2 d-flex align-items-center justify-content-around d-print-none"
         style="background: #ffffff">
        <span class="small">
            <span i18n>Impersonating</span>&nbsp;<strong>{{authService.impersonation_name}}</strong>&nbsp;
            <button (click)="restoreUser()" class="btn btn-sm btn-secondary" i18n>Restore user</button>
        </span>
    </div>
</div>
