import * as dayjs from 'dayjs';

export class VatType {

    id: string;
    name: string;
    rate: number;
    is_default: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: VatTypeJSON | string): VatType {
        if (typeof json === 'string') {
            return JSON.parse(json, VatType.reviver);
        } else {
            let vatType = Object.create(VatType.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(vatType, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? VatType.fromJSON(value) : value;
    }
}

// A representation of VatType data that can be converted to
// and from JSON without being altered.
interface VatTypeJSON {
    id?: string;
    name?: string;
    rate?: number;
    is_default?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
