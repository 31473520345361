import {Component, OnInit} from '@angular/core';
import {ProductEditComponent} from '../../../product-edit/product-edit.component';
import {ProductsService} from '../../../services/products.service';
import {VatTypesService} from '../../../services/vat-types.service';
import {CreditorsService} from '../../../services/creditors.service';
import {PriceGroupsService} from '../../../services/price-groups.service';
import {ProductFieldsService} from '../../../services/product-fields.service';
import {ReferencesService} from '../../../services/references.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderLineFieldPreset} from '../../../services/order-line-field-preset';
import {ThorsensDeliveryDetailsComponent} from '../thorsens-delivery-details/thorsens-delivery-details.component';
import {ProductFieldPresetsService} from '../../../services/product-field-presets.service';

@Component({
    selector: 'app-analysis-detail',
    templateUrl: './analysis-detail.component.html',
    styleUrls: ['./analysis-detail.component.scss'],
    providers: [ProductsService, ProductFieldPresetsService]
})
export class AnalysisDetailComponent extends ThorsensDeliveryDetailsComponent implements OnInit {

    constructor(
        public router: Router, private route: ActivatedRoute,
        public productsService: ProductsService, public productFieldPresetsService: ProductFieldPresetsService) {
        super(productsService, productFieldPresetsService);

        this.route.paramMap.subscribe(params => {
            this.productId = params.get('productId');
        });
    }
}
