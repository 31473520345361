<p class="h3" i18n>Relations</p>
<button (click)="addProductRelation(false)" class="btn  btn-sm  btn-primary" i18n>Add</button>

<hr>

<app-table *ngIf="_product.product_relation_parents" [autoSizeLastColumn]="true">
    <thead>
    <tr>
        <th i18n>Product name</th>
        <th i18n>Note</th>
        <th i18n>Standard</th>
        <th i18n>Extra</th>
        <th style="width: 60px;"></th>
    </tr>
    </thead>

    <tbody>
    <ng-template ngFor let-productRelation [ngForOf]="_product.product_relation_parents" let-i="index">
        <tr>
            <td><a routerLink="/products/{{ productRelation.product.id }}">{{ productRelation.product.name }}</a></td>
            <td>{{productRelation.note}}</td>
            <td>
                <fa-icon class="ml-1" *ngIf="productRelation.is_standard" [icon]="faGears"
                         [ngbTooltip]="'Standard'"></fa-icon>
            </td>
            <td>
                <div *ngIf="productRelation.isFollowProduct()" class="mt-2">
                    <span class="text-muted  mr-1" i18n>Minimum</span>
                    <input type="text" class="form-control  form-control-sm"
                           style="display: inline; width: 50px;"
                           name="product-product-relation-child-{{ i }}-follow-requirement-minimum"
                           [(ngModel)]="productRelation.follow_requirement_minimum">

                    <span class="text-muted  ml-2  mr-1" i18n>Repeat</span>
                    <input type="text" class="form-control  form-control-sm"
                           style="display: inline; width: 50px;"
                           name="product-product-relation-child-{{ i }}-follow-requirement-repeat"
                           [(ngModel)]="productRelation.follow_requirement_repeat">

                    <span class="text-muted  ml-2  mr-1" i18n>Amount</span>
                    <input type="text" class="form-control  form-control-sm"
                           style="display: inline; width: 50px;"
                           name="product-product-relation-child-{{ i }}-follow-amount"
                           [(ngModel)]="productRelation.follow_amount">

                    <button (click)="productRelation.setNotFollowProduct()"
                            class="btn  btn-sm  btn-danger  float-right"
                            i18n>Not follow product
                    </button>
                </div>

            </td>
            <td><a (click)="_product.product_relation_parents.splice(i, 1)" href="javascript:"
                   class="text-danger ml-3" i18n>Remove
            </a></td>
        </tr>
    </ng-template>
</app-table>

<div *ngIf="_product.product_relation_parents.length === 0">
    <span class="text-muted" i18n>No parent relations</span>

    <hr>
</div>
