import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ProductsService} from "../../../services/products.service";
import {Product} from "../../../services/product";
import {ProductRelation} from "../../../services/product-relation";
import {ProductFieldPresetsService} from "../../../services/product-field-presets.service";
import {ProductFieldPreset} from "../../../services/product-field-preset";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-order-deliveries-modal',
    templateUrl: './order-deliveries-modal.component.html',
    styleUrls: ['./order-deliveries-modal.component.scss'],
    providers: [ProductsService, ProductFieldPresetsService]
})
export class OrderDeliveriesModalComponent implements OnInit {

    page = 1;
    limit = 25;
    count;
    perPage;

    loading: boolean;
    referenceValue: string;
    products: Product[];
    relatedProducts: ProductRelation[];
    productFieldPresets: ProductFieldPreset;
    analysisFieldPresets: ProductFieldPreset;

    constructor(public router: Router, private route: ActivatedRoute, public productsService: ProductsService, public productFieldPresetsService: ProductFieldPresetsService) {
        this.route.paramMap.subscribe(params => {
            this.referenceValue = params.get('referenceValue');
        });

    }

    ngOnInit(): void {
        this.load(1);
        this.loadPresets();
    }

    loadPresets() {
        this.productFieldPresetsService.getProductFieldPreset('110db364-36dd-4929-a75f-e3433a20575e').subscribe((result) => {
            this.productFieldPresets = result.data;
        });
    }

    load(page: number): void {
        this.loading = true;
        // @todo make byReferenceId dynamic
        this.productsService.getProducts(page, this.limit, true, false, false, true, true, false,
            {
                withProductRelations: true,
                withProductFields: true,
                byReferenceId: '6cb6ee10-8165-4459-a274-474768aaf895', // orderline-order reference
                byRefrenceValue: this.referenceValue
            }).subscribe(response => {
            this.products = response.data;
            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    pageChange(page: number) {
        this.load(page);
    }
}
