import {Component, OnInit} from '@angular/core';
import {ProductCategory} from '../services/product-category';
import {ProductCategoriesService} from '../services/product-categories.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductCategoryEditModalComponent} from '../product-category-edit-modal/product-category-edit-modal.component';
import {LanguagesService} from '../services/languages.service';

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.sass'],
    providers: [ProductCategoriesService, LanguagesService]
})
export class ProductCategoriesComponent implements OnInit {

    productCategories: ProductCategory[];

    loading = false;
    missingLocalizations = [];


    constructor(public productCategoriesService: ProductCategoriesService,
                public ngbModal: NgbModal,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.languagesService.getLanguages().subscribe(() => {
            this.load();
        });
    }

    load(): void {
        this.loading = true;

        this.productCategoriesService.getProductCategories(true, true).subscribe(response => {
            this.productCategories = response.data;

            this.productCategories.forEach((category) => {
                this.missingLocalizations.push(category._translations.checkMissingLocalizations(this.languagesService.languages));
            });

            this.loading = false;
        });
    }

    add(): void {
        const self = this;

        const modalRef = this.ngbModal.open(ProductCategoryEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = () => {
            self.load();
        };
    }
}
