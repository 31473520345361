import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-checkbox-confirm-modal',
    templateUrl: './checkbox-confirm-modal.component.html',
    styleUrls: ['./checkbox-confirm-modal.component.scss'],
    providers: []
})
export class CheckboxConfirmModalComponent implements OnInit {

    public title = '';
    public message = '';
    public label = '';

    public checkbox = false;

    public saved;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saved(this.checkbox);


        this.activeModal.close();
    }
}
