<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Select product category</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!productCategories" class="text-muted" i18n>Loading...</p>

            <div *ngIf="productCategories">
                <app-product-categories-tree [missingLocalizations]="[]" [productCategories]="productCategories" [selectingForCategorizable]="categorizable" (didSelect)="select($event)"></app-product-categories-tree>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" i18n>Close</button>
    </div>
</div>
