import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../services/auth.service';
import {ProductCategory} from '../services/product-category';
import {ProductCategoriesService} from '../services/product-categories.service';
import {Reference} from '../services/reference';
import {ReferencesService} from '../services/references.service';
import {forkJoin} from 'rxjs';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from '../services/locale-translation';

@Component({
    selector: 'app-product-category-edit-modal',
    templateUrl: './product-category-edit-modal.component.html',
    styleUrls: ['./product-category-edit-modal.component.scss'],
    providers: [ProductCategoriesService, ReferencesService, LanguagesService]
})
export class ProductCategoryEditModalComponent implements OnInit {

    public productCategory: ProductCategory;
    // tslint:disable-next-line:variable-name
    public _productCategory: ProductCategory;

    public productCategoriesList;

    public saving = false;
    public saved;
    name: string[] = [];
    loading = false;
    public missingLocalizations;


    constructor(public activeModal: NgbActiveModal,
                public productCategoriesService: ProductCategoriesService,
                public referencesService: ReferencesService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.languagesService.getLanguages().subscribe(() => {
        });
        forkJoin([
            this.referencesService.getReferences(),
            this.productCategoriesService.getProductCategoriesAsTreeList(),
            // @todo Den hænger for evigt hvis jeg kalder den med
            // this.languagesService.getLanguages(),
        ]).subscribe(joinData => {
            // tslint:disable-next-line:max-line-length
            this._productCategory = this.productCategory ? ProductCategory.fromJSON(this.productCategory) : ProductCategory.fromJSON({parent_id: null});

            // References
            for (const reference of joinData[0].data) {
                if (reference.is_product_category_reference && !this._productCategory.hasReference(reference.id)) {
                    this._productCategory.references.push(Reference.fromJSON({
                        id: reference.id,
                        name: reference.name,
                        _joinData: {
                            value: ''
                        }
                    }));
                }
            }

            // Product categories list
            this.productCategoriesList = joinData[1].data;
            this.loadLanguages();
            this.loading = false;
        });
    }

    loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {
            if (language.locale === this.languagesService.primaryLanguage.locale) {
                if (this._productCategory.name) {
                    this.name[i] = this._productCategory.name;
                } else {
                    this.name[i] = '';
                }
            } else {
                if (this._productCategory._translations) {
                    const translation = this._productCategory._translations[language.locale];
                    if (translation) {
                        if (translation.name) {
                            this.name[i] = translation.name;
                        } else {
                            this.name[i] = '';
                        }
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }
            }
            i++;
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        this.saveLanguages();

        if (this.productCategory) {
            this.productCategoriesService.updateProductCategory(this._productCategory).subscribe(productCategory => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productCategory);
            });
        } else {
            this.productCategoriesService.addProductCategory(this._productCategory).subscribe(productCategory => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productCategory);
            });
        }
    }

    public keepOriginalOrder = (a, b) => a.key;

    saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {
            if (this.languagesService.isSame(language, this.languagesService.primaryLanguage)) {
                this._productCategory.name = this.name[i];
            } else {
                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;
            }
            this._productCategory._translations = translations;
            i++;
        });
    }
}
