import * as dayjs from 'dayjs';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {AuditLog} from "./audit-log";

@Injectable()
export class AuditLogsService {

    protected url = environment.apiBase + 'audit-logs';

    constructor(public apiService: ApiService) {
    }

    getAuditLog(id: string): Observable<{data: AuditLog, count: number, perPage: number}> {
        return this.apiService.get<AuditLog>(this.url + '/' + id, true, AuditLog);
    }

    getAuditLogs(page: number, limit: number, start: dayjs.Dayjs, end: dayjs.Dayjs, withUser: boolean): Observable<{data: AuditLog[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            parameters.push('limit=' + limit);
        }

        if (start) {
            parameters.push('start=' + start.format('YYYY-MM-DD'));
        }

        if (end) {
            parameters.push('end=' + end.format('YYYY-MM-DD'));
        }

        if (withUser) {
            parameters.push('with-user=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<AuditLog[]>(url, true, AuditLog);
    }
}
