<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container i18n>Edit product relation</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p class="h3 mb-3">
            {{productRelation.related_product.name}}
        </p>

        <form>
            <div class="form-group">
                <label for="product-relation-standard" i18n>Standard</label>
                <input type="checkbox" id="product-relation-standard"
                       [(ngModel)]="productRelation.is_standard" name="standard">
            </div>

            <div class="form-group">
                <label for="product-relation-note" i18n>Note</label>
                <input type="text" id="product-relation-note" class="form-control"
                       [(ngModel)]="productRelation.note" name="note">
            </div>

            <a *ngIf="!productRelation.isFollowProduct()" href="javascript:"
               (click)="addAsFollowProduct()" class="text-primary" i18n>Add as follow
                product</a>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deleteRelation()" [disabled]="saving" i18n>Delete</button>
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
    </div>
</div>
