import {Component, OnInit} from '@angular/core';
import {User} from "../services/user";
import {UsersService} from "../services/users.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserEditModalComponent} from "../user-edit-modal/user-edit-modal.component";
import {ActivatedRoute, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.sass'],
    providers: [UsersService]
})
export class UsersComponent implements OnInit {

    users: User[];

    loading = false;
    page = 1;
    limit = 25;
    count;
    perPage;
    query: any = {search: null};

    constructor(public usersService: UsersService,
                public ngbModal: NgbModal,
                public activatedRoute: ActivatedRoute,
                public router: Router) {


        this.activatedRoute.queryParams.subscribe(params => {
            if (!isNaN(params.page)) {
                this.page = +params.page;
                if (params.query) {
                    this.query = JSON.parse(params.query);
                }
                this.load();
            }
        });
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.usersService.getUsers(true, this.page, 25, this.query).subscribe(response => {
            this.users = response.data;
            this.count = response.count;
            this.perPage = response.perPage;
            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(UserEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(user: User) {
        let self = this;

        const modalRef = this.ngbModal.open(UserEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.user = user;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    disable2FA(user: User) {
        if (confirm($localize`Are you sure?\n\nThis will disable 2FA for ` + user.name)) {
            /**
             * Disable 2FA for user
             */
            this.loading = true;

            user.two_factor_authentication_secret = null;

            this.usersService.updateUser(user).subscribe(_ => {
                this.load();
            }, error => {
                this.loading = false;
                console.error(error); // potential for setting error indicators in the UI
            });
        }
    }

    delete(user: User): void {
        if (confirm($localize`Are you sure?\n\nThe user will be deleted`)) {
            this.loading = true;

            this.usersService.deleteUser(user).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }

    pageChange($event: number) {
        this.router.navigate(
            [],
            {
                queryParams: {page: $event, query: JSON.stringify(this.query)},
            });
    }

    changeQuery() {
        this.router.navigate(
            [],
            {
                queryParams: {page: 1, query: JSON.stringify(this.query)},
            });
    }
}
