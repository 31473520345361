<ng-container *ngIf="order && !loading">
<!--    <a [routerLink]="'/custom/inventory'" class="btn btn-primary mb-2">Tilbage</a>-->
    <h1 class="heading mb-0 pb-0" *ngIf="!factoryView">{{product?.name}}</h1>

    <div class="row" *ngIf="!factoryView">
        <div class="col">
            <small class="font-weight-bold">{{order.order_state.name}} #:</small><br/>
            <span>{{ order.getIdentificationReference() }}</span>
        </div>

        <div class="col">
            <small class="font-weight-bold"><span i18n>TMT</span> #</small><br/>
            <span>{{ order.getReference(idValuesService.tmtNumberId) }}</span>
        </div>

        <div class="col">
            <small class="font-weight-bold"><span i18n>Status</span>:</small><br/>
            <span>{{order.order_state ? order.order_state.name : 'Ordre' }}</span>
        </div>

        <div class="col">
            <small class="font-weight-bold"><span i18n>Type</span>:</small><br/>
            <span>{{order.order_type ? order.order_type.name : 'Ordre' }}</span>
        </div>

        <ng-template ngFor let-date [ngForOf]="order.dates">
            <div class="col">
                <small class="font-weight-bold">{{ date.name }}:</small><br/>
                <span>
                    <ng-container *ngIf="date.is_production">
                        Uge {{ order.getDate('is_production')._joinData.time.isoWeek()|json }}
                        - {{ order.getDate('is_production')._joinData.time.isoWeekYear()|json }}
                    </ng-container>
                    <ng-container *ngIf="!date.is_production">
                        {{ date._joinData.time.format('YYYY-MM-DD HH:mm') }}
                    </ng-container>
                </span>
            </div>
        </ng-template>
    </div>

    <ng-container *ngIf="authService.hasPermission('admin') && !factoryView">
        <p class="mb-0"><span i18n>Set status</span>:</p>
        <ng-container *ngFor="let orderState of orderStates">
            <button
                [ngClass]="{
                'btn-outline-primary':order.order_state.sorting >= orderState.sorting,
                'btn-primary':order.order_state.sorting < orderState.sorting}"

                [disabled]="order.order_state.sorting === orderState.sorting"
                *ngIf="order.order_state" (click)="setState(orderState)" class="btn btn-sm  mt-3  mr-1 ">{{orderState.name}}
            </button>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!authService.hasPermission('admin') && !factoryView && order.order_state_id == 'b57c5930-7f13-451f-a842-3b7bf001d682'">
        <p class="mb-0"><span i18n>Set status</span>:</p>
        <ng-container *ngFor="let orderState of orderStates">
            <button
                [ngClass]="{
                'btn-outline-primary':order.order_state.sorting >= orderState.sorting,
                'btn-primary':order.order_state.sorting < orderState.sorting}"

                [disabled]="order.order_state.sorting === orderState.sorting"
                *ngIf="order.order_state && orderState.id == 'ce8e0faa-6070-44c6-9a00-4a9e0feea197'" (click)="setState(orderState)" class="btn btn-sm  mt-3  mr-1 ">{{orderState.name}}
            </button>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="order.order_state_id === '01190160-e140-4129-b711-13f0c5d7a3f6' && !factoryView" >
        <div class="row">
            <div class="col">
                <small class="font-weight-bold"><span i18n>TMT</span>:</small><br/>
                <span>{{ order.getReference(idValuesService.tmtNumberId) }}</span>
            </div>
            <div class="col">
                <small class="font-weight-bold"><span i18n>Stock ID</span>:</small><br/>
                <span>{{ order.getFieldValueForId(idValuesService.storageIdFieldValueId) }}</span>
            </div>
        </div>
    </ng-container>

    <button (click)="onEditToggle()" *ngIf="product && !order.isOrderLinesFrozen() && !factoryView" class="btn  btn-primary  btn-sm  float-right  mt-3 mb-2">
        <fa-icon [icon]="editing ? faCheck : faPen"></fa-icon>
        <span *ngIf="editing" i18n>End editing</span>
        <span *ngIf="!editing" i18n>Add/remove equipment</span>
    </button>

    <app-table additionalClasses="mt-4" [autoSizeFirstColumn]="true">
        <tbody>
        <tr>
            <th colspan="2"></th>
            <th class="text-center"><span i18n>Standard</span></th>
            <th class="text-right" *ngIf="!factoryView"><span i18n>Price</span></th>
        </tr>

        <tr *ngIf="product">
            <th colspan="4"><span i18n>Machine</span></th>
        </tr>
        <tr *ngIf="product">
            <td></td>
            <td>
                {{ product.name }}
            </td>
            <td></td>
            <td class="text-right" style="width: 100px;" *ngIf="!factoryView">{{ machinesService.getMachineOrderLine(order).getUnAdjustedTotal() | currency:'DKK' }}

<!--                <span  class="font-italic" *ngIf="showDetails"><br/><span [innerHTML]="machinesService.getMachineOrderLine(order).price_adjustment_name | safeHtml"></span>-->
<!--                     ({{machinesService.getMachineOrderLine(order).getAdjustedSavings() | currency:'DKK'}})</span>-->

            </td>
        </tr>
        <tr *ngIf="product && showDetails">
            <td></td>
            <td><span [innerHTML]="machinesService.getMachineOrderLine(order).price_adjustment_name | safeHtml"></span></td>
            <td></td>
            <td class="text-right" style="width: 100px;" *ngIf="!factoryView">
                <span  class="font-italic" *ngIf="showDetails">({{machinesService.getMachineOrderLine(order).getAdjustedSavings() | currency:'DKK'}})</span>
            </td>
        </tr>
        <tr *ngIf="product && showDetails && !factoryView" >
            <td></td>
            <td>Maskintotal</td>
            <td></td>
            <td class="text-right" style="width: 100px;">{{machinesService.getMachineOrderLine(order).getTotal() | currency:'DKK'}}</td>
        </tr>

        <ng-template ngFor let-groupData [ngForOf]="groupedData">

            <!-- @todo display orderline prices instead  see https://git.hassel-it.dk/10-4/winston/-/issues/245 -->
            <ng-container
                *ngIf="groupData.group === 'Redskaber' && showRedskabGroup || groupData.group ==='Hjul' && showWheelGroup || groupData.group ===  'Extraudstyr'">
                <tr>
                    <th colspan="2">
                        <span *ngIf="groupData.group === 'Redskaber'" i18n>Tools</span>
                        <span *ngIf="groupData.group === 'Hjul'" i18n>Wheel</span>
                        <span *ngIf="groupData.group === 'Extraudstyr'" i18n>Additional equpiment</span>
                    </th>
                    <th class="text-center"></th>
                    <th></th>
                </tr>

                <ng-template ngFor let-product [ngForOf]="groupData.products">
                    <tr *ngIf="editing || order.hasOrderLineWithProduct(product) !== null">
                        <td><input *ngIf="editing" (click)="onCheckboxChange(product)" type="checkbox"
                                   [checked]="order.hasOrderLineWithProduct(product) !== null"
                                   [disabled]="groupData.group ==='Extraudstyr' && order.order_type_id==='be969ca9-d5f0-4eec-bdc7-dab679ea7979' || order.isFrozen()"
                        ></td>
                        <td>
                            <ng-container *ngIf="factoryView">
                                {{product.getFieldValueForId('6e0ecafd-358c-4d14-8168-a1322cd041aa') ? product.getFieldValueForId('6e0ecafd-358c-4d14-8168-a1322cd041aa') : ('('+product.name+')') }}
                            </ng-container>
                            <ng-container *ngIf="!factoryView">
                            {{ product.name }}
                            </ng-container>
                        </td>
                        <td class="text-center " style="white-space: nowrap">
                            <fa-icon *ngIf="product.product_relation_parents[0].is_standard" [icon]="faCheck"
                                     [ngbTooltip]="'Standard'"></fa-icon>
                        </td>
                        <td class="text-right " style="white-space: nowrap" *ngIf="!factoryView">
                            <span
                                *ngIf="order.hasOrderLineWithProduct(product) === null">{{ product.price | currency:'DKK' }}</span>
                            <span *ngIf="order.hasOrderLineWithProduct(product) !== null">
                            {{ order.order_lines[order.hasOrderLineWithProduct(product)].price | currency:'DKK' }}
                                <span  class="font-italic" *ngIf="showDetails && showDebug"><br/>

                                   <span [innerHTML]="order.order_lines[order.hasOrderLineWithProduct(product)].price_adjustment_name | safeHtml"></span>
                                   ({{order.order_lines[order.hasOrderLineWithProduct(product)].getAdjustedSavings() | currency:'DKK'}})</span>

                        </span>
                        </td>
                    </tr>
                </ng-template>
                <tr class="border-top" *ngIf="showDetails">
                    <td></td>
                    <td>
                        {{groupData.group}} subtotal
                    </td>
                    <td></td>
                    <td class="text-right" style="vertical-align:bottom">{{groupData.unadjusted_total | currency:'DKK'}}</td>
                </tr>
                <tr class="border-top" *ngIf="showDetails && groupData.discount_amount">
                    <td></td>
                    <td>
                        <span
                            [innerHTML]="groupData.discounts_texts.join('<br>') | safeHtml"></span>
                    </td>
                    <td></td>
                    <td class="text-right font-italic" style="vertical-align:bottom">{{groupData.discount_amount | currency:'DKK'}}</td>
                </tr>
                <tr class="border-top" *ngIf="showDetails && !factoryView">
                    <td></td>
                    <td>
                        {{groupData.group}} total
                    </td>
                    <td></td>
                    <td class="text-right" style="vertical-align:bottom">{{groupData.total | currency:'DKK'}}</td>
                </tr>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="!product && groupedData.length === 0">
            <tr *ngFor="let orderLine of order.order_lines">
                <td></td>
                <td>
                    {{orderLine.product.name}}
                </td>
                <td class="text-right"></td>
                <td class="text-right" *ngIf="!factoryView">
                    {{orderLine.price | currency:'DKK' }}
                    <span  class="font-italic" *ngIf="showDetails"><br/>
                        <span [innerHTML]="orderLine.price_adjustment_name | safeHtml"></span>
                        ({{orderLine.getAdjustedSavings() | currency:'DKK'}})</span>
                </td>
            </tr>
        </ng-container>

        <tr class="font-weight-bold  bg-dark  text-white  text-uppercase" *ngIf="!factoryView">
            <td></td>
            <td><span i18n>Subtotal</span></td>
            <td></td>
            <td class="text-right">{{ order.getTotalUnadjusted() | currency:'DKK' }}</td>
        </tr>
        <tr *ngIf="showDetails && !factoryView">
            <td></td>
            <td><span i18n>Discount</span></td>
            <td></td>
            <td class="text-right">{{ (order.getTotalUnadjusted() - order.getTotal()) | currency:'DKK' }}</td>
        </tr> <tr *ngIf="showDetails && !factoryView">
            <td></td>
            <td><span i18n>Total</span></td>
            <td></td>
            <td class="text-right">{{ order.getTotal() | currency:'DKK' }}</td>
        </tr>
        </tbody>
    </app-table>

    <p *ngIf="order.comment && !factoryView">
        <strong>Kommentar:</strong><br>
    {{order.comment}}
    </p>

    <ng-container *ngIf="authService.hasPermission('admin') && !factoryView">
        <div class="row">
            <div class="col">
                <label for="condition" class="mb-0"><span i18n>Condition</span>:</label>
                <select class="form-control mb-4" id="condition" [(ngModel)]="condition" name="product_id"
                        (change)="save()">
                    <option [ngValue]="'ny'"><span i18n>New</span></option>
                    <option [ngValue]="'demo'"><span i18n>Demo</span></option>
                </select>
            </div>
            <div class="col">
                <label for="discount" class="mb-0"><span i18n>Discount</span>:</label>
                <input type="number" id="discount" class="form-control" [ngModel]='discountValue'
                       (ngModelChange)='onDiscountInputChange($event)'/>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col">
                <label for="location" class="mb-0"><span i18n>Location</span>:</label>
                <select class="form-control mb-4" [(ngModel)]="location" id="location" name="location" (ngModelChange)="save()">
                    <option [ngValue]="null" i18n>-- Select location --</option>
                    <option *ngFor="let debtor of debtors"
                            [ngValue]="debtor.id">{{ debtor.name }}</option>
                </select>

                <app-switch (click)="onDemoChange()" [smallSwitch]="true" [checked]="isDemo"><span i18n>Demo machine</span>
                </app-switch>
                <app-switch (click)="onHiddenChange()" [smallSwitch]="true" [checked]="isHidden"><span i18n>Hide for other dealers</span>
                </app-switch>
            </div>
            <div *ngIf="authService.hasPermission('admin')" class="col">
                <label for="memo" class="mb-0"><span i18n>Internal memo</span>:</label>
                <textarea id="memo" rows="4" class="form-control mb-4" [ngModel]='memoText'
                          (ngModelChange)='onMemoInputChange($event)'></textarea>
            </div>
            <div class="col">
                <label for="memo-external" class="mb-0"><span i18n>External memo</span>:</label>
                <textarea id="memo-external" rows="4" class="form-control mb-4" [ngModel]='memoTextExtern'
                          (ngModelChange)='onMemoExternInputChange($event)'></textarea>
            </div>
        </div>
    </ng-container>

    <div class="d-flex justify-content-between" *ngIf="!factoryView">
    <button [routerLink]="['/','custom','print-friendly-order', order.id]" class="btn  btn-sm  btn-primary" i18n>Print offer</button>
   <!-- <button (click)="printModal('offer')" class="btn  btn-sm  btn-primary  mr-2" i18n>Print offer</button> -->
    <button class="btn  btn-sm  btn-primary" (click)="showDetails = !showDetails">Vis detaljer</button>
    <button class="btn  btn-sm  btn-primary" (click)="showDebug = !showDebug" *ngIf="showDetails && authService.hasPermission('admin')">Vis debug</button>

    <ng-container *ngIf="authService.hasPermission('admin')">
        <button (click)="removeDebtor()" *ngIf="order.debtor" class="btn btn-link text-danger" i18n>Remove dealer</button>
        <button (click)="deleteOrder(order)" *ngIf="!order.isFrozen()" class="btn btn-link text-danger" i18n>Delete</button>
    </ng-container>
    </div>

</ng-container>
<app-loader *ngIf="!order || isSaving || loading"
            [useOverlayLoader]="isSaving || (product && order && loading)"></app-loader>
