<ng-container *ngIf="groupedData.length > 0">
    <a [routerLink]="'/production-lines'" class="btn  btn-primary  btn-sm"><fa-icon [icon]="faChevronLeft"></fa-icon> Tilbage</a>

    <div *ngIf="hasError" class="alert  alert-danger">
        {{errorMessage}}
    </div>

    <h1 class="heading" i18n>Production Line Slots for {{productionLine.name}}</h1>

    <div class="d-flex  justify-content-between">
        <button class="btn  btn-sm  btn-secondary  mb-0" (click)="acc.expandAll()"><fa-icon [icon]="faArrowUpDown"></fa-icon>&nbsp;<ng-container i18n>Expand all</ng-container></button>
        <button class="btn  btn-sm  btn-warning  mb-0" (click)="openModal(content)"><fa-icon [icon]="faClockBack"></fa-icon>&nbsp;<ng-container i18n>Delay production line</ng-container></button>
    </div>

    <hr/>

    <ng-container *ngIf="swapQueue.length === 2">
        <div class="alert  alert-info sticky-top">
            <div class="d-flex  justify-content-between  align-content-center">
                <fa-icon [icon]="faArrowUpDown" class="align-self-center  h1  mr-4"></fa-icon>
                <ul class="list-group  mr-4" style="width: 80%">
                    <li *ngFor="let swapSlot of swapQueue"
                        class="list-group-item">
                        <small>
                            <fa-icon [icon]="faCalendar"></fa-icon> Uge: {{swapSlot.date.format('W')}} - År: {{swapSlot.date.format('YYYY')}}<br />

                            <span *ngIf="swapSlot._warning" class="badge  badge-warning">
                                <fa-icon [icon]="faTriangleExclamation"></fa-icon>Du flytter til en uge uden et definereet antal
                            </span>

                            <ng-container *ngIf="swapSlot.hasOwnProperty('product')"><fa-icon [icon]="faCog"></fa-icon> Maskine: {{ swapSlot.product.name}}<br /></ng-container>
                            <ng-container *ngIf="swapSlot.hasOwnProperty('order')" ><fa-icon [icon]="faClipboardList"></fa-icon> TMT: {{swapSlot.order.getReference(idValuesService.tmtNumberId)}}</ng-container>
                        </small>
                    </li>
                </ul>
                <button class="btn btn-success align-self-center" (click)="performSwap()" [disabled]="processing" style="width: 20%">
                    <fa-icon [icon]="faArrowDownUpAcross"></fa-icon> Perform swaps
                </button>
            </div>
        </div>
    </ng-container>

    <ngb-accordion #acc>
        <ngb-panel *ngFor="let group of groupedData">
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between">
                    <div class="d-flex" style="color: #1c2128;">
                        <b>{{group.group}}</b>
                        &nbsp;
                        <span [ngClass]="{ 'text-muted': group.slots.length === 0 }">({{ group.slots.length }} / {{ getProductionSlots(group.group) }})</span>
                    </div>
                    <div class="d-flex">
                        <ng-container *ngIf="swapQueue.length === 1">
                            <button class="btn btn-success btn-sm  mr-3" style="padding: 3px 10px !important; font-size: 10px;" (click)="addToSwap(productionLineSlotFromGroup(group,
                            (group.slots.length >= getProductionSlots(group.group))))">
                                <fa-icon [icon]="faArrowDownUpAcross" class="mr-1"></fa-icon> Move
                            </button>
                        </ng-container>

                        <button class="btn btn-warning btn-sm  mr-3" style="padding: 3px 10px !important; font-size: 10px;"
                                (click)="removeGroupFromQueue(group)"
                                *ngIf="checkQueueForGroup(group)">
                            <fa-icon [icon]="faTimes" class="mr-1"></fa-icon> <span i18n>Remove from swap</span>
                        </button>

                        <fa-icon [icon]="faChevron"></fa-icon>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="tbl">
                    <table class="tbl_table  tbl__table--has-utilities  tbl--no-margin  table  table-sm  table-striped">
                        <thead>
                            <tr>
                                <th>Maskine</th>
                                <th>Ordre</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let productionLineSlot of group.slots">
                                <td>{{productionLineSlot.product.name}}</td>
                                <td>
                                    <a [routerLink]="['/','orders',productionLineSlot.order.id]">
                                    TMT Nr. {{ productionLineSlot.order.getReference(idValuesService.tmtNumberId) }}
                                    </a>
                                    <span class="badge  badge-secondary">{{productionLineSlot.order.order_state.name}}</span>
                                </td>
                                <td style="width: 1%; white-space: nowrap">
                                    <button class="btn btn-success btn-sm"
                                            (click)="addToSwap(productionLineSlot)"
                                            *ngIf="swapQueue.indexOf(productionLineSlot) === -1"
                                            style="font-size: 10px;">
                                        <fa-icon [icon]="faArrowDownUpAcross" class="mr-1"></fa-icon> <span i18n>Swap slot</span>
                                    </button>
                                    <button class="btn btn-warning btn-sm"
                                            (click)="swapQueue.splice(swapQueue.indexOf(productionLineSlot), 1)"
                                            *ngIf="swapQueue.indexOf(productionLineSlot) !== -1"
                                            style="font-size: 10px;">
                                        <fa-icon [icon]="faTimes" class="mr-1"></fa-icon> <span i18n>Remove from swap</span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</ng-container>


<ng-container *ngFor="let productionLineSlot of productionLineSlots">
</ng-container>


<ng-template #content let-modal>
    <form class="" (ngSubmit)="delayOrders()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Delay orders</h4>
            <button type="button" class="btn btn-close" aria-label="Close" (click)="modal.dismiss()">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </div>

        <div class="modal-body">
            <div *ngIf="hasModalError" class="alert  alert-danger">
                {{modalErrorMessage}}
            </div>

            <div class="form-group  mr-3  mb-2">
                <label>Delay from date:</label>
                <div>
                    <ngb-datepicker #dp name="date" [(ngModel)]="delayData.date" [markDisabled]="disableDays" outsideDays="hidden" [showWeekNumbers]="true" class="mb-3"></ngb-datepicker>
                </div>

                <!-- [dayTemplate]="t"-->
                <ng-template #t let-date let-focused="focused">
                    <span class="custom-day">
                        {{ date.day }}
                    </span>
                </ng-template>
            </div>

            <div class="form-group  mr-3  mb-2">
                <label>Delay weeks</label>
                <input type="number" min="1" class="form-control  form-control-sm" [(ngModel)]="delayData.weeks" name="weeks">
            </div>

            <div class="modal-footer">
                <button type="button" class="btn  btn-danger  float-left" (click)="modal.close()" [disabled]="processing">Fortryd</button>
                <button type="submit" class="btn  btn-success" [disabled]="processing">Delay</button>
            </div>
        </div>
    </form>
</ng-template>
