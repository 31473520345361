<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!branch" i18n>Add branch</ng-container>
            <ng-container *ngIf="branch" i18n>Edit branch</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label i18n>Name</label>
                <input type="text" class="form-control" [(ngModel)]="_branch.name" name="name">
            </div>

            <div class="form-group">
                <label i18n>Slug</label>
                <input type="text" class="form-control" [(ngModel)]="_branch.slug" name="slug">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
