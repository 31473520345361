import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {AttachmentsService} from "../services/attachments.service";
import {Attachment} from "../services/attachment";
import {ProductCategoriesService} from "../services/product-categories.service";
import {ProductsService} from "../services/products.service";

@Component({
    selector: 'app-attachment-upload-modal',
    templateUrl: './attachment-upload-modal.component.html',
    styleUrls: ['./attachment-upload-modal.component.scss'],
    providers: [AttachmentsService, ProductCategoriesService, ProductsService]
})
export class AttachmentUploadModalComponent implements OnInit {

    public attachment: Attachment;

    public productCategories;
    public products;

    public productCategoryId = null;
    public productId = null;
    public model = null;

    file: File = null;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public attachmentsService: AttachmentsService,
                public productCategoriesService: ProductCategoriesService,
                public productsService: ProductsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.attachment = Attachment.fromJSON({
            foreign_key: null,
            model: this.model ? this.model : null
        });

        this.productCategoriesService.getProductCategoriesAsTreeList().subscribe(response => {
            let productCategories = [];

            for (let productCategoryId of Object.keys(response.data)) {
                productCategories.push({
                    id: productCategoryId,
                    name: response.data[productCategoryId]
                });
            }

            this.productCategories = productCategories;
        });
    }

    productCategoryChanged() {
        this.products = null;

        this.productsService.getProducts(1, 100, false, false, false, false, false, false, {byCategories: this.productCategoryId}).subscribe(response => {
            this.products = response.data;
        });
    }

    fileChanged(event: Event) {
        this.file = event.target['files'].item(0);

        // @todo set file type

        this.attachment.type = 'FILE TYPE';
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.productId) {
        this.attachment.foreign_key = this.productId;
        } else {

        }

        // @todo better handling of generic types when submitting formdata ?
        this.attachmentsService.addAttachment(this.attachment, this.file).subscribe((attachment: any) => {
            this.attachmentsService.getAttachment(attachment.data.id).subscribe((realAttachment) => {
                if (this.saved) {
                    this.saved();
                }
                this.saving = false;
                this.activeModal.close(realAttachment.data);
            });
        });
    }
}
