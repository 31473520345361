import {Component, OnInit} from '@angular/core';
import {Creditor} from "../services/creditor";
import {CreditorsService} from "../services/creditors.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreditorEditModalComponent} from "../creditor-edit-modal/creditor-edit-modal.component";
import {ActivatedRoute} from "@angular/router";
import {User} from "../services/user";
import {UserEditModalComponent} from "../user-edit-modal/user-edit-modal.component";
import {UsersService} from "../services/users.service";
import {CreditorsUserEditModalComponent} from "../creditors-user-edit-modal/creditors-user-edit-modal.component";
import {ContactEditComponent} from "../contact-edit/contact-edit.component";
import {Contact} from "../services/contact";
import {ContactFieldPreset} from "../services/contact-field-preset";
import {faPen, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'app-creditor',
    templateUrl: './creditor.component.html',
    styleUrls: ['./creditor.component.scss'],
    providers: [CreditorsService, ContactFieldPresetsService]
})
export class CreditorComponent implements OnInit {

    creditorId: string;

    _creditor: Creditor;
    activeTab: 1;

    loading = false;

    contactFieldPresets: ContactFieldPreset[];

    editIcon = faPen;
    deleteIcon = faTimes;

    constructor(
            public authService: AuthService,
            public creditorsService: CreditorsService,
                public activatedRoute: ActivatedRoute,
                public contactFieldPresetsService: ContactFieldPresetsService,
                public usersService: UsersService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            /**
             * Unset creditor, it could be set already if we navigate to another creditor
             */
            this._creditor = null;

            /**
             * Set creditor ID and trigger a load of content
             */
            this.creditorId = params.get('creditorId');

            this.load(this.creditorId);

            this.contactFieldPresetsService.getContactFieldPresets(true).subscribe(response => {
                this.contactFieldPresets = response.data;
            });
        });
    }

    load(creditorId: string) {
        this.loading = true;

        this.creditorsService.getCreditor(creditorId, true, true, true, false).subscribe(response => {
            this._creditor = response.data;

            this.loading = false;
        });
    }

    edit() {
        let self = this;

        const modalRef = this.ngbModal.open(CreditorEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.creditor = this._creditor;
        modalRef.componentInstance.saved = function () {
            self.load(self.creditorId);
        }
    }

    addUser() {
        let self = this;

        const modalRef = this.ngbModal.open(UserEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.isManaged = true;
        modalRef.componentInstance.randomPassword = true;
        modalRef.componentInstance.creditorId = self.creditorId;
        modalRef.componentInstance.saved = function (user: User) {
            self.loading = true;

            /**
             * Add user to creditor
             */
            self.creditorsService.addCreditorsUser(self.creditorId, user).subscribe(_ => {
                self.load(self.creditorId);
            });
        }
    }


    editUser(user: User) {
        let self = this;

        const modalRef = this.ngbModal.open(CreditorsUserEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.creditorsUser = user['_joinData'];
        modalRef.componentInstance.saved = function () {
            self.load(self.creditorId);
        }
    }

    deleteUser(user: User) {
        if (confirm($localize `Are you sure?\n\nThe user will be deleted`)) {
            this.loading = true;

            this.creditorsService.deleteCreditorsUser(user['_joinData']).subscribe(_ => {
                this.loading = false;

                this.load(this.creditorId);
            });
        }
    }

    addContact(preset: ContactFieldPreset): void {

        const self = this;
        const modalRef = this.ngbModal.open(ContactEditComponent, {size: 'lg'});

        modalRef.componentInstance.contact = undefined;
        modalRef.componentInstance.preset = preset;
        modalRef.componentInstance.saved = (contact: Contact) => {
            self.loading = true;
            this.creditorsService.addCreditorsContact(this.creditorId, contact).subscribe(_ => {
                self.loading = false;
                self.load(this.creditorId);
            });
        };
    }

    editContact(contact: Contact): void {
        const self = this;
        const modalRef = this.ngbModal.open(ContactEditComponent, {size: 'lg'});

        modalRef.componentInstance.contact = contact;
        modalRef.componentInstance.saved = (editedContact: Contact) => {
            self.loading = true;
            this.creditorsService.updateCreditorsContact(contact._joinData.id, editedContact).subscribe(_ => {
                self.loading = false;
                self.load(this.creditorId);
            });
        };
    }

    deleteContact(contact: Contact): void {
        if (confirm($localize `Are you sure?\n\nThe contact will be deleted`)) {
            this.loading = true;

            this.creditorsService.deleteCreditorsContact(contact._joinData.id).subscribe(_ => {
                this.loading = false;
                this.load(this.creditorId);
            });
        }
    }
}
