<input
    (input)="onClientInputChange($event.target.value)"
    [class]="'form-control  form-control-sm' + (showClientSearchResults ? '  mb-3' : '')"
    type="text"
    [placeholder]="placeholder ? placeholder : (order.isCreditor() ? 'Indtast Kreditor Nr.' : 'Indtast Debitor Nr.')"
    [disabled]="loadingClientChanges"/>

<div class="alert  alert-secondary  mb-0" *ngIf="(debtorSearchResult && debtorSearchResult.length === 0) || (creditorSearchResult && creditorSearchResult.length === 0) " i18n>
    Vi kunne desværre ikke finde hvad du ledte efter.
</div>

<app-loader [minimal]="true" *ngIf="showClientSearchResults && !debtorSearchResult && !creditorSearchResult"></app-loader>

<app-table [additionalClasses]="'  mb-0'" [autoSizeFirstColumn]="true" *ngIf="(debtorSearchResult && debtorSearchResult.length >= 1) || (creditorSearchResult && creditorSearchResult.length >= 1)">
    <thead>
        <tr>
            <th></th>
            <th>Navn</th>
        </tr>
    </thead>

    <tbody>
        <ng-template ngFor let-item [ngForOf]="isCreditor ? creditorSearchResult : debtorSearchResult">
            <tr [class]="doNotFilterSelected && orderService.client && orderService.client.id === item.id ? 'highlighted' : ''">
                <td>
                    <button (click)="setClient(item, null)" class="button  btn  btn-xs  btn-success m-0" [disabled]="loadingClientChanges">
                        <fa-icon *ngIf="!loadingClientChanges" [icon]="(doNotFilterSelected && orderService.client && orderService.client.id === item.id ? selectedIcon : (customListIcon ? customListIcon : selectIcon))"></fa-icon>
                        <span class="spinner-border  spinner-border-sm" role="status" *ngIf="loadingClientChanges"></span>
                    </button>
                </td>
                <td class="centerized">
                    {{item.name}}
                    <span class="badge  badge-secondary">{{item.references[0]._joinData.value}}</span>

                    <ng-template ngFor let-contact [ngForOf]="item.contacts">
                        <div *ngIf="contact.isOrderInvoiceType()">
                            <hr class="my-2">

                            <button (click)="setClient(item, contact)" class="button  btn  btn-xs  btn-success  mr-1" [disabled]="loadingClientChanges">
                                <fa-icon *ngIf="!loadingClientChanges" [icon]="(doNotFilterSelected && orderService.client && orderService.client.id === item.id ? selectedIcon : (customListIcon ? customListIcon : selectIcon))"></fa-icon>
                                <span class="spinner-border  spinner-border-sm" role="status" *ngIf="loadingClientChanges"></span>
                            </button>

                            {{ contact.name }}, {{ contact.address }}, {{ contact.zip }} {{ contact.city }}
                        </div>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </tbody>
</app-table>
