import {Component, Input, OnInit} from '@angular/core';
import {INav} from "../navigation/navigation.component";
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
    selector: 'app-inline-navigation',
    templateUrl: './inline-navigation.component.html',
    styleUrls: ['./inline-navigation.component.scss']
})

export class InlineNavigationComponent implements OnInit {

    public currentNavItem: INav;

    @Input() navData: INav[];

    constructor(private router: Router) {
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event): void {
        this.router.events.forEach((e) => {
            if (e instanceof NavigationEnd) {
                this.setCurrentNavItem(e.url);
            }
        });

    }

    ngOnInit(): void {
        this.setCurrentNavItem();
    }

    setCurrentNavItem(specificURL?: string): void {
        if (!specificURL){
            specificURL = this.router.url;
        }
        this.currentNavItem = this.navData.find((item) => {
            if (item.children){
                return item.children.find((childItem) => {
                    return specificURL === this.router.createUrlTree(childItem.url).toString();
                });
            } else {
                return specificURL === this.router.createUrlTree(item.url).toString();
            }
        });
    }

    onClick(item: INav, noNavigate: boolean = false): void {
        this.currentNavItem = item;

        if (item.children && item.children.length >= 1 && !noNavigate) {
            // When we change menu item, make sure the first child is set as the active one by navigating to it.
            this.router.navigate(item.children[0].url);
        }
    }
}
