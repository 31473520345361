import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {ProductsService} from "../services/products.service";
import {OrderLine} from "../services/order-line";
import {OrdersService} from "../services/orders.service";
import {Product} from "../services/product";
import {Order} from "../services/order";

@Component({
    selector: 'app-order-line-ajour-modal',
    templateUrl: './order-line-ajour-modal.component.html',
    styleUrls: ['./order-line-ajour-modal.component.scss'],
    providers: [OrdersService, ProductsService]
})
export class OrderLineAjourModalComponent implements OnInit {

    public loading = false;

    public saved;

    public order: Order;
    public orderLine: OrderLine;
    public _orderLine: OrderLine;
    public product: Product;

    constructor(public activeModal: NgbActiveModal,
                public ordersService: OrdersService,
                public productsService: ProductsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.loading = true;

        this._orderLine = OrderLine.fromJSON(this.orderLine);

        this.productsService.getProduct(this._orderLine.product_id, false, true, true, false, false, false, this.order.debtor_id, true, false, false, false, {
            withProductQuantityDiscounts: true
        }).subscribe(response => {
            this.product = response.data;

            this.loading = false;
        });
    }

    save() {
        this.saved(this._orderLine);
        this.activeModal.dismiss();
    }

    close() {
        this.activeModal.dismiss();
    }
}
