import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from "../../services/user";
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {RecursiveHelper} from "../../utilities/recursive-helper";
import {NgForm} from "@angular/forms";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    @ViewChild('basketContactForm') basketContactForm: NgForm;

    user ={
        password: ''
    };

    hasError = false;
    hasValidated = false;
    token: string = null;

    environment = environment;
    logo = 'assets/themes/' + this.environment.customer + '/images/logo.png';

    constructor(private router: Router, public activatedRoute: ActivatedRoute, public http: HttpClient) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.token = params.token;
        });
    }

    ngOnInit(): void {
    }

    onSubmit() {

        this.http.post(environment.apiBase + 'users/complete-reset-password', this.user, {
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: this.token
            })
        }).subscribe((respoine) => {
            this.hasError = false;
            this.hasValidated = true;
            this.router.navigate([], {queryParams: {token: null}});

        }, (error) => {

            RecursiveHelper.recursiveFunction(error.error, (path, value, key) => {
                let realKey = path.split('.').pop();
                let formGroup = this.basketContactForm.form.get(realKey);
                if (formGroup) {
                    formGroup.setErrors({invalid: value});
                }
            });
            this.hasError = true;
        });
    }
}
