<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!_user.two_factor_authentication" i18n>Enable 2FA</ng-container>
            <ng-container *ngIf="_user.two_factor_authentication" i18n>Disable 2FA</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p *ngIf="loading" class="text-muted" i18n>Loading...</p>

        <form *ngIf="!loading">
            <div *ngIf="!_user.two_factor_authentication" class="form-group">
                <label for="user-two-factor-authentication-secret" i18n>Secret</label>
                <input type="text" id="user-two-factor-authentication-secret" class="form-control" [(ngModel)]="_user.two_factor_authentication_secret" name="two_factor_authentication_secret" [disabled]="true">
            </div>

            <div *ngIf="!_user.two_factor_authentication">
                <img alt="QR code" [src]="'data:image/png;base64, ' + qr | safeEmbeddedImage" width="400" height="400" />
            </div>

            <div class="form-group">
                <label for="user-two-factor-authentication-password" i18n>One time password</label>
                <input type="text" maxlength="6" id="user-two-factor-authentication-password" autocomplete="off" class="form-control" [(ngModel)]="_user.two_factor_authentication_password" name="two_factor_authentication_password" required>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn  btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn" [ngClass]="{ 'btn-success': !_user.two_factor_authentication, 'btn-danger': _user.two_factor_authentication }" (click)="save()" [disabled]="saving">
            <span *ngIf="!_user.two_factor_authentication">{{ saving ? 'Enabling 2FA...' : 'Enable 2FA' }}</span>
            <span *ngIf="_user.two_factor_authentication">{{ saving ? 'Disabling 2FA...' : 'Disable 2FA' }}</span>
        </button>
    </div>
</div>
