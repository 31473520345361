<div class="orders">
    <h1 class="heading" i18n>Awaiting arrival</h1>

    <app-helms-orders
        [embedded]="true"
        [limitInfo]="true"
        [disableEdit]="true"
        [disableFilterUI]="false"
        [advancedFilter]="false"
        [orderState]="[
    '46ff26ba-ecbc-428f-bd56-fe7516f31c03','67c6c963-28d1-4ade-a8e6-7399ea1a6600'
    ].join(',')"

        [orderType]="['5b394b80-e003-44bf-a5cb-a805121caa9b','cf0d5dfd-bcaf-41a4-b24c-723c82fa8ebf'].join(',')"
    ></app-helms-orders>

</div>

