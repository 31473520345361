import {Component, OnInit} from '@angular/core';
import {ContactFieldPreset} from "../services/contact-field-preset";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactFieldPresetEditModalComponent} from "../contact-field-preset-edit-modal/contact-field-preset-edit-modal.component";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";

@Component({
    selector: 'app-contact-field-presets',
    templateUrl: './contact-field-presets.component.html',
    styleUrls: ['./contact-field-presets.component.sass'],
    providers: [ContactFieldPresetsService]
})
export class ContactFieldPresetsComponent implements OnInit {

    contactFieldPresets: ContactFieldPreset[];

    loading = false;

    constructor(public contactFieldPresetsService: ContactFieldPresetsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.contactFieldPresetsService.getContactFieldPresets(true).subscribe(response => {
            this.contactFieldPresets = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(ContactFieldPresetEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(contactFieldPreset: ContactFieldPreset) {
        let self = this;

        const modalRef = this.ngbModal.open(ContactFieldPresetEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.contactFieldPreset = contactFieldPreset;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(contactFieldPreset: ContactFieldPreset) {
        if (confirm($localize `Are you sure?\n\nThe contact field preset will be deleted`)) {
            this.loading = true;

            this.contactFieldPresetsService.deleteContactFieldPreset(contactFieldPreset).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }
}
