import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from "../services/product";
import {Branch} from "../services/branch";
import {BranchesService} from "../services/branches.service";
import {ProductStock} from "../services/product-stock";

@Component({
    selector: 'app-product-stock-add-modal',
    templateUrl: './product-stock-add-modal.component.html',
    styleUrls: ['./product-stock-add-modal.component.scss'],
    providers: [BranchesService]
})
export class ProductStockAddModalComponent implements OnInit {

    public product: Product;

    branches: Branch[];

    productStock: ProductStock;

    constructor(public activeModal: NgbActiveModal,
                public branchesService: BranchesService) {
    }

    ngOnInit() {
        this.branchesService.getBranches().subscribe(response => {
            let unusedBranches = [];

            for (let branch of response.data) {
                if (!this.product.hasProductStockWithBranch(branch)) {
                    unusedBranches.push(branch);
                }
            }

            this.branches = unusedBranches;
        });

        this.productStock = ProductStock.fromJSON({
            quantity: 0,
            location_reference: ""
        });
    }

    add() {
        this.productStock.branch_id = this.productStock.branch.id;

        this.product.addProductStock(this.productStock);

        this.close();
    }

    close() {
        this.activeModal.dismiss();
    }
}
