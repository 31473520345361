import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {DebtorsService} from "../services/debtors.service";
import {DebtorsUser} from "../services/debtors-user";

@Component({
    selector: 'app-debtors-user-edit-modal',
    templateUrl: './debtors-user-edit-modal.component.html',
    styleUrls: ['./debtors-user-edit-modal.component.scss'],
    providers: [DebtorsService]
})
export class DebtorsUserEditModalComponent implements OnInit {

    public debtorsUser: DebtorsUser;
    public _debtorsUser: DebtorsUser;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public debtorsService: DebtorsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._debtorsUser = this.debtorsUser ? DebtorsUser.fromJSON(this.debtorsUser) : DebtorsUser.fromJSON({});
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        this.debtorsService.updateDebtorsUser(this._debtorsUser).subscribe(_ => {
            this.saved();

            this.saving = false;

            this.activeModal.close();
        });
    }
}
