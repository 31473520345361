<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!reference" i18n>Add reference</ng-container>
            <ng-container *ngIf="reference" i18n>Edit reference</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="reference-name" i18n>Name</label>
                <input type="text" id="reference-name" class="form-control" [(ngModel)]="_reference.name" name="name">
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_order_identification" name="is_order_identification"> <ng-container i18n>Order identification reference</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_order_reference" name="is_order_reference"> <ng-container i18n>Order reference</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_product_identification" name="is_product_identification"> <ng-container i18n>Product identification reference</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_product_reference" name="is_product_reference"> <ng-container i18n>Product reference</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_creditor_reference" name="is_creditor_reference"> <ng-container i18n>Creditor reference</ng-container>
                    </label>
                </div>
            </div>  <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_debtor_reference" name="is_debtor_reference"> <ng-container i18n>Debtor reference</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_product_category_reference" name="is_product_category_reference"> <ng-container i18n>Product category reference</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_reference.is_incremental" name="is_incremental"> <ng-container i18n>Reference is incremented by one on creation</ng-container>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
