import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ProductFieldPreset} from "./product-field-preset";

@Injectable()
export class ProductFieldPresetsService {

    protected url = environment.apiBase + 'product-field-presets';

    constructor(public apiService: ApiService) {
    }

    getProductFieldPreset(id: string): Observable<{data: ProductFieldPreset, count: number, perPage: number}> {
        return this.apiService.get<ProductFieldPreset>(this.url + '/' + id, true, ProductFieldPreset);
    }

    getProductFieldPresets(withProductFields: boolean, withCategories: boolean): Observable<{data: ProductFieldPreset[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        if (withProductFields) {
            parameters.push('with-product-fields=1');
        }

        if (withCategories) {
            parameters.push('with-categories=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<ProductFieldPreset[]>(url, true, ProductFieldPreset);
    }

    addProductFieldPreset(productFieldPreset: ProductFieldPreset): Observable<{data: ProductFieldPreset, count: number, perPage: number}> {
        return this.apiService.post<ProductFieldPreset>(this.url, productFieldPreset, true, ProductFieldPreset);
    }

    updateProductFieldPreset(productFieldPreset: ProductFieldPreset): Observable<{data: ProductFieldPreset, count: number, perPage: number}> {
        return this.apiService.patch<ProductFieldPreset>(this.url + '/' + productFieldPreset.id, productFieldPreset, true, ProductFieldPreset);
    }

    deleteProductFieldPreset(productFieldPreset: ProductFieldPreset): Observable<{data: ProductFieldPreset, count: number, perPage: number}> {
        return this.apiService.delete<ProductFieldPreset>(this.url + '/' + productFieldPreset.id, true, ProductFieldPreset);
    }
}
