import * as dayjs from 'dayjs';
import {User} from "./user";
import {Product} from "./product";

export class Attachment {

    id: string;
    foreign_key: string;
    model: string;
    name: string;
    description: string = '';
    language: string = '';
    type: string;
    size: number;
    source: string;
    sort: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    thumbnails: Thumbnail[] = [];
    product: Product;

    constructor() {
    }

    static fromJSON(json: AttachmentJSON | string): Attachment {
        if (typeof json === 'string') {
            return JSON.parse(json, Attachment.reviver);
        } else {
            let attachment = new Attachment();

            let extra = {
                modified: dayjs(json.created),
                created: dayjs(json.created)
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'user' && json[key]) {
                    extra[key] = User.fromJSON(json[key]);
                }

                if (key === 'product' && json[key]) {
                    extra[key] = Product.fromJSON(json[key]);
                }
            });

            return Object.assign(attachment, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Attachment.fromJSON(value) : value;
    }

    public thumbnailUrl?(key) {
        const results = this.thumbnails.find((item) => {
            return item.key === key;
        });
        if (results) {
            return results.url;
        } else {
            return null;
        }
    }
}

// A representation of Attachment data that can be converted to
// and from JSON without being altered.
interface AttachmentJSON {
    id?: string;
    foreign_key?: string;
    model?: string;
    name?: string;
    description?: string;
    language?: string;
    type?: string;
    size?: number;
    source?: string;
    sort?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}

export interface Thumbnail {
    key: string;
    url: string;
}
