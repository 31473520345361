<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Contact fields</ng-container>
        <small *ngIf="loading && contactFields" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!contactFields" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="contactFields">
        <a class="btn  btn-primary  button   button--add-to-table" href="javascript:" (click)="add()" i18n>Add contact field</a>
    </p>

    <app-table *ngIf="contactFields" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th i18n>Name</th>
            <th style="width: 60px;"></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-contactField [ngForOf]="contactFields">
            <tr>
                <td>
                    {{ contactField.name }}
                    <span class="badge  badge-secondary" style="transform: translateY(-1px);">{{ contactField.type }}</span>
                </td>
                <td class="text-right">
                    <a href="javascript:" (click)="edit(contactField)" i18n>Edit</a>
                    <a href="javascript:" (click)="delete(contactField)" class="text-danger" i18n>Delete</a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="contactFields && count > contactFields.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
