<header [className]="'header  d-print-none  ' + headerClass">
    <div class="header__wrapper">
        <a routerLink="/" class="header__logo" [innerHTML]="headerLogo()">
        </a>
        <app-navigation [navData]="menuData">
        </app-navigation>
    </div>

    <!--
    <div *ngIf="authService.currentUser" class="position-absolute p-1" style="top:0;left: 0">
        <small class="text-white">Logget ind som: {{this.authService.currentUser.name}}</small>
    </div>
    -->
</header>
