import {Component, Input, OnInit} from '@angular/core';
import {OfferGeneratorService} from "../../services/offer-generator.service";
import {Product} from "../../services/product";
import {OrderLine} from "../../services/order-line";
import {ProductRelation} from "../../services/product-relation";
import {Router} from "@angular/router";
import {ProductsService} from "../../services/products.service";
import {faCamera} from '@fortawesome/free-solid-svg-icons';
@Component({
    selector: 'app-tool-select',
    templateUrl: './tool-select.component.html',
    styleUrls: ['./tool-select.component.scss'],
    providers: [ProductsService]
})
export class ToolSelectComponent implements OnInit {

    @Input() extraTools: Product[];

    public activeThumbnail: string;
    faCamera = faCamera;

    constructor(
        private router: Router,
        public productsService: ProductsService,
        public offerService: OfferGeneratorService) {

    }

    ngOnInit(): void {

        if (!this.offerService.selectedTool) {
            this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['tool-orders']}}]);
        } else {

            this.productsService.getProducts(1, -1, false, false, true, false, false, false, {
                byCategories: '0cd897bc-178b-46cb-a42e-ce36b744545e',
                withProductRelations: false,
                withProductStocks: true,
                withAdjustedPrices: true,
                byParentRelation: this.offerService.selectedTool.product.id,
                withAttachments: true
            }).subscribe((response) => {
                this.extraTools = response.data;
                this.offerService.pausenext = false;
            });
        }

        // this.extraTools = this.offerService.selectedTool.product.product_relation_childrens.filter((el) => {
        //     return el.product.hasCategory({id: '0cd897bc-178b-46cb-a42e-ce36b744545e'});
        // });
    }

    onAddClick(tool: Product): void {
        this.offerService.onAddClick(tool);
    }

    onRemoveClick(tool: Product): void {
        this.offerService.onRemoveClick(tool);
    }

    quantityForTool(tool: Product) {
        return this.offerService.quantityForTool(tool);
    }

    onThumbnailClick(event, tooltip, extraTool: Product) {
        event.stopPropagation();

        this.productsService.getProduct(extraTool.id, false, false, false, false, false, false, null, false, false, false, false, {withAttachments: true}).subscribe((response) => {
            if (response.data.attachments && response.data.attachments.length > 0) {
                this.activeThumbnail = response.data.getThumbnail('product_list_preview');
            }
        });
        if (tooltip.isOpen()) {
            tooltip.close();
        } else {
            tooltip.open();
        }
    }
}
