import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Date} from "./date";

@Injectable()
export class DatesService {

    protected url = environment.apiBase + 'dates';

    constructor(public apiService: ApiService) {
    }

    getDate(id: string): Observable<{data: Date, count: number, perPage: number}> {
        return this.apiService.get<Date>(this.url + '/' + id, true, Date);
    }

    getDates(): Observable<{data: Date[], count: number, perPage: number}> {
        return this.apiService.get<Date[]>(this.url, true, Date);
    }

    addDate(date: Date): Observable<{data: Date, count: number, perPage: number}> {
        return this.apiService.post<Date>(this.url, date, true, Date);
    }

    updateDate(date: Date): Observable<{data: Date, count: number, perPage: number}> {
        return this.apiService.patch<Date>(this.url + '/' + date.id, date, true, Date);
    }

    deleteDate(date: Date): Observable<{data: Date, count: number, perPage: number}> {
        return this.apiService.delete<Date>(this.url + '/' + date.id, true, Date);
    }
}
