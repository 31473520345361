import * as dayjs from 'dayjs';
import {Reference} from './reference';
import {ProductFieldPreset} from './product-field-preset';
import {Translation} from './translation';

export class ProductCategory {

    id: string;
    // tslint:disable-next-line:variable-name
    parent_id: string;
    lft: number;
    rght: number;
    name: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    children: ProductCategory[];
    references: Reference[];
    // tslint:disable-next-line:variable-name
    product_field_presets: ProductFieldPreset[];
    // tslint:disable-next-line:variable-name
    _translations: Translation;

    constructor() {
    }

    static fromJSON(json: ProductCategoryJSON | string): ProductCategory {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductCategory.reviver);
        } else {
            const productCategory = Object.create(ProductCategory.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                children: [],
                references: [],
                product_field_presets: [],
                _translations: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (key === 'children') {
                    json[key].forEach((value, _) => {
                        extra.children.push(ProductCategory.fromJSON(value));
                    });
                }

                if (key === 'references') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Reference.fromJSON(value));
                    });
                }

                if (key === 'product_field_presets') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductFieldPreset.fromJSON(value));
                    });
                }
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(productCategory, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? ProductCategory.fromJSON(value) : value;
    }

    hasReference(id: string): boolean {
        const index = this.references.findIndex((element) => {
            return element.id === id;
        });

        return index >= 0;
    }


}

// A representation of ProductCategory data that can be converted to
// and from JSON without being altered.
export interface ProductCategoryJSON {
    id?: string;
    parent_id?: string;
    lft?: number;
    rght?: number;
    name?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    references?: Reference[];
}

export interface Categorizable {
    addCategory(productCategory: ProductCategory): void;
    hasCategory(productCategory: ProductCategory): boolean;
}
