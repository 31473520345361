import {Component, Input, OnInit} from '@angular/core';
import {Order} from "../../../services/order";

@Component({
    selector: 'app-total-prices',
    templateUrl: './total-prices.component.html',
    styleUrls: ['./total-prices.component.scss']
})
export class TotalPricesComponent implements OnInit {

    @Input() order: Order;

    constructor() {
    }

    ngOnInit(): void {
    }

}
