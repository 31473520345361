import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {ProductFieldPreset} from "../services/product-field-preset";
import {ProductFieldPresetsService} from "../services/product-field-presets.service";
import {ProductField} from "../services/product-field";
import {ProductFieldsService} from "../services/product-fields.service";
import {ProductCategoriesService} from "../services/product-categories.service";
import {ProductCategory} from "../services/product-category";

@Component({
    selector: 'app-product-field-preset-edit-modal',
    templateUrl: './product-field-preset-edit-modal.component.html',
    styleUrls: ['./product-field-preset-edit-modal.component.scss'],
    providers: [ProductFieldPresetsService, ProductFieldsService, ProductCategoriesService]
})
export class ProductFieldPresetEditModalComponent implements OnInit {

    public productFieldPreset: ProductFieldPreset;
    public _productFieldPreset: ProductFieldPreset;

    public productFields: ProductField[];
    public productCategories: ProductCategory[];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public productFieldPresetsService: ProductFieldPresetsService,
                public productFieldsService: ProductFieldsService,
                public productCategoriesService: ProductCategoriesService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._productFieldPreset = this.productFieldPreset ? ProductFieldPreset.fromJSON(this.productFieldPreset) : ProductFieldPreset.fromJSON({});

        this.productFieldsService.getProductFields(null, null, true).subscribe(response => {
            this.productFields = response.data;
        });

        this.productCategoriesService.getProductCategories(true, false).subscribe(response => {
            this.productCategories = response.data;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        this.updateSorting();

        if (this.productFieldPreset) {
            this.productFieldPresetsService.updateProductFieldPreset(this._productFieldPreset).subscribe(productFieldPreset => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productFieldPreset);
            });
        } else {
            this.productFieldPresetsService.addProductFieldPreset(this._productFieldPreset).subscribe(productFieldPreset => {
                this.saved();

                this.saving = false;

                this.activeModal.close(productFieldPreset);
            });
        }
    }

    protected updateSorting() {
        let sorting = 0;

        for (let productField of this._productFieldPreset.product_fields) {
            if (!productField._joinData) {
                productField._joinData = {};
            }

            productField._joinData.sorting = sorting;

            sorting++;
        }
    }
}
