import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OrderLineField} from "../services/order-line-field";
import {OrderLineFieldsService} from "../services/order-line-fields.service";
import {OrderLineFieldEditModalComponent} from "../order-line-field-edit-modal/order-line-field-edit-modal.component";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {LanguagesService} from "../services/languages.service";

@Component({
    selector: 'app-order-line-fields',
    templateUrl: './order-line-fields.component.html',
    styleUrls: ['./order-line-fields.component.sass'],
    providers: [OrderLineFieldsService, LanguagesService]
})
export class OrderLineFieldsComponent implements OnInit {

    orderLineFields: OrderLineField[];

    loading = false;
    faExclamation = faExclamationCircle;
    missingLocalizations = [];

    constructor(public orderLineFieldsService: OrderLineFieldsService,
                public ngbModal: NgbModal,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this.languagesService.getLanguages().subscribe(() => {
            this.load();
        });
    }

    load(): void {
        this.loading = true;

        this.orderLineFieldsService.getOrderLineFields().subscribe(response => {
            this.orderLineFields = response.data;
            this.orderLineFields.forEach((orderLineField) => {
                this.missingLocalizations.push(orderLineField._translations.checkMissingLocalizations(this.languagesService.languages));
            });

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(OrderLineFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(orderLineField: OrderLineField, index: number) {
        let self = this;

        const modalRef = this.ngbModal.open(OrderLineFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.orderLineField = orderLineField;
        modalRef.componentInstance.missingLocalizations = this.missingLocalizations[index];
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(orderLineField: OrderLineField) {
        if (confirm($localize `Are you sure?\n\nThe order line field will be deleted`)) {
            this.loading = true;

            this.orderLineFieldsService.deleteOrderLineField(orderLineField).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }
}
