import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from "../services/order";
import {OrdersService} from "../services/orders.service";
import {PrintService} from "../services/print.service";

@Component({
    selector: 'app-order-print-modal',
    templateUrl: './order-print-modal.component.html',
    styleUrls: ['./order-print-modal.component.scss'],
    providers: [OrdersService, PrintService]
})
export class OrderPrintModalComponent implements OnInit {

    public loading = false;

    public order: Order;

    public type: string;

    public pdf;

    constructor(public activeModal: NgbActiveModal,
                public printService: PrintService,
                public ordersService: OrdersService) {
    }

    ngOnInit() {
        //@todo option to call:
        // this.printService.getContentPdf('https://my-url');
        this.ordersService.getOrderPdf(this.order.id, this.type).subscribe(response => {
            this.pdf = response.data['pdf'];
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    print() {

    }
}
