import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ProductRelation} from './product-relation';

@Injectable()
// @ts-ignore
export class ProductRelationsService {

    protected url = environment.apiBase + 'product-relations';

    constructor(public apiService: ApiService) {
    }

    addProductRelation(productRelation: ProductRelation): Observable<{data: ProductRelation, count: number, perPage: number}> {
        return this.apiService.post<ProductRelation>(this.url, productRelation, true, ProductRelation);
    }

    updateProductRelation(productRelation: ProductRelation): Observable<{data: ProductRelation, count: number, perPage: number}> {
        return this.apiService.patch<ProductRelation>(this.url + '/' + productRelation.id, productRelation, true, ProductRelation);
    }

    deleteProductRelation(productRelation: ProductRelation): Observable<{data: ProductRelation, count: number, perPage: number}> {
        return this.apiService.delete<ProductRelation>(this.url + '/' + productRelation.id, true, ProductRelation);
    }
}
