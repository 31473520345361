import * as dayjs from 'dayjs';

export class ContactField {

    id: string;
    name: string;
    type: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    _joinData;

    constructor() {
    }

    static fromJSON(json: ContactFieldJSON | string): ContactField {
        if (typeof json === 'string') {
            return JSON.parse(json, ContactField.reviver);
        } else {
            let contactField = Object.create(ContactField.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _joinData: null
            }

            Object.keys(json).forEach((key, _) => {
                if (key === '_joinData' && json[key]) {
                    extra._joinData = {};

                    Object.keys(json[key]).forEach((keyKey, _) => {
                        extra._joinData[keyKey] = json[key][keyKey]
                    });
                }
            });

            return Object.assign(contactField, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ContactField.fromJSON(value) : value;
    }
}

// A representation of ContactField data that can be converted to
// and from JSON without being altered.
interface ContactFieldJSON {
    id?: string;
    name?: string;
    type?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    _joinData?;
}
