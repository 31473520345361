import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute} from "@angular/router";
import {User} from "../services/user";
import {UserEditModalComponent} from "../user-edit-modal/user-edit-modal.component";
import {UsersService} from "../services/users.service";
import {Empty} from "../services/empty";
import {DebtorsService} from "../services/debtors.service";
import {Debtor} from "../services/debtor";
import {DebtorEditModalComponent} from "../debtor-edit-modal/debtor-edit-modal.component";
import {DebtorsUserEditModalComponent} from "../debtors-user-edit-modal/debtors-user-edit-modal.component";
import {ContactEditSelector} from "../contact-edit-selector/contact-edit-selector.component";
import {Contact} from "../services/contact";
import {ContactEditComponent} from "../contact-edit/contact-edit.component";
import {faPen, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";
import {ContactFieldPreset} from "../services/contact-field-preset";
import {AuthService} from "../services/auth.service";
import {UserSelectModalComponent} from "../user-select-modal/user-select-modal.component";
import {CheckboxConfirmModalComponent} from "../checkbox-confirm-modal/checkbox-confirm-modal.component";

@Component({
    selector: 'app-debtor',
    templateUrl: './debtor.component.html',
    styleUrls: ['./debtor.component.scss'],
    providers: [DebtorsService, ContactFieldPresetsService]
})
export class DebtorComponent implements OnInit {

    debtorId: string;

    _debtor: Debtor;
    contactFieldPresets: ContactFieldPreset[];

    loading = false;
    activeTab = 1;

    editIcon = faPen;
    deleteIcon = faTimes;

    constructor(
        public authService: AuthService,
        public debtorsService: DebtorsService,
        public activatedRoute: ActivatedRoute,
        public usersService: UsersService,
        public contactFieldPresetsService: ContactFieldPresetsService,
        public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            /**
             * Unset debtor, it could be set already if we navigate to another debtor
             */
            this._debtor = null;

            /**
             * Set debtor ID and trigger a load of content
             */
            this.debtorId = params.get('debtorId');

            this.load(this.debtorId);

        });

        this.contactFieldPresetsService.getContactFieldPresets(true).subscribe(response => {
            this.contactFieldPresets = response.data;
        });

    }

    load(debtorId: string) {
        this.loading = true;

        this.debtorsService.getDebtor(debtorId, {
            withReferences: true,
            withPaymentAgreement: true,
            withBranch: true,
            withContacts: true,
            withUsers: true,
            withProductCategories: true,
        }).subscribe(response => {
            this._debtor = response.data;
            this.loading = false;
        });
    }

    edit() {
        let self = this;

        const modalRef = this.ngbModal.open(DebtorEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.debtor = this._debtor;
        modalRef.componentInstance.saved = function () {
            self.load(self.debtorId);
        }
    }

    addUser() {
        let self = this;

        const modalRef = this.ngbModal.open(UserEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.isManaged = true;
        modalRef.componentInstance.randomPassword = true;
        modalRef.componentInstance.debtorId = self.debtorId;
        modalRef.componentInstance.saved = function (user: User) {
            self.loading = true;

            /**
             * Add user to debtor
             */
            self.debtorsService.addDebtorsUser(self.debtorId, user).subscribe(_ => {
                self.load(self.debtorId);
            });
        }
    }

    addExistingUser() {
        let self = this;

        const modalRef = this.ngbModal.open(UserSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.excludeUsers = this._debtor.users;
        modalRef.componentInstance.saved = function (user: User) {
            self.loading = true;

            /**
             * Add user to debtor
             */
            self.debtorsService.addDebtorsExistingUser(self.debtorId, user.id).subscribe(_ => {
                self.load(self.debtorId);
            });
        }
    }

    editUser(user: User): void {
        const self = this;

        const modalRef = this.ngbModal.open(DebtorsUserEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.debtorsUser = user['_joinData'];
        modalRef.componentInstance.saved = () => {
            self.load(self.debtorId);
        };
    }

    deleteUser(user: User) {
        const self = this;
        const modalRef = this.ngbModal.open(CheckboxConfirmModalComponent, {size: 'lg'});

        modalRef.componentInstance.title = $localize`Delete user`;
        modalRef.componentInstance.message = $localize`Are you sure?\n\nThe debtor user relation will be deleted`;
        modalRef.componentInstance.label = $localize`Do you also want to remove the user`;
        modalRef.componentInstance.saved = (deleteUser: boolean) => {
            self.loading = true;

            this.debtorsService.deleteDebtorsUser(user['_joinData'], deleteUser).subscribe(_ => {
                self.loading = false;

                self.load(this.debtorId);
            });
        };
    }

    addContact(): void {
        const self = this;
        const modalRef = this.ngbModal.open(ContactEditComponent, {size: 'lg'});

        modalRef.componentInstance.contact = undefined;
        modalRef.componentInstance.saved = (contact: Contact) => {
            self.loading = true;
            this.debtorsService.addDebtorsContact(this.debtorId, contact).subscribe(_ => {
                self.loading = false;
                self.load(this.debtorId);
            });
        };
    }

    editContact(contact: Contact): void {
        const self = this;
        const modalRef = this.ngbModal.open(ContactEditComponent, {size: 'lg'});

        modalRef.componentInstance.contact = contact;
        modalRef.componentInstance.saved = (editedContact: Contact) => {
            self.loading = true;
            this.debtorsService.updateDebtorsContact(contact._joinData.id, editedContact).subscribe(_ => {
                self.loading = false;
                self.load(this.debtorId);
            });
        };
    }

    deleteContact(contact: Contact): void {
        if (confirm($localize`Are you sure?\n\nThe contact will be deleted`)) {
            this.loading = true;

            this.debtorsService.deleteDebtorsContact(contact._joinData.id).subscribe(_ => {
                this.loading = false;
                this.load(this.debtorId);
            });
        }
    }

    impersonateUser(user: User):void {
        this.usersService.getToken(user).subscribe((resulr) => {
            localStorage.setItem('_user_token', localStorage.getItem('user_token'));
            localStorage.setItem('user_token', resulr.data.getToken());
            localStorage.setItem('impersonation_name', user.name + ' (' + this._debtor.name + ')');
            window.location.href = '/'; // @todo make eventstream/listner to post changes to impersonation status
        });
    }
}
