<ng-container *ngIf="models && competition && !loading">
    <h1 class="heading" i18n>Compare</h1>
    <div class="table-responsive  helms-compare">
        <table class="table-striped">
            <tr>
                <th></th>
                <td *ngFor="let selectedItem of selectedComparisons, let i = index">
                    <div class="d-flex">
                        <select class="form-control  form-control-sm"  [(ngModel)]="selectedItem.selection" (ngModelChange)="changeSelectedValue($event, selectedItem)">
                            <option *ngIf="selectedComparisons.length === 0 || (selectedComparisons.includes('0') && (i == (selectedComparisons.length - 1)))" i18n>
                                Select product for comparison
                            </option>
                            <option *ngFor="let option of (i === 0 ? models : competition)" [ngValue]="option"  [selected]="selectedItem.selection === option">
                                {{option.name}}
                            </option>
                        </select>
                        <button class="btn  btn-danger  btn-sm  ml-1" (click)="removeComparison(selectedItem)" *ngIf="selectedComparisons.length > 1">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                    </div>
                </td>
                <td *ngIf="(selectedComparisons.length < 4)">
                    <button class="btn  btn-block  btn-success  btn-sm  text-center" style="height: 31px;" *ngIf="selectedComparisons.length <= 3" (click)="addComparison()" [disabled]="selectedComparisons.includes(0)">
                        <fa-icon class="mr-1" [icon]="faPlus"></fa-icon>
                        <span i18n>Add product</span>
                    </button>
                </td>
            </tr>

            <ng-container *ngIf="result.length >= 1">
                <tr *ngFor="let entry of result; let i = index">
                    <ng-container *ngFor="let value of entry; let y = index">
                        <ng-container *ngIf="y ===0">
                        <th>{{value}}:</th>
                        </ng-container>
                        <ng-container *ngIf="y !==0">
                        <td>{{value}}</td>
                        </ng-container>
                    </ng-container>

                    <td *ngIf="selectedComparisons.length < 4"></td>
                </tr>
            </ng-container>
        </table>
    </div>
</ng-container>
<app-loader *ngIf="loading"></app-loader>
