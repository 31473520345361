<div class="row">
    <div class="col-md-6">
        <p *ngIf="contactable.contacts.length == 0" class="text-muted" i18n>No contacts</p>

        <app-contacts-editor [contactable]="contactable"></app-contacts-editor>
    </div>

    <div class="col-md-6">
        <ul class="list-unstyled">
            <ng-template ngFor let-contactFieldPreset [ngForOf]="contactFieldPresets">
                <li>
                    <span class="mr-3">{{ contactFieldPreset.name }}</span>

                    <ng-template ngFor let-contactField [ngForOf]="contactFieldPreset.contact_fields">
                        <span class="badge  badge-secondary  mr-2">{{ contactField.name }}</span>
                    </ng-template>

                    <button (click)="addContactFromContactFieldPreset(contactFieldPreset)" class="btn  btn-sm  btn-primary  float-right">Add</button>

                    <hr>
                </li>
            </ng-template>
        </ul>
    </div>
</div>
