<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!this._contact" i18n>Add Contact</ng-container>
            <ng-container *ngIf="this._contact"><span i18n>Edit</span> {{_contact.name}}</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="!contactTypes" class="text-muted" i18n>Loading...</p>

        <form *ngIf="contactTypes">
            <div class="row  mb-3">
                <div class="col">
                    <label for="name" class="form-label" i18n>Name</label>
                    <input type="text" id="name" class="form-control" name="name" [(ngModel)]="_contact.name">
                </div>

                <div class="col">
                    <label for="contact-type" class="form-label" i18n>Type</label>

                    <select id="contact-type" class="form-control" [(ngModel)]="_contact.contact_type_id" name="contact_type_id" (change)="contactTypeChanged()">
                        <option [ngValue]="null" i18n>-- Select contact type --</option>
                        <option *ngFor="let contactType of contactTypes" [ngValue]="contactType.id">{{ contactType.name }}</option>
                    </select>
                </div>
            </div>
            <div class="row  mb-3">
                <div class="col">
                    <label for="full-name" class="form-label" i18n>Full Name</label>
                    <input type="text" id="full-name" class="form-control" name="full-name" [(ngModel)]="_contact.full_name">
                </div>
                <div class="col">
                    <label for="nickname" class="form-label" i18n>Nickname</label>
                    <input type="text" id="nickname" class="form-control" name="nickname" [(ngModel)]="_contact.nickname">
                </div>
            </div>
            <hr />
            <div class="row  mb-3">
                <div class="col">
                    <label for="address" class="form-label" i18n>Address</label>
                    <input type="text" id="address" class="form-control" name="address" [(ngModel)]="_contact.address">
                </div>
            </div>
            <div class="row  mb-3">
                <div class="col">
                    <label for="zip" class="form-label" i18n>Zipcode</label>
                    <input type="text" id="zip" class="form-control" name="zip" [(ngModel)]="_contact.zip">
                </div>
                <div class="col">
                    <label for="city" class="form-label" i18n>City</label>
                    <input type="text" id="city" class="form-control" name="city" [(ngModel)]="_contact.city">
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col  col-md-6  mb-3" *ngFor="let contactField of _contact.contact_fields">
                    <label for="{{contactField.id}}" class="form-label">{{contactField.name}}</label>
                    <input
                        type="{{contactField.type}}"
                        id="{{contactField.id}}"
                        class="form-control"
                        name="{{contactField.name.toLowerCase()}}"
                        [(ngModel)]="contactField._joinData.value"
                    />
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
