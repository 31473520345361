import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.scss'],
    providers: []
})
export class PrintComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

        //

    }
}
