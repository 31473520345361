import * as dayjs from 'dayjs';
import {Translation} from './translation';

export class OrderState {

    id: string;
    name: string;
    is_default: boolean;
    is_frozen: boolean;
    is_order_lines_frozen: boolean;
    is_completed: boolean;
    is_products_reserved: boolean;
    is_shipped: boolean;
    sorting: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    // tslint:disable-next-line:variable-name
    _translations: Translation;

    constructor() {
    }

    static fromJSON(json: OrderStateJSON | string): OrderState {
        if (typeof json === 'string') {
            return JSON.parse(json, OrderState.reviver);
        } else {
            const orderState = Object.create(OrderState.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _translations: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(orderState, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? OrderState.fromJSON(value) : value;
    }
}

// A representation of OrderState data that can be converted to
// and from JSON without being altered.
interface OrderStateJSON {
    id?: string;
    name?: string;
    is_default?: boolean;
    is_frozen?: boolean;
    is_order_lines_frozen?: boolean;
    is_completed?: boolean;
    is_products_reserved?: boolean;
    is_shipped?: boolean;
    sorting?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
