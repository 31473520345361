import * as dayjs from 'dayjs';
import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {AttachmentsService} from "../services/attachments.service";
import {Attachment} from "../services/attachment";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AttachmentUploadModalComponent} from "../attachment-upload-modal/attachment-upload-modal.component";

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.sass'],
    providers: [AttachmentsService]
})
export class AttachmentsComponent implements OnInit {

    @Input() attachments: Attachment[];
    @Input() embedded: boolean;
    @Input() model;

    @Output() pickedAttachment = new EventEmitter<Attachment>();

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    constructor(public attachmentsService: AttachmentsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        if (this.attachments === undefined) {
            this.load(this.page);
        }
    }

    load(page: number) {
        this.loading = true;

        this.attachmentsService.getAttachments(page, this.limit, {
            withProducts: true,
            forModel: this.model ? this.model : null
        }).subscribe(response => {
            this.attachments = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    pageChange(page: number) {
        this.load(page);
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(AttachmentUploadModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load(self.page);
        }
    }

    delete(attachment: Attachment) {
        if (confirm($localize `Are you sure?\n\nThe attachment will be deleted`)) {
            this.loading = true;

            this.attachmentsService.deleteAttachment(attachment).subscribe(_ => {
                this.loading = false;

                this.load(this.page);
            });
        }
    }

    pickAttachment(attachment: Attachment) {
       this.pickedAttachment.next(attachment);
    }
}
