import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Unit} from "./unit";

@Injectable()
export class UnitsService {

    protected url = environment.apiBase + 'units';

    constructor(public apiService: ApiService) {
    }

    getUnit(id: string): Observable<{data: Unit, count: number, perPage: number}> {
        return this.apiService.get<Unit>(this.url + '/' + id, true, Unit);
    }

    getUnits(): Observable<{data: Unit[], count: number, perPage: number}> {
        return this.apiService.get<Unit[]>(this.url, true, Unit);
    }

    addUnit(unit: Unit): Observable<{data: Unit, count: number, perPage: number}> {
        return this.apiService.post<Unit>(this.url, unit, true, Unit);
    }

    updateUnit(unit: Unit): Observable<{data: Unit, count: number, perPage: number}> {
        return this.apiService.patch<Unit>(this.url + '/' + unit.id, unit, true, Unit);
    }

    deleteUnit(unit: Unit): Observable<{data: Unit, count: number, perPage: number}> {
        return this.apiService.delete<Unit>(this.url + '/' + unit.id, true, Unit);
    }
}
