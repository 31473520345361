import * as dayjs from 'dayjs';

export class GroupPermission {

    id: string;
    group_id: string;
    permission: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: GroupPermissionJSON | string): GroupPermission {
        if (typeof json === 'string') {
            return JSON.parse(json, GroupPermission.reviver);
        } else {
            let groupPermission = Object.create(GroupPermission.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(groupPermission, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? GroupPermission.fromJSON(value) : value;
    }
}

// A representation of GroupPermission data that can be converted to
// and from JSON without being altered.
interface GroupPermissionJSON {
    id?: string;
    group_id?: string;
    permission?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
