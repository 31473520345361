import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../../services/order';
import {DebtorsService} from "../../../services/debtors.service";
import {CreditorsService} from "../../../services/creditors.service";
import {Debtor} from "../../../services/debtor";
import {Creditor} from "../../../services/creditor";
import {Contact} from "../../../services/contact";

@Component({
    selector: 'app-client-contact-info',
    templateUrl: './client-contact-info.component.html',
    styleUrls: ['./client-contact-info.component.scss']
})
export class ClientContactInfoComponent implements OnInit {

    @Input() order: Order;

    public contact: Contact | undefined;
    public _client: Debtor | Creditor;

    constructor(public debtorService: DebtorsService, public creditorService: CreditorsService) {
    }

    ngOnInit(): void {

        if (!this.contact && this.order){
            this.contact = this.order.contacts.find((c) => {
                return c.name === 'Contact';
            });
        }

        if (this.contact && this.contact.full_name === '' && this.order.hasClient()){
            if (this.order.isCreditor()){
                this.creditorService.getCreditor(this.order.creditor_id, false, false, false, false).subscribe((response) => {
                    this._client = response.data;
                });
            } else {
                this.debtorService.getDebtor(this.order.debtor_id).subscribe((response) => {
                    this._client = response.data;
                });
            }
        }
    }

}
