import {Component} from "@angular/core";
import {OrdersQueryInterface, OrdersService} from "../../../services/orders.service";
import {AuthService} from "../../../services/auth.service";
import {IdValuesService} from "../../../services/themes/helms/id-values.service";
import {MachinesService} from "../../../services/themes/helms/machines.service";
import {OrderStatesService} from "../../../services/order-states.service";
import {OrderTypesService} from "../../../services/order-types.service";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import * as dayjs from "dayjs";
import {Dayjs} from "dayjs";
import {Order} from "../../../services/order";

@Component({
    selector: 'app-helms-orders-factory',
    templateUrl: './helms-orders-factory.component.html',
    providers: [OrdersService, AuthService, IdValuesService, MachinesService, OrderStatesService, OrderTypesService]

})
export class HelmsOrdersFactoryComponent {
    disableDays = (date: NgbDate, current): boolean => {
        const checkThisDate = dayjs(date.year + '-' + date.month + '-' + date.day);
        if (checkThisDate.day() !== 1) {
            return true;
        } else {
            return false;
        }
    }
    date: Dayjs;
    public orders: Order[];
    tmtNummer: any;

    constructor(public idValuesService: IdValuesService, public orderService: OrdersService) {
    }


    search() {


        let query: OrdersQueryInterface = {};
        if (this.tmtNummer) {
            query.searchQuery = this.tmtNummer;
        }

        if (this.date && this.date.isValid()) {
            const start = this.date.startOf('isoWeek');
            const end = this.date.endOf('isoWeek');
            query.forOrderDates = this.idValuesService.productionDateId;
            query.forOrderDateValue = start.toJSON() + ';' + end.toJSON(); //this.date.toJSON(),
        }

        this.orderService.getOrders(1, 20, true, true, true, true, true, true, false, false, query).subscribe((respone) => {
            this.orders = respone.data;
        });
    }
}
