import * as dayjs from 'dayjs';
import {Debtor} from "../../debtor";

export class Reservation {

    id?: string;
    debtor_id?: string;
    order_id: string;
    start?: dayjs.Dayjs;
    end?: dayjs.Dayjs;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    debtor?: Debtor;
    allow_override?: boolean;
    constructor() {
    }

    static fromJSON(json: ReservationJSON | string): Reservation {
        if (typeof json === 'string') {
            return JSON.parse(json, Reservation.reviver);
        } else {
            const reservation = Object.create(Reservation.prototype);

            const extra = {
                start: dayjs(json.start),
                end: dayjs(json.end),
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                debtor: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (key === 'debtor' && json[key]) {
                    extra.debtor = Debtor.fromJSON(json[key]);
                }
            });

            return Object.assign(reservation, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? Reservation.fromJSON(value) : value;
    }
}

// A representation of Reservation data that can be converted to
// and from JSON without being altered.
interface ReservationJSON {
    id?: string;
    debtor_id?: string;
    order_id?: string;
    start?: dayjs.Dayjs;
    end?: dayjs.Dayjs;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    allow_override?:boolean;
}
