import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {ContactTypesService} from "../services/contact-types.service";
import {ContactType} from "../services/contact-type";
import {forkJoin} from "rxjs";
import {ContactFieldPreset} from "../services/contact-field-preset";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";

@Component({
    selector: 'app-contact-type-edit-modal',
    templateUrl: './contact-type-edit-modal.component.html',
    styleUrls: ['./contact-type-edit-modal.component.scss'],
    providers: [ContactTypesService, ContactFieldPresetsService]
})
export class ContactTypeEditModalComponent implements OnInit {

    public contactType: ContactType;
    public _contactType: ContactType;

    public contactFieldPresets: ContactFieldPreset[];

    public saving = false;
    public saved;

    loading = true;

    constructor(public activeModal: NgbActiveModal,
                public contactFieldPresetsService: ContactFieldPresetsService,
                public contactTypesService: ContactTypesService,
                public authService: AuthService) {
    }

    ngOnInit() {

        forkJoin([
            this.contactFieldPresetsService.getContactFieldPresets(false),
        ]).subscribe(joinData => {
            this.contactFieldPresets = joinData[0].data;
            this._contactType = this.contactType ? ContactType.fromJSON(this.contactType) : ContactType.fromJSON({
                is_order_invoice: false,
                is_order_delivery: false
            });

            this.loading = false;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.contactType) {
            this.contactTypesService.updateContactType(this._contactType).subscribe(contactType => {
                this.saved();

                this.saving = false;

                this.activeModal.close(contactType);
            });
        } else {
            this.contactTypesService.addContactType(this._contactType).subscribe(contactType => {
                this.saved();

                this.saving = false;

                this.activeModal.close(contactType);
            });
        }
    }
}
