import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    faChevronDown,
    faChevronUp,
    faSort,
    faSortDown,
    faSortUp,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: 'app-table-sorting-button',
    templateUrl: './table-sorting-button.component.html',
    styleUrls: ['./table-sorting-button.component.scss']
})
export class TableSortingButtonComponent implements OnInit {

    @Input() direction: 'ASC' | 'DESC' | undefined;

    constructor() {
    }

    ngOnInit(): void {
    }

    changeIcon(): any {
        if (!this.direction){
            return faSort;
        } else if (this.direction === 'ASC') {
            return faSortUp;
        } else {
            return faSortDown;
        }
    }

}
