import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {ContactFieldPreset} from "../services/contact-field-preset";
import {ContactFieldPresetsService} from "../services/contact-field-presets.service";
import {ContactField} from "../services/contact-field";
import {ContactFieldsService} from "../services/contact-fields.service";

@Component({
    selector: 'app-contact-field-preset-edit-modal',
    templateUrl: './contact-field-preset-edit-modal.component.html',
    styleUrls: ['./contact-field-preset-edit-modal.component.scss'],
    providers: [ContactFieldPresetsService, ContactFieldsService]
})
export class ContactFieldPresetEditModalComponent implements OnInit {

    public contactFieldPreset: ContactFieldPreset;
    public _contactFieldPreset: ContactFieldPreset;

    public contactFields: ContactField[];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public contactFieldPresetsService: ContactFieldPresetsService,
                public contactFieldsService: ContactFieldsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._contactFieldPreset = this.contactFieldPreset ? ContactFieldPreset.fromJSON(this.contactFieldPreset) : ContactFieldPreset.fromJSON({});

        // @todo this could create some problems if there are more than 100 contact fields
        this.contactFieldsService.getContactFields(1, 100).subscribe(response => {
            this.contactFields = response.data;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        this.updateSorting();

        if (this.contactFieldPreset) {
            this.contactFieldPresetsService.updateContactFieldPreset(this._contactFieldPreset).subscribe(contactFieldPreset => {
                this.saved();

                this.saving = false;

                this.activeModal.close(contactFieldPreset);
            });
        } else {
            this.contactFieldPresetsService.addContactFieldPreset(this._contactFieldPreset).subscribe(contactFieldPreset => {
                this.saved();

                this.saving = false;

                this.activeModal.close(contactFieldPreset);
            });
        }
    }

    protected updateSorting() {
        let sorting = 0;

        for (let contactField of this._contactFieldPreset.contact_fields) {
            if (!contactField._joinData) {
                contactField._joinData = {};
            }

            contactField._joinData.sorting = sorting;

            sorting++;
        }
    }
}
