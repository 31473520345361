import {Injectable} from '@angular/core';
import {Token} from "./token";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as dayjs from 'dayjs';
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

@Injectable()
export class TokenService {

    constructor(public http: HttpClient) {
    }

    getToken(): Token {
        let userToken = localStorage.getItem('user_token');

        if (Token.verifyFormat(userToken)) {
            let token = Token.fromJSON({token: userToken});

            if (!token.isExpired()) {
                this.refreshToken(token);

                return token;
            } else {
                localStorage.removeItem('user_token');
            }
        }

        return null;
    }

    saveTokenString(token: string): void {
        localStorage.setItem('user_token', token);
    }

    protected refreshToken(token: Token) {
        let cutOff = dayjs.unix(token.getExpiration()).subtract(9, "d");

        if (cutOff.isBefore(dayjs())) {
            /**
             * We only want to refresh the token once, so we need to note when we last tried to refresh
             */
            let lastRefresh = localStorage.getItem('user_token_last_refresh');

            if (!lastRefresh || dayjs.unix(+lastRefresh).isBefore(dayjs().subtract(15, 's'))) {
                localStorage.setItem('user_token_last_refresh', "" + dayjs().unix());
            } else {
                return;
            }

            /**
             * Refresh token
             */
            return this.http.post(environment.apiBase + 'token', {}, {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Authorization': token.getToken()
                })
            }).toPromise().then((response) => {
                let token = Token.fromJSON(response['data']);
                localStorage.setItem('user_token', token.getToken());
            }).catch(AuthService.handleError);
        }
    }
}
