<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Select user</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!users" class="text-muted" i18n>Loading...</p>

            <div *ngIf="users">
                <div class="form-group">
                    <label for="user" i18n>User</label>
                    <select id="user" class="form-control  form-control-sm" [(ngModel)]="user" name="user">
                        <option [ngValue]="null" i18n>-- Select user --</option>
                        <ng-template ngFor let-user [ngForOf]="users">
                            <option *ngIf="!isExcluded(user)" [ngValue]="user">{{ user.name }}</option>
                        </ng-template>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="!users" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!users || !user" i18n>Select</button>
    </div>
</div>
