<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!this.productionLine" i18n>Add production line</ng-container>
            <ng-container *ngIf="this.productionLine" i18n>Edit production line</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="production-line-name" i18n>Name:</label>
                <input type="text" id="production-line-name" class="form-control" [(ngModel)]="_productionLine.name" name="name">
            </div>

            <div class="form-group">
                <label for="production-line-name">Antal Slots pr. uge:</label>
                <input type="text" id="production-line-slots_week" class="form-control" [(ngModel)]="_productionLine.slots_week" name="slots_week">
            </div>

            <hr>

            <div class="form-group">
                <label><span i18n>Products</span>:</label>
                <a (click)="showProductForm()" href="javascript:" class="float-right" i18n>Add</a>

                <ng-container *ngIf="addingProduct">
                    <div class="row">
                        <div class="col-md">
                            <div class="input-group">
                                <input type="text" id="search-term" class="form-control" name="search-term" [(ngModel)]="searchTerm">

                                <div class="input-group-append">
                                    <button (click)="searchProduct()" class="btn  btn-primary" type="button" i18n>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="loading || products">
                        <hr>

                        <p *ngIf="loading" class="text-muted" i18n>Loading...</p>

                        <p *ngIf="products && products.length === 0" class="text-muted" i18n>No products found using search term.</p>

                        <table class="table  table-sm  table-striped  table-hover">
                            <tbody>
                            <ng-template ngFor let-searchProduct [ngForOf]="products">
                                <tr>
                                    <td>{{ searchProduct.name }}</td>
                                    <td class="text-right"><button (click)="addProduct(searchProduct)" class="btn  sm  btn-primary" i18n>Select</button></td>
                                </tr>
                            </ng-template>

                            </tbody>
                        </table>
                    </ng-container>
                </ng-container>

                <ul>
                    <ng-template ngFor let-product [ngForOf]="_productionLine.products" let-i="index">
                        <li>{{ product.name }} <a (click)="removeProduct(i)" href="javascript:" class="float-right" i18n>Remove</a></li>
                    </ng-template>
                </ul>
            </div>

            <hr>

            <div class="form-group">
                <label i18n>Uger:</label>
                <a (click)="showProductionLineWeekForm()" href="javascript:" class="float-right" i18n>Add</a>

                <div *ngIf="addingProductionLineWeek" class="row">
                    <div class="col-md">
                        <div class="form-group">
                            <label for="production-line-week-date" i18n>Week</label>

                            <div class="input-group mb-3">
                                <input type="text" class="form-control" id="production-line-week-date" name="date" i18n-placeholder placeholder="Select week" [(ngModel)]="productionLineWeekDate" ngbDatepicker #date="ngbDatepicker" [showWeekNumbers]="true">

                                <div class="input-group-append">
                                    <button (click)="date.toggle()" class="btn  btn-primary" type="button" i18n>Date picker</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md">
                        <div class="form-group">
                            <label for="production-line-week-slots-week">Antal Slots pr. uge:</label>
                            <input type="number" id="production-line-week-slots-week" class="form-control" [(ngModel)]="productionLineWeekSlotsWeek" name="slots_week">
                        </div>
                    </div>

                    <div class="col-md">
                        <button type="button" class="btn  btn-primary" style="margin-top: 32px;" (click)="addProductionLineWeek()" [disabled]="saving || deleting" i18n>Save</button>
                    </div>
                </div>

                <ul>
                    <ng-template ngFor let-productionLineWeek [ngForOf]="_productionLine.production_line_weeks" let-i="index">
                        <li>
                            Uge {{ productionLineWeek.getWeek()}} / {{productionLineWeek.getYear() }}
                            <b>{{ productionLineWeek.slots_week }} <span i18n>slots</span></b>
                            <a (click)="removeProductionLineWeek(i)" href="javascript:" class="float-right"
                               i18n>Remove</a>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button *ngIf="_productionLine.id" type="button" class="btn  btn-danger" (click)="delete()" [disabled]="saving || deleting">
            <ng-container *ngIf="deleting" i18n>Deleting...</ng-container>
            <ng-container *ngIf="!deleting" i18n>Delete</ng-container>
        </button>

        <button type="button" class="btn  btn-secondary" (click)="close()" [disabled]="saving || deleting" i18n>Close</button>
        <button type="button" class="btn  btn-primary" (click)="save()" [disabled]="saving || deleting">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
