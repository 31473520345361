<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!orderState" i18n>Add order state</ng-container>
            <ng-container *ngIf="orderState" i18n>Edit order state</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-loader *ngIf="!languagesService.languages" class="text-muted" i18n>Loading...</app-loader>

    <div class="modal-body" *ngIf="languagesService.languages">
            <app-language-picker [missingLocalizations]="missingLocalizations"></app-language-picker>
            <form>
                <div class="form-group">
                    <label for="order-state-name" i18n>Name</label><app-language-indicator></app-language-indicator>
                    <input type="text" id="order-state-name" class="form-control" [(ngModel)]="name[languagesService.index]" name="name">
                </div>

                <div class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_orderState.is_default" name="is_default">&nbsp;<ng-container i18n>Default for new orders</ng-container>
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_orderState.is_frozen" name="is_frozen">&nbsp;<ng-container i18n>Freeze order for editing</ng-container>
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_orderState.is_order_lines_frozen" name="is_order_lines_frozen">&nbsp;<ng-container i18n>Freeze order lines for editing</ng-container>
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_orderState.is_completed" name="is_completed">&nbsp;<ng-container i18n>Mark order as completed</ng-container>
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_orderState.is_products_reserved" name="is_products_reserved">&nbsp;<ng-container i18n>Reserve products in order</ng-container>
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_orderState.is_shipped" name="is_shipped">&nbsp;<ng-container i18n>Mark order as shipped</ng-container>
                        </label>
                    </div>
                </div>
            </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
