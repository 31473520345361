<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!currency" i18n>Add unit</ng-container>
            <ng-container *ngIf="currency" i18n>Edit unit</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-loader *ngIf="!_currency" class="text-muted" i18n>Loading...</app-loader>

    <div class="modal-body" *ngIf="_currency">
        <app-language-picker></app-language-picker>

        <form>
            <div class="form-group">
                <label for="currency-name" i18n>Name</label>
                <input type="text" id="currency-name" class="form-control" [(ngModel)]="_currency.name" name="name">
            </div>

            <div class="form-group">
                <label for="currency-symbol" i18n>Symbol</label>
                <input type="text" id="currency-symbol" class="form-control" [(ngModel)]="_currency.symbol" name="symbol">
            </div>

            <div class="form-group">
                <label for="currency-price" i18n>Price</label>
                <input type="number" id="currency-price" class="form-control" [(ngModel)]="_currency.price" name="price">
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_currency.is_default" name="is_default"> <ng-container i18n>Default currency</ng-container>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
