<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!this.date" i18n>Add date</ng-container>
            <ng-container *ngIf="this.date" i18n>Edit date</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="date-name" i18n>Name</label>
                <input type="text" id="date-name" class="form-control" [(ngModel)]="_date.name" name="name">
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_date.is_editable" name="is_editable">&nbsp;<ng-container i18n>Allow editing of dates</ng-container>
                    </label>
                </div>
            </div>

            <hr>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_date.is_creation" name="is_creation">&nbsp;<ng-container i18n>Is creation date</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_date.is_due" name="is_due">&nbsp;<ng-container i18n>Is due date</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_date.is_shipped" name="is_shipped">&nbsp;<ng-container i18n>Is shipped date</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_date.is_completed" name="is_completed">&nbsp;<ng-container i18n>Is completed date</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_date.is_invoiced" name="is_invoiced">&nbsp;<ng-container i18n>Is invoice date</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_date.is_production" name="is_production">&nbsp;<ng-container i18n>Is production date</ng-container>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
