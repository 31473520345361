<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!contactField" i18n>Add contact field</ng-container>
            <ng-container *ngIf="contactField" i18n>Edit contact field</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="contact-field-name" i18n>Name</label>
                <input type="text" id="contact-field-name" class="form-control" [(ngModel)]="_contactField.name" name="name">
            </div>

            <div class="form-group">
                <label for="contact-field-type" i18n>Type</label>
                <select id="contact-field-type" class="form-control" [(ngModel)]="_contactField.type" name="type">
                    <option [ngValue]="'text'" i18n>Text</option>
                </select>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
