<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!contactFieldPreset" i18n>Add contact field preset</ng-container>
            <ng-container *ngIf="contactFieldPreset" i18n>Edit contact field preset</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p *ngIf="!contactFields" class="text-muted" i18n>Loading...</p>

        <form *ngIf="contactFields">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="contact-field-preset-name" i18n>Name</label>
                        <input type="text" id="contact-field-preset-name" class="form-control" [(ngModel)]="_contactFieldPreset.name" name="name">
                    </div>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="col-md-6">
                    <b i18n>Contact fields</b>

                    <p *ngIf="_contactFieldPreset.contact_fields.length >= contactFields.length" class="text-muted" i18n>All contact fields selected</p>

                    <ul class="list-unstyled">
                        <ng-template ngFor let-contactField [ngForOf]="contactFields">
                            <li *ngIf="!_contactFieldPreset.hasContactField(contactField)">
                                {{ contactField.name }}

                                <a href="javascript:" (click)="_contactFieldPreset.addContactField(contactField)" i18n>Add</a>
                            </li>
                        </ng-template>
                    </ul>
                </div>

                <div class="col-md-6">
                    <b i18n>Selected contact fields</b>

                    <p *ngIf="_contactFieldPreset.contact_fields.length === 0" class="text-muted" i18n>No contact fields selected</p>

                    <ul class="list-unstyled" [sortablejs]="_contactFieldPreset.contact_fields" [sortablejsOptions]="{ handle: '.sortablejs--handle' }">
                        <ng-template ngFor let-contactField [ngForOf]="_contactFieldPreset.contact_fields">
                            <li>
                                <span class="sortablejs--handle  mr-2  text-muted" style="cursor: move;">=</span>

                                {{ contactField.name }}

                                <a href="javascript:" (click)="_contactFieldPreset.removeContactField(contactField)" class="text-danger" i18n>Remove</a>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
