import {Component, OnInit} from '@angular/core';
import {Branch} from "../services/branch";
import {BranchesService} from "../services/branches.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BranchEditModalComponent} from "../branch-edit-modal/branch-edit-modal.component";

@Component({
    selector: 'app-branches',
    templateUrl: './branches.component.html',
    styleUrls: ['./branches.component.sass'],
    providers: [BranchesService]
})
export class BranchesComponent implements OnInit {

    branches: Branch[];

    loading = false;

    constructor(public branchesService: BranchesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.branchesService.getBranches().subscribe(response => {
            this.branches = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(BranchEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(branch: Branch) {
        let self = this;

        const modalRef = this.ngbModal.open(BranchEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.branch = branch;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(branch: Branch) {
        if (confirm($localize `Are you sure?\n\nThe branch will be deleted`)) {
            this.loading = true;

            this.branchesService.deleteBranch(branch).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
