import * as dayjs from 'dayjs';
import {Product} from "./product";
import {AlternativePriceUnit} from "./alternative-price-unit";

export class OrderLine {

    id?: string;
    order_id?: string;
    product_id: string;
    quantity: number;
    price: number;
    price_adjusted: number;
    price_final: number;
    exch_price: number;
    exch_price_adjusted: number;
    exch_price_final: number;
    price_adjustment_percentage: number;
    price_adjustment_name: string;
    quantity_discount_percentage: number;
    discount_percentage: number;
    cost_price?: number;
    cost_price_adjusted?: number;
    alternative_price_unit_id: string;
    alternative_price_unit_factor: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    product: Product;
    sorting: number;
    alternative_price_unit: AlternativePriceUnit;

    is_follow_product: boolean;
    is_related_product: boolean;

    order_line_fields: any[] = [];

    _isEditing?: boolean;

    public fieldValueCache: any = {};
    public fieldMetaCache: any = {};

    constructor() {
    }

    static fromJSON(json: OrderLineJSON | string): OrderLine {
        if (typeof json === 'string') {
            return JSON.parse(json, OrderLine.reviver);
        } else {
            // let orderLine = Object.create(OrderLine.prototype);
            let orderLine = new OrderLine();
            let extra = {
                price: json.price ? +json.price : null,
                price_adjusted: json.price_adjusted ? +json.price_adjusted : null,
                price_final: json.price_final ? +json.price_final : null,
                exch_price: json.exch_price ? +json.exch_price : null,
                exch_price_adjusted: json.exch_price_adjusted ? +json.exch_price_adjusted : null,
                exch_price_final: json.exch_price_final ? +json.exch_price_final : null,
                price_adjustment_percentage: json.price_adjustment_percentage >= 0 ? +json.price_adjustment_percentage : null,
                price_adjustment_name: json.price_adjustment_name ? json.price_adjustment_name : null,
                quantity_discount_percentage: json.quantity_discount_percentage >= 0 ? +json.quantity_discount_percentage : null,
                discount_percentage: json.discount_percentage >= 0 ? +json.discount_percentage : null,
                cost_price: json.cost_price ? +json.cost_price : null,
                cost_price_adjusted: json.cost_price_adjusted ? +json.cost_price_adjusted : null,
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product: null,
                alternative_price_unit: null
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'product' && json[key]) {
                    extra.product = Product.fromJSON(json[key]);
                }

                if (key === 'alternative_price_unit' && json[key]) {
                    extra.alternative_price_unit = AlternativePriceUnit.fromJSON(json[key]);
                }
            });

            orderLine = Object.assign(orderLine, json, extra);

            return orderLine;
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? OrderLine.fromJSON(value) : value;
    }

    getPriceWithVAT(): number {
        return this.price_final * (this.product.vat_type.rate / 100 + 1);
    }

    getTotal(): number {
        if (!this.price_final || !this.quantity || this.quantity <= 0) {
            return 0;
        }

        return this.price_final * this.quantity;
    }

    public getUnAdjustedTotal?(): number
    {
       return this.quantity * this.price;
    }

    public getAdjustedSavings?():number {
        return ((this.quantity * this.price) - (this.quantity * this.price_adjusted));
    }


    getTotalCost(): number {
        /**
         * If we dont have a cost price, we return the total price as we assume no profit
         */
        if (!this.cost_price) {
            return this.getTotal();
        }

        return this.cost_price * this.quantity;
    }

    getTotalCostAdjusted(): number {
        if (!this.cost_price_adjusted) {
            return this.getTotal();
        }


        return this.cost_price_adjusted * this.quantity;
    }

    getTotalWithVAT(): number {
        return this.getPriceWithVAT() * this.quantity;
    }

    getVATAmount(): number {
        if (!this.product.vat_type || this.product.vat_type.rate <= 0) {
            return 0;
        }

        return (this.getPriceWithVAT() - this.price_final) * this.quantity;
    }

    getProfit(): number {
        return this.getTotal() - this.getTotalCost();
    }

    getProfitPercentage(): number {
        return this.getProfit() / this.getTotal() * 100;
    }

    getDiscount(): number {
        const result = (this.price - this.price_final) * this.quantity;
        return parseFloat(result.toPrecision(15));
    }

    setPriceFromProduct(product: Product) {
        this.price = product.adjusted_price;
        this.price_adjustment_percentage = product.adjusted_percentage;
        this.price_adjustment_name = product.adjusted_name;
    }

    setPrice(price: number) {
        this.price = price;
        this.price_adjustment_percentage = null;
        this.price_adjustment_name = null;
    }

    setCostPrice(price: number) {
        this.cost_price = price;
    }

    onQuantityChanged() {
        for (let alternativePriceUnit of this.product.alternative_price_units) {
            alternativePriceUnit.quantity = alternativePriceUnit.getUnits(this.quantity);
        }

        this.alternative_price_unit = null;
        this.alternative_price_unit_id = null;
        this.alternative_price_unit_factor = null;
    }

    onAlternativePriceUnitAmountChanged(alternativePriceUnit) {
        /**
         * Set other alternative price unit quantities to null
         */
        for (let orderLineAlternativePriceUnit of this.product.alternative_price_units) {
            if (orderLineAlternativePriceUnit.id !== alternativePriceUnit.id) {
                orderLineAlternativePriceUnit.quantity = orderLineAlternativePriceUnit.getUnits(this.quantity);
            }
        }

        /**
         * Set the order line quantity from this alternative price unit
         */
        this.alternative_price_unit = alternativePriceUnit;
        this.alternative_price_unit_id = alternativePriceUnit.id;
        this.alternative_price_unit_factor = alternativePriceUnit.factor;

        this.quantity = alternativePriceUnit.getQuantity();
    }

    public getFieldValueForId(id: string) {
        if (this.fieldValueCache[id]) {
            return this.fieldValueCache[id];
        }
        if (this.order_line_fields.length > 0) {
            let result: any = this.order_line_fields.find((item) => {
                return item.id === id;
            });
            if (result) {
                this.fieldValueCache[id] = result._joinData.value;
                return result._joinData.value;
            }
        }
        this.fieldValueCache[id] = null;
        return null;
    }

    public setFieldValueForId(id: string, value): boolean {
      //  if (this.order_line_fields.length === 0) {
      //      return false;
      //  }

        const index = this.order_line_fields.findIndex((element) => {
            return element.id === id;
        });

        if (index >= 0) {
            this.order_line_fields[index]._joinData.value = value;
        } else {
            this.order_line_fields.push({
                id: id,
                _joinData: {
                    value: value
                }
            });
        }

        return false;
    }


}

// A representation of OrderLine data that can be converted to
// and from JSON without being altered.
interface OrderLineJSON {
    id?: string;
    order_id?: string;
    product_id?: string;
    quantity?: number;
    price?: number;
    price_adjusted?: number;
    price_final?: number;
    exch_price?: number;
    exch_price_adjusted?: number;
    exch_price_final?: number;
    price_adjustment_percentage?: number;
    price_adjustment_name?: string;
    quantity_discount_percentage?: number;
    discount_percentage?: number;
    cost_price?: number;
    cost_price_adjusted?: number;
    alternative_price_unit_id?: string;
    alternative_price_unit_factor?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;

    product?: Product;

    is_related_product?: boolean;
}
