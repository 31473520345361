import * as dayjs from 'dayjs';

export class CreditorsUser {

    id: string;
    creditor_id: string;
    user_id: string;
    is_supervisor: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: CreditorsUserJSON | string): CreditorsUser {
        if (typeof json === 'string') {
            return JSON.parse(json, CreditorsUser.reviver);
        } else {
            let creditorsUser = Object.create(CreditorsUser.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(creditorsUser, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? CreditorsUser.fromJSON(value) : value;
    }
}

// A representation of CreditorsUser data that can be converted to
// and from JSON without being altered.
interface CreditorsUserJSON {
    id?: string;
    creditor_id?: string;
    user_id?: string;
    is_supervisor?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
