import * as dayjs from 'dayjs';
import {ProductRelationsGroup} from './product-relations-group';
import {ProductRelation} from './product-relation';
import {ProductRelatable} from "./product";

export class ProductRelationsProductRelationsGroups {

    id: string;
    // tslint:disable-next-line:variable-name
    product_relations_id: string;
    // tslint:disable-next-line:variable-name
    product_relations_groups_id: string;
    sorting: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    // tslint:disable-next-line:variable-name
    product_relation: ProductRelation;
    productRelation: ProductRelation;
    productRelationsGroup: ProductRelationsGroup;

    constructor() {
    }

    static fromJSON(json: ProductRelationsProductRelationsGroupsJSON | string): ProductRelationsProductRelationsGroups {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductRelationsProductRelationsGroups.reviver);
        } else {
            const productRelationsProductRelationsGroups = Object.create(ProductRelationsProductRelationsGroups.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                productRelation: null,
                productRelationsGroup: null
            };

            Object.keys(json).forEach((key, _) => {
                if ((key === 'product_relation') && json[key]) {
                    extra.productRelation = ProductRelation.fromJSON(json[key]);
                }
                if ((key === 'product_relations_group') && json[key]) {
                    extra.productRelationsGroup = ProductRelationsGroup.fromJSON(json[key]);
                }
            });

            return Object.assign(productRelationsProductRelationsGroups, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? ProductRelationsProductRelationsGroups.fromJSON(value) : value;
    }
}

// A representation of ProductRelationsProductRelationsGroups data that can be converted to
// and from JSON without being altered.
interface ProductRelationsProductRelationsGroupsJSON {
    id?: string;
    product_relations_id?: string;
    product_relations_groups_id?: string;
    sorting?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    productRelation?: ProductRelation;
    productRelationsGroup?: ProductRelationsGroup;
}
