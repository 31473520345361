<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Add product price</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!priceGroups" class="text-muted" i18n>Loading...</p>

            <p *ngIf="priceGroups && priceGroups.length === 0" class="text-danger" i18n>All price groups are already added to product</p>

            <ng-container *ngIf="priceGroups && priceGroups.length > 0">
                <form class="form">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="product-price-name" i18n>Price</label>
                            <input type="number" id="product-price-name" class="form-control" [(ngModel)]="price.price" name="product-price-name">
                        </div>

                        <div class="form-group col-md-6">
                            <label for="product-price-price_group_id" i18n>Price group</label>

                            <select id="product-price-price_group_id" class="form-control" [(ngModel)]="price.price_group" name="product-price-price_group_id">
                                <option [ngValue]="null" i18n>-- Select price group --</option>
                                <option *ngFor="let priceGroup of priceGroups" [ngValue]="priceGroup">{{ priceGroup.name }}</option>
                            </select>
                        </div>
                    </div>
                </form>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="add()" [disabled]="!priceGroups || !price.price_group || (price.price !==0&&!price.price)" i18n>Add</button>
    </div>
</div>
