<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container i18n>Edit creditor user</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="_creditorsUser.is_supervisor" name="is_supervisor"> <ng-container i18n>Is supervisor</ng-container>
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn  btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn  btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
