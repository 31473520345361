import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {OrderType} from "../services/order-type";
import {OrderTypesService} from "../services/order-types.service";

@Component({
    selector: 'app-order-type-edit-modal',
    templateUrl: './order-type-edit-modal.component.html',
    styleUrls: ['./order-type-edit-modal.component.scss'],
    providers: [OrderTypesService]
})
export class OrderTypeEditModalComponent implements OnInit {

    public orderType: OrderType;
    public _orderType: OrderType;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public orderTypesService: OrderTypesService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._orderType = this.orderType ? OrderType.fromJSON(this.orderType) : OrderType.fromJSON({});
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.orderType) {
            this.orderTypesService.updateOrderType(this._orderType).subscribe(orderType => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderType);
            });
        } else {
            this.orderTypesService.addOrderType(this._orderType).subscribe(orderType => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderType);
            });
        }
    }
}
