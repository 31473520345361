<!--
<pre>{{ missingLocalizations|json }}</pre>
<pre>{{ languagesService.languages|json }}</pre>
<pre *ngIf="languagesService.languages">{{ languagesService.languages.length|json }}</pre>
-->

<ul *ngIf="productCategories && missingLocalizations">
    <ng-template ngFor let-productCategory [ngForOf]="productCategories" let-i="index">
        <li>
            <ng-container *ngIf="missingLocalizations.length > 0">
                <fa-icon [ngbTooltip]="'Localizations missing'" *ngIf="!missingLocalizations[i+missingLocalizationsIndex].complete" [icon]="faExclamation"></fa-icon>
            </ng-container>

            {{ productCategory.name }}

            <a *ngIf="selectingForCategorizable && !selectingForCategorizable.hasCategory(productCategory)" href="javascript:" (click)="select(productCategory)" class="ml-2" i18n>Select</a>

            <a *ngIf="selectingForCategorizable && selectingForCategorizable.hasCategory(productCategory) && didDeselect.observers.length > 0" href="javascript:" (click)="deselect(productCategory)" class="text-danger  ml-2" i18n>Deselect</a>

            <span *ngIf="selectingForCategorizable && selectingForCategorizable.hasCategory(productCategory) && didDeselect.observers.length === 0" class="text-muted  ml-2" i18n>Selected</span>

            <ng-template ngFor let-reference [ngForOf]="productCategory.references">
                <span *ngIf="reference._joinData.value" class="badge  badge-secondary  mr-1">
                    {{ reference.name }}: {{ reference._joinData.value }}
                </span>
            </ng-template>

            <a *ngIf="!selectingForCategorizable" href="javascript:" (click)="edit(productCategory, i+missingLocalizationsIndex)" i18n>Edit</a>

            <a *ngIf="!selectingForCategorizable" href="javascript:" (click)="delete(productCategory)" class="text-danger  ml-2" i18n>Delete</a>

            <app-product-categories-tree *ngIf="productCategory.children && didDeselect.observers.length > 0" [missingLocalizations]="missingLocalizations" [missingLocalizationsIndex]="i" [productCategories]="productCategory.children" [selectingForCategorizable]="selectingForCategorizable" (didSelect)="select($event)" (didDeselect)="deselect($event)" (shouldUpdate)="shouldUpdate.emit()" (didDelete)="didDelete.emit()"></app-product-categories-tree>

            <app-product-categories-tree *ngIf="productCategory.children && didDeselect.observers.length === 0" [missingLocalizations]="missingLocalizations" [missingLocalizationsIndex]="i" [productCategories]="productCategory.children" [selectingForCategorizable]="selectingForCategorizable" (didSelect)="select($event)" (shouldUpdate)="shouldUpdate.emit()" (didDelete)="didDelete.emit()"></app-product-categories-tree>
        </li>
    </ng-template>
</ul>
