import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product, ProductRelatable} from "../services/product";
import {ProductsQueryInterface, ProductsService} from "../services/products.service";
import {OrderLine} from "../services/order-line";
import {Reference} from "../services/reference";
import {ReferencesService} from "../services/references.service";
import {ProductCategoriesService} from "../services/product-categories.service";

@Component({
    selector: 'app-product-select-modal',
    templateUrl: './product-select-modal.component.html',
    styleUrls: ['./product-select-modal.component.scss'],
    providers: [ProductsService, ReferencesService, ProductCategoriesService]
})
export class ProductSelectModalComponent implements OnInit {

    public productRelatable: ProductRelatable;

    productReferences: Reference[];

    searchTerm: string = '';

    loading: boolean = false;

    products: Product[];

    public selected;
    productCategoryId: any;
    public productCategories: any[];
    searchProductsText: any;

    constructor(public activeModal: NgbActiveModal,
                public referencesService: ReferencesService,
                public productsService: ProductsService,
                public productCategoriesService: ProductCategoriesService) {
    }

    ngOnInit() {

        this.productCategoriesService.getProductCategoriesAsTreeList().subscribe(response => {
            const productCategories = [];

            for (const productCategoryId of Object.keys(response.data)) {
                productCategories.push({
                    id: productCategoryId,
                    name: response.data[productCategoryId]
                });
            }

            this.productCategories = productCategories;
        });

        this.referencesService.getReferences().subscribe(response => {
            let productReferences = [];

            for (const reference of response.data) {
                if (reference.is_product_reference) {
                    productReferences.push(reference);
                }
            }

            this.productReferences = productReferences;
        });
    }

    search() {
        this.loading = true;
        this.products = undefined;

        const query: ProductsQueryInterface = {};
        if (this.searchProductsText && this.searchProductsText.length > 0) {
            query.forProductName = this.searchProductsText;
        }

        if (this.productCategoryId) {
            query.byCategories = this.productCategoryId;
        }

        this.productsService.getProducts(1, 100, false, false, true, false, true, false, query).subscribe(response => {
            this.products = response.data;

            this.loading = false;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    select(product: Product) {
        this.productRelatable.addProduct(product);

        this.selected(this.productRelatable);
        this.activeModal.close();
    }
}
