import * as dayjs from 'dayjs';
import {Reference} from "./reference";
import {ProductField} from "./product-field";
import {PriceGroup} from "./price-group";

export class ProductImportMappingColumn {

    id: string;
    product_import_mapping_id: string;
    column: number;
    is_key: boolean;
    product_field_column: string;
    product_field_id: string;
    price_group_id: string;
    reference_id: string;
    is_product_category_reference: boolean;
    is_creditor_reference: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    option: string;

    constructor() {
    }

    static fromJSON(json: ProductImportMappingColumnJSON | string): ProductImportMappingColumn {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductImportMappingColumn.reviver);
        } else {
            let productImportMappingColumn = Object.create(ProductImportMappingColumn.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(productImportMappingColumn, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductImportMappingColumn.fromJSON(value) : value;
    }

    public onOptionChanged() {
        this.product_field_column = null;
        this.product_field_id = null;
        this.price_group_id = null;
        this.reference_id = null;
        this.is_product_category_reference = false;
        this.is_creditor_reference = false;

        if (!this.option) {
            this.is_key = false;
            return;
        }

        if (this.option === 'product_name') {
            this.product_field_column = 'name';
        } else if (this.option === 'is_product_category_reference') {
            this.is_product_category_reference = true;
        } else if (this.option === 'is_creditor_reference') {
            this.is_creditor_reference = true;
        }

        for (let needle of ['reference_', 'product_field_', 'price_group_']) {
            if (this.option.startsWith(needle)) {
                this[needle + 'id'] = this.option.replace(needle, '');

                break;
            }
        }

        /**
         * If not set to a reference, unset key
         */
        if (!this.reference_id) {
            this.is_key = false;
        }
    }

    public createOption(): string {
        if (this.product_field_column) {
            return 'product_' + this.product_field_column;
        } else if (this.product_field_id) {
            return 'product_field_' + this.product_field_id;
        } else if (this.price_group_id) {
            return 'price_group_' + this.price_group_id;
        } else if (this.reference_id) {
            return 'reference_' + this.reference_id;
        } else if (this.is_product_category_reference) {
            return 'is_product_category_reference';
        } else if (this.is_creditor_reference) {
            return 'is_creditor_reference';
        }

        return null;
    }

    static getMappingOptions(references: Reference[], productFields: ProductField[], priceGroups: PriceGroup[]) {
        let options = [
            {
                value: 'product_name',
                name: $localize `Product name`
            },
            {
                value: 'is_product_category_reference',
                name: $localize `Product category`
            },
            {
                value: 'is_creditor_reference',
                name: $localize `Creditor`
            }
        ];

        for (let reference of references) {
            options.push({
                value: 'reference_' + reference.id,
                name: $localize `Reference` + ': ' + reference.name
            });
        }

        for (let productField of productFields) {
            options.push({
                value: 'product_field_' + productField.id,
                name: $localize `Product field` + ': ' + productField.name
            });
        }

        for (let priceGroup of priceGroups) {
            options.push({
                value: 'price_group_' + priceGroup.id,
                name: $localize `Price` + ': ' + priceGroup.name
            });
        }

        return options;
    }
}

// A representation of ProductImportMappingColumn data that can be converted to
// and from JSON without being altered.
interface ProductImportMappingColumnJSON {
    id?: string;
    product_import_mapping_id?: string;
    column?: number;
    is_key?: boolean;
    product_field_column?: string;
    product_field_id?: string;
    price_group_id?: string;
    reference_id?: string;
    is_product_category_reference?: boolean;
    is_creditor_reference?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    option?: string;
}
