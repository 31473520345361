import * as dayjs from 'dayjs';
import {PaymentAgreement} from "./payment-agreement";
import {Contact, Contactable} from "./contact";
import {User} from "./user";
import {Reference} from "./reference";
import {PriceGroup} from "./price-group";
import {Branch} from "./branch";
import {Categorizable, ProductCategory} from "./product-category";
import {Attachment} from "./attachment";

export class Debtor implements Contactable, Categorizable {

    id: string;
    payment_agreement_id: string;
    branch_id: string;
    name: string;
    is_active: boolean;
    default_language: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    references: Reference[];
    payment_agreement: PaymentAgreement;
    contacts: Contact[];
    users: User[];
    price_groups: PriceGroup[];
    product_categories: ProductCategory[];
    balance: number;
    attachments: Attachment[] = [];

    constructor() {
    }

    static fromJSON(json: DebtorJSON | string): Debtor {
        if (typeof json === 'string') {
            return JSON.parse(json, Debtor.reviver);
        } else {
            let debtor = Object.create(Debtor.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                references: [],
                payment_agreement: null,
                branch: null,
                contacts: [],
                users: [],
                price_groups: [],
                product_categories: [],
                attachments: [],
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'references') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Reference.fromJSON(value));
                    });
                }

                if (key === 'payment_agreement' && json[key]) {
                    extra[key] = PaymentAgreement.fromJSON(json[key]);
                }

                if (key === 'branch' && json[key]) {
                    extra[key] = Branch.fromJSON(json[key]);
                }

                if (key === 'contacts') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Contact.fromJSON(value));
                    });
                }

                if (key === 'users') {
                    json[key].forEach((value, _) => {
                        extra[key].push(User.fromJSON(value));
                    });
                }

                if (key === 'price_groups') {
                    json[key].forEach((value, _) => {
                        extra[key].push(PriceGroup.fromJSON(value));
                    });
                }

                if (key === 'product_categories') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductCategory.fromJSON(value));
                    });
                }

                if (key === 'attachments') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Attachment.fromJSON(value));
                    });
                }
            });

            return Object.assign(debtor, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Debtor.fromJSON(value) : value;
    }

    hasReference(id: string): boolean {
        let index = this.references.findIndex((element) => {
            return element['id'] === id;
        });

        return index >= 0;
    }

    public getReference(id: string): string | null {
        let index = this.references.findIndex((element) => {
            return element.id === id;
        });

        if (index >= 0) {
            return this.references[index]._joinData.value;
        }

        return null;
    }

    public hasCategory(productCategory: ProductCategory): boolean {
        return this.product_categories.some(e => e.id === productCategory.id);
    }

    public addCategory(productCategory: ProductCategory): void {
        const newCategory = new ProductCategory();
        newCategory.id = productCategory.id;
        newCategory.name = productCategory.name;
        this.product_categories.push(newCategory);
    }

    public getThumbnail?(key: string, index?: number): string {
        if (!index) {
            index = 0;
        }
        if (this.attachments && this.attachments.length > 0) {
            return this.attachments[index].thumbnailUrl(key);
        }
        return null;
    }
}

// A representation of Debtor data that can be converted to
// and from JSON without being altered.
interface DebtorJSON {
    id?: string;
    payment_agreement_id?: string;
    branch_id?: string;
    name?: string;
    default_language?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    references?: Reference[];
    users?: User[];
    product_categories?: ProductCategory[];
    attachments?: Attachment[];
}

export interface DebtorRelatable {
    addDebtor(debtor: Debtor): void

    removeDebtor(debtor: Debtor): void
}
