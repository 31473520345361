<input autocomplete="off" [disabled]="!contactSearchResult" (input)="onContactSearchInputChange($event.target.value)" [class]="'form-control  form-control-sm' + (showContactSearchResults ? '  mb-3' : '')" type="text" i18n-placeholder placeholder="Type name or address" data-lpignore="true"/>

<app-loader [minimal]="true" *ngIf="showContactSearchResults && !filteredSearchResults"></app-loader>
<app-loader [useOverlayLoader]="true" *ngIf="saving"></app-loader>

<div class="alert  alert-secondary  mb-0" *ngIf="(showContactSearchResults && filteredSearchResults && filteredSearchResults.length === 0) " i18n>
    Vi kunne desværre ikke finde hvad du ledte efter.
</div>

<app-table [additionalClasses]="'  mb-0'" [autoSizeFirstColumn]="true"  *ngIf="(showContactSearchResults && filteredSearchResults && filteredSearchResults.length >= 1)">
    <thead>
        <tr>
            <th></th>
            <th i18n>Navn:</th>
            <th i18n>Fulde Navn:</th>
            <th i18n>Adresse:</th>
            <th i18n>Post Nr:</th>
            <th i18n>By:</th>

        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let contact of filteredSearchResults">
            <td>
                <button (click)="onClick(contact)" class="button  btn  btn-xs  btn-success m-0">
                    <fa-icon [icon]="pinIcon"></fa-icon>
                </button>
            </td>
            <td class="centerized">{{contact.name}}</td>
            <td clasS="centerized">{{contact.full_name}}</td>
            <td class="centerized">{{contact.address}}</td>
            <td class="centerized">{{contact.zip}}</td>
            <td class="centerized">{{contact.city}}</td>
        </tr>
    </tbody>
</app-table>
