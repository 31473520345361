import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../services/users.service';
import {User} from '../services/user';
import {GroupsService} from "../services/groups.service";

@Component({
    selector: 'app-user-2fa-modal',
    templateUrl: './user-2fa-modal.component.html',
    styleUrls: ['./user-2fa-modal.component.scss'],
    providers: [UsersService, GroupsService]
})
export class User2FAModalComponent implements OnInit {

    public user: User;
    public _user: User;

    public qr;

    public loading = false;
    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public usersService: UsersService) {
    }

    ngOnInit() {
        this._user = Object.assign({}, this.user);

        if (!this._user.two_factor_authentication) {
            this.loading = true;

            this.usersService.generateTwoFactorAuthenticationSecret().subscribe(response => {
                this._user.two_factor_authentication_secret = response.data['two_factor_authentication_secret'];

                this.qr = response.data['two_factor_authentication_secret_qr'];

                this.loading = false;
            });
        } else {
            this._user.two_factor_authentication_secret = null;
        }
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        this.usersService.updateUser(this._user).subscribe(_ => {
            this.saved();

            this.saving = false;

            this.activeModal.close();
        }, error => {
            console.log(error.error.error); // potential for setting error indicators in the UI

            this.saving = false;
        });
    }
}
