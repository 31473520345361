<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Profile</ng-container>
    </h1>

    <button *ngIf="user" (click)="edit()" class="btn  btn-primary  button  button--add-to-table" i18n>Edit</button>

    <app-loader *ngIf="!user" class="text-muted" i18n>Loading...</app-loader>

    <div *ngIf="user">
        <p>

            <ng-container i18n>Name</ng-container>
            : <b>{{ user.name }}</b><br>
            <ng-container i18n>Email</ng-container>
            : <b>{{ user.email }}</b><br>
            <ng-container i18n>Group</ng-container>
            : <b>{{ user.group.name }}</b>
        </p>


        <div *ngIf="debtors || creditors">
            <h3>Stamdata</h3>
            <ng-container *ngFor="let debtor of debtors">
                <dl>
                    <dt>Firma</dt>
                    <dd>{{debtor.name}}</dd>
                    <ng-container *ngFor="let contact of debtor.contacts">
                        <dt>Adresse</dt>
                        <dd>{{contact.address}}</dd>
                        <dd>{{contact.city}}</dd>
                    </ng-container>
                </dl>
            </ng-container>
            <ng-container *ngFor="let creditor of creditors">
                <dl>
                    <dt>Firma</dt>
                    <dd>{{creditor.name}}</dd>
                    <ng-container *ngFor="let contact of creditor.contacts">
                        <dt>Adresse</dt>
                        <dd>{{contact.address}}</dd>
                        <dd>{{contact.city}}</dd>
                    </ng-container>
                </dl>
            </ng-container>
        </div>

        <button *ngIf="!user.two_factor_authentication" (click)="user2FAModal()" class="btn  btn-sm  btn-success" i18n>
            Enable 2FA
        </button>
        <button *ngIf="user.two_factor_authentication" (click)="user2FAModal()" class="btn  btn-sm  btn-danger" i18n>
            Disable 2FA
        </button>
    </div>


</div>
