import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrdersService} from '../../../services/orders.service';
import {Order} from '../../../services/order';
import {Options} from 'sortablejs';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Subject, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";

interface ISorting {
    type: string;
    direction: 'ASC' | 'DESC' | undefined;
}

@Component({
    selector: 'app-ten-four-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    providers: [OrdersService, AuthService]
})
export class TenFourOrdersComponent implements OnInit {

    page = 1;
    limit = 25;
    orders: Order[];
    options: Options;

    debounceTime = 500;
    searchQuery: undefined | string;
    isSearching = false;
    searchOrderSubcription: Subscription;
    searchOrdersModelChanged: Subject<string> = new Subject<string>();
    searchResult: Order[];
    currentSearchSorting: ISorting = {
        type: '',
        direction: undefined
    };

    changeSorting = false;
    currentSorting: ISorting = {
        type: '',
        direction: undefined
    };

    changingClient = false;
    activeClient = 'debtor';

    searchIcon = faSearch;

    constructor(public ordersService: TenFourOrderService,
                public authService: AuthService,
                public ngbModal: NgbModal) {
        this.options = {
            handle: '.sortablejs-handle',
            onUpdate: (event: any) => {
            }
        };
    }

    ngOnInit(): void {
        this.loadPage();
        this.searchOrderSubcription = this.searchOrdersModelChanged
            .pipe(
                debounceTime(this.debounceTime),
            )
            .subscribe((val) => {
                this.searchOrders(val);
            });
    }

    changeClientType(type: 'debtor' | 'creditor'): void {
        this.searchQuery = undefined;
        this.searchResult = [];
        this.activeClient = type;
        this.loadPage();
    }

    loadPage(): void {
        this.changingClient = true;
        this.ordersService.getOrders(
            this.page,
            this.limit,
            true,
            true,
            true,
            true,
            true,
            true,
            (this.activeClient === 'debtor' ? false : true),
            (this.activeClient === 'creditor' ? false : true),
            {
                withContacts: true,
                withOrderType: true
            }
        ).subscribe((response) => {
            this.changingClient = false;
            this.orders = response.data;
        });
    }

    deleteOrder(order: Order): void {
        if (confirm('Er du sikker på du vil slette denne ordre?')){
            this.ordersService.deleteOrder(order).subscribe(_ => {
                this.loadPage();
            });
        }
    }

    getContactName(order: Order): string {
        const contact = order.contacts.find((c) => c.name === 'Contact');
        return contact && contact.full_name ? contact.full_name : 'Ukendt';
    }

    onSearchInputChange(val: string): void {
        this.searchResult = [];
        if (val.length > 0) {
            this.isSearching = true;
        } else {
            this.isSearching = false;
        }
        this.searchOrdersModelChanged.next(val);
    }

    searchOrders(val: string, type?: string, direction?: 'ASC' | 'DESC' | undefined): void {
        if (val.length > 0) {
            this.ordersService.getOrders(1, -1, true, true, true, true, true, true, false, false, {
                searchQuery: val,
                withContacts: true,
                withOrderType: true,
                sort: type,
                direction,
            }).subscribe(response => {
                this.searchResult = response.data;
                this.isSearching = false;
            });
        } else {
            this.searchResult = [];
        }
    }

    shouldChangeDirection(direction: 'ASC' | 'DESC' | undefined, field: string): void {
        this.changeSorting = true;
        this.ordersService.getOrders(
            this.page,
            this.limit,
            true,
            true,
            true,
            true,
            true,
            true,
            (this.activeClient === 'debtor' ? false : true),
            (this.activeClient === 'creditor' ? false : true),
            {
                withContacts: true,
                withOrderType: true,
                sort: direction ? field : undefined,
                direction
            }
        ).subscribe((response) => {
            this.changeSorting = false;
            this.orders = response.data;
            this.currentSorting = {
                type: field,
                direction: (field !== this.currentSorting.type ? 'ASC' : (direction ? (direction === 'ASC' ? 'DESC' : undefined) : 'ASC'))
            };
        });
    }

    shouldChangeSearchDirection(direction: 'ASC' | 'DESC' | undefined, field: string): void{
        this.searchOrders(this.searchQuery, field, direction);
    }

    getCurrentDirection(type: string): 'ASC' | 'DESC' | undefined {
        if (type !== this.currentSorting.type) {
            return undefined;
        } else {
            return this.currentSorting.direction;
        }
    }

    getCurrentSearchDirection(type: string): 'ASC' | 'DESC' | undefined {
        if (type !== this.currentSearchSorting.type) {
            return undefined;
        } else {
            return this.currentSearchSorting.direction;
        }
    }

    updateOrder(): void {
        // @todo Opdatering af ordren i et endpoint når rækkefølgen er ændret!
    }

}
