import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {OrderField} from './order-field';

@Injectable()
export class OrderFieldsService {

    protected url = environment.apiBase + 'order-fields';

    constructor(public apiService: ApiService) {
    }

    getOrderField(id: string): Observable<{data: OrderField, count: number, perPage: number}> {
        return this.apiService.get<OrderField>(this.url + '/' + id, true, OrderField);
    }

    getOrderFields(): Observable<{data: OrderField[], count: number, perPage: number}> {
        return this.apiService.get<OrderField[]>(this.url, true, OrderField);
    }

    addOrderField(orderField: OrderField): Observable<{data: OrderField, count: number, perPage: number}> {
        return this.apiService.post<OrderField>(this.url, orderField, true, OrderField);
    }

    updateOrderField(orderField: OrderField): Observable<{data: OrderField, count: number, perPage: number}> {
        return this.apiService.patch<OrderField>(this.url + '/' + orderField.id, orderField, true, OrderField);
    }

    deleteOrderField(orderField: OrderField): Observable<{data: OrderField, count: number, perPage: number}> {
        return this.apiService.delete<OrderField>(this.url + '/' + orderField.id, true, OrderField);
    }
}
