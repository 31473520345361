<div class="creditors">
    <h1 class="heading">
        <ng-container i18n>Creditors</ng-container>
        <small *ngIf="loading && creditors" class="text-muted" i18n>Loading...</small>
    </h1>

    <p *ngIf="!creditors" class="text-muted" i18n>Loading...</p>

    <p *ngIf="creditors && authService.hasPermission('admin')">
        <a href="javascript:" (click)="add()" i18n>Add creditor</a>
    </p>

    <app-table *ngIf="creditors" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>References</th>
                <th i18n>Contacts</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-creditor [ngForOf]="creditors">
                <tr>
                    <td>{{ creditor.name }}</td>
                    <td>
                        <ng-template ngFor let-reference [ngForOf]="creditor.references">
                            <span *ngIf="reference._joinData.value" class="badge  badge-secondary  ml-2">
                                {{ reference.name }}: {{ reference._joinData.value }}
                            </span>
                        </ng-template>
                    </td>
                    <td>{{ creditor.contacts.length }}</td>
                    <td class="text-right">
                        <a class="btn  btn-xs  btn-primary mr-1" routerLink="/creditors/{{ creditor.id }}">
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </a>
                        <button *ngIf="authService.hasPermission('admin')" (click)="delete(creditor)" class="btn  btn-xs  btn-danger">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="creditors && count > creditors.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
