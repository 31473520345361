<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Select product</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!productReferences" class="text-muted" i18n>Loading...</p>

            <ng-container *ngIf="productReferences">

                <div class="form-group col-md-5">
                    <label i18n>Category</label>
                    <select class="form-control  mb-3" [(ngModel)]="productCategoryId" name="product_category_id"  [disabled]="productCategoryId && !products">
                        <option [ngValue]="null" i18n>-- Select product category --</option>
                        <option *ngFor="let productCategory of productCategories" [ngValue]="productCategory.id">{{ productCategory.name }}</option>
                    </select>
                </div>

                <label for="search-term" i18n>Search by product name</label>

                <div class="input-group">
                    <input type="text" id="search-term" class="form-control" name="search-term" [(ngModel)]="searchProductsText">

                    <div class="input-group-append">
                        <button (click)="search()" class="btn  btn-primary" type="button" i18n>Search</button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="loading || products">
                <hr>

                <p *ngIf="loading" class="text-muted" i18n>Loading...</p>

                <p *ngIf="products && products.length === 0" class="text-muted" i18n>No products found using search term.</p>

                <table class="table  table-sm  table-striped  table-hover">
                    <tbody>
                        <ng-template ngFor let-searchProduct [ngForOf]="products">
                            <tr>
                                <td>{{ searchProduct.name }}

                                    <ng-template ngFor let-productCategory [ngForOf]="searchProduct.product_categories">
                            <span class="badge  badge-secondary  ml-2">
                                {{ productCategory.name }}
                            </span>
                                    </ng-template>

                                </td>
                                <td class="text-right"><button (click)="select(searchProduct)" class="btn  sm  btn-primary" i18n>Select</button></td>
                            </tr>
                        </ng-template>

                    </tbody>
                </table>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="!productReferences" i18n>Close</button>
    </div>
</div>
