import * as dayjs from 'dayjs';
import {User} from './user';
import {Attachment} from './attachment';
import {Translation} from './translation';

export class Language {

    locale: string;
    name: string;
    isPrimary: boolean;

    constructor() {
    }

    static fromJSON(json: LanguageJSON | string): Language {
        if (typeof json === 'string') {
            return JSON.parse(json, Language.reviver);
        } else {
            const language = Object.create(Language.prototype);

            const extra = {
            };

            Object.keys(json).forEach((key, _) => {

            });

            return Object.assign(language, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? Language.fromJSON(value) : value;
    }
}
// A representation of Language data that can be converted to
// and from JSON without being altered.
interface LanguageJSON {
    locale?: string;
    name?: string;
    isPrimary?: boolean;
}
