<div *ngIf="!saving" class="newOrder container app__component">
    <h1 class="heading" i18n>Ny ordre</h1>
    <app-select-new-order-type></app-select-new-order-type>
    <ng-container *ngIf="order.order_type">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink i18n>Debtor</a>
                <ng-template ngbNavContent>
                    <app-select-new-order-client [useClientType]="'debtor'" (didChangeClient)="setNewOrder($event)"></app-select-new-order-client>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink i18n>Creditor</a>
                <ng-template ngbNavContent>
                    <app-select-new-order-client [useClientType]="'creditor'" (didChangeClient)="setNewOrder($event)"></app-select-new-order-client>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </ng-container>
    <button
        *ngIf="order.order_type && order.hasClient()" (click)="createOrder()"
        class="btn btn-lg btn-primary float-right text-uppercase"
        i18n
    >
        Opret
    </button>
</div>
<app-loader *ngIf="saving"></app-loader>
