import * as dayjs from 'dayjs';
import {User} from './user';
import {Attachment} from './attachment';
import {Translation} from './translation';

export class Currency {

    id: string;
    name: string;
    symbol: string;
    price: number;
    // tslint:disable-next-line:variable-name
    is_default: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    constructor() {
    }

    static fromJSON(json: CurrencyJSON | string): Currency {
        if (typeof json === 'string') {
            return JSON.parse(json, Currency.reviver);
        } else {
            const article = Object.create(Currency.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
            };

            Object.keys(json).forEach((key, _) => {
            });

            return Object.assign(article, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? Currency.fromJSON(value) : value;
    }
}
// A representation of Currency data that can be converted to
// and from JSON without being altered.
interface CurrencyJSON {
    id?: string;
    name?: string;
    symbol?: string;
    price?: number;
    is_default?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
