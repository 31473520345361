import * as dayjs from 'dayjs';
import {OrderLine} from "./order-line";
import {Debtor, DebtorRelatable} from "./debtor";
import {OrderState} from "./order-state";
import {Reference} from "./reference";
import {Date} from "./date";
import {Branch} from "./branch";
import {Creditor} from "./creditor";
import {Contact} from "./contact";
import {OrderType} from "./order-type";
import {Product} from "./product";
import {OrderField} from "./order-field";

export class Order implements DebtorRelatable {

    id: string;
    parent_id: string;
    branch_id: string
    debtor_id: string;
    creditor_id: string;
    order_state_id: string;
    payment_agreement_is_running_month: boolean;
    payment_agreement_days: number;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    branch: Branch;
    debtor: Debtor;
    creditor: Creditor;
    order_state: OrderState;
    order_type: OrderType;
    order_type_id: string;
    order_lines: OrderLine[] = [];
    references: Reference[] = [];
    dates: Date[];
    contacts: Contact[];
    is_exchanged?: boolean;
    comment?: string;
    order_fields?: OrderField[] = [];

    public fieldValueCache: any = {};

    constructor() {
    }

    static fromJSON(json: OrderJSON | string): Order {
        if (typeof json === 'string') {
            return JSON.parse(json, Order.reviver);
        } else {
            let order = new Order();
            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                branch: null,
                debtor: null,
                creditor: null,
                order_state: null,
                order_type: null,
                order_lines: [],
                references: [],
                dates: [],
                contacts: [],
                order_fields: []
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'branch' && json[key]) {
                    extra[key] = Branch.fromJSON(json[key]);
                }

                if (key === 'debtor' && json[key]) {
                    extra[key] = Debtor.fromJSON(json[key]);
                }

                if (key === 'creditor' && json[key]) {
                    extra[key] = Creditor.fromJSON(json[key]);
                }

                if (key === 'order_state' && json[key]) {
                    extra[key] = OrderState.fromJSON(json[key]);
                }

                if (key === 'order_type' && json[key]) {
                    extra[key] = OrderType.fromJSON(json[key]);
                }

                if (key === 'order_lines') {
                    json[key].forEach((value, _) => {
                        extra[key].push(OrderLine.fromJSON(value));
                    });
                }

                if (key === 'order_fields') {
                    json[key].forEach((value, _) => {
                        extra[key].push(OrderField.fromJSON(value));
                    });
                }

                if (key === 'references') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Reference.fromJSON(value));
                    });
                }

                if (key === 'dates') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Date.fromJSON(value));
                    });
                }

                if (key === 'contacts') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Contact.fromJSON(value));
                    });
                }
            });

            return Object.assign(order, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Order.fromJSON(value) : value;
    }

    public isFrozen(): boolean {
        return this.order_state && this.order_state.is_frozen;
    }

    public isOrderLinesFrozen(): boolean {
        return this.order_state && this.order_state.is_order_lines_frozen;
    }

    public isCompleted(): boolean {
        return this.order_state && this.order_state.is_completed;
    }

    public onPaymentAgreementDaysChange() {
        this.payment_agreement_is_running_month = null;
    }

    public onPaymentAgreementIsRunningMonthChange() {
        this.payment_agreement_days = null;
    }

    public getVATTotal(): number {
        let VATTotal = 0;

        for (let orderLine of this.order_lines) {
            VATTotal += orderLine.getVATAmount();
        }

        return VATTotal;
    }

    public getTotal(): number {
        let total = 0;

        for (let orderLine of this.order_lines) {
            total += orderLine.getTotal();
        }

        return total;
    }

    public getTotalWithVAT(): number {
        return this.getTotal() + this.getVATTotal();
    }

    public getTotalCost(): number {
        let total = 0;

        for (let orderLine of this.order_lines) {
            total += orderLine.getTotalCost();
        }

        return total;
    }

    public getTotalCostAdjusted(): number {
        let total = 0;

        for (let orderLine of this.order_lines) {
            total += orderLine.getTotalCostAdjusted();
        }

        return total;
    }

    public getTotalProfit(): number {
        return this.getTotal() - this.getTotalCost();
    }

    public getTotalProfitAdjustedCost(): number {
        return this.getTotal() - this.getTotalCostAdjusted();
    }

    public getTotalProfitPercentage(): number {
        return this.getTotalProfit() / this.getTotal() * 100;
    }

    public getTotalProfitAdjustedCostPercentage(): number {
        return this.getTotalProfitAdjustedCost() / this.getTotal() * 100;
    }

    public getTotalDiscount(): number {
        let discount = 0;

        for (let orderLine of this.order_lines) {
            discount += orderLine.getDiscount();
        }

        return discount;
    }

    public getTotalUnadjusted(): number {
        let unadjusted = 0;
        for (let orderLine of this.order_lines) {
            unadjusted += orderLine.getUnAdjustedTotal();
        }

        return unadjusted;
    }

    public getIdentificationReference(): string | null {
        for (const reference of this.references) {
            if (reference.is_order_identification) {
                return reference._joinData.value;
            }
        }
        return null;
    }

    public getReference(id: string): string | null {
        let index = this.references.findIndex((element) => {
            return element.id === id;
        });

        if (index >= 0) {
            return this.references[index]._joinData.value;
        }

        return null;
    }

    public hasOrderState(): boolean {
        return !!this.order_state_id;
    }

    public hasClient(): boolean {
        return this.isCreditor() || this.isDebtor();
    }

    public isCreditor(): boolean {
        return !!this.creditor_id;
    }

    public isDebtor(): boolean {
        return !!this.debtor_id;
    }

    public getCreditorDebtorContactReference(): string | null{
        const referenceType = this.isCreditor() ? 'is_creditor_reference' : 'is_debtor_reference';
        for (const reference of this.references) {
            if (reference[referenceType]) {
                return reference._joinData.value;
            }
        }
    }

    public hasDateWithType(type: string): boolean {
        let index = this.dates.findIndex((element) => {
            return element[type];
        });

        return index >= 0;
    }

    public getDate(property: string) {
        let index = this.dates.findIndex((element) => {
            return element[property];
        });

        if (index >= 0) {
            return this.dates[index];
        }

        return null;
    }

    public getDateById(id: string): null|Date {
        let tempDate = null;
        this.dates.forEach((date) => {
            if (date.id === id) {
                tempDate = date;
            }
        });

        return tempDate;
    }

    public getContactWithName(name: string): Contact | null {
        let index = this.contacts.findIndex((element) => {
            return element.name === name;
        });

        if (index >= 0) {
            return this.contacts[index];
        }

        return null;
    }

    public addOrderLine(orderLine: OrderLine): void {
        this.order_lines.push(orderLine);

        /**
         * Go though product relation childrens and if it is a follow product, add a new order line
         * but check if the follow product already exists
         */
        for (let productRelation of orderLine.product.product_relation_childrens) {
            let index = this.order_lines.findIndex((element) => {
                return element['product_id'] === productRelation.product.id;
            });

            if (productRelation.isFollowProduct() && index < 0) {
                let followProductOrderLine = OrderLine.fromJSON({
                    product: productRelation.product,
                    product_id: productRelation.product.id,
                    quantity: productRelation.getFollowProductQuantity(orderLine.quantity),
                    cost_price: productRelation.product.cost_price,
                    price: productRelation.product.adjusted_price
                });

                followProductOrderLine.is_follow_product = true;

                this.addOrderLine(followProductOrderLine);
            }
        }
    }

    public hasOrderLineWithProduct(product: Product): number {
        let index = this.order_lines.findIndex((orderLine) => {
            return orderLine['product_id'] === product.id;
        });

        return index >= 0 ? index : null;
    }

    public addDebtor(debtor: Debtor): void {
        this.debtor_id = debtor.id;
        this.debtor = Debtor.fromJSON({
            id: debtor.id,
            name: debtor.name
        });

        /**
         * Remove creditor as they are mutually exclusive
         */
        this.removeCreditor();

        /**
         * Set payment agreement
         */
        if (debtor.payment_agreement) {
            this.payment_agreement_days = debtor.payment_agreement.days;
            this.payment_agreement_is_running_month = debtor.payment_agreement.is_running_month;
        }
    }

    public removeDebtor(): void {
        this.debtor_id = null;
        this.debtor = null;
    }

    public addCreditor(creditor: Creditor): void {
        this.creditor_id = creditor.id;
        this.creditor = Creditor.fromJSON({
            id: creditor.id,
            name: creditor.name
        });

        /**
         * Remove debtor as they are mutually exclusive
         */
        this.removeDebtor();
    }

    public removeCreditor(): void {
        this.creditor_id = null;
        this.creditor = null;
    }

    public getFieldValueForId?(id: string) {
        if (this.fieldValueCache[id]) {
            return this.fieldValueCache[id];
        }
        if (this.order_fields.length > 0) {
            const result: any = this.order_fields.find((item) => {
                return item.id === id;
            });
            if (result) {
                this.fieldValueCache[id] = result._joinData.value;
                return result._joinData.value;
            }
        }
        this.fieldValueCache[id] = null;
        return null;
    }

    public setFieldValueForId(id: string, value): boolean {
        //  if (this.order_line_fields.length === 0) {
        //      return false;
        //  }

        const index = this.order_fields.findIndex((element) => {
            return element.id === id;
        });

        if (index >= 0) {
            this.order_fields[index]._joinData.value = value;
        } else {
            this.order_fields.push({
                id: id,
                _joinData: {
                    value: value
                }
            });
        }

        return false;
    }
}

// A representation of Order data that can be converted to
// and from JSON without being altered.
export interface OrderJSON {
    id?: string;
    order_state_id?: string;
    order_type_id?: string;
    branch_id?: string;
    debtor_id?: string;
    creditor_id?: string;
    payment_agreement_is_running_month?: boolean;
    payment_agreement_days?: number;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    contacts?: Contact[];
    order_fields?: OrderField[];
    is_exchanged?: boolean;
}
