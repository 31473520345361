<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!this.debtor" i18n>Add debtor</ng-container>
            <ng-container *ngIf="this.debtor" i18n>Edit debtor</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="!paymentAgreements || !contactFieldPresets" class="text-muted" i18n>Loading...</p>

        <form *ngIf="paymentAgreements && contactFieldPresets">
            <div class="form-group">
                <label for="debtor-name" i18n>Name</label>
                <input type="text" id="debtor-name" class="form-control" [(ngModel)]="_debtor.name" name="name">
            </div>

            <div class="form-group">
                <label for="branch-id" i18n>Branch</label>
                <select class="form-control" id="branch-id" name="branch_id" [(ngModel)]="_debtor.branch_id" required>
                    <option [ngValue]="null" i18n>Choose branch</option>
                    <option *ngFor="let branch of branches" [ngValue]="branch.id">{{ branch.name }}</option>
                </select>
            </div>


            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_debtor.is_active" name="is_active" class="mr-1"> <ng-container i18n>Aktiv</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <label for="payment-agreement-id" i18n>Payment agreement</label>

                <select class="form-control" id="payment-agreement-id" name="payment_agreement_id" [(ngModel)]="_debtor.payment_agreement_id" required>
                    <option *ngFor="let paymentAgreement of paymentAgreements" [ngValue]="paymentAgreement.id">{{ paymentAgreement.name }}</option>
                </select>
            </div>

            <ng-template ngFor let-reference [ngForOf]="_debtor.references">
                <div class="form-group">
                    <label for="debtor-reference-{{ reference.id }}-value">{{ reference.name }}</label>
                    <input type="text" id="debtor-reference-{{ reference.id }}-value" class="form-control" [(ngModel)]="reference._joinData.value" name="{{ reference.id }}-value">
                </div>
            </ng-template>

            <div class="form-group">
                <label for="default_language" i18n>Default language</label>
                <select class="form-control" id="default_language" name="default_language" [(ngModel)]="_debtor.default_language" required>
                    <option [value]="null" i18n>Choose default language</option>
                    <option [value]="language.locale" *ngFor="let language of this.languagesService.languages" >{{ language.locale }}</option>
                </select>
            </div>

             <label i18n>Product Categories</label>
                <br>
                <button (click)="selectCategory()" class="btn btn-success" i18n>Add product category</button>
                <table *ngIf="_debtor.product_categories" class="table  table-sm  table-striped">
                    <tbody>

                    <ng-container *ngFor="let category of _debtor.product_categories; let i = index">
                        <tr>
                            <td>
                                {{category.name}}
                            </td>
                            <td class="text-right">
                                <button class="btn btn-danger" (click)="removeLine(i)" i18n>Remove</button>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>

            <p class="text-muted" i18n>Attachments</p>
            <app-attachments [attachments]="_debtor.attachments" [embedded]="true" [model]="'ERP.Debtors'"></app-attachments>
            <button (click)="addAttachment()" class="btn  btn-sm  btn-primary" i18n>Add attachment</button>

            <hr/>

            <app-contact-edit-selector [contactable]="_debtor"
                                       [contactFieldPresets]="contactFieldPresets"></app-contact-edit-selector>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
