import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {PaymentAgreement} from "./payment-agreement";

@Injectable()
export class PaymentAgreementsService {

    protected url = environment.apiBase + 'payment-agreements';

    constructor(public apiService: ApiService) {
    }

    getPaymentAgreement(id: string): Observable<{ data: PaymentAgreement, count: number, perPage: number }> {
        return this.apiService.get<PaymentAgreement>(this.url + '/' + id, true, PaymentAgreement);
    }

    getPaymentAgreements(): Observable<{ data: PaymentAgreement[], count: number, perPage: number }> {
        return this.apiService.get<PaymentAgreement[]>(this.url, true, PaymentAgreement);
    }

    addPaymentAgreement(paymentAgreement: PaymentAgreement): Observable<{ data: PaymentAgreement, count: number, perPage: number }> {
        return this.apiService.post<PaymentAgreement>(this.url, paymentAgreement, true, PaymentAgreement);
    }

    updatePaymentAgreement(paymentAgreement: PaymentAgreement): Observable<{ data: PaymentAgreement, count: number, perPage: number }> {
        return this.apiService.patch<PaymentAgreement>(this.url + '/' + paymentAgreement.id, paymentAgreement, true, PaymentAgreement);
    }

    deletePaymentAgreement(paymentAgreement: PaymentAgreement): Observable<{ data: PaymentAgreement, count: number, perPage: number }> {
        return this.apiService.delete<PaymentAgreement>(this.url + '/' + paymentAgreement.id, true, PaymentAgreement);
    }
}
