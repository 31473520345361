import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

    @Input() smallSwitch = false;
    @Input() checked = false;
    @Output() sliderEvent = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onClick(val: boolean): void {
        this.sliderEvent.emit(val);
    }

}
