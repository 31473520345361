import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {OrderType} from "./order-type";

@Injectable()
export class OrderTypesService {

    protected url = environment.apiBase + 'order-types';

    constructor(public apiService: ApiService) {
    }

    getOrderType(id: string): Observable<{ data: OrderType, count: number, perPage: number }> {
        return this.apiService.get<OrderType>(this.url + '/' + id, true, OrderType);
    }

    getOrderTypes(): Observable<{ data: OrderType[], count: number, perPage: number }> {
        return this.apiService.get<OrderType[]>(this.url, true, OrderType);
    }

    addOrderType(order: OrderType): Observable<{ data: OrderType, count: number, perPage: number }> {
        return this.apiService.post<OrderType>(this.url, order, true, OrderType);
    }

    updateOrderType(order: OrderType): Observable<{ data: OrderType, count: number, perPage: number }> {
        return this.apiService.patch<OrderType>(this.url + '/' + order.id, order, true, OrderType);
    }

    updateOrderTypes(orderTypes: OrderType[]): Observable<{ data: OrderType[], count: number, perPage: number }> {
        return this.apiService.patch<OrderType[]>(this.url, orderTypes, true, OrderType);
    }

    deleteOrderType(order: OrderType): Observable<{ data: OrderType, count: number, perPage: number }> {
        return this.apiService.delete<OrderType>(this.url + '/' + order.id, true, OrderType);
    }
}
