import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-lookup',
    templateUrl: './lookup.component.html',
    styleUrls: ['./lookup.component.scss'],
    providers: []
})
export class LookupComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {

        //

    }
}
