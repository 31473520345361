<div class="container  app__component">
    <h1 class="heading">
        <ng-container i18n>Product categories</ng-container>
        <small *ngIf="loading && productCategories" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!productCategories" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="productCategories">
        <a href="javascript:" class="btn  btn-primary  button  button--add-to-table" (click)="add()" i18n>Add product category</a>
    </p>
    <ng-template [ngIf]="productCategories">
        <app-product-categories-tree [missingLocalizations]="missingLocalizations" [productCategories]="productCategories" (shouldUpdate)="load()" (didDelete)="loading = true"></app-product-categories-tree>
    </ng-template>
</div>
