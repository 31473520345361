import * as dayjs from 'dayjs';
import {Translation} from './translation';

export class ProductsProductField {

    id: string;
    product_id: string;
    product_field_id: string;
    value: string;
    meta: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    _translations: Translation;

    constructor() {
    }

    static fromJSON(json: ProductsProductFieldJSON | string): ProductsProductField {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductsProductField.reviver);
        } else {
            const productsProductField = Object.create(ProductsProductField.prototype);

            const extra = {
                // @todo product og product field
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _translations: null,
            };

            Object.keys(json).forEach((key, index) => {
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(productsProductField, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductsProductField.fromJSON(value) : value;
    }
}

// A representation of ProductsProductField data that can be converted to
// and from JSON without being altered.
interface ProductsProductFieldJSON {
    id?: string;
    product_id?: string;
    product_field?: string;
    value?: boolean;
    meta?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    _translations?;
}
