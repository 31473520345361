import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceGroup} from '../services/price-group';
import {AuthService} from '../services/auth.service';
import {PriceGroupsService} from '../services/price-groups.service';
import {DebtorsService} from '../services/debtors.service';
import {Debtor} from '../services/debtor';
import {ReferencesService} from '../services/references.service';
import {Reference} from '../services/reference';
import {Product} from '../services/product';
import {ProductsService} from '../services/products.service';
import {CurrenciesService} from '../services/currencies.service';
import {Currency} from '../services/currency';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-price-group-edit-modal',
    templateUrl: './price-group-edit-modal.component.html',
    styleUrls: ['./price-group-edit-modal.component.scss'],
    providers: [PriceGroupsService, DebtorsService, ReferencesService, ProductsService, CurrenciesService]
})
export class PriceGroupEditModalComponent implements OnInit {

    public priceGroup: PriceGroup;
    // tslint:disable-next-line:variable-name
    public _priceGroup: PriceGroup;

    public debtors: Debtor[];

    public saving = false;
    public saved;

    productReferences: Reference[];
    currencies: Currency[];
    defaultCurrency: Currency;

    searchTerm = '';

    loading = false;

    products: Product[];

    constructor(public activeModal: NgbActiveModal,
                public priceGroupsService: PriceGroupsService,
                public authService: AuthService,
                public debtorsService: DebtorsService,
                public referencesService: ReferencesService,
                public productsService: ProductsService,
                public currenciesService: CurrenciesService) {
    }

    ngOnInit(): void {
        this._priceGroup = this.priceGroup ? PriceGroup.fromJSON(this.priceGroup) : PriceGroup.fromJSON({});
        forkJoin([
            this.debtorsService.getDebtors(1, -1, false, false, false, false, false),
            this.referencesService.getReferences(),
            this.currenciesService.getCurrencies(1, 10),
        ]).subscribe(joinData => {
            this.debtors = joinData[0].data;

            const productReferences = [];
            for (const reference of joinData[1].data) {
                if (reference.is_product_reference) {
                    productReferences.push(reference);
                }
            }
            this.productReferences = productReferences;

            this.currencies = joinData[2].data;
            this.currencies.forEach((currency) => {
                if (currency.is_default) {
                    this.defaultCurrency = currency;
                }
            });
        });
        /*this.debtorsService.getDebtors(1, -1, false, false, false).subscribe(response => {
            this.debtors = response.data;
        });

        this.referencesService.getReferences().subscribe(response => {
            let productReferences = [];

            for (const reference of response.data) {
                if (reference.is_product_reference) {
                    productReferences.push(reference);
                }
            }

            this.productReferences = productReferences;
        });
        this.currenciesService.getCurrencies(1, 10).subscribe((response) => {
            this.currencies = response.data;
            this.currencies.forEach((currency) => {
                if (currency.is_default) {
                    this.defaultCurrency = currency;
                }
            });
        });
        */
    }

    search(): void {
        this.loading = true;
        this.products = undefined;

        this.productsService.getProducts(1, 100, false, false, false, false, false, false, {
            byReferenceId: this.productReferences.map(reference => reference.id).join(','),
            byRefrenceValue: this.searchTerm
        }).subscribe(response => {
            this.products = response.data;

            this.loading = false;
        });
    }

    select(product: Product): void {
        this._priceGroup.addProduct(product);
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        if (this.priceGroup) {
            this.priceGroupsService.updatePriceGroup(this._priceGroup).subscribe(priceGroup => {
                this.saved();

                this.saving = false;

                this.activeModal.close(priceGroup);
            });
        } else {
            this.priceGroupsService.addPriceGroup(this._priceGroup).subscribe(priceGroup => {
                this.saved();

                this.saving = false;

                this.activeModal.close(priceGroup);
            });
        }
    }
}
