import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {Date} from "../services/date";
import {DatesService} from "../services/dates.service";

@Component({
    selector: 'app-date-edit-modal',
    templateUrl: './date-edit-modal.component.html',
    styleUrls: ['./date-edit-modal.component.scss'],
    providers: [DatesService]
})
export class DateEditModalComponent implements OnInit {

    public date: Date;
    public _date: Date;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public datesService: DatesService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._date = this.date ? Date.fromJSON(this.date) : Date.fromJSON({
            is_creation: false,
            is_due: false,
            is_shipped: false,
            is_completed: false,
            is_invoiced: false,
            is_production: false,
            is_editable: false
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.date) {
            this.datesService.updateDate(this._date).subscribe(date => {
                this.saved();

                this.saving = false;

                this.activeModal.close(date);
            });
        } else {
            this.datesService.addDate(this._date).subscribe(date => {
                this.saved();

                this.saving = false;

                this.activeModal.close(date);
            });
        }
    }
}
