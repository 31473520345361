<div class="container">
    <h1 class="heading">
        <ng-container i18n>Units</ng-container>
        <small *ngIf="loading && units" class="text-muted" i18n>Loading...</small>
    </h1>

    <p *ngIf="!units" class="text-muted" i18n>Loading...</p>

    <p *ngIf="units">
        <a href="javascript:" (click)="add()" i18n>Add unit</a>
    </p>

    <table *ngIf="units" class="table  table-sm  table-striped  table-hover">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th style="width: 60px;"></th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-unit [ngForOf]="units" let-i="index">
                <tr>
                    <td>
                        <fa-icon [ngbTooltip]="'Localizations missing'" *ngIf="!missingLocalizations[i].complete" [icon]="faExclamation"></fa-icon>
                        {{ unit.name }}
                        <span *ngIf="unit.is_default" class="badge  badge-primary  mr-2" i18n>Default</span>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(unit, i)" class="mr-3" i18n>Edit</a>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="delete(unit)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
</div>
