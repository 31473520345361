import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../services/order';
import {OrdersQueryInterface, OrdersService} from '../services/orders.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../services/auth.service';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    providers: [OrdersService, AuthService]
})
export class OrdersComponent implements OnInit {

    @Input() embedded :boolean;
    @Input() filterQuery: OrdersQueryInterface = {};

    orders: Order[];

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    typeFilter = null;

    constructor(public ordersService: OrdersService,
                public authService: AuthService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number): void {
        this.loading = true;

        const hideDebtors = this.typeFilter === 'creditor';
        const hideCreditors = this.typeFilter === 'debtor';

        this.ordersService.getOrders(
            page,
            this.limit,
            true,
            true,
            true,
            true,
            true,
            true,
            hideDebtors,
            hideCreditors,
            this.filterQuery
        ).subscribe(response => {
            this.orders = response.data;

            this.count = response.count;
            this.perPage = response.perPage;

            this.loading = false;
        });
    }

    filterChanged(): void {
        this.load(this.page);
    }

    pageChange(page: number): void {
        this.load(page);
    }

    deleteOrder(order: Order): void {
        if (confirm($localize `Are you sure?\n\nThe order will be deleted`)){
            this.loading = true;
            this.ordersService.deleteOrder(order).subscribe((response) => {
                this.load(this.page);
            });
        }
    }
}
