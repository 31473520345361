export class Empty {

    constructor() {
    }

    static fromJSON(json: EmptyJSON | string): Empty {
        if (typeof json === 'string') {
            return JSON.parse(json, Empty.reviver);
        } else {
            return Object.assign(Object.create(Empty.prototype), json);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Empty.fromJSON(value) : value;
    }
}

// A representation of Empty data that can be converted to
// and from JSON without being altered.
interface EmptyJSON {
}
