import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Router} from "@angular/router";

export interface IStepStone {
    title: string;
    url: any;
    hideNextButton?: boolean;
}

@Component({
    selector: 'app-step-stones',
    templateUrl: './step-stones.component.html',
    styleUrls: ['./step-stones.component.scss']
})
export class StepStonesComponent implements OnInit {

    @Output() shouldChangeStepIndex = new EventEmitter<number>();

    @Input() stepStones: IStepStone[];
    @Input() activeStepIndex: number;
    @Input() showStepStones: boolean;
    @Input() showStepStoneNavigation: boolean;
    @Input() canGoToNextStep: boolean = false;

    constructor(private router: Router) {

        if (!this.showStepStones) {
            this.showStepStones = true;
        }

        if (!this.showStepStoneNavigation) {
            this.showStepStoneNavigation = true;
        }

        if (!this.activeStepIndex) {
            this.activeStepIndex = 0;
        }

        if (!this.canGoToNextStep) {
            this.canGoToNextStep = false;
        }
    }

    ngOnInit(): void {
    }

    onTimelineClick(index: number): void {
        this.shouldChangeStepIndex.emit(index);
    }

    onNextClick(item: IStepStone): void {
        this.router.navigate(item.url);
        this.shouldChangeStepIndex.emit(this.activeStepIndex + 1);
    }

    onPrevClick(item: IStepStone): void {
        this.router.navigate(item.url);
        this.shouldChangeStepIndex.emit(this.activeStepIndex - 1);
    }
}
