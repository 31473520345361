import * as dayjs from 'dayjs';
import {Translation} from './translation';

export class OrderField {

    id: string;
    name?: string;
    type?: string;
    is_localized?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    _joinData;
    _translations?: Translation;

    constructor() {
    }

    static fromJSON(json: OrderFieldJSON | string): OrderField {
        if (typeof json === 'string') {
            return JSON.parse(json, OrderField.reviver);
        } else {
            const orderField = Object.create(OrderField.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _joinData: null,
                _translations: null,
            };

            Object.keys(json).forEach((key, index) => {
                if (key === '_joinData' && json[key]) {
                    extra._joinData = {};

                    Object.keys(json[key]).forEach((keyKey, index) => {
                        extra._joinData[keyKey] = json[key][keyKey];
                    });
                }
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(orderField, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? OrderField.fromJSON(value) : value;
    }
}

// A representation of OrderField data that can be converted to
// and from JSON without being altered.
interface OrderFieldJSON {
    id?: string;
    name?: string;
    type?: string;
    is_localized?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    _joinData?;
}
