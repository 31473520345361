import {Component, OnInit} from '@angular/core';
import {User} from "../../services/user";
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    user = {
        email: ''
    };

    hasError = false;
    hasValidated = false;
    environment = environment;
    logo = 'assets/themes/' + this.environment.customer + '/images/logo.png';

    constructor(private router: Router, public activatedRoute: ActivatedRoute, public http: HttpClient) {

    }

    ngOnInit(): void {
    }

    onSubmit() {

        this.http.post(environment.apiBase + 'users/reset-password', this.user, {
            headers: new HttpHeaders({
                Accept: 'application/json',
            })
        }).subscribe((respoine) => {
            this.hasValidated = true;
            this.hasError = false;

        }, error => {
            this.hasError = true;
        });
    }
}
