<ng-container *ngIf="!loading">
    <h1 class="heading"><ng-container i18n>Ads</ng-container> &amp; <ng-container i18n>Files</ng-container></h1>
    <input type="text" class="form-control form-control-sm mb-4" i18n-placeholder placeholder="Looking for something specific ?" (input)="onSearchChange($event.target.value)" />
    <app-table [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th><span i18n>Description</span>:</th>
                <th><span i18n>Model</span>:</th>
                <th><span i18n>Type</span>:</th>
                <th><span i18n>Language</span>:</th>
                <th><span i18n>Size</span>:</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let attachment of filteredAttachments">
                <td>{{attachment.description}}</td>
                <td>{{attachment.model}}</td>
                <td><fa-icon [icon]="showIcon(attachment.type)"></fa-icon> {{humanFileName(attachment.type)}}</td>
                <td>{{attachment.language}}</td>
                <td>{{humanFileSize(attachment.size)}}</td>
                <td><a href="javascript:" (click)="downloadFile(attachment)"><fa-icon [icon]="faDownload"></fa-icon> <span i18n>Download</span></a></td>
            </tr>
        </tbody>
    </app-table>

    <ng-container *ngIf="attachments && count > attachments.length">
        <ngb-pagination class="d-flex  justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</ng-container>
<app-loader *ngIf="loading"></app-loader>
