import {Component, OnInit} from '@angular/core';
import {Unit} from '../services/unit';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UnitsService} from '../services/units.service';
import {AuthService} from '../services/auth.service';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from '../services/locale-translation';
import {CurrenciesService} from "../services/currencies.service";
import { Currency } from '../services/currency';

@Component({
    selector: 'app-currencies-edit-modal',
    templateUrl: './currency-edit-modal.component.html',
    styleUrls: ['./currency-edit-modal.component.scss'],
    providers: [CurrenciesService, LanguagesService]
})
export class CurrencyEditModalComponent implements OnInit {

    public currency: Currency;
    // tslint:disable-next-line:variable-name
    public _currency: Currency;
    // name: string[] = [];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public currenciesService: CurrenciesService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this._currency = this.currency ? Currency.fromJSON(this.currency) : Currency.fromJSON({is_default: false});
    }

    /*loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {
            if (language.locale === this.languagesService.primaryLanguage.locale) {
                if (this._currency.name) {
                    this.name[i] = this._unit.name;
                } else {
                    this.name[i] = '';
                }
            } else {
                if (this._unit._translations) {
                    const translation = this._unit._translations[language.locale];
                    if (translation) {
                        if (translation.name) {
                            this.name[i] = translation.name;
                        } else {
                            this.name[i] = '';
                        }
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }
            }
            i++;
        });
        console.log(this.name);
    }*/

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        // this.saveLanguages();
        if (this.currency) {
            this.currenciesService.updateCurrency(this._currency).subscribe(unit => {
                this.saved();

                this.saving = false;

                this.activeModal.close(unit);
            });
        } else {
            this.currenciesService.addCurrency(this._currency).subscribe(unit => {
                this.saved();

                this.saving = false;

                this.activeModal.close(unit);
            });
        }
    }

    /*saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {
            if (this.languagesService.isSame(language, this.languagesService.primaryLanguage)) {
                this._unit.name = this.name[i];
            } else {
                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;
            }
            this._unit._translations = translations;
            i++;
        });
    }*/

}
