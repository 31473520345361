import {Component, OnInit} from '@angular/core';
import {OrderType} from "../services/order-type";
import {OrderTypesService} from "../services/order-types.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OrderTypeEditModalComponent} from "../order-type-edit-modal/order-type-edit-modal.component";
import {PriceGroup} from "../services/price-group";

@Component({
    selector: 'app-order-types',
    templateUrl: './order-types.component.html',
    styleUrls: ['./order-types.component.sass'],
    providers: [OrderTypesService]
})
export class OrderTypesComponent implements OnInit {

    orderTypes: OrderType[];

    loading = false;

    constructor(public orderTypesService: OrderTypesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.orderTypesService.getOrderTypes().subscribe(response => {
            this.orderTypes = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(OrderTypeEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(orderType: OrderType) {
        let self = this;

        const modalRef = this.ngbModal.open(OrderTypeEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.orderType = orderType;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(orderType: OrderType) {
        if (confirm($localize `Are you sure?\n\nThe order Type will be deleted`)) {
            this.loading = true;

            this.orderTypesService.deleteOrderType(orderType).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }

    saveSorting() {
        this.loading = true;

        let sorting = 0;
        let orderTypes = [];

        for (let orderType of this.orderTypes) {
            orderTypes.push(OrderType.fromJSON({
                id: orderType.id,
                sorting: sorting
            }));

            sorting++;
        }

        this.orderTypesService.updateOrderTypes(orderTypes).subscribe(_ => {
            this.loading = false;
        });
    }
}
