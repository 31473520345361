<ng-container *ngIf="(debtors && products && debtorsPieChartData && debtorsPieChartLabels && productsPieChartData && productsPieChartLabels)">
    <h1 class="heading" i18n>Sales statistics</h1>
    <ul ngbNav #nav="ngbNav" class="nav-tabs flex-nowrap" [(activeId)]="active">
        <li ngbNavItem *ngIf="isAdmin">
            <a ngbNavLink i18n>Dealers</a>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-12  col-md-8">
                        <app-table>
                            <tr>
                                <th i18n>Dealer</th>
                                <th i18n>Zip</th>
                                <th class="text-right" i18n>Offers</th>
                                <th class="text-right" i18n>Orders</th>
                                <th class="text-right" i18n>Conversion</th>
                                <th class="text-right"><span i18n>Previous year</span> ({{ year - 1 }}<span *ngIf="month"> {{ monthStrings[month] }}</span>)</th>
                                <th class="text-right"><span i18n>Previous year</span> ({{ year - 2 }}<span *ngIf="month"> {{ monthStrings[month] }}</span>)</th>
                            </tr>
                            <tr *ngFor="let debtor of debtorData, let i = index" (mouseenter)="onRowEnter(i)" (mouseleave)="onRowLeave()">
                                <td>{{debtor.name}}</td>
                                <td>{{debtor.zipcode}}</td>
                                <td class="text-right">{{ debtor.offers ? debtor.offers : 0 }}</td>
                                <td class="text-right">{{ debtor.count ? debtor.count : 0 }}</td>
                                <td class="text-right">
                                    <span *ngIf="debtor.offersConversion > 0">
                                        {{ (debtor.offersConversion / debtor.offers * 100).toFixed(1) }} %
                                    </span>
                                </td>
                                <td class="text-right">{{ debtor.lastCount ? debtor.lastCount : 0 }}</td>
                                <td class="text-right">{{ debtor.laterCount ? debtor.laterCount : 0 }}</td>
                            </tr>
                            <tfoot>
                            <tr>
                                <td><b>Total</b></td>
                                <td></td>
                                <td class="text-right"><b>{{debtorTotals.totalOffers}}</b></td>
                                <td class="text-right"><b>{{debtorTotals.totalOrders}}</b></td>
                                <td></td>
                                <td class="text-right"><b>{{debtorTotals.totalLast}}</b></td>
                                <td class="text-right"><b>{{debtorTotals.totalLater}}</b></td>
                            </tr>
                            </tfoot>
                        </app-table>
                    </div>
                    <div class="col-12  col-md-4">
                        <div style="display: block; height: 340px">
                            <canvas baseChart #chart
                                    [data]="debtorsPieChartData"
                                    [labels]="debtorsPieChartLabels"
                                    [chartType]="pieChartType"
                                    [options]="pieChartOptions"
                                    [plugins]="pieChartPlugins"
                                    [legend]="pieChartLegend">
                            </canvas>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink i18n>Models</a>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-12  col-md-8">
                        <app-table>
                            <tr>
                                <th i18n>Model</th>
                                <th i18n>Offers</th>
                                <th class="text-right" i18n>Orders</th>
                                <th class="text-right" i18n>Conversion</th>
                                <th class="text-right"><span i18n>Previous year</span> ({{ year - 1 }}<span *ngIf="month"> {{ monthStrings[month] }}</span>)</th>
                                <th class="text-right"><span i18n>Previous year</span> ({{ year - 2 }}<span *ngIf="month"> {{ monthStrings[month] }}</span>)</th>
                            </tr>
                            <ng-container *ngFor="let product of productData, let i = index">
                                <tr *ngIf="product.is_active || product.orders_within_three_years > 0" (mouseenter)="onRowEnter(i)" (mouseleave)="onRowLeave()">
                                    <td>{{ product.name }}</td>
                                    <td class="text-right">{{ product.offers ? product.offers : 0 }}</td>
                                    <td class="text-right">{{ product.count ? product.count : 0 }}</td>
                                    <td class="text-right">
                                    <span *ngIf="product.offersConversion > 0">
                                        {{ (product.offersConversion / product.offers * 100).toFixed(1) }} %
                                    </span>
                                    </td>
                                    <td class="text-right">{{ product.lastCount ? product.lastCount : 0 }}</td>
                                    <td class="text-right">{{ product.laterCount ? product.laterCount : 0 }}</td>
                                </tr>
                            </ng-container>
                            <tfoot>
                                <tr>
                                    <td><b>Total</b></td>
                                    <td class="text-right"><b>{{productTotals.totalOffers}}</b></td>
                                    <td class="text-right"><b>{{productTotals.totalOrders}}</b></td>
                                    <td></td>
                                    <td class="text-right"><b>{{productTotals.totalLast}}</b></td>
                                    <td class="text-right"><b>{{productTotals.totalLater}}</b></td>
                                </tr>
                            </tfoot>
                        </app-table>
                    </div>
                    <div class="col-12  col-md-4">
                        <div style="display: block; height: 340px">
                            <canvas baseChart #chart
                                    [data]="productsPieChartData"
                                    [labels]="productsPieChartLabels"
                                    [chartType]="pieChartType"
                                    [options]="pieChartOptions"
                                    [plugins]="pieChartPlugins"
                                    [legend]="pieChartLegend">
                            </canvas>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem *ngIf="isAdmin">
            <a ngbNavLink i18n>Logins</a>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-12">
                        <app-table>
                            <tr>
                                <th i18n>Dealer</th>
                                <th i18n>Zip</th>
                                <th i18n>Logins</th>
                                <th i18n>Last login</th>
                            </tr>
                            <tr *ngFor="let debtor of debtorData, let i = index">
                                <td>{{debtor.name}}</td>
                                <td>{{debtor.zipcode}}</td>
                                <td>{{debtor.logins}}</td>
                                <td><span *ngIf="debtor.logins > 0">{{debtor.lastLogin}}</span></td>
                            </tr>
                        </app-table>
                    </div>
                </div>
            </ng-template>
        </li>
        <li class="w-100">
            <div class="float-right">
                <div class="row">
                    <div *ngIf="isAdmin && active == 'ngb-nav-0'" class="col">
                        <select class="form-control" [(ngModel)]="debtorId" name="debtor" (change)="load()" [disabled]="!isAdmin">
                            <option [ngValue]="null" i18n>-- Alle forhandlere eller lande --</option>
                            <optgroup label="Lande">
                                <option [ngValue]="'da_DK'" i18n>Danmark</option>
                                <option [ngValue]="'nb_NO'" i18n>Norge</option>
                                <option [ngValue]="'sv_SE'" i18n>Sverige</option>
                            </optgroup>
                            <optgroup label="Forhandler">
                                <option *ngFor="let debtor of debtors" [ngValue]="debtor.id">{{ debtor.name }}</option>
                            </optgroup>
                        </select>
                    </div>

                    <div class="col">
                        <select class="form-control" [(ngModel)]="year" name="year" (change)="load()">
                            <option *ngFor="let yearNumber of years" [ngValue]="yearNumber">{{ yearNumber }}</option>
                        </select>
                    </div>

                    <div class="col">
                        <select class="form-control" [(ngModel)]="month" name="month" (change)="load()">
                            <option [ngValue]="undefined" i18n>-- Entire year --</option>
                            <option *ngFor="let monthElement of months" [ngValue]="monthElement.number">{{ monthElement.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</ng-container>

<app-loader *ngIf="!debtors || !products || loading > 0" [useOverlayLoader]="debtors && products && loading > 0"></app-loader>
