<div class="container  pb-5">
    <h1  class="heading" i18n>Product import</h1>

    <app-loader *ngIf="loading" class="text-muted" i18n>Loading...</app-loader>

    <ng-container *ngIf="!loading && !productsFile">
        <div class="row  justify-content-md-center">
            <div class="col-md-4">
                <div class="custom-file">
                    <input type="file" id="products-file" class="custom-file-input" (change)="fileChanged($event)">
                    <label class="custom-file-label" for="products-file" i18n>Choose file</label>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!loading && productsFile">
        <p *ngIf="productsFile">
            <ng-container i18n>File</ng-container>: <b>{{ productsFile.name }}</b>
            <span *ngIf="rows" class="badge  badge-primary  ml-2" i18n>{{ rows }} products</span>

            <button *ngIf="headers" (click)="resetFile()" class="btn  btn-sm  btn-danger  float-right" i18n>Reset</button>

            <button *ngIf="headers" (click)="saveMapping()" class="btn  btn-sm  btn-primary  ml-2  mr-4  float-right" [disabled]="!isKeySet() || saving">
                <ng-container *ngIf="saving" i18n>Saving mapping...</ng-container>
                <ng-container *ngIf="!saving" i18n>Save mapping</ng-container>
            </button>

            <select *ngIf="headers" id="product-import-mapping-id" class="form-control  form-control-sm  float-right" style="width: 210px;" [(ngModel)]="productImportMappingId" (change)="onProductImportMappingChanged()" name="product-import-mapping-id">
                <option [ngValue]="null" i18n>Load an existing mapping</option>
                <option *ngFor="let productImportMapping of productImportMappings" [ngValue]="productImportMapping.id">{{ productImportMapping.name }}</option>
            </select>

            <select *ngIf="headers" id="creditor-id" class="form-control  form-control-sm  float-right  mr-4" style="width: 190px;" [(ngModel)]="creditorId" name="creditor_id">
                <option [ngValue]="null" i18n>Select creditor for file</option>
                <option *ngFor="let creditor of creditors" [ngValue]="creditor.id">{{ creditor.name }}</option>
            </select>
        </p>

        <p *ngIf="!headers" class="text-muted" i18n>Loading...</p>

        <ng-container *ngIf="headers">
            <table class="table  table-sm  table-hover">
                <thead>
                    <tr>
                        <th i18n>Column</th>
                        <th i18n>Examples</th>
                        <th i18n>Matcher</th>
                        <th i18n>Mapping</th>
                    </tr>
                </thead>

                <tbody>
                    <ng-template ngFor let-header [ngForOf]="headers" let-i="index">
                        <tr>
                            <td>{{ header}}</td>
                            <td>
                                <ng-template ngFor let-example [ngForOf]="examples[i]">
                                    <span class="badge  badge-secondary  mr-2">{{ example }}</span>
                                </ng-template>
                            </td>
                            <td class="text-center">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" [(ngModel)]="productImportMappingColumns[i].is_key" (change)="onKeyChanged(i)" name="product-import-mapping-column-{{ i }}-is_key" [disabled]="!productImportMappingColumns[i].reference_id">
                                    </label>
                                </div>
                            </td>
                            <td>
                                <select id="product-import-mapping-column-{{ i }}-option" class="form-control  form-control-sm" [(ngModel)]="productImportMappingColumns[i].option" (change)="productImportMappingColumns[i].onOptionChanged()" name="product-import-mapping-column-{{ i }}-option">
                                    <option [ngValue]="null" i18n>-- Select product field --</option>
                                    <option *ngFor="let mappingOption of mappingOptions" [ngValue]="mappingOption.value">{{ mappingOption.name }}</option>
                                </select>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>

            <button (click)="import()" class="btn  btn-sm  btn-primary  float-right" [disabled]="!isKeySet() || importing">
                <ng-container *ngIf="importing" i18n>Importing...</ng-container>
                <ng-container *ngIf="!importing" i18n>Import</ng-container>
            </button>
        </ng-container>
    </ng-container>
</div>
