import * as dayjs from 'dayjs';
import {Categorizable, ProductCategory} from "./product-category";
import {Debtor, DebtorRelatable} from "./debtor";
import {Product, ProductRelatable} from "./product";
import {Creditor, CreditorRelatable} from "./creditor";
import {PriceGroup, PriceGroupRelatable} from "./price-group";

export class PriceAdjustmentRelation implements ProductRelatable, Categorizable, DebtorRelatable, CreditorRelatable, PriceGroupRelatable {

    id: string;
    price_adjustment_id: string;
    product_id: string;
    product_category_id: string;
    debtor_id: string;
    creditor_id: string;
    price_group_id: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    product: Product;
    product_category: ProductCategory;
    debtor: Debtor;
    creditor: Creditor;
    price_group: PriceGroup;

    constructor() {
    }

    static fromJSON(json: PriceAdjustmentRelationJSON | string): PriceAdjustmentRelation {
        if (typeof json === 'string') {
            return JSON.parse(json, PriceAdjustmentRelation.reviver);
        } else {
            let priceAdjustmentRelation = Object.create(PriceAdjustmentRelation.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                product: null,
                product_category: null,
                debtor: null,
                creditor: null,
                price_group: null
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'product' && json[key]) {
                    extra.product = Product.fromJSON(json[key]);
                }

                if (key === 'product_category' && json[key]) {
                    extra.product_category = ProductCategory.fromJSON(json[key]);
                }

                if (key === 'debtor' && json[key]) {
                    extra.debtor = Debtor.fromJSON(json[key]);
                }

                if (key === 'creditor' && json[key]) {
                    extra.creditor = Creditor.fromJSON(json[key]);
                }

                if (key === 'price_group' && json[key]) {
                    extra.price_group = PriceGroup.fromJSON(json[key]);
                }
            });

            return Object.assign(priceAdjustmentRelation, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? PriceAdjustmentRelation.fromJSON(value) : value;
    }

    addProduct(product: Product): void {
        this.product_id = product.id;
        this.product = product;
    }

    removeProduct(): void {
        this.product_id = null;
        this.product = null;
    }

    addCategory(productCategory: ProductCategory): void {
        this.product_category_id = productCategory.id;

        this.product_category = ProductCategory.fromJSON({
            id: productCategory.id,
            name: productCategory.name
        });
    }

    removeCategory(_ = null): void {
        this.product_category_id = null;

        this.product_category = null;
    }

    hasCategory(productCategory: ProductCategory): boolean {
        return this.product_category_id === productCategory.id;
    }

    addDebtor(debtor: Debtor): void {
        this.debtor_id = debtor.id;
        this.debtor = Debtor.fromJSON({
            id: debtor.id,
            name: debtor.name
        });
    }

    removeDebtor(): void {
        this.debtor_id = null;
        this.debtor = null;
    }

    addCreditor(creditor: Creditor): void {
        this.creditor_id = creditor.id;
        this.creditor = Creditor.fromJSON({
            id: creditor.id,
            name: creditor.name
        });
    }

    removeCreditor(): void {
        this.creditor_id = null;
        this.creditor = null;
    }

    addPriceGroup(priceGroup: PriceGroup): void {
        this.price_group_id = priceGroup.id;
        this.price_group = PriceGroup.fromJSON({
            id: priceGroup.id,
            name: priceGroup.name
        });
    }

    removePriceGroup(): void {
        this.price_group_id = null;
        this.price_group = null;
    }
}

// A representation of PriceAdjustmentRelation data that can be converted to
// and from JSON without being altered.
interface PriceAdjustmentRelationJSON {
    id?: string;
    price_adjustment_id?: string;
    product_id?: string;
    product_category_id?: string;
    debtor_id?: string;
    creditor_id?: string;
    price_group_id?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
