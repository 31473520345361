<div class="pb-5">
    <h1 class="heading">
        <ng-container i18n>Farm fields</ng-container>
        <small *ngIf="loading && fields" class="text-muted" i18n>Loading...</small>
    </h1>


    <div class="row  mb-4" *ngIf="creditor">
        <div class="col-12  col-md-6">
            <div class="data-board">
                <p>
                    <strong>{{creditor?.name}}</strong><br/>
                </p>
            </div>
        </div>
    </div>

    <app-loader *ngIf="!fields || (loading && fields)" [useOverlayLoader]="loading && fields ? true : false" i18n>
        Loading...
    </app-loader>

    <app-table-helper>

        <div class="form-group">
            <select class="form-control  form-control-sm" name="order-type-filter" style="width: 150px;"
                    [(ngModel)]="filter.year" (change)="loadFields()" [disabled]="loading">
                <option [ngValue]="2021">2021</option>
                <option [ngValue]="2020">2020</option>
                <option [ngValue]="2019">2019</option>
            </select>
        </div>
        <div class="form-group">
            <select class="form-control  form-control-sm" name="order-type-filter" style="width: 150px;"
                    [(ngModel)]="filter.breed" (change)="loadFields()" [disabled]="loading">
                <option i18n [ngValue]="null">All species</option>
                <option *ngFor="let breed  of breeds" [ngValue]="breed">{{breed}}</option>
            </select>
        </div>
        <div class="mb-3">
            <button (click)="loadFields()" class="btn  btn-sm  btn-secondary  ml-2" style="transform: translateY(-1px);"
                    [disabled]="loading" i18n>Load
            </button>
        </div>
    </app-table-helper>

    <div class="mb-3">
        <div>

            <app-table *ngIf="fields" [autoSizeLastColumn]="true">
                <thead>
                <tr>
                    <th i18n>Farm field</th>
                    <ng-container *ngFor="let presetField of productFieldPresets.product_fields">
                        <th [ngClass]="{'text-right': presetField.type ==='number'}">{{presetField.name}}</th>
                    </ng-container>
                    <th class="text-right">Hkg</th>
                    <th i18n>Deliveries</th>
                    <th class="text-right" i18n>Avg. Hkg</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                <ng-template ngFor let-field [ngForOf]="fields">
                    <tr>
                        <td>
                            {{field.field_reference}}
                        </td>
                        <ng-container *ngFor="let presetField of productFieldPresets.product_fields">
                            <td [ngClass]="{'text-right': presetField.type ==='number'}">
                                {{presetField.type === 'number' ? (field.getFieldValueForId(presetField.id)| number:'1.2-2':'da') : field.getFieldValueForId(presetField.id)}}
                            </td>
                        </ng-container>
                        <td class="text-right">{{field.sum_hkg | number:'1.2-2':'da'}}</td>
                        <td>{{field.number_deliveries}}</td>
                        <td class="text-right">{{field.avg_hkg | number:'1.2-2':'da'}}</td>
                        <td><a routerLink="/products" [queryParams]="{
limit: -1,
fieldValue: field.field_reference,
fieldId:'23ead9f5-457c-421e-8d83-751190a16df3',
creditorId: creditorId,
categoryId: 'a2c21341-b893-4719-8d3e-4ffc0390675c'
}"
                               i18n>Farm fields details</a>
                        </td>
                    </tr>
                </ng-template>
                </tbody>
                <tfoot>
                <ng-template ngFor let-field [ngForOf]="sumFields">
                    <tr style="border-top: 2px solid #000000!important;">

                        <th>Sum</th>
                        <ng-container *ngFor="let presetField of productFieldPresets.product_fields">
                            <th></th>
                        </ng-container>
                        <th class="text-right"><strong>{{field.sum_hkg | number:'1.2-2':'da'}}</strong></th>
                        <th><strong>{{field.number_deliveries}}</strong></th>
                        <th class="text-right"><strong>{{field.avg_hkg | number:'1.2-2':'da'}}</strong></th>
                        <th></th>
                    </tr>
                </ng-template>
                </tfoot>
            </app-table>
        </div>
    </div>
</div>
