<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Select debtor</h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!debtors" class="text-muted" i18n>Loading...</p>

            <div *ngIf="debtors">
                <div class="form-group">
                    <label for="debtor" i18n>Debtor</label>
                    <select id="debtor" class="form-control  form-control-sm" [(ngModel)]="debtor" name="debtor" (change)="debtorChanged()">
                        <option [ngValue]="null" i18n>-- Select debtor --</option>
                        <option *ngFor="let debtor of debtors" [ngValue]="debtor">{{ debtor.name }}</option>
                    </select>
                </div>
            </div>

            <ng-container *ngIf="debtor">
                <hr>

                <ng-template ngFor let-debtorContact [ngForOf]="debtor.contacts">
                    <button *ngIf="referenceContact !== debtorContact" (click)="selectReferenceContact(debtorContact)" class="btn  btn-sm  btn-secondary  ml-2  float-right">Select contact</button>
                    <button *ngIf="referenceContact === debtorContact" (click)="deselectReferenceContact()" class="btn  btn-sm  btn-primary  ml-2  float-right">Deselect contact</button>

                    <button *ngIf="deliveryContact !== debtorContact" (click)="selectDeliveryContact(debtorContact)" class="btn  btn-sm  btn-secondary  ml-2  float-right">Select delivery</button>
                    <button *ngIf="deliveryContact === debtorContact" (click)="deselectDeliveryContact()" class="btn  btn-sm  btn-primary  ml-2  float-right">Deselect delivery</button>

                    <h3>{{ debtorContact.name }} <small>{{ debtorContact.nickname }}</small></h3>

                    <p>
                        Full name: {{ debtorContact.full_name }}<br>
                        Address: {{ debtorContact.address }}<br>
                        Zip: {{ debtorContact.zip }}<br>
                        City: {{ debtorContact.city }}<br>
                        <ng-template ngFor let-contactField [ngForOf]="debtorContact.contact_fields">
                            {{ contactField.name }}: {{ contactField._joinData.value }}<br>
                        </ng-template>
                    </p>

                    <hr>
                </ng-template>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="!debtors" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!debtors || !debtor" i18n>Select</button>
    </div>
</div>
