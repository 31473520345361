import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {ArticlesService} from '../../../services/articles.service';
import {Article} from '../../../services/article';
import {Observable} from "rxjs";

@Component({
    selector: 'app-helms-dashboard',
    templateUrl: './helms-dashboard.component.html',
    styleUrls: ['./helms-dashboard.component.scss'],
    providers: [ArticlesService]
})
export class HelmsDashboardComponent implements OnInit {

    private authedPermissions: Observable<any[]>;

    isAdmin = false;

    public environment;
    public articles: Article[];

    constructor(public authService: AuthService, public articlesService: ArticlesService) {
        this.authedPermissions = this.authService.authedPermissionsDataStream();
        this.environment = environment;
    }

    ngOnInit(): void {
        this.authedPermissions.subscribe((_) => {
            this.isAdmin = this.authService.hasPermission('admin');
        });

        this.articlesService.getArticles(1, 8, true).subscribe((response) => {
            this.articles = response.data;
        });
    }
}
