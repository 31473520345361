import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UsersService} from "../services/users.service";
import {User} from "../services/user";
import {TokenService} from "../services/token.service";
import {UserEditModalComponent} from "../user-edit-modal/user-edit-modal.component";
import {User2FAModalComponent} from "../user-2fa-modal/user-2fa-modal.component";
import {CreditorsService} from "../services/creditors.service";
import {DebtorsService} from "../services/debtors.service";
import {Debtor} from "../services/debtor";
import {Creditor} from "../services/creditor";

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    providers: [UsersService, CreditorsService, DebtorsService]
})
export class UserComponent implements OnInit {

    id: string;

    user: User;

    saving = false;

    debtors: Debtor[];
    creditors : Creditor[];

    constructor(public usersService: UsersService,
                public tokenService: TokenService,
                public creditorsService: CreditorsService,
                public debtorsService: DebtorsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.id = this.tokenService.getToken().getUserId();

        this.load();
    }

    public load() {
        this.user = null;

        this.usersService.getUser(this.id, true).subscribe(response => {
            this.user = response.data;
            this.loadExtras();
        });
    }

    public edit() {
        let self = this;

        const modalRef = this.ngbModal.open(UserEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.user = this.user;
        modalRef.componentInstance.isProfileEdit = true;
        modalRef.componentInstance.saved = () => {
            self.load();
        };
    }

    public user2FAModal(): void {
        let self = this;

        const modalRef = this.ngbModal.open(User2FAModalComponent, {size: 'lg'});
        modalRef.componentInstance.user = this.user;
        modalRef.componentInstance.saved = () => {
            self.load();
        }
    }

    public loadExtras() {
        if (this.user.group.hasPermission('creditors')) {
            this.creditorsService.getCreditors(1, -1, false, true, null).subscribe(creditors => {
                this.creditors  = creditors.data;
            });
        }
        if (this.user.group.hasPermission('debtors')) {
            this.debtorsService.getDebtors(1, -1, false, false, false, true, false).subscribe(debtors => {
                this.debtors = debtors.data;
            });
        }
    }
}
