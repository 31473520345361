import {Component, OnInit, Input, HostListener, ViewChild, AfterViewInit, ElementRef} from '@angular/core';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, AfterViewInit {

    public hasXScroll = false;

    @ViewChild('tableWrapper') tableWrapper: ElementRef;
    @Input() autoSizeLastColumn: boolean;
    @Input() autoSizeFirstColumn: boolean;
    @Input() noMargin: boolean;
    @Input() additionalClasses: undefined | string;

    @HostListener('window:resize', ['$event'])
    onResize = () => {
        Promise.resolve(null).then(() => {
            if (this.tableWrapper.nativeElement.scrollWidth > this.tableWrapper.nativeElement.clientWidth){
                this.hasXScroll = true;
            } else {
                this.hasXScroll = false;
            }
        });
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.onResize();
    }

}
