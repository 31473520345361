import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProductionLinesComponent} from "./production-lines/production-lines.component";
import {ProductionLineSlotsComponent} from "./production-line-slots/production-line-slots.component";

const routes: Routes = [{ path: '', component: ProductionLinesComponent },{path: ':productionLineId/slots', component:ProductionLineSlotsComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelmsProductionLinesRoutingModule { }
