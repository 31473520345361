import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderField} from '../services/order-field';
import {OrderFieldsService} from '../services/order-fields.service';
import {OrderFieldEditModalComponent} from '../order-field-edit-modal/order-field-edit-modal.component';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {LanguagesService} from '../services/languages.service';

@Component({
    selector: 'app-order-fields',
    templateUrl: './order-fields.component.html',
    styleUrls: ['./order-fields.component.sass'],
    providers: [OrderFieldsService, LanguagesService]
})
export class OrderFieldsComponent implements OnInit {

    orderFields: OrderField[];

    loading = false;
    faExclamation = faExclamationCircle;
    missingLocalizations = [];

    constructor(public orderFieldsService: OrderFieldsService,
                public ngbModal: NgbModal,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this.languagesService.getLanguages().subscribe(() => {
            this.load();
        });
    }

    load(): void {
        this.loading = true;

        this.orderFieldsService.getOrderFields().subscribe(response => {
            this.orderFields = response.data;
            this.orderFields.forEach((orderField) => {
                this.missingLocalizations.push(orderField._translations.checkMissingLocalizations(this.languagesService.languages));
            });

            this.loading = false;
        });
    }

    add() {
        const self = this;

        const modalRef = this.ngbModal.open(OrderFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function() {
            self.load();
        };
    }

    edit(orderField: OrderField, index: number) {
        const self = this;

        const modalRef = this.ngbModal.open(OrderFieldEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.orderField = orderField;
        modalRef.componentInstance.missingLocalizations = this.missingLocalizations[index];
        modalRef.componentInstance.saved = function() {
            self.load();
        };
    }

    delete(orderField: OrderField) {
        if (confirm($localize `Are you sure?\n\nThe order line field will be deleted`)) {
            this.loading = true;

            this.orderFieldsService.deleteOrderField(orderField).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }
}
