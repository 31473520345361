<div class="articles    app__component" [ngClass]="{'container':!embedded}">
    <h1 class="heading" i18n *ngIf="!embedded">Articles</h1>

    <app-loader *ngIf="!articles || (loading && articles)" [useOverlayLoader]="!!(loading && articles)" i18n>
        Loading...
    </app-loader>

    <p *ngIf="!embedded && articles && authService.hasPermission('admin')">
        <a class="btn  btn-primary  button  button--add-to-table" routerLink="/articles/add" i18n>Add article</a>
    </p>

    <app-table-helper *ngIf="articles && !embedded">
        <div>
            <input type="number" min="1" class="form-control  form-control-sm" [(ngModel)]="limit" name="limit"
                   style="display: inline; width: 75px;" [disabled]="loading">
            <button (click)="load(page)" class="btn  btn-sm  btn-secondary  ml-2" style="transform: translateY(-1px);"
                    [disabled]="loading" i18n>Load
            </button>
        </div>
    </app-table-helper>

    <app-table *ngIf="articles" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th i18n>Title</th>
            <th i18n>User</th>
            <th class="text-right" i18n>Date</th>
            <th></th>
            <th></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-article [ngForOf]="articles">
            <tr>
                <td>
                    <a routerLink="/articles/{{ article.id }}">{{ article.title }}</a>
                </td>
                <td>{{ article.user.name }}</td>
                <td class="text-right">{{ article.created.format('YYYY-MM-DD HH:mm:ss')}}</td>
                <td>
                    <a routerLink="/articles/{{ article.id }}" i18n>Edit</a>
                    <a (click)="deleteArticle(article)" class="text-danger" id="delete" i18n>Delete</a>
                </td>
                <td>
                    <a (click)="updateArticle(article)" class="text-danger" id="update">
                        <span *ngIf="article.is_active" i18n>Unpublish</span>
                        <span *ngIf="!article.is_active" i18n>Publish</span>
                    </a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="articles && count > articles.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10"
                        [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'"
                        [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
