import * as dayjs from 'dayjs';

export class Date {

    id: string;
    name: string;
    is_creation: boolean;
    is_due: boolean;
    is_shipped: boolean;
    is_completed: boolean;
    is_invoiced: boolean;
    is_production: boolean;
    is_editable: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    _joinData;

    constructor() {
    }

    static fromJSON(json: DateJSON | string): Date {
        if (typeof json === 'string') {
            return JSON.parse(json, Date.reviver);
        } else {
            let date = Object.create(Date.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _joinData: null
            }

            Object.keys(json).forEach((key, _) => {
                if (key === '_joinData' && json[key]) {
                    extra._joinData = {};

                    Object.keys(json[key]).forEach((keyKey, _) => {
                        extra._joinData[keyKey] = keyKey === 'time' ? dayjs(json[key][keyKey]) : json[key][keyKey];
                    });
                }
            });

            return Object.assign(date, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Date.fromJSON(value) : value;
    }
}

// A representation of Date data that can be converted to
// and from JSON without being altered.
interface DateJSON {
    id?: string;
    name?: string;
    is_creation?: boolean;
    is_due?: boolean;
    is_shipped?: boolean;
    is_completed?: boolean;
    is_invoiced?: boolean;
    is_production?: boolean;
    is_editable?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    _joinData?;
}
