import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {faMapPin} from "@fortawesome/free-solid-svg-icons";
import {Contact} from "../../../services/contact";
import {DebtorsService} from "../../../services/debtors.service";
import {CreditorsService} from "../../../services/creditors.service";
import {Order} from "../../../services/order";
import {TenFourOrderService} from "../../../services/themes/10-4/ten-four-order.service";

@Component({
    selector: 'app-change-order-delivery-contact',
    templateUrl: './change-order-delivery-contact.component.html',
    styleUrls: ['./change-order-delivery-contact.component.scss']
})
export class ChangeOrderDeliveryContactComponent implements OnInit {

    pinIcon = faMapPin;

    currentDeliveryContact: Contact | null;
    showContactSearchResults: boolean;
    contactSearchResult: Contact[] | null;
    filteredSearchResults: Contact[] | null;
    saving: boolean;
    _order: Order | null | undefined;

    @Output() didChangeDeliveryContact = new EventEmitter<boolean>();


    constructor(public orderService: TenFourOrderService, public debtorService: DebtorsService, public creditorService: CreditorsService) {
        this.showContactSearchResults = false;
        this.contactSearchResult = null;
        this.saving = false;
        this.currentDeliveryContact = null;
    }

    ngOnInit(): void {
        this.orderService.currentOrder.subscribe(response => {
            this._order = response;
            this.currentDeliveryContact = this._order.contacts.find((c) => {
                return c.name === 'Delivery';
            });
        });
        if (this._order.isCreditor()) {
            this.creditorService.getCreditor(this._order.creditor_id, false, true, false, false).subscribe(response => {
                this.contactSearchResult = response.data.contacts;
            });
        } else {
            this.debtorService.getDebtor(this._order.debtor_id, {
                withContacts: true
            }).subscribe(response => {
                this.contactSearchResult = response.data.contacts;
            });
        }
    }

    onClick(newContact: Contact): void {
        this.saving = true;
        this._order = this.orderService.setNewContact(this._order, newContact, 'Delivery');
        if (this._order.id){
            this.orderService.updateOrder(this._order).subscribe((response) => {
                this.saving = false;
                this.filteredSearchResults = [];
                this.showContactSearchResults = false;
                this.orderService.setOrder(response.data);
                this.didChangeDeliveryContact.emit(true);
            });
        } else {
            this.saving = false;
            this.filteredSearchResults = [];
            this.showContactSearchResults = false;
            this.orderService.setOrder(this._order);
            this.didChangeDeliveryContact.emit(true);
        }
    }

    onContactSearchInputChange(val: string): void {
        this.filteredSearchResults = null;

        if (val.length > 0) {
            this.showContactSearchResults = true;

            this.filteredSearchResults = this.contactSearchResult.filter((c) => {
                if (c.full_name === this.currentDeliveryContact.full_name) {
                    return false;
                }

                if (c.full_name.toLowerCase().includes(val.toLowerCase()) || c.address.toLowerCase().includes(val.toLowerCase()) || c.name.toLowerCase().includes(val.toLowerCase())) {
                    return c;
                }

                return false;
            });
        } else {
            this.showContactSearchResults = false;
        }
    }

}
