<div class="container">
    <h1 class="heading">
        <ng-container *ngIf="!priceAdjustmentId" i18n>Add price adjustment</ng-container>
        <ng-container *ngIf="priceAdjustmentId" i18n>Edit price adjustment</ng-container>
        <small>
            <span *ngIf="_priceAdjustment && !_priceAdjustment.isActive()" class="badge  badge-danger  ml-2" i18n>Inactive</span>
        </small>
    </h1>

    <app-loader *ngIf="!_priceAdjustment" class="text-muted" i18n>Loading...</app-loader>

    <div *ngIf="_priceAdjustment">
        <form>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="price-adjustment-name" i18n>Name</label>
                        <input type="text" id="price-adjustment-name" class="form-control" [(ngModel)]="_priceAdjustment.name" name="name">
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label i18n>Date start</label>

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="date_start" i18n-placeholder placeholder="Start date" [(ngModel)]="_priceAdjustment.date_start" ngbDatepicker #dateStart="ngbDatepicker">

                            <div class="input-group-append">
                                <button (click)="dateStart.toggle()" class="btn  btn-outline-secondary" type="button" i18n>Date picker</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label i18n>Date end</label>

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="date_end" i18n-placeholder placeholder="End date" [(ngModel)]="_priceAdjustment.date_end" ngbDatepicker #dateEnd="ngbDatepicker">

                            <div class="input-group-append">
                                <button (click)="dateEnd.toggle()" class="btn  btn-outline-secondary" type="button" i18n>Date picker</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="price-adjustment-pct" i18n>Adjustment percentage</label>
                        <input type="number" id="price-adjustment-pct" class="form-control" [(ngModel)]="_priceAdjustment.pct" name="pct">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="price-adjustment-pct" i18n>Adjustment amount</label>
                        <input type="number" id="price-adjustment-amount" class="form-control" [(ngModel)]="_priceAdjustment.amount" name="amount">
                    </div>
                </div> <div class="col-md-4">
                    <div class="form-group">
                        <label for="price-adjustment-pct" i18n>Priority</label>
                        <input type="number" id="price-adjustment-sort" class="form-control" [(ngModel)]="_priceAdjustment.sort" name="sort">
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_priceAdjustment.for_cost_price" name="for_cost_price"> <ng-container i18n>Cost price adjustment</ng-container>
                        </label>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="_priceAdjustment.is_override" name="is_override"> <ng-container i18n>Override other adjustments</ng-container>
                        </label>
                    </div>
                </div>
            </div>

            <app-table [autoSizeLastColumn]="true">
                <thead>
                    <tr>
                        <th i18n>Product</th>
                        <th i18n>Product category</th>
                        <th *ngIf="!_priceAdjustment.for_cost_price" i18n>Limit to debtor</th>
                        <th i18n>Limit to creditor</th>
                        <th i18n>Limit to price group</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-priceAdjustmentRelation [ngForOf]="_priceAdjustment.price_adjustment_relations" let-i="index">
                        <tr>
                            <td>
                                <span *ngIf="priceAdjustmentRelation.product_id">{{ priceAdjustmentRelation.product.name }}</span>

                                <a *ngIf="!priceAdjustmentRelation.product_id" href="javascript:" (click)="addProduct(priceAdjustmentRelation)" i18n>Add</a>
                                <a *ngIf="priceAdjustmentRelation.product_id" href="javascript:" (click)="priceAdjustmentRelation.removeProduct()" class="text-danger  ml-2" i18n>Delete</a>
                            </td>
                            <td>
                                <span *ngIf="priceAdjustmentRelation.product_category_id">
                                    <span class="badge  badge-secondary  mr-2">{{ priceAdjustmentRelation.product_category.name }}</span>
                                </span>

                                <a *ngIf="!priceAdjustmentRelation.product_category_id" href="javascript:" (click)="addCategory(priceAdjustmentRelation)" i18n>Add</a>
                                <a *ngIf="priceAdjustmentRelation.product_category_id" href="javascript:" (click)="priceAdjustmentRelation.removeCategory()" class="text-danger  ml-2" i18n>Delete</a>
                            </td>
                            <td *ngIf="!_priceAdjustment.for_cost_price">
                                <span *ngIf="priceAdjustmentRelation.debtor_id">{{ priceAdjustmentRelation.debtor.name }}</span>

                                <a *ngIf="!priceAdjustmentRelation.debtor_id" href="javascript:" (click)="addDebtor(priceAdjustmentRelation)" i18n>Add</a>
                                <a *ngIf="priceAdjustmentRelation.debtor_id" href="javascript:" (click)="priceAdjustmentRelation.removeDebtor()" class="text-danger  ml-2" i18n>Delete</a>
                            </td>
                            <td>
                                <span *ngIf="priceAdjustmentRelation.creditor_id">{{ priceAdjustmentRelation.creditor.name }}</span>

                                <a *ngIf="!priceAdjustmentRelation.creditor_id" href="javascript:" (click)="addCreditor(priceAdjustmentRelation)" i18n>Add</a>
                                <a *ngIf="priceAdjustmentRelation.creditor_id" href="javascript:" (click)="priceAdjustmentRelation.removeCreditor()" class="text-danger  ml-2" i18n>Delete</a>
                            </td>
                            <td>
                                <span *ngIf="priceAdjustmentRelation.price_group_id">{{ priceAdjustmentRelation.price_group.name }}</span>

                                <a *ngIf="!priceAdjustmentRelation.price_group_id" href="javascript:" (click)="addPriceGroup(priceAdjustmentRelation)" i18n>Add</a>
                                <a *ngIf="priceAdjustmentRelation.price_group_id" href="javascript:" (click)="priceAdjustmentRelation.removePriceGroup()" class="text-danger  ml-2" i18n>Delete</a>
                            </td>
                            <td class="text-right"><a href="javascript:" (click)="_priceAdjustment.price_adjustment_relations.splice(i, 1)" class="text-danger" i18n>Delete relation</a></td>
                        </tr>
                    </ng-template>
                </tbody>
            </app-table>

            <button (click)="addPriceAdjustmentRelation()" class="btn  btn-sm  btn-primary" i18n>Add price adjustment relation</button>
        </form>

        <div class="text-right">
            <button (click)="save()" class="btn  btn-success" [disabled]="saving">
                <ng-container *ngIf="saving" i18n>Saving...</ng-container>
                <ng-container *ngIf="!saving" i18n>Save</ng-container>
            </button>
        </div>
    </div>
</div>
