<div class="container app__component">
    <h1 class="heading">
        <ng-container i18n>Order states</ng-container>
        <small *ngIf="loading && orderStates" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!orderStates" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="orderStates">
        <a class="btn btn-primary button button--add-to-table" href="javascript:" (click)="add()" i18n>Add order state</a>
    </p>

    <app-table [autoSizeLastColumn]="true" *ngIf="orderStates">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th i18n>Features</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody [sortablejs]="orderStates" [sortablejsOptions]="{ handle: '.sortablejs--handle' }">
            <ng-template ngFor let-orderState [ngForOf]="orderStates" let-i="index">
                <tr>
                    <td>
                        <fa-icon [ngbTooltip]="'Localizations missing'" *ngIf="!missingLocalizations[i].complete" [icon]="faExclamation"></fa-icon>
                        <span class="sortablejs--handle  mr-2  text-muted" style="cursor: move;">=</span>
                        {{ orderState.name }}
                    </td>
                    <td>
                        <span *ngIf="orderState.is_default" class="badge  badge-secondary  mr-2" i18n>Default</span>
                        <span *ngIf="orderState.is_frozen" class="badge  badge-secondary" i18n>Frozen</span>
                        <span *ngIf="orderState.is_order_lines_frozen" class="badge  badge-secondary" i18n>Frozen order lines</span>
                        <span *ngIf="orderState.is_completed" class="badge  badge-secondary" i18n>Completed</span>
                        <span *ngIf="orderState.is_products_reserved" class="badge  badge-secondary" i18n>Products Reserved</span>
                        <span *ngIf="orderState.is_shipped" class="badge  badge-secondary" i18n>Order is shipped</span>
                    </td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(orderState, i)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(orderState)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>

    <button *ngIf="orderStates" (click)="saveSorting()" class="btn  btn-sm  btn-primary" [disabled]="loading" i18n>Save sorting</button>
</div>
