<ng-container *ngIf="productionLines">
    <h1 class="heading">
        <span i18n>Production Lines</span>
        <a *ngIf="authService.hasPermission('admin')" href="javascript:" (click)="add()" class="float-right" i18n>Add</a>
    </h1>

    <div class="row">
        <div *ngFor="let productionLine of productionLines" class="col-12  col-md-6  mb-4">
            <div class="card  shadow" style="height: 100%">
                <div class="card-header">
                    <strong>{{productionLine.name}}</strong>
                    <a *ngIf="authService.hasPermission('admin')" href="javascript:" (click)="edit(productionLine)" class="float-right" i18n>Edit</a>
                </div>
                <div class="card-body">
                    <div class="row  small">
                        <strong class="col-4" i18n>Machines: </strong>
                        <div class="col-8">
                            <span class="badge  badge-secondary  mr-2" *ngFor="let product of productionLine.products">
                                {{product.name}}
                            </span>
                            <span *ngIf="productionLine.products.length == 0" class="text-muted" i18n>None</span>
                        </div>
                    </div>
                    <ng-container *ngIf="authService.hasPermission('admin')">

                        <div class="row  small mt-3">
                            <strong class="col-8" i18n>Active slots: </strong>
                            <span class="col-4">{{ productionLine.slots_count }}</span>
                        </div>
                        <div class="row  small">
                            <strong class="col-8" i18n>Solgte: </strong>
                            <span class="col-4">{{ productionLine.slots_sold_count }}</span>
                        </div>
                        <div class="row  small">
                            <strong class="col-8" i18n>Ledige pladser: </strong>
                            <span class="col-4">{{ productionLine.slots_free_count }}</span>
                        </div>
                    </ng-container>
                    <div class="row  small">
                        <strong class="col-8" i18n>Next available slot: </strong>
                        <span class="col-4">
                            <span *ngIf="productionLine.open_from && productionLine.open_from.isValid()"><ng-container i18n>Week</ng-container> {{ productionLine.open_from.isoWeek() }} - {{ productionLine.open_from.isoWeekYear() }} </span>
                            <span *ngIf="!productionLine.open_from || !productionLine.open_from.isValid()" class="text-muted">Leveringsuge ukendt</span>
                        </span>
                    </div>
                </div>
                <div class="card-footer" *ngIf="authService.hasPermission('admin')">
                    <a [routerLink]="[productionLine.id,'slots']" class="btn  btn-sm  btn-primary  float-right" i18n>Show Slots</a>
                </div>
            </div>
        </div>

        <div class="col-12">
            <small>Vær opmærksom på at ved lange leveringstider kan Helms kontaktes for mulighed for at bytte på produktions pladser</small>
        </div>
    </div>
</ng-container>
