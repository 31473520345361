import {Component, OnInit} from '@angular/core';
import {ProductsService} from "../../../services/products.service";
import {ProductFieldPresetsService} from "../../../services/product-field-presets.service";
import {mergeMap} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import {ProductFieldPreset} from "../../../services/product-field-preset";
import {Product} from "../../../services/product";

@Component({
    selector: 'app-thorsens-delivery-details',
    templateUrl: './thorsens-delivery-details.component.html',
    styleUrls: ['./thorsens-delivery-details.component.scss'],
    providers: [ProductsService, ProductFieldPresetsService]
})
export class ThorsensDeliveryDetailsComponent implements OnInit {

    loading: boolean = true;
    productId: string;
    product: Product;
    productFieldPresets: ProductFieldPreset | null = null;
    analysisFieldPresets: ProductFieldPreset | null = null;
    analysisFieldsDetailsPresets: ProductFieldPreset | null = null;

    constructor(public productsService: ProductsService, public productFieldPresetsService: ProductFieldPresetsService) {
    }

    ngOnInit(): void {
        this.load(1);
    }

    load(page: number): void {

        this.loading = true;

        this.productsService.getProduct(this.productId, false, false, false, false,
            true,
            true,
            null,
            false,
            true,
            false,
            false,
        ).pipe(
            mergeMap((result1) => {

                let deliveryFieldsPresetid;
                let analysysFieldsPresetId;
                let analysisFieldsDetailsPresetid;
                this.product = result1.data;
                const firstCategory = result1.data.product_categories[0];
                if (firstCategory) {
                    // depending on the category, we find the matching presets for delivery and analysis products
                    switch (firstCategory.id) {
                        case 'e70fbf95-79c3-4f37-a5d4-e85740d58cb6': // OUT
                            deliveryFieldsPresetid = '110db364-36dd-4929-a75f-e3433a20575e';
                            analysysFieldsPresetId = '768c75b0-6f9d-47f4-bbce-fe7fd4d0a3ec';
                            analysisFieldsDetailsPresetid = '1ad12b79-deb2-4988-9da9-e8e36f091743';
                            break;

                        case '00b99997-5e9b-4ee5-8f6c-75c19d2c8b73': // IN
                            deliveryFieldsPresetid = 'db9ec72d-8280-4da0-bee8-c3c868207662';
                            analysysFieldsPresetId = 'e34fa64a-d720-4604-89ce-0774179b2066';
                            analysisFieldsDetailsPresetid = '1ad12b79-deb2-4988-9da9-e8e36f091743';
                            break;

                        default:
                            deliveryFieldsPresetid = '58d92304-d6b4-43e5-ac46-5d5180b701eb';
                            analysysFieldsPresetId = 'e34fa64a-d720-4604-89ce-0774179b2066';
                            analysisFieldsDetailsPresetid = '1ad12b79-deb2-4988-9da9-e8e36f091743';
                            break;

                    }
                } else {
                    deliveryFieldsPresetid = '58d92304-d6b4-43e5-ac46-5d5180b701eb';
                    analysysFieldsPresetId = 'e34fa64a-d720-4604-89ce-0774179b2066';
                    analysisFieldsDetailsPresetid = '1ad12b79-deb2-4988-9da9-e8e36f091743';
                    // return of(false);
                }
                return forkJoin(
                    [
                        this.productFieldPresetsService.getProductFieldPreset(deliveryFieldsPresetid),
                        this.productFieldPresetsService.getProductFieldPreset(analysysFieldsPresetId),
                        this.productFieldPresetsService.getProductFieldPreset(analysisFieldsDetailsPresetid)
                    ]
                );
            })).subscribe((results) => {

                if (results[0]) {
                    this.productFieldPresets = results[0].data;
                }
                if (results[1]) {
                    this.analysisFieldPresets = results[1].data;
                }
                if (results[2]) {
                    this.analysisFieldsDetailsPresets = results[2].data;
                }
                this.loading = false;
            }
        );

    }
}
