import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {OrderState} from "./order-state";

@Injectable()
export class OrderStatesService {

    protected url = environment.apiBase + 'order-states';

    constructor(public apiService: ApiService) {
    }

    getOrderState(id: string): Observable<{ data: OrderState, count: number, perPage: number }> {
        return this.apiService.get<OrderState>(this.url + '/' + id, true, OrderState);
    }

    getOrderStates(): Observable<{ data: OrderState[], count: number, perPage: number }> {
        return this.apiService.get<OrderState[]>(this.url, true, OrderState);
    }

    addOrderState(order: OrderState): Observable<{ data: OrderState, count: number, perPage: number }> {
        return this.apiService.post<OrderState>(this.url, order, true, OrderState);
    }

    updateOrderState(order: OrderState): Observable<{ data: OrderState, count: number, perPage: number }> {
        return this.apiService.patch<OrderState>(this.url + '/' + order.id, order, true, OrderState);
    }

    updateOrderStates(orderStates: OrderState[]): Observable<{ data: OrderState[], count: number, perPage: number }> {
        return this.apiService.patch<OrderState[]>(this.url, orderStates, true, OrderState);
    }

    deleteOrderState(order: OrderState): Observable<{ data: OrderState, count: number, perPage: number }> {
        return this.apiService.delete<OrderState>(this.url + '/' + order.id, true, OrderState);
    }
}
