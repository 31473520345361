<div class="container app__component">
    <h1 class="heading">
        <ng-container i18n>Groups</ng-container>
        <small *ngIf="loading && groups" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!groups" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="groups">
        <a class="btn btn-primary button button--add-to-table" href="javascript:" (click)="add()" i18n>Add group</a>
    </p>

    <app-table *ngIf="groups" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th i18n>Name</th>
            <th i18n>Permissions</th>
            <th i18n>Users</th>
            <th style="width: 60px;"></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-group [ngForOf]="groups">
            <tr>
                <td>
                    {{ group.name }}
                    <span *ngIf="group.default" class="badge  badge-secondary" i18n>Default</span>
                </td>
                <td>
                    <ng-template ngFor let-groupPermission [ngForOf]="group.group_permissions">
                        <span class="badge  badge-primary  mr-2">{{ groupPermission.permission }}</span>
                    </ng-template>
                </td>
                <td>
                    <span *ngIf="group.users.length === 0" class="text-muted" i18n>None</span>
                    <b *ngIf="group.users.length > 0">{{ group.users.length }}</b>
                </td>
                <td class="text-right">
                    <a href="javascript:" (click)="edit(group)" i18n>Edit</a>
                    <a *ngIf="group.users.length === 0" href="javascript:" (click)="delete(group)" class="text-danger" i18n>Delete</a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>
</div>
