import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';

export interface INav {
    title?: string;
    url?: any;
    queryParams?: any;
    seperator?: boolean;
    onClick?: (any) => any;
    children?: INav[];
    className?: string;
    permission?: string;
    icon?: any;
}

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent implements OnInit {

    @Input() navData: INav[];
    @Input() isSubMenu: boolean | undefined;
    @Input() title: string | undefined;
    @Input() permissions: string | undefined;

    @Output() shouldClose = new EventEmitter<boolean>();

    public navBarOpen: boolean;

    constructor(public authService: AuthService) {
        this.navBarOpen = false;
    }

    ngOnInit(): void {
    }

    toggleNavbar(event: Event): void {
        event.preventDefault();
        this.navBarOpen = !this.navBarOpen;
    }

    closeNavbar(value: boolean): void {
        this.navBarOpen = false;
        this.shouldClose.emit(value);
    }

    checkPermissions(permissionName?: string): boolean {
        if (!permissionName) {
            return true;
        } else if (permissionName === 'loggedIn') {
            return this.authService.loggedIn();
        } else {
            const permissions = permissionName.split(',');
            const result = permissions.filter((item) => {
                return this.authService.loggedIn() && this.authService.hasPermission(item.trim());
            });
            return result.length > 0;
        }
    }
}
