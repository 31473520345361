import {LocaleTranslation} from './locale-translation';

export class Translation {

    // tslint:disable-next-line:variable-name
    da_DK: LocaleTranslation;
    // tslint:disable-next-line:variable-name
    de_DE: LocaleTranslation;
    // tslint:disable-next-line:variable-name
    en_US: LocaleTranslation;
    // tslint:disable-next-line:variable-name
    fr_FR: LocaleTranslation;

    constructor() {
    }

    static fromJSON(json: TranslationJSON | string): Translation {
        if (typeof json === 'string') {
            return JSON.parse(json, Translation.reviver);
        } else {
            const translation = Object.create(Translation.prototype);

            const extra = {
                da_DK: null,
                de_DE: null,
                en_US: null,
                fr_FR: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (json[key]) {
                    extra[key] = LocaleTranslation.fromJSON(json[key]);
                }
            });

            return Object.assign(translation, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? Translation.fromJSON(value) : value;
    }

    checkMissingLocalizations(languages, attribute: string = 'name'): {languages: any[], complete: boolean} {
        const localization = {
            languages: [],
            complete: true,
        };
        languages.forEach((language) => {
            if (language.isPrimary) {
                localization.languages.push(true);
            } else {
                switch (attribute) {
                    case 'name':
                        if (this[language.locale]?.name) {
                            localization.languages.push(true);
                        } else {
                            localization.complete = false;
                            localization.languages.push(false);
                        }
                        break;
                    case 'body':
                        if (this[language.locale]?.body) {
                            localization.languages.push(true);
                        } else {
                            localization.complete = false;
                            localization.languages.push(false);
                        }
                        break;
                    case 'title':
                        if (this[language.locale]?.title) {
                            localization.languages.push(true);
                        } else {
                            localization.complete = false;
                            localization.languages.push(false);
                        }
                        break;
                }
            }
        });
        return localization;
    }
}
// A representation of Translation data that can be converted to
// and from JSON without being altered.
interface TranslationJSON {
}
