import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactType} from "../services/contact-type";
import {ContactTypesService} from "../services/contact-types.service";
import {ContactTypeEditModalComponent} from "../contact-type-edit-modal/contact-type-edit-modal.component";

@Component({
    selector: 'app-contact-types',
    templateUrl: './contact-types.component.html',
    styleUrls: ['./contact-types.component.sass'],
    providers: [ContactTypesService]
})
export class ContactTypesComponent implements OnInit {

    contactTypes: ContactType[];

    loading = false;

    constructor(public contactTypesService: ContactTypesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.contactTypesService.getContactTypes().subscribe(response => {
            this.contactTypes = response.data;

            this.loading = false;
        });
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(ContactTypeEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    edit(contactType: ContactType) {
        let self = this;

        const modalRef = this.ngbModal.open(ContactTypeEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.contactType = contactType;
        modalRef.componentInstance.saved = function () {
            self.load();
        }
    }

    delete(contactType: ContactType) {
        if (confirm($localize `Are you sure?\n\nThe contact type will be deleted`)) {
            this.loading = true;

            this.contactTypesService.deleteContactType(contactType).subscribe(_ => {
                this.loading = false;

                this.load();
            });
        }
    }

    saveSorting() {
        this.loading = true;

        let sorting = 0;
        let contactTypes = [];

        for (let contactType of this.contactTypes) {
            contactTypes.push(ContactType.fromJSON({
                id: contactType.id,
                sorting: sorting
            }));

            sorting++;
        }

        this.contactTypesService.updateContactTypes(contactTypes).subscribe(_ => {
            this.loading = false;
        });
    }
}
