import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProductsService} from "../services/products.service";
import {forkJoin} from "rxjs";
import {ProductFieldsService} from "../services/product-fields.service";
import {PriceGroupsService} from "../services/price-groups.service";
import {ReferencesService} from "../services/references.service";
import {ProductField} from "../services/product-field";
import {PriceGroup} from "../services/price-group";
import {Reference} from "../services/reference";
import {ProductImportMappingColumn} from "../services/product-import-mapping-column";
import {ProductImportMapping} from "../services/product-import-mapping";
import {ProductImportMappingsService} from "../services/product-import-mapping.service";
import {CreditorsService} from "../services/creditors.service";
import {Creditor} from "../services/creditor";

@Component({
    selector: 'app-product-import',
    templateUrl: './product-import.component.html',
    styleUrls: ['./product-import.component.sass'],
    providers: [ProductsService, ProductFieldsService, PriceGroupsService, ReferencesService, ProductImportMappingsService, CreditorsService]
})
export class ProductImportComponent implements OnInit {

    loading = false;
    saving = false;
    importing = false;

    productFields: ProductField[];
    priceGroups: PriceGroup[];
    references: Reference[];
    creditors: Creditor[];

    creditorId: string = null;

    productsFile: File = null;

    headers;
    examples;
    rows;

    productImportMappings: ProductImportMapping[];
    productImportMappingId = null;

    mappingOptions = [];

    productImportMappingColumns: ProductImportMappingColumn[] = [];

    constructor(public productService: ProductsService,
                public productFieldsService: ProductFieldsService,
                public priceGroupsService: PriceGroupsService,
                public referencesService: ReferencesService,
                public productImportMappingsService: ProductImportMappingsService,
                public creditorsService: CreditorsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.loading = true;

        forkJoin([
            this.productFieldsService.getProductFields(null, null),
            this.priceGroupsService.getPriceGroups(1, -1),
            this.referencesService.getReferences(),
            this.productImportMappingsService.getProductImportMappings(),
            this.creditorsService.getCreditors(1, -1,false, false)
        ]).subscribe(joinData => {
            this.productFields = joinData[0].data;
            this.priceGroups = joinData[1].data;
            this.references = joinData[2].data;
            this.productImportMappings = joinData[3].data;
            this.creditors = joinData[4].data;

            this.loading = false;
        });
    }

    fileChanged(event: Event) {
        this.productsFile = event.target['files'].item(0);

        this.productService.parseFile(this.productsFile).subscribe(response => {
            this.headers = response.data['headers'];
            this.examples = response.data['examples'];
            this.rows = response.data['rows'];

            for (let i = 0; i < this.headers.length; i++) {
                this.productImportMappingColumns[i] = ProductImportMappingColumn.fromJSON({ column: i, option: null });
            }

            this.mappingOptions = ProductImportMappingColumn.getMappingOptions(this.references, this.productFields, this.priceGroups);
        });
    }

    import() {
        this.importing = true;

        this.productService.processFile(this.productsFile, this.productImportMappingColumns, this.creditorId).subscribe(response => {
            let result = response.data;


            let message = $localize `Added` + ' ' + result['added'] + ' ' + $localize `products` + ', ';
            message += $localize `updated` + ' ' + (result['saved'] - result['added']) + ' ' + $localize `products` + ', ';
            message += result['same'] + ' ' + $localize `were the same and` + ' ';
            message += $localize `there was` + ' ' + result['errors'] + ' ' + $localize `errors`;

            alert(message);

            this.importing = false;
        }, error => {
            this.importing = false;

            alert(error.error.error);
        });
    }

    onKeyChanged(i) {
        for (let j = 0; j < this.productImportMappingColumns.length; j++) {
            if (j === i) {
                continue;
            }

            this.productImportMappingColumns[j].is_key = false;
        }
    }

    isKeySet() {
        for (let productImportMappingColumn of this.productImportMappingColumns) {
            if (!productImportMappingColumn.is_key) {
                continue;
            }

            if (productImportMappingColumn.option) {
                return true;
            }
        }

        return false;
    }

    resetFile() {
        this.productsFile = null;

        this.headers = null;
        this.examples = null;
        this.rows = null;

        this.creditorId = null;

        this.productImportMappingColumns = [];

        this.productImportMappingId = null;

        this.mappingOptions = [];
    }

    saveMapping() {
        let name = prompt($localize `What should be mapping be called ?`, '');

        if (name) {
            this.saving = true;

            let productImportMappingColumns = [];

            for (let productImportMappingColumn of this.productImportMappingColumns) {
                if (productImportMappingColumn.option) {
                    productImportMappingColumns.push(productImportMappingColumn);
                }
            }

            this.productImportMappingsService.addProductImportMapping(ProductImportMapping.fromJSON({
                name: name,
                product_import_mapping_columns: productImportMappingColumns
            })).subscribe(response => {
                this.saving = false;

                alert($localize `Product import mapping` + ' "' + response.data.name + '" ' + $localize `saved`);
            });
        }
    }

    onProductImportMappingChanged() {
        if (!this.productImportMappingId) {
            return;
        }

        if (confirm($localize `Are you sure?\n\nThe current mapping will be discarded`)) {
            let i = this.productImportMappings.findIndex((element) => {
                return element['id'] === this.productImportMappingId;
            });

            /**
             * Reset product import mapping columns in UI and then set from product import mapping
             */
            this.productImportMappingColumns = [];

            for (let i = 0; i < this.headers.length; i++) {
                this.productImportMappingColumns[i] = ProductImportMappingColumn.fromJSON({ column: i, option: null });
            }

            for (let productImportMappingColumn of this.productImportMappings[i].product_import_mapping_columns) {
                this.productImportMappingColumns[productImportMappingColumn.column] = ProductImportMappingColumn.fromJSON({
                    column: productImportMappingColumn.column,
                    is_key: productImportMappingColumn.is_key,
                    product_field_column: productImportMappingColumn.product_field_column,
                    product_field_id: productImportMappingColumn.product_field_id,
                    price_group_id: productImportMappingColumn.price_group_id,
                    reference_id: productImportMappingColumn.reference_id,
                    is_product_category_reference: productImportMappingColumn.is_product_category_reference,
                    is_creditor_reference: productImportMappingColumn.is_creditor_reference,
                    option: productImportMappingColumn.createOption()
                });
            }
        }
    }
}
