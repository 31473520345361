import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ProductRelationsGroup} from './product-relations-group';
import {Product} from "./product";

@Injectable()
// @ts-ignore
export class ProductRelationsGroupsService {

    protected url = environment.apiBase + 'product-relations-groups';

    constructor(public apiService: ApiService) {
    }

    getGroupsForProduct(product: Product, withAdjustedPrices: boolean): Observable<{data: ProductRelationsGroup[], count: number, perPage: number}> {
        let url = this.url + '/' + product.id;

        const parameters = [];

        if (withAdjustedPrices) {
            parameters.push('with-adjusted-prices=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<ProductRelationsGroup[]>(url, true, ProductRelationsGroup);
    }

    addProductRelationsGroup(productRelationsGroup: ProductRelationsGroup): Observable<{data: ProductRelationsGroup, count: number, perPage: number}> {
        return this.apiService.post<ProductRelationsGroup>(this.url, productRelationsGroup, true, ProductRelationsGroup);
    }

    updateProductRelationsGroup(productRelationsGroup: ProductRelationsGroup): Observable<{data: ProductRelationsGroup, count: number, perPage: number}> {
        return this.apiService.patch<ProductRelationsGroup>(this.url + '/' + productRelationsGroup.id, productRelationsGroup, true, ProductRelationsGroup);
    }

    deleteProductRelationsGroup(productRelationsGroup: ProductRelationsGroup): Observable<{data: ProductRelationsGroup, count: number, perPage: number}> {
        return this.apiService.delete<ProductRelationsGroup>(this.url + '/' + productRelationsGroup.id, true, ProductRelationsGroup);
    }
}
