<div class="row">
    <div class="col-12  col-lg-4  col-xl-6">
        <div class="input-group  input-group-sm  mb-2  mb-lg-0">
            <input [disabled]="!productReferences" type="text" class="form-control" i18n-placeholder placeholder="What are you looking for? (Product no., name ect.)" [(ngModel)]="search" (ngModelChange)="productsSearchInputChange()">
            <div class="input-group-append">
                <button (click)="resetSearchInput()" class="btn btn-outline-secondary" type="button">&times;</button>
            </div>
        </div>
    </div>
    <div class="col-12  col-lg-8  col-xl-6  text-right">
        <ng-content>
        </ng-content>
    </div>
</div>
<div *ngIf="showProductsSearchResult" class="row  mt-2">
    <div class="col-12">
        <div class="data-board">
            <div class="alert  alert-secondary  mb-0" *ngIf="orderlinesFromProductsSearch && orderlinesFromProductsSearch.length === 0">
                Vi kunne desværre ikke finde hvad du ledte efter.
            </div>
            <app-loader [minimal]="true" *ngIf="!orderlinesFromProductsSearch"></app-loader>
            <app-table *ngIf="orderlinesFromProductsSearch && orderlinesFromProductsSearch.length > 0" [autoSizeLastColumn]="true" [noMargin]="true">
                <thead>
                <tr>
                    <th colspan="2">Vare</th>
                    <th>Tekst</th>
                    <th>Antal / Enhed</th>
                    <th></th>
                    <th>á Pris</th>
                    <th>Pris m. rabat</th>
                    <th>Pris m. rabat %</th>
                    <th>Rabat %</th>
                    <th>Kost</th>
                    <th>Kost 2</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-orderLine [ngForOf]="orderlinesFromProductsSearch">
                        <tr>
                            <td *ngIf="orderLine.is_related_product"> ⎿ </td>

                            <td class="centerized" [colSpan]="orderLine.is_related_product ? 1 : 2">
                                <span *ngIf="orderLine.product.references.length >= 1">{{orderLine.product.references[0]._joinData.value}}</span>
                            </td>

                            <td class="centerized">
                                <a routerLink="/products/{{ orderLine.product.id }}">{{orderLine.product.name}}</a>
                            </td>
                            <td class="centerized">
                                <div class="input-group  input-group-sm">
                                    <input type="number" class="form-control" [(ngModel)]="orderLine.quantity" (ngModelChange)="orderLine.onQuantityChanged()" name="quantity-new">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{orderLine.product.unit.name ?? 'Ukendt' }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <ng-template ngFor let-alternativePriceUnit [ngForOf]="orderLine.product.alternative_price_units" let-i="index">
                                    <div class="form-group">
                                        <div class="input-group  input-group-sm">
                                            <input type="number" min="0" id="order-line-alternative-price-unit-{{ i }}-amount" class="form-control" [(ngModel)]="alternativePriceUnit.quantity" (ngModelChange)="orderLine.onAlternativePriceUnitAmountChanged(alternativePriceUnit)" name="amount-{{ i }}">
                                            <div class="input-group-append">
                                                <span class="input-group-text" [ngClass]="{ 'bg-primary  border-primary': orderLine.alternative_price_unit_id === alternativePriceUnit.id }">
                                                    {{ alternativePriceUnit.unit }}
                                                    ({{ alternativePriceUnit.factor }})
                                                    |
                                                    {{ alternativePriceUnit.getPrice(orderLine.price) | currency:'DKK':'':null }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </td>
                            <td class="centerized">
                                <input type="number" class="form-control  form-control-sm" [(ngModel)]="orderLine.price" name="price-new">
                            </td>
                            <td class="centerized">
                                {{ orderLine.price_adjusted | currency:'DKK':'':null }}
                            </td>
                            <td class="centerized">
                                <ng-container *ngIf="orderLine.price_adjustment_percentage > 0">
                                    {{ orderLine.price_adjustment_percentage }} % ({{ orderLine.price_adjustment_name ?? 'Ukendt' }})
                                </ng-container>
                                <ng-container *ngIf="!orderLine.price_adjustment_percentage">
                                    <span class="text-muted">-</span>
                                </ng-container>
                            </td>
                            <td class="centerized">
                                <input type="number" class="form-control  form-control-sm" [(ngModel)]="orderLine.discount_percentage" min="0" max="100" name="discount">
                            </td>
                            <td class="centerized">
                                {{ orderLine.cost_price ? (orderLine.cost_price | currency:'DKK':'':null) : '-' }}
                            </td>
                            <td class="centerized">
                                {{ orderLine.cost_price_adjusted ? (orderLine.cost_price_adjusted | currency:'DKK':'':null) : '-' }}
                            </td>
                            <td class="centerized">
                                <button (click)="addOrderlineToOrder(orderLine)" class="btn  btn-success  btn-sm">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </app-table>
        </div>
    </div>
</div>
