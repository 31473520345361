<nav [className]="'inline-navigation'">
    <ul [className]="'inline-navigation__wrapper'">
        <ng-container *ngFor="let item of navData">
            <li [className]="'inline-navigation__item' + (currentNavItem && (item.title === currentNavItem.title) ? '  inline-navigation__item--active' : '')">
                <ng-container *ngIf="item.url">
                    <a [routerLink]="item.url" (click)="onClick(item)" [className]="'inline-navigation__link'">
                        {{item.title}}
                    </a>
                </ng-container>
                <ng-container *ngIf="!item.url">
                    <button [className]="'inline-navigation__link'" (click)="onClick(item, true)">
                        {{item.title}}
                    </button>
                </ng-container>
            </li>
        </ng-container>
    </ul>
    <ng-container *ngIf="currentNavItem && currentNavItem.children && currentNavItem.children.length >= 1">
        <ul [className]="'inline-navigation__wrapper   inline-navigation__wrapper--children'">
            <li [className]="'inline-navigation__item'" [routerLinkActive]="['inline-navigation__item--active']" *ngFor="let item of currentNavItem.children">
                <a [routerLink]="item.url" [className]="'inline-navigation__link'">
                    {{item.title}}
                </a>
            </li>
        </ul>
    </ng-container>
</nav>
