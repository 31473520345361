import * as dayjs from 'dayjs';
import {ProductRelation} from './product-relation';
import {ProductRelationsProductRelationsGroups} from "./product-relations-product-relations-groups";

export class ProductRelationsGroup {

    id: string;
    // tslint:disable-next-line:variable-name
    product_id: string;
    name: string;
    // tslint:disable-next-line:variable-name
    is_mutually_exclusive: boolean;
    // tslint:disable-next-line:variable-name
    is_dependent: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    // tslint:disable-next-line:variable-name
    _product_relations_product_relations_groups: ProductRelation[];
    product_relations_product_relations_groups: ProductRelationsProductRelationsGroups[];

    constructor() {
    }

    static fromJSON(json: ProductRelationsGroupJSON | string): ProductRelationsGroup {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductRelationsGroup.reviver);
        } else {
            const productRelationsGroup = Object.create(ProductRelationsGroup.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _joinData: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (key === '_joinData' && json[key]) {
                    extra._joinData = {};

                    Object.keys(json[key]).forEach((keyKey, index) => {
                        extra._joinData[keyKey] = json[key][keyKey];
                    });
                }
            });

            return Object.assign(productRelationsGroup, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? ProductRelationsGroup.fromJSON(value) : value;
    }
}

// A representation of ProductRelationsGroup data that can be converted to
// and from JSON without being altered.
interface ProductRelationsGroupJSON {
    id?: string;
    product_id?: string;
    name?: string;
    is_mutually_exclusive?: boolean;
    is_dependent?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
