<div class="orders  container  app__component">
    <h1 class="heading" i18n>Orders</h1>
    <p *ngIf="orders && authService.hasPermission('admin')">
        <a class="btn  btn-primary  button  button--add-to-table" routerLink="/orders/add" i18n>Add order</a>
    </p>
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a [ngStyle]="{pointerEvents: changingClient ? 'none' : 'initial'}" (click)="changeClientType('debtor')" ngbNavLink i18n>Debitors</a>
            <ng-template ngbNavContent>
                <ng-container [ngTemplateOutlet]="table"></ng-container>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a [ngStyle]="{pointerEvents: changingClient ? 'none' : 'initial'}" (click)="changeClientType('creditor')" ngbNavLink i18n>Kreditors</a>
            <ng-template ngbNavContent>
                <ng-container [ngTemplateOutlet]="table"></ng-container>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a [ngStyle]="{pointerEvents: changingClient ? 'none' : 'initial'}" ngbNavLink>
                <fa-icon [icon]="searchIcon"></fa-icon>
            </a>
            <ng-template ngbNavContent>
                <ng-container [ngTemplateOutlet]="search"></ng-container>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>

<ng-template #table>
    <app-loader *ngIf="changingClient || !orders"></app-loader>
    <app-loader *ngIf="changeSorting" [useOverlayLoader]="true"></app-loader>
    <app-table *ngIf="!changingClient && orders" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th>
                    <app-table-sorting-button [direction]="getCurrentDirection('id')" (click)="shouldChangeDirection(currentSorting.direction, 'id')">
                        #
                    </app-table-sorting-button>
                </th>
                <th>
                    <app-table-sorting-button [direction]="getCurrentDirection('state')" (click)="shouldChangeDirection(currentSorting.direction, 'state')" i18n>
                        State
                    </app-table-sorting-button>
                </th>
                <th>
                    <app-table-sorting-button [direction]="getCurrentDirection('type')" i18n>
                        Type
                    </app-table-sorting-button>
                </th>
                <th>
                    <app-table-sorting-button [direction]="getCurrentDirection('name')" (click)="shouldChangeDirection(currentSorting.direction, 'name')" i18n>
                        Name
                    </app-table-sorting-button>
                </th>
                <th>
                    <app-table-sorting-button [direction]="getCurrentDirection('order_lines')" (click)="shouldChangeDirection(currentSorting.direction, 'order_lines')" i18n>
                        Order lines
                    </app-table-sorting-button>
                </th>
                <th>
                    <app-table-sorting-button [direction]="getCurrentDirection('revenue')" (click)="shouldChangeDirection(currentSorting.direction, 'revenue')" i18n>
                        Revenue
                    </app-table-sorting-button>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody [sortablejs]="orders" [sortablejsOptions]="options">
            <tr *ngFor="let order of orders">
                <td>{{ order.getIdentificationReference() }}</td>
                <td>
                    {{ order.order_state && order.order_state.name }}
                    <span *ngIf="order.isFrozen()" class="badge  badge-danger" i18n>Frozen</span>
                </td>
                <td>
                    <span *ngIf="order.order_type">{{ order.order_type.name }}</span>
                    <span *ngIf="!order.order_type" class="text-muted">Ingen type</span>
                </td>
                <td>{{getContactName(order)}}</td>
                <td>{{order.order_lines.length.toString()}}</td>
                <td>{{order.getTotal() | currency:'DKK':'':null}}</td>
                <td>
                    <a routerLink="/orders/{{ order.id }}">{{ order.isFrozen() ? 'View' : 'Edit' }}</a>
                    <a (click)="deleteOrder(order)" *ngIf="!order.isFrozen()" class="text-danger" i18n>Delete</a>
                </td>
            </tr>
        </tbody>
    </app-table>
</ng-template>

<ng-template #search>
    <input type="text" class="form-control mb-4" [(ngModel)]="searchQuery" (ngModelChange)="onSearchInputChange($event)" />
    <app-no-search-input-or-results *ngIf="!isSearching && (searchResult && searchResult.length === 0)" [noResults]="searchResult && searchResult.length === 0" [noInput]="!searchQuery || (searchQuery && searchQuery.length === 0)"></app-no-search-input-or-results>
    <app-loader *ngIf="isSearching"></app-loader>
    <app-table *ngIf="!isSearching && searchQuery && searchQuery.length >= 1 && searchResult && searchResult.length >= 1" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th>
                <app-table-sorting-button [direction]="getCurrentSearchDirection('id')" (click)="shouldChangeDirection(currentSorting.direction, 'id')">
                    #
                </app-table-sorting-button>
            </th>
            <th>
                <app-table-sorting-button [direction]="getCurrentSearchDirection('id')" (click)="shouldChangeDirection(currentSorting.direction, 'id')">
                    Debtor / Creditor
                </app-table-sorting-button>
            </th>
            <th>
                <app-table-sorting-button [direction]="getCurrentSearchDirection('state')" (click)="shouldChangeDirection(currentSorting.direction, 'state')" i18n>
                    State
                </app-table-sorting-button>
            </th>
            <th>
                <app-table-sorting-button [direction]="getCurrentSearchDirection('type')" i18n>
                    Type
                </app-table-sorting-button>
            </th>
            <th>
                <app-table-sorting-button [direction]="getCurrentSearchDirection('name')" (click)="shouldChangeDirection(currentSorting.direction, 'name')" i18n>
                    Name
                </app-table-sorting-button>
            </th>
            <th>
                <app-table-sorting-button [direction]="getCurrentSearchDirection('order_lines')" (click)="shouldChangeDirection(currentSorting.direction, 'order_lines')" i18n>
                    Order lines
                </app-table-sorting-button>
            </th>
            <th>
                <app-table-sorting-button [direction]="getCurrentSearchDirection('revenue')" (click)="shouldChangeDirection(currentSorting.direction, 'revenue')" i18n>
                    Revenue
                </app-table-sorting-button>
            </th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of searchResult">
            <td>{{ order.getIdentificationReference() }}</td>
            <td>
                <span *ngIf="order.isCreditor()" i18n>Creditor</span>
                <span *ngIf="!order.isCreditor()" i18n>Debtor</span>
            </td>
            <td>
                {{ order.order_state && order.order_state.name }}
                <span *ngIf="order.isFrozen()" class="badge  badge-danger" i18n>Frozen</span>
            </td>
            <td>{{order.order_type.name}}</td>
            <td>{{getContactName(order)}}</td>
            <td>{{order.order_lines.length.toString()}}</td>
            <td>{{order.getTotal() | currency:'DKK':'':null}}</td>
            <td>
                <a routerLink="/orders/{{ order.id }}">{{ order.isFrozen() ? 'View' : 'Edit' }}</a>
                <a (click)="deleteOrder(order)" *ngIf="!order.isFrozen()" class="text-danger" i18n>Delete</a>
            </td>
        </tr>
        </tbody>
    </app-table>
</ng-template>
