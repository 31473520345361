import { Component, OnInit } from '@angular/core';
import {DashboardComponent} from "../../../dashboard/dashboard.component";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-thorsens-dashboard',
  templateUrl: './thorsens-dashboard.component.html',
  styleUrls: ['./thorsens-dashboard.component.scss']
})
export class ThorsensDashboardComponent extends DashboardComponent implements OnInit {

    constructor(public authService: AuthService) {
      super(authService);
  }

  ngOnInit(): void {
  }

}
