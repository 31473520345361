import {Injectable} from '@angular/core';
import {Debtor} from './debtor';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Empty} from "./empty";
import {DebtorsUser} from "./debtors-user";
import {User} from "./user";
import {Contact} from "./contact";

@Injectable()
export class DebtorsService {

    protected url = environment.apiBase + 'debtors';

    constructor(public apiService: ApiService) {
    }

    getDebtor(id: string, query?: DebtorsQueryInterface): Observable<{ data: Debtor, count: number, perPage: number }> {
        let url = this.url + '/' + id;

        let parameters = [];

        if (query && query.withReferences) {
            parameters.push('with-references=1');
        }

        if (query && query.withPaymentAgreement) {
            parameters.push('with-payment-agreement=1');
        }

        if (query && query.withBranch) {
            parameters.push('with-branch=1');
        }

        if (query && query.withContacts) {
            parameters.push('with-contacts=1');
        }

        if (query && query.withUsers) {
            parameters.push('with-users=1');
        }

        if (query && query.withBalance) {
            parameters.push('with-balance=1');
        }

        if (query && query.withProductCategories) {
            parameters.push('with-product-categories=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.get<Debtor>(url, true, Debtor);
    }

    getDebtors(page: number, limit: number, withReferences: boolean, withPaymentAgreement: boolean, withBranch: boolean, withContacts: boolean, withProductCategories: boolean, query?: DebtorsQueryInterface): Observable<{
        data: Debtor[],
        count: number,
        perPage: number
    }> {
        let url = this.url;

        let parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            if (limit === -1) {
                parameters.push('all=1');
            } else {
                parameters.push('limit=' + limit);
            }
        }

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (withPaymentAgreement) {
            parameters.push('with-payment-agreement=1');
        }

        if (withBranch) {
            parameters.push('with-branch=1');
        }

        if (withContacts) {
            parameters.push('with-contacts=1');
        }

        if (withProductCategories) {
            parameters.push('with-product-categories=1');
        }

        if (query && query.byReferenceId && query.byRefrenceValue) {
            parameters.push('by-ref-id=' + query.byReferenceId);
            parameters.push('by-ref-value=' + query.byRefrenceValue);
        }

        if (query && query.withStatistics) {
            parameters.push('with-statistics=' + query.withStatistics);
        }

        if (query && query.statisticsYear) {
            parameters.push('statistics-year=' + query.statisticsYear);
        }

        if (query && query.statisticsMonth) {
            parameters.push('statistics-month=' + query.statisticsMonth);
        }
        if (query && query.byName) {
            parameters.push('by-name=' + query.byName);
        }

        if (query && query.includeInactive) {
            parameters.push('include-inactive=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<Debtor[]>(url, true, Debtor);
    }

    addDebtor(debtor: Debtor): Observable<{ data: Debtor, count: number, perPage: number }> {
        return this.apiService.post<Debtor>(this.url, debtor, true, Debtor);
    }

    updateDebtor(debtor: Debtor): Observable<{ data: Debtor, count: number, perPage: number }> {
        return this.apiService.patch<Debtor>(this.url + '/' + debtor.id, debtor, true, Debtor);
    }

    deleteDebtor(debtor: Debtor): Observable<{ data: Debtor, count: number, perPage: number }> {
        return this.apiService.delete<Debtor>(this.url + '/' + debtor.id, true, Debtor);
    }

    addDebtorsUser(debtorId: string, debtorsUser: User): Observable<{ data: Empty, count: number, perPage: number }> {
        return this.apiService.post<Empty>(this.url + '/' + debtorId + '/users', debtorsUser, true, Empty);
    }

    addDebtorsExistingUser(debtorId: string, userId: string): Observable<{
        data: Empty,
        count: number,
        perPage: number
    }> {
        return this.apiService.post<Empty>(this.url + '/' + debtorId + '/users/' + userId, {}, true, Empty);
    }

    updateDebtorsUser(debtorsUser: DebtorsUser): Observable<{ data: DebtorsUser, count: number, perPage: number }> {
        return this.apiService.patch<DebtorsUser>(this.url + '/users/' + debtorsUser.id, debtorsUser, true, DebtorsUser);
    }

    deleteDebtorsUser(debtorsUser: DebtorsUser, deleteUser: boolean): Observable<{
        data: DebtorsUser,
        count: number,
        perPage: number
    }> {
        let url = this.url + '/users/' + debtorsUser.id;

        let parameters = [];

        if (deleteUser) {
            parameters.push('delete-user=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&')
        }

        return this.apiService.delete<DebtorsUser>(url, true, DebtorsUser);
    }

    addDebtorsContact(debtorId: string, contact: Contact): Observable<{ data: Empty, count: number, perPage: number }> {
        return this.apiService.post<Contact>(this.url + '/' + debtorId + '/contacts', contact, true, Contact);
    }

    updateDebtorsContact(debtorContactId: string, contact: Contact): Observable<{
        data: Contact,
        count: number,
        perPage: number
    }> {
        return this.apiService.patch<Contact>(this.url + '/contacts/' + debtorContactId, contact, true, Contact);
    }

    deleteDebtorsContact(debtorContactId: string): Observable<{ data: Contact, count: number, perPage: number }> {
        return this.apiService.delete<Contact>(this.url + '/contacts/' + debtorContactId, true, Contact);
    }
}

export interface DebtorsQueryInterface {
    includeInactive?: boolean;
    withReferences?: boolean;
    withPaymentAgreement?: boolean;
    withBranch?: boolean;
    withContacts?: boolean;
    withUsers?: boolean;
    withBalance?: boolean;
    withProductCategories?: boolean;
    withStatistics?: string;
    statisticsYear?: number;
    statisticsMonth?: number;
    byReferenceId?: string;
    byRefrenceValue?: any;
    byName?: string;
}
