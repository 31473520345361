import * as dayjs from 'dayjs';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Attachment} from "./attachment";
import {Empty} from "./empty";
import {map} from "rxjs/operators";

@Injectable()
export class AttachmentsService {

    protected url = environment.apiBase + 'attachments';

    constructor(public apiService: ApiService) {
    }

    getAttachment(id: string): Observable<{data: Attachment, count: number, perPage: number}> {
        return this.apiService.get<Attachment>(this.url + '/' + id, true, Attachment);
    }

    downloadAttachment(id: string): Observable<{data: Empty, count: number, perPage: number}> {
        return this.apiService.get<Empty>(this.url + '/download/' + id, true, Empty);
    }

    getAttachments(page: number, limit: number, query?: AttachmentsQueryInterface): Observable<{data: Attachment[], count: number, perPage: number}> {
        let url = this.url;

        let parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            parameters.push('limit=' + limit);
        }

        if (query && query.withProducts) {
            parameters.push('with-products=1');
        }

        if (query && query.forModel) {
            parameters.push('for-model=' + query.forModel);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<Attachment[]>(url, true, Attachment);
    }

    addAttachment(attachment: Attachment, file: File): Observable<{ data: Empty, count: number, perPage: number }> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        formData.append('foreign_key', attachment.foreign_key);
        formData.append('model', attachment.model);
        formData.append('name', file.name);
        formData.append('description', attachment.description);
        formData.append('language', attachment.language);

        return this.apiService.post<Empty>(this.url, formData, true, Empty, null);
    }

    updateAttachment(attachment: Attachment): Observable<{ data: Attachment, count: number, perPage: number }> {
        return this.apiService.patch<Attachment>(this.url + '/' + attachment.id, attachment, true, Attachment);
    }

    deleteAttachment(attachment: Attachment): Observable<{ data: Attachment, count: number, perPage: number }> {
        return this.apiService.delete<Attachment>(this.url + '/' + attachment.id, true, Attachment);
    }
}

export interface AttachmentsQueryInterface {
    withProducts?: boolean;
    forModel?: string;
}
