import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
    transform(allHeroes: any[], key, value: any) {
        return allHeroes.filter(hero => {
            if (value && Array.isArray(value)) {
                if (value.includes(hero[key])) {
                    return true;
                }
            } else if (hero[key] && hero[key] === value) {
                return true;
            }
        });
    }
}
