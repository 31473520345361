import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductRelationsGroup} from '../services/product-relations-group';
import {ProductRelationsGroupsService} from '../services/product-relations-groups.service';
import {ProductRelation} from "../services/product-relation";
import {Product} from "../services/product";
import {ProductRelationsProductRelationsGroups} from "../services/product-relations-product-relations-groups";

@Component({
    selector: 'app-product-relation-group-edit-modal',
    templateUrl: './product-relation-group-edit-modal.component.html',
    styleUrls: ['./product-relation-group-edit-modal.component.scss'],
    providers: [ProductRelationsGroupsService]

})
export class ProductRelationGroupEditModalComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal,
        public productRelationsGroupsService: ProductRelationsGroupsService) {
    }

    @Input() productRelationsGroup: ProductRelationsGroup;
    groupType: string;
    @Input() productChildRelations: ProductRelation[];
    _productChildRelations: ProductRelation[];
    selectedProductRelation: ProductRelation;
    @Input() isNew = false;
    @Input() delete: any;

    public saving = false;
    public saved;

    types = [
        'Mutually exclusive',
        'Dependent',
        'None'
    ];

    ngOnInit(): void {
        if (this.isNew) {
            this.productRelationsGroup.product_relations_product_relations_groups = [];
        } else if (!this.productRelationsGroup.product_relations_product_relations_groups) {
            this.productRelationsGroup.product_relations_product_relations_groups = [];
        }
        this.loadGroupType();
        this.filterRelations();
    }

    filterRelations(): void {
        /**
         * Filter products already in group, and filter by note
         */
        this._productChildRelations = [];

        let note;
        if (this.productRelationsGroup.product_relations_product_relations_groups.length > 0) {
            note = this.productRelationsGroup.product_relations_product_relations_groups[0].product_relation.note;
        }
        this.productChildRelations.forEach((relation) => {
            let add = true;
            for (const productRelation of this.productRelationsGroup.product_relations_product_relations_groups) {
                if (productRelation.product_relation.related_product_id === relation.related_product_id) {
                    add = false;
                }
                if (note !== relation.note) {
                    add = false;
                }
            }
            if (add) {
                this._productChildRelations.push(relation);
            }
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }

    removeFromGroup(index): void {
        this.productRelationsGroup.product_relations_product_relations_groups.splice(index, 1);
    }

    addToGroup(productRelation: ProductRelation): void {
        const productRelationProductRelationsGroup = new ProductRelationsProductRelationsGroups();
        productRelationProductRelationsGroup.product_relations_id = productRelation.id;
        productRelationProductRelationsGroup.product_relation = productRelation;
        productRelationProductRelationsGroup.product_relations_groups_id = this.productRelationsGroup.id;
        productRelationProductRelationsGroup.sorting = 0;
        this.productRelationsGroup.product_relations_product_relations_groups.push(productRelationProductRelationsGroup);
        // this._productChildRelations.splice(this._productChildRelations.indexOf(productRelation), 1);
        this.filterRelations();
    }

    deleteGroup(): void {
        this.delete(this.productRelationsGroup);
        this.activeModal.close();
    }

    loadGroupType(): void {
        if (this.productRelationsGroup) {
            if (this.productRelationsGroup.is_mutually_exclusive) {
                this.groupType = 'Mutually exclusive';
            } else if (this.productRelationsGroup.is_dependent) {
                this.groupType = 'Dependent';
            } else {
                this.groupType = 'None';
            }
        }
    }

    setGroupType(groupType): void {
        switch (groupType) {
            case 'Mutually exclusive':
                this.productRelationsGroup.is_mutually_exclusive = true;
                this.productRelationsGroup.is_dependent = false;
                break;
            case 'Dependent':
                this.productRelationsGroup.is_mutually_exclusive = false;
                this.productRelationsGroup.is_dependent = true;
                break;
            default:
                this.productRelationsGroup.is_mutually_exclusive = false;
                this.productRelationsGroup.is_dependent = false;
        }
    }
}
