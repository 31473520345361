import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupsService} from '../services/groups.service';
import {Group} from '../services/group';
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'app-group-edit-modal',
    templateUrl: './group-edit-modal.component.html',
    styleUrls: ['./group-edit-modal.component.scss'],
    providers: [GroupsService]
})
export class GroupEditModalComponent implements OnInit {

    public group: Group;
    public _group: Group;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public groupsService: GroupsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._group = this.group ? Group.fromJSON(this.group) : Group.fromJSON({});
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        if (this.group) {
            this.groupsService.updateGroup(this._group).subscribe(group => {
                this.saved();

                this.saving = false;

                this.activeModal.close(group);
            });
        } else {
            this.groupsService.addGroup(this._group).subscribe(group => {
                this.saved();

                this.saving = false;

                this.activeModal.close(group);
            });
        }
    }
}
