import * as dayjs from 'dayjs';
import {User} from "./user";

export class AuditLog {

    id: string;
    user_id: string
    masquerade_user_reference: string;
    model: string;
    foreign_key: string;
    operation: string;
    data: string;
    sequence: number;
    created: dayjs.Dayjs;
    user: User;

    constructor() {
    }

    static fromJSON(json: AuditLogJSON | string): AuditLog {
        if (typeof json === 'string') {
            return JSON.parse(json, AuditLog.reviver);
        } else {
            let auditLog = Object.create(AuditLog.prototype);

            let extra = {
                created: dayjs(json.created),
                user: null
            }

            Object.keys(json).forEach((key, index) => {
                if (key === 'user' && json[key]) {
                    extra[key] = User.fromJSON(json[key]);
                }
            });

            return Object.assign(auditLog, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? AuditLog.fromJSON(value) : value;
    }

    public getModel(): string {
        let model = this.model;

        model = model.replace('ERP.', '');
        model = model.replace('Users.', '');

        return model;
    }

    public getIdentification() {
        let data = JSON.parse(this.data);

        if (['create', 'update', 'delete'].indexOf(this.operation) >= 0) {
            switch (this.model) {
                case 'ERP.Branches':
                case 'ERP.Creditors':
                case 'ERP.Debtors':
                case 'ERP.OrderStates':
                case 'ERP.PriceAdjustments':
                case 'ERP.PriceGroups':
                case 'ERP.Products':
                case 'ERP.ProductCategories':
                case 'ERP.ProductFields':
                case 'ERP.ProductFieldPresets':
                case 'ERP.References':
                case 'Users.Groups':
                case 'Users.Users':
                    return data.name;

                case 'OrdersReferences':
                    return data.value;
            }
        }

        if (this.operation === 'order_state_update') {
            return data.old + ' -> ' + data.new;
        }



        /* @todo no usable identification directly on model

        // ERP
        //'Orders',
        //'OrderLines',
        //'Prices',
        //'PriceAdjustmentRelations',
        //'ProductCategoriesProductFieldPresets',
        //'ProductFieldsProductFieldPresets',
        //'ProductRelations',
        //'ProductsProductCategories',
        //'ProductsProductFields',
        //'ProductsReferences',
        //'ProductStocks',

        // Users
        //'GroupPermissions',

        */

    }
}

// A representation of AuditLog data that can be converted to
// and from JSON without being altered.
interface AuditLogJSON {
    id?: string;
    user_id?: string
    masquerade_user_reference?: string;
    model?: string;
    foreign_key?: string;
    operation?: string;
    data?: string;
    sequence?: number;
    created?: dayjs.Dayjs;
}
