import {Component, Input, OnInit} from '@angular/core';
import {ContactFieldPreset} from "../../../services/contact-field-preset";
import {Order} from "../../../services/order";

@Component({
    selector: 'app-client-contact-meta',
    templateUrl: './client-contact-meta.component.html',
    styleUrls: ['./client-contact-meta.component.scss']
})
export class ClientContactMetaComponent implements OnInit {

    @Input() order: Order;
    @Input() contactFields: ContactFieldPreset;

    constructor() {
    }

    ngOnInit(): void {
    }

}
