import * as dayjs from 'dayjs';
import {Creditor} from "./creditor";
import {Price} from "./price";
import {Reference} from "./reference";
import {Categorizable, ProductCategory, ProductCategoryJSON} from "./product-category";
import {ProductField} from "./product-field";
import {PriceGroup} from "./price-group";
import {ProductRelation} from "./product-relation";
import {ProductStock} from "./product-stock";
import {Branch} from "./branch";
import {AlternativePriceUnit} from "./alternative-price-unit";
import {VatType} from "./vat-type";
import {Unit} from "./unit";
import {ProductQuantityDiscount} from "./product-quantity-discount";
import {Attachment} from "./attachment";
import {Translation} from "./translation";
import {ProductsProductField} from "./products-product-field";
import {ProductRelationsGroup} from "./product-relations-group";
import {ProductionLine} from "../helms-production-lines/services/production-line";

export class Product implements Categorizable {

    id: string;
    creditor_id: string;
    vat_type_id: string;
    unit_id: string;
    name: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    date_start: dayjs.Dayjs;
    date_end: dayjs.Dayjs;
    is_active: boolean;
    creditor: Creditor;
    prices: Price[];
    references: Reference[];
    product_categories: ProductCategory[];
    product_fields: ProductField[];
    products_product_fields: ProductsProductField[];
    product_relations: ProductRelation[];
    product_relation_parents: ProductRelation[];
    product_relation_childrens: ProductRelation[];
    product_relations_groups: ProductRelationsGroup[];
    product_stocks: ProductStock[];
    alternative_price_units: AlternativePriceUnit[];
    product_quantity_discounts: ProductQuantityDiscount[] = [];
    production_lines?: ProductionLine[] = [];
    attachments: Attachment[] = [];
    vat_type: VatType;
    unit: Unit;
    price: number;
    cost_price: number;
    cost_price_adjusted: number;
    adjusted_price: number;
    adjusted_percentage: number;
    adjusted_name: string;
    public fieldValueCache: any = {};
    public fieldMetaCache: any = {};
    sorting: number;
    _translations: Translation;

    constructor() {
    }

    static fromJSON(json: ProductJSON | string): Product {
        if (typeof json === 'string') {
            return JSON.parse(json, Product.reviver);
        } else {
            const product = new Product();

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                date_start: dayjs(json.date_start),
                date_end: dayjs(json.date_end),
                creditor: null,
                vat_type: null,
                unit: null,
                prices: [],
                references: [],
                product_categories: [],
                product_fields: [],
                products_product_fields: [],
                product_relation_parents: [],
                product_relation_childrens: [],
                product_relations_groups: [],
                product_stocks: [],
                alternative_price_units: [],
                production_lines: [],
                attachments: [],
                _translations: null
            };

            Object.keys(json).forEach((key, _) => {
                if (key === 'creditor' && json[key]) {
                    extra[key] = Creditor.fromJSON(json[key]);
                }

                if (key === 'vat_type' && json[key]) {
                    extra[key] = VatType.fromJSON(json[key]);
                }

                if (key === 'unit' && json[key]) {
                    extra[key] = Unit.fromJSON(json[key]);
                }

                if (key === 'prices') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Price.fromJSON(value));
                    });
                }

                if (key === 'references') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Reference.fromJSON(value));
                    });
                }

                if (key === 'product_categories') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductCategory.fromJSON(value));
                    });
                }

                if (key === 'product_fields') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductField.fromJSON(value));
                    });
                }

                if (key === 'products_product_fields') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductsProductField.fromJSON(value));
                    });
                }

                if (key === 'product_relation_parents' || key === 'product_relation_childrens') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductRelation.fromJSON(value));
                    });
                }

                if (key === 'product_relations_groups') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductRelationsGroup.fromJSON(value));
                    });
                }

                if (key === 'production_lines') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductionLine.fromJSON(value));
                    });
                }

                if (key === 'product_stocks') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ProductStock.fromJSON(value));
                    });
                }

                if (key === 'attachments') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Attachment.fromJSON(value));
                    });
                }

                if (key === 'alternative_price_units') {
                    json[key].forEach((value, _) => {
                        extra[key].push(AlternativePriceUnit.fromJSON(value));
                    });
                }
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(product, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Product.fromJSON(value) : value;
    }

    addPrice(price: Price): void {
        this.prices.push(Price.fromJSON({
            id: price.id,
            price_group_id: price.price_group_id,
            price_group: PriceGroup.fromJSON({
                id: price.price_group.id,
                name: price.price_group.name
            }),
            price: price.price,
        }));
    }

    hasPriceWithGroup(priceGroup: PriceGroup): boolean {
        let index = this.prices.findIndex((element) => {
            return element['price_group_id'] === priceGroup.id;
        });

        return index >= 0;
    }

    addCategory(productCategory: ProductCategory): void {
        this.product_categories.push(ProductCategory.fromJSON({
            id: productCategory.id,
            name: productCategory.name
        }));
    }

    hasCategory(productCategory: ProductCategory|ProductCategoryJSON): boolean {
        let index = this.product_categories.findIndex((element) => {
            return element['id'] === productCategory.id;
        });

        return index >= 0;
    }

    hasProductField(id: string): boolean {
        let index = this.product_fields.findIndex((element) => {
            return element['id'] === id;
        });

        return index >= 0;
    }

    hasReference(id: string): boolean {
        let index = this.references.findIndex((element) => {
            return element['id'] === id;
        });

        return index >= 0;
    }

    hasProductStockWithBranch(branch: Branch) {
        let index = this.product_stocks.findIndex((element) => {
            return element['branch_id'] === branch.id;
        });

        return index >= 0;
    }

    addProductStock(productStock: ProductStock): void {
        this.product_stocks.push(ProductStock.fromJSON({
            id: productStock.id,
            branch_id: productStock.branch_id,
            branch: Branch.fromJSON({
                id: productStock.branch.id,
                name: productStock.branch.name
            }),
            quantity: productStock.quantity,
            location_reference: productStock.location_reference
        }));
    }

    addAlternativePriceUnit(): void {
        this.alternative_price_units.push(AlternativePriceUnit.fromJSON({}));
    }

    public getFieldById(id) {
        if (this.product_fields.length > 0) {
            const result: any = this.product_fields.find((item) => {
                return item.id === id;
            });
            if (result) {
                return result;
            }
        }
        return null;
    }

    public getFieldValueForId(id) {
        if (this.fieldValueCache[id]) {
            return this.fieldValueCache[id];
        }

        let field = this.getFieldById(id);

        if (field) {
            this.fieldValueCache[id] = field._joinData.value;
            return field._joinData.value;
        }

        this.fieldValueCache[id] = null;
        return null;
    }

    public getFieldMetaForId(id) {
        if (this.fieldMetaCache[id]) {
            return this.fieldMetaCache[id];
        }

        let field = this.getFieldById(id);

        if (field) {
            this.fieldMetaCache[id] = field._joinData.meta;
            return field._joinData.meta;
        }

        this.fieldMetaCache[id] = null;
        return null;
    }

    public getThumbnail?(key: string, index?: number): string {
        if (!index) {
            index = 0;
        }
        if (this.attachments && this.attachments.length > 0) {
            return this.attachments[index].thumbnailUrl(key);
        }
        return null;
    }
}

// A representation of Product data that can be converted to
// and from JSON without being altered.
interface ProductJSON {
    id?: string;
    name?: string;
    creditor_id?: string;
    vat_type_id?: string;
    unit_id?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    date_end?: dayjs.Dayjs;
    date_start?: dayjs.Dayjs;
    is_active?: boolean;
    references?: Reference[];
}

export interface ProductRelatable {
    addProduct(product: Product): void
    removeProduct(): void
}
