import {Pipe, PipeTransform} from '@angular/core';
import {ProductRelation} from "../services/product-relation";

@Pipe({name: 'productRelationFilter'})
export class ProductRelationFilterPipe implements PipeTransform {
    transform(allHeroes: ProductRelation[], key, value: any) {
        return allHeroes.filter(hero => {
            if (!value || value === '') {
                return true;
            }
            if (value && Array.isArray(value)) {
                if (value.includes(hero.related_product[key])) {
                    return true;
                }
            } else if (hero.related_product[key].toLowerCase().includes(value.toLowerCase())) {
                return true;
            }
        });
    }
}
