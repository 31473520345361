import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'app-sale',
    templateUrl: './sale.component.html',
    styleUrls: ['./sale.component.scss'],
    providers: []
})
export class SaleComponent implements OnInit {

    constructor(public authService: AuthService) {
    }

    ngOnInit(): void {

        //

    }
}
