import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public navData: any[];

    constructor() {
    }

    ngOnInit(): void {
        this.navData = [
            {
                title: 'Prices',
                children: [
                    {
                        title: 'Price Groups',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['price-groups']}}]
                    },
                    {
                        title: 'Price Adjustments',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['price-adjustments']}}]
                    }
                ]
            },
            {
                title: 'Contact',
                children: [
                    {
                        title: 'Contact fields',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['contact-fields']}}]
                    },
                    {
                        title: 'Contact field presets',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['contact-field-presets']}}]
                    },
                    {
                        title: 'Contact types',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['contact-types']}}]
                    }
                ]
            },
            {
                title: 'Products',
                children: [
                    {
                        title: 'Products',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['products']}}]
                    },
                    {
                        title: 'Product categories',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['product-categories']}}]
                    },
                    {
                        title: 'Product fields',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['product-fields']}}]
                    },
                    {
                        title: 'Product field presets',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['product-field-presets']}}]
                    },
                    {
                        title: 'Import products',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['products', 'import']}}]
                    }
                ]
            },
            {
                title: 'Orders',
                children: [
                    {
                        title: 'Order fields',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['order-fields']}}]
                    },
                    {
                        title: 'Order line fields',
                        url: ['/', 'settings', {outlets: {settings_outlet: ['order-line-fields']}}]
                    }
                ]
            },
            {
                title: 'VAT types',
                url: ['/', 'settings', {outlets: {settings_outlet: ['vat-types']}}]
            },
            {
                title: 'Units',
                url: ['/', 'settings', {outlets: {settings_outlet: ['units']}}]
            },
            {
                title: 'Payment agreements',
                url: ['/', 'settings', {outlets: {settings_outlet: ['payment-agreements']}}]
            },
            {
                title: 'References',
                url: ['/', 'settings', {outlets: {settings_outlet: ['references']}}]
            },
            {
                title: 'Dates',
                url: ['/', 'settings', {outlets: {settings_outlet: ['dates']}}]
            },
            {
                title: 'Order types',
                url: ['/', 'settings', {outlets: {settings_outlet: ['order-types']}}]
            },
            {
                title: 'Order states',
                url: ['/', 'settings', {outlets: {settings_outlet: ['order-states']}}]
            },
            {
                title: 'Branches',
                url: ['/', 'settings', {outlets: {settings_outlet: ['branches']}}]
            },
            {
                title: 'User',
                url: ['/', 'settings', {outlets: {settings_outlet: ['user']}}]
            },
            {
                title: 'Users',
                url: ['/', 'settings', {outlets: {settings_outlet: ['users']}}]
            },
            {
                title: 'Groups',
                url: ['/', 'settings', {outlets: {settings_outlet: ['groups']}}]
            },
            {
                title: 'Audit log',
                url: ['/', 'settings', {outlets: {settings_outlet: ['audit-log']}}]
            },
            {
                title: 'Attachments',
                url: ['/', 'settings', {outlets: {settings_outlet: ['attachments']}}]
            },
            {
                title: 'Articles',
                url: ['/', 'settings', {outlets: {settings_outlet: ['articles']}}]
            },
            {
                title: 'Currencies',
                url: ['/', 'settings', {outlets: {settings_outlet: ['currencies']}}]
            },
        ];
    }
}
