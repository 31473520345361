import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from "../services/product";
import {PriceGroupsService} from "../services/price-groups.service";
import {PriceGroup} from "../services/price-group";
import {PriceAdjustment} from "../services/price-adjustment";
import {PriceAdjustmentsService} from "../services/price-adjustments.service";
import {ProductQuantityDiscount} from "../services/product-quantity-discount";
import {OrderLine} from "../services/order-line";

@Component({
    selector: 'app-product-quantity-discount-modal',
    templateUrl: './product-quantity-discount-modal.component.html',
    styleUrls: ['./product-quantity-discount-modal.component.scss'],
    providers: [PriceGroupsService, PriceAdjustmentsService]
})
export class ProductQuantityDiscountModalComponent implements OnInit {

    product: Product;
    priceGroups: PriceGroup[];
    priceAdjustments: PriceAdjustment[];

    productQuantityDiscount: ProductQuantityDiscount;
    _productQuantityDiscount: ProductQuantityDiscount;

    constructor(public activeModal: NgbActiveModal,
                public priceGroupsService: PriceGroupsService,
                public priceAdjustmentsService: PriceAdjustmentsService) {
    }

    ngOnInit() {
        this.priceGroupsService.getPriceGroups(1, -1).subscribe(response => {
            this.priceGroups = response.data;
        });

        this.priceAdjustmentsService.getPriceAdjustments().subscribe(response => {
            this.priceAdjustments = response.data;
        })

        this._productQuantityDiscount = this.productQuantityDiscount ? ProductQuantityDiscount.fromJSON(this.productQuantityDiscount) : ProductQuantityDiscount.fromJSON({
            product_id: this.product.id,
            price_group_id: null,
            price_adjustment_id: null,
            requires_min_multiple: false
        });
    }

    save() {
        if (this.productQuantityDiscount) {
            let index = this.product.product_quantity_discounts.findIndex((element) => {
                return element.id === this.productQuantityDiscount.id;
            });

            if (index >= 0) {
                this.product.product_quantity_discounts[index] = this._productQuantityDiscount;
            }
        } else {
            this.product.product_quantity_discounts.push(this._productQuantityDiscount);
        }

        this.close();
    }

    close() {
        this.activeModal.dismiss();
    }
}
