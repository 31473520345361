import {Component, Input, OnInit} from '@angular/core';
import {faPlusSquare, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ProductsService} from "../../../services/products.service";
import {Product} from "../../../services/product";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../../services/api.service";
import {environment} from "../../../../environments/environment";

@Component({
    providers: [ProductsService],
    selector: 'app-helms-compare',
    templateUrl: './helms-compare.component.html',
    styleUrls: ['./helms-compare.component.scss']
})
export class HelmsCompareComponent implements OnInit {

    faPlus = faPlusSquare;
    faTimes = faTimes;

    public data: {
        data: Product[],
        count: number,
        perPage: number
    } = undefined;
    public entries: {
        title: string,
        key: string
    }[] = undefined;

    public selectedComparisons: any[] = [{selection: null}];

    public models: { name: string | unknown; id: number }[];
    public competition: { name: string | unknown; id: number }[];
    public result: string[][] = [];
    public loading: boolean = false;

    constructor(public productService: ProductsService, public http: HttpClient, public apiService: ApiService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.http.get('https://www.helmstmt.com/api/compare').subscribe((response: any) => {
            this.models = Object.entries(response.models).map((value, key) => {
                return {id: parseInt(value[0], 10), name: value[1],};
            });

            this.competition = Object.entries(response.competition).map((value, key) => {
                return {id: parseInt(value[0], 10), name: value[1]};
            });
            this.loading = false;
        });
    }

    performCompare() {

        let data = {model: null, comp: []};
        this.selectedComparisons.forEach((item, index) => {
            if (index === 0) {
                data.model = item.selection.id;
            } else {
                data.comp.push(item.selection.id);
            }
        });
        this.loading = true;

        this.http.post(environment.apiBase + 'comparisons/compare', data, {headers: this.apiService.getHeaders(true, 'application/json')}).subscribe((response: { data: string[][] }) => {
            this.loading = false;
            this.result = response.data;
        });
    }

    addComparison(): void {
        this.selectedComparisons.push({selection: null});
    }

    changeSelectedValue(value: any, oldValue: string): void {
        this.performCompare();
    }

    removeComparison(value: string): void {
        const index = this.selectedComparisons.indexOf(value);
        if (index > -1) {
            this.selectedComparisons.splice(index, 1);
        }
    }

    getValue(id: string, name: string): string {
        const object = this.data.data.find((item) => {
            return item.id === id
        });
        if (object) {
            return object[name];
        } else {
            return '';
        }
    }

    getMetaValue(id: string, key: string): string {
        const object = this.data.data.find((item) => {
            return item.id === id
        });
        const metaObject = object.product_fields.find((meta) => {
            return meta.id === key
        });
        if (object && metaObject) {
            return metaObject._joinData.value;
        } else {
            return '';
        }
    }

}
