<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!contactType" i18n>Add contact type</ng-container>
            <ng-container *ngIf="contactType" i18n>Edit contact type</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-loader *ngIf="loading" i18n>Loading...</app-loader>
        <form *ngIf="!loading">
            <div class="form-group">
                <label for="contact-type-name" i18n>Name</label>
                <input type="text" id="contact-type-name" class="form-control" [(ngModel)]="_contactType.name" name="name">
            </div>

            <div class="form-group">
                <label for="contact-type" class="form-label" i18n>Contact field preset</label>
                <select id="contact-type" class="form-control" [(ngModel)]="_contactType.contact_field_preset_id" name="contact_field_preset_id">
                    <option [ngValue]="null" i18n>-- Select contact preset --</option>
                    <option *ngFor="let contactFieldPreset of contactFieldPresets" [ngValue]="contactFieldPreset.id">{{ contactFieldPreset.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_contactType.is_order_invoice" name="is_order_invoice" class="mr-1"> <ng-container i18n>Order invoice</ng-container>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="_contactType.is_order_delivery" name="is_order_delivery" class="mr-1"> <ng-container i18n>Order delivery</ng-container>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="saving">
            <ng-container *ngIf="saving" i18n>Saving...</ng-container>
            <ng-container *ngIf="!saving" i18n>Save</ng-container>
        </button>
    </div>
</div>
