<div class="container app__component">
    <h1 class="heading">
        <ng-container i18n>VAT types</ng-container>
        <small *ngIf="loading && vatTypes" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!vatTypes" class="text-muted" i18n>Loading...</app-loader>

    <p *ngIf="vatTypes">
        <a class="btn  btn-primary  button  button--add-to-table" href="javascript:" (click)="add()" i18n>Add VAT type</a>
    </p>

    <app-table *ngIf="vatTypes" [autoSizeLastColumn]="true">
        <thead>
            <tr>
                <th i18n>Name</th>
                <th class="text-right" i18n>Rate</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-vatType [ngForOf]="vatTypes">
                <tr>
                    <td>
                        {{ vatType.name }}
                        <span *ngIf="vatType.is_default" class="badge  badge-primary  mr-2" i18n>Default</span>
                    </td>
                    <td class="text-right">{{ vatType.rate | number }}%</td>
                    <td class="text-right">
                        <a href="javascript:" (click)="edit(vatType)" i18n>Edit</a>
                        <a href="javascript:" (click)="delete(vatType)" class="text-danger" i18n>Delete</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </app-table>
</div>
