import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Language} from './language';

@Injectable()
export class LanguagesService {

    protected url = environment.apiBase + 'languages';

    public cachedLanguages: Observable<{data: Language[], count: number, perPage: number}>;
    public selectedLanguage: Language;
    public languages: Language[];
    public primaryLanguage: Language;
    public index: number;
    private selectedLanguageIndex: Subject<number> = new Subject<number>();
    private languagesLoaded: Subject<any> = new Subject<any>();

    constructor(public apiService: ApiService) {
    }

    getLanguages(): Observable<number>  {
        // @todo cache doesnt work very well because of many routings that clear it
        if (!this.cachedLanguages) {
            this.cachedLanguages = this.apiService.get<Language[]>(this.url, true, Language);
        }
        // Fill out languagesservice as it's called in component
        this.cachedLanguages.subscribe((response) => {
            this.languages = response.data;
            this.languages.forEach((language) => {
                if (language.isPrimary) {
                    this.primaryLanguage = language;
                    this.selectedLanguage = language;
                }
            });
            this.index = this.findIndex(this.selectedLanguage);
            // component may now load languages
            this.languagesLoaded.next();
        });
        // use languagesLoaded to ping component when languagesservice is loaded
        // component reads from service anyway
        return this.languagesLoaded.asObservable();
    }

    getLanguageIndex(): Observable<number> {
        return this.selectedLanguageIndex.asObservable();
    }

    setLanguage(language): void {
        this.selectedLanguage = language;
        let i = 0;
        let index;
        this.languages.forEach((listLanguage) => {
            if (this.isSame(listLanguage, language)) {
                index = i;
            }
            i++;
        });
        this.index = index;
        this.selectedLanguageIndex.next(this.index);
    }

    findIndex(language): number {
        let i = 0;
        let index = -1;
        this.languages.forEach((listLanguage) => {
            if (this.isSame(listLanguage, language)) {
                index = i;
            }
            i++;
        });

        return index;
    }

    isSame(language: Language, languageToCompare: Language): boolean {
        return language.locale === languageToCompare.locale;
    }
}
