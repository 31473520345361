<table *ngIf="contactFields" class="table  table-bordered  table-sm  mb-0">
    <ng-template ngFor let-contactField [ngForOf]="contactFields.contact_fields">
        <tr>
            <td>{{ contactField.name }}</td>
            <td>
                <strong>
                    {{ order.getContactWithName('Contact') && order.getContactWithName('Contact').getContactField(contactField.id) ? order.getContactWithName('Contact').getContactField(contactField.id) : '-' }}
                </strong>
            </td> <!-- @todo -->
        </tr>
    </ng-template>
    <tr *ngIf="order.isDebtor()">
        <td>Prislister / Rabat</td>
        <td>
            <ul class="list-unstyled  mb-0">
                <ng-template *ngIf="order.debtor" ngFor let-priceGroup [ngForOf]="order.debtor.price_groups">
                    <li><strong>{{ priceGroup.name }}</strong></li>
                </ng-template>
            </ul>

            <strong *ngIf="order.debtor && order.debtor.price_groups.length === 0">-</strong>
        </td>
    </tr>
</table>
