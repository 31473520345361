<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!productStock.id" i18n>Add product stock</ng-container>
            <ng-container *ngIf="productStock.id" i18n>Edit product stock</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!branches" class="text-muted" i18n>Loading...</p>

            <p *ngIf="branches && branches.length === 0" class="text-danger" i18n>All branches are already added to product</p>

            <ng-container *ngIf="branches && branches.length > 0">
                <form class="form">
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="product-product-stock-quanity" i18n>Quantity</label>
                            <input type="number" min="0" id="product-product-stock-quanity" class="form-control" [(ngModel)]="productStock.quantity" name="product-product-stock-quanity">
                        </div>

                        <div class="form-group col-md-6">
                            <label for="product-product-stock-location-reference" i18n>Location reference</label>
                            <input type="text" id="product-product-stock-location-reference" class="form-control" [(ngModel)]="productStock.location_reference" name="product-product-stock-location-reference">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="product-product-stock-branch_id" i18n>Branch</label>

                            <select id="product-product-stock-branch_id" class="form-control" [(ngModel)]="productStock.branch" name="product-product-stock-branch_id">
                                <option [ngValue]="null" i18n>-- Select branch --</option>
                                <option *ngFor="let branch of branches" [ngValue]="branch">{{ branch.name }}</option>
                            </select>
                        </div>
                    </div>
                </form>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="add()" [disabled]="!branches || !productStock.branch || !productStock.quantity" i18n>Add</button>
    </div>
</div>
