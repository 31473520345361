import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../../services/order';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {OrderLine} from '../../../services/order-line';
import {faHatCowboy, faPen, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Options} from "sortablejs";
import {ProductsService} from "../../../services/products.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OrderLineAjourModalComponent} from "../../../order-line-ajour-modal/order-line-ajour-modal.component";

@Component({
    selector: 'app-orderlines',
    templateUrl: './orderlines.component.html',
    styleUrls: ['./orderlines.component.scss'],
    providers: [ProductsService]
})
export class OrderlinesComponent implements OnInit {

    @Input() order: Order | null | undefined;
    @Input() showExtendedCostPrice:boolean;

    faPen = faPen;
    faHat = faHatCowboy;
    faTimes = faTimes;
    faSave = faSave;

    disableOrderlinesManipulation = false;
    options: Options;

    constructor(public ordersService: TenFourOrderService,
                public productsService: ProductsService,
                public ngbModal: NgbModal) {
        this.options = {
            handle: '.sortablejs-handle',
            onUpdate: (event: any) => {
                this.updateOrderLine(undefined);
            }
        };

    }

    public ngOnInit(): void {
    }

    public ngOnChanges() {
        /**
         * When something changes, like the order, we recalculate the alternative pice unit quantities
         */
        for (let orderLine of this.order.order_lines) {
            if (orderLine.alternative_price_unit) {
                orderLine.alternative_price_unit.quantity = orderLine.alternative_price_unit.getUnits(orderLine.quantity);
            }
        }

        /**
         * We disable order line manipulation
         */
        this.disableOrderlinesManipulation = false;
    }

    public editOrderLine(id: string): void {
        const editLine = this.order.order_lines.find((item) => {
            return item.id === id;
        });
        editLine._isEditing = !editLine._isEditing;
    }

    public updateOrderLine(orderLine?: OrderLine): void {
        this.disableOrderlinesManipulation = true;

        if (orderLine) {
            orderLine._isEditing = false;
        }

        this.ordersService.updateOrder(this.order).subscribe((response) => {
            this.ordersService.refreshOrder();

            // We do not enable order line manipulation yet, we expect to see a change, and then it will be enabled
        });
    }

    public deleteOrderLine(orderLine: OrderLine): void {
        if (confirm($localize `Are you sure?\n\nThe order line will be deleted`)) {

            let index = this.order.order_lines.findIndex((element) => {
                return element['id'] === orderLine.id;
            });

            if (index >= 0) {
                this.order.order_lines.splice(index, 1);

                this.ordersService.updateOrder(this.order).subscribe((_) => {
                    this.ordersService.refreshOrder();
                });
            }
        }
    }

    public openOrderLineAjourModal(orderLine: OrderLine) {
        let self = this;

        const modalRef = this.ngbModal.open(OrderLineAjourModalComponent, {size: 'lg'});
        modalRef.componentInstance.order = this.order;
        modalRef.componentInstance.orderLine = orderLine;
        modalRef.componentInstance.saved = function (orderLine: OrderLine) {
            /**
             * Replace order line in order
             */
            let index = self.order.order_lines.findIndex((element) => {
                return element.id === orderLine.id;
            });

            if (index >= 0) {
                self.order.order_lines[index] = orderLine;
                self.updateOrderLine(orderLine);
            }
        }
    }

    public quantityChanged(orderLine: OrderLine) {
        /**
         * If we have a alternative price unit, then set the quantity
         */
        if (orderLine.alternative_price_unit) {
            orderLine.alternative_price_unit.quantity = orderLine.alternative_price_unit.getUnits(orderLine.quantity);
        }
    }
}
