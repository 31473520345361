import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Article} from './article';
import {Attachment} from './attachment';
import {Empty} from './empty';

@Injectable()
export class ArticlesService {

    protected url = environment.apiBase + 'articles';

    constructor(public apiService: ApiService) {
    }

    getArticle(id: string): Observable<{data: Article, count: number, perPage: number}>  {
        const url = this.url + '/' + id;

        return this.apiService.get<Article>(url, true, Article);
    }

    getArticles(page: number, limit: number, isActive?: boolean): Observable<{ data: Article[], count: number, perPage: number }>  {
        let url = this.url;

        const parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            parameters.push('limit=' + limit);
        }

        if (isActive) {
            parameters.push('is_active=1');
        } else if (isActive === false) {
            parameters.push('is_active=0');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<Article[]>(url, true, Article);
    }

    addArticle(article: Article): Observable<{data: Article, count: number, perPage: number}>  {
        return this.apiService.post<Article>(this.url, article, true, Article);
    }

    addArticleDraft(): Observable<{data: Article, count: number, perPage: number}>  {
        const article = new Article();
        article.title = 'Article draft';
        article.body = 'This article is just a draft, remember to publish when it is finished';
        article.is_active = false;
        return this.apiService.post<Article>(this.url, article, true, Article);
    }

    updateArticle(article: Article): Observable<{data: Article, count: number, perPage: number}>   {
        return this.apiService.patch<Article>(this.url + '/' + article.id, article, true, Article);
    }

    deleteArticle(article: Article): Observable<{data: Article, count: number, perPage: number}>   {
        return this.apiService.delete<Article>(this.url + '/' + article.id, true, Article);
    }

    attachment(attachment: Attachment, file: File, id: string): Observable<{data: Attachment, count: number, perPage: number}>   {
        const url = this.url + '/' + id + '/attachment';
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('name', file.name);
        formData.append('description', attachment.description);
        formData.append('language', attachment.language);
        if (attachment.sort === 0) {
            formData.append('sort', attachment.sort.toString());
        }

        return this.apiService.upload<Attachment>(url, formData, true, Attachment, null);
    }

}
