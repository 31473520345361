import {Injectable} from '@angular/core';
import {IdValuesService} from './id-values.service';
import {Order} from '../../order';
import {OrderLine} from '../../order-line';

@Injectable()
export class MachinesService {

    constructor(public idValuesService: IdValuesService) {
    }

    checkMachineOrderLine(orderLine: OrderLine): boolean{
        for (const category of orderLine.product.product_categories) {
            if (category.id === this.idValuesService.productMachineCategoryId) {
                return true;
            }
        }
        return false;
    }

    getMachineOrderLine(order: Order, provideFallback?: boolean): OrderLine {
        for (const orderLine of order.order_lines) {
            for (const category of orderLine.product.product_categories) {
                if (category.id === this.idValuesService.productMachineCategoryId) {
                    return orderLine;
                }
            }
        }

        // fallback, we need a "dummy" machine orderline to save extra order data @todo add order_fields instead ?
        if (provideFallback && order.order_lines.length > 0) {
            return order.order_lines[0];
        }
        return null;
    }
}
