import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login/login.component';
import {AuthService} from './services/auth.service';
import {UsersComponent} from './users/users.component';
import {GroupsComponent} from './groups/groups.component';
import {BranchesComponent} from './branches/branches.component';
import {ProductsComponent} from './products/products.component';
import {CreditorsComponent} from './creditors/creditors.component';
import {DebtorsComponent} from './debtors/debtors.component';
import {PriceGroupsComponent} from './price-groups/price-groups.component';
import {OrdersComponent} from './orders/orders.component';
import {OrderEditComponent} from './order-edit/order-edit.component';
import {OrderStatesComponent} from './order-states/order-states.component';
import {ReferencesComponent} from './references/references.component';
import {ProductCategoriesComponent} from './product-categories/product-categories.component';
import {ProductEditComponent} from './product-edit/product-edit.component';
import {ProductFieldsComponent} from './product-fields/product-fields.component';
import {ProductFieldPresetsComponent} from './product-field-presets/product-field-presets.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PriceAdjustmentsComponent} from './price-adjustments/price-adjustments.component';
import {PriceAdjustmentEditComponent} from './price-adjustment-edit/price-adjustment-edit.component';
import {AuditLogsComponent} from './audit-logs/audit-logs.component';
import {environment} from '../environments/environment';
import {ThemeDarkComponent} from './theme-dark/theme.component';
import {ThemeLightComponent} from './theme-light/theme.component';
import {VatTypesComponent} from './vat-types/vat-types.component';
import {DatesComponent} from './dates/dates.component';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {UserComponent} from './user/user.component';
import {PaymentAgreementsComponent} from './payment-agreements/payment-agreements.component';
import {ProductImportComponent} from './product-import/product-import.component';
import {ContactFieldsComponent} from './contact-fields/contact-fields.component';
import {ContactFieldPresetsComponent} from './contact-field-presets/contact-field-presets.component';
import {OfferGeneratorComponent} from './offer-generator/offer-generator.component';
import {SettingsComponent} from './settings/settings.component';
import {ToolOrdersComponent} from './offer-generator/tool-orders/tool-orders.component';
import {ReadyOrdersComponent} from './offer-generator/ready-orders/ready-orders.component';
import {CustomOrderComponent} from './offer-generator/custom-order/custom-order.component';
import {ConfirmOrderComponent} from './offer-generator/confirm-order/confirm-order.component';
import {WheelSelectComponent} from './offer-generator/wheel-select/wheel-select.component';
import {ToolSelectComponent} from './offer-generator/tool-select/tool-select.component';
import {ThorsensOrdersComponent} from './themes/thorsens/orders/orders.component';
import {CreditorComponent} from './creditor/creditor.component';
import {DebtorComponent} from './debtor/debtor.component';
import {ResetPasswordComponent} from './login/reset-password/reset-password.component';
import {ThorsensEditOrderComponent} from './themes/thorsens/edit-order/edit-order.component';
import {TenFourStep1OrderDetailsComponent} from './themes/10-4/step-1-order-details/step-1-order-details.component';
import {TenFourStep2OrderOverviewComponent} from './themes/10-4/step-2-order-overview/step-2-order-overview.component';
import {TenFourOrderComponent} from './themes/10-4/order/order.component';
import {TenFourOrdersComponent} from './themes/10-4/orders/orders.component';


// @todo router change based on environment variables
function foo(): any {
    return environment.darkMode ? ThemeDarkComponent : ThemeLightComponent;
}

import {Order} from './services/order';
import {DeliveriesComponent} from './themes/thorsens/deliveries/deliveries.component';
import {FieldsComponent} from './themes/thorsens/fields/fields.component';
import {AnalysisDetailComponent} from './themes/thorsens/analysis-detail/analysis-detail.component';
import {ThorsensDashboardComponent} from './themes/thorsens/thorsens-dashboard/thorsens-dashboard.component';
import {UnitsComponent} from './units/units.component';
import {HelmsOrdersComponent} from './themes/helms/helms-orders/helms-orders.component';
import {OrderDeliveriesModalComponent} from './themes/thorsens/order-deliveries-modal/order-deliveries-modal.component';
import {OrderTypesComponent} from './order-types/order-types.component';
import {ContactTypesComponent} from './contact-types/contact-types.component';
import {HelmsDashboardComponent} from './themes/helms/helms-dashboard/helms-dashboard.component';
import {HelmsStatisticsComponent} from './themes/helms/helms-statistics/helms-statistics.component';
import {HelmsCompareComponent} from './themes/helms/helms-compare/helms-compare.component';
import {AttachmentsComponent} from './attachments/attachments.component';
import {OrderLineFieldsComponent} from './order-line-fields/order-line-fields.component';
import {HelmsInventoryComponent} from './themes/helms/helms-inventory/helms-inventory.component';
import {HelmsFilecenterComponent} from './themes/helms/helms-filecenter/helms-filecenter.component';
import {HelmsDemosComponent} from './themes/helms/helms-demos/helms-demos.component';
import {HelmsToolsOrderComponent} from './themes/helms/helms-tools-order/helms-tools-order.component';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleEditComponent} from './article-edit/article-edit.component';
import {HelmsPrintFriendlyOrderComponent} from './themes/helms/helms-print-friendly-order/helms-print-friendly-order.component';
import {SaleComponent} from './themes/10-4/sale/sale.component';
import {BuyComponent} from './themes/10-4/buy/buy.component';
import {LookupComponent} from './themes/10-4/lookup/lookup.component';
import {InventoryComponent} from './themes/10-4/inventory/inventory.component';
import {PrintComponent} from './themes/10-4/print/print.component';
import {FinanceComponent} from './themes/10-4/finance/finance.component';
import {CorporateComponent} from './themes/10-4/corporate/corporate.component';
import {CurrenciesComponent} from './currencies/currencies.component';
import {ProductEditorComponent} from './product-editor/product-editor.component';
import {HelmsOrderEditComponent} from './themes/helms/helms-order-edit/helms-order-edit.component';
import {LoadOfferComponent} from './offer-generator/load-offer/load-offer.component';
import {HelmsOrdersArrivalsComponent} from './themes/helms/helms-orders-arrivals/helms-orders.component';
import {HelmsOrdersFactoryComponent} from './themes/helms/helms-orders-factory/helms-orders-factory.component';
import {HelmsOffersComponent} from './themes/helms/helms-offers/helms-offers.component';
import {AttachmentDownloadComponent} from './attachment-download/attachment-download.component';
import {OrderFieldsComponent} from './order-fields/order-fields.component';

// @todo router change based on environment variables
function custom(): any {
    if (environment.customer === '10-4') {
        return [
            {path: 'salgsordre', component: SaleComponent, canActivate: [AuthService]},
            {path: 'koebsordre', component: BuyComponent, canActivate: [AuthService]},
            {path: 'opslag', component: LookupComponent, canActivate: [AuthService]},
            {path: 'kartotek', component: InventoryComponent, canActivate: [AuthService]},
            {path: 'udskrifter', component: PrintComponent, canActivate: [AuthService]},
            {path: 'finans', component: FinanceComponent, canActivate: [AuthService]},
            {path: 'koncern', component: CorporateComponent, canActivate: [AuthService]}
        ];
    }
    if (environment.customer === 'helms') {
        return [
            {path: 'admin/orders/add', component: OrderEditComponent, canActivate: [AuthService]},
            {path: 'admin/orders/:orderId', component: OrderEditComponent, canActivate: [AuthService]},
            {path: 'admin/orders', component: OrdersComponent, canActivate: [AuthService]},
            {path: 'inventory', component: ReadyOrdersComponent, canActivate: [AuthService], data: {standalone: true, mode: 'inventory'}},
            {path: 'pipeline', component: ReadyOrdersComponent, canActivate: [AuthService], data: {standalone: true, mode: 'pipeline'}},
            //   {path: 'inventory', component: HelmsInventoryComponent, canActivate: [AuthService]},
            //   {path: 'inventory/:orderId', component: HelmsOrderEditComponent, canActivate: [AuthService]},
            {path: 'file-center', component: HelmsFilecenterComponent, canActivate: [AuthService]},
            {path: 'statistics', component: HelmsStatisticsComponent, canActivate: [AuthService]},
            {path: 'compare', component: HelmsCompareComponent, canActivate: [AuthService]},
            {path: 'demos', component: HelmsDemosComponent, canActivate: [AuthService]},
            {
                path: 'print-friendly-order/:orderId',
                component: HelmsPrintFriendlyOrderComponent,
                canActivate: [AuthService]
            },
            {path: 'my-orders', component: HelmsOrdersComponent, canActivate: [AuthService], data: {
                    excludeState: '44019a09-dca4-435b-bf53-7e77a59694f2',
                forSelf: true}},
            {path: 'arrivals', component: HelmsOrdersArrivalsComponent, canActivate: [AuthService]},
            {path: 'factory', component: HelmsOrdersFactoryComponent, canActivate: [AuthService]},
            {path: 'offers', component: HelmsOffersComponent, canActivate: [AuthService]}
        ];
    }
    if (environment.customer === 'thorsens') {
        return [

            {
                path: 'orders/deliveries/:referenceValue', component: OrderDeliveriesModalComponent, canActivate: [AuthService],
            },
            {
                path: 'fields', component: FieldsComponent, canActivate: [AuthService],
            },
            {
                path: 'analysis/:productId', component: AnalysisDetailComponent, canActivate: [AuthService],
            }
        ];
    } else {
        return [];
    }
}

const TenFourOrderPaths = [
    {
        path: 'order-details', component: TenFourStep1OrderDetailsComponent, canActivate: [AuthService],
        outlet: 'order_outlet'
    },
    {
        path: 'order-overview', component: TenFourStep2OrderOverviewComponent, canActivate: [AuthService],
        outlet: 'order_outlet'
    },
];

function singleOrder(): any {
    switch (environment.customer) {
        case 'thorsens':
            return {
                new: {path: 'orders/add', component: ThorsensEditOrderComponent, canActivate: [AuthService]},
                edit: {path: 'orders/:orderId', component: ThorsensEditOrderComponent, canActivate: [AuthService]}
            };
        case '10-4':
            return {
                new: {
                    path: 'orders/add',
                    component: TenFourOrderComponent,
                    canActivate: [AuthService],
                    children: TenFourOrderPaths
                },
                edit: {
                    path: 'orders/:orderId',
                    component: TenFourOrderComponent,
                    canActivate: [AuthService],
                    children: TenFourOrderPaths
                }
            };
        case 'helms':
            return {
                new: {path: 'orders/add', component: HelmsOrderEditComponent, canActivate: [AuthService]},
                edit: {path: 'orders/:orderId', component: HelmsOrderEditComponent, canActivate: [AuthService]}
            };
        default:
            return {
                new: {path: 'orders/add', component: OrderEditComponent, canActivate: [AuthService]},
                edit: {path: 'orders/:orderId', component: OrderEditComponent, canActivate: [AuthService]}
            };
    }
}

function ordersComponent(): any {
    switch (environment.customer) {
        case '10-4':
            return TenFourOrdersComponent;
        case 'thorsens':
            return ThorsensOrdersComponent;
        case 'helms':
            return HelmsOrdersComponent;
    }
    return OrdersComponent;
}


const singleOrderValues = singleOrder();

function productsComponent(): any {

    switch (environment.customer) {
        case 'thorsens':
            return DeliveriesComponent;
        default:
            return ProductsComponent;
    }
}

function dashBoardComponent(): any {
    switch (environment.customer) {
        case 'thorsens':
            return ThorsensDashboardComponent;
        case 'helms':
            return HelmsDashboardComponent;
    }
    return DashboardComponent;
}

const routes: Routes = [
    {
        path: '', component: dashBoardComponent(), canActivate: [AuthService]
    },
    {path: 'login', component: LoginComponent},
    {path: 'forgot-password', component: ForgotPasswordComponent},
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: 'download/:attachmentId', component: AttachmentDownloadComponent, canActivate: [AuthService]},
    {path: 'users', component: UsersComponent, canActivate: [AuthService]},
    {path: 'groups', component: GroupsComponent, canActivate: [AuthService]},
    {path: 'audit-log', component: AuditLogsComponent, canActivate: [AuthService]},
    {path: 'branches', component: BranchesComponent, canActivate: [AuthService]},
    {path: 'products/add', component: ProductEditorComponent, canActivate: [AuthService]},
    {path: 'products/:productId', component: ProductEditorComponent, canActivate: [AuthService]},
    {path: 'creditors', component: CreditorsComponent, canActivate: [AuthService]},
    {path: 'creditors/:creditorId', component: CreditorComponent, canActivate: [AuthService]},
    {path: 'debtors', component: DebtorsComponent, canActivate: [AuthService]},
    {path: 'orders', component: ordersComponent(), canActivate: [AuthService]},
    {path: 'debtors/:debtorId', component: DebtorComponent, canActivate: [AuthService]},
    {path: 'articles', component: ArticlesComponent, canActivate: [AuthService]},
    {path: 'articles/add', component: ArticleEditComponent, canActivate: [AuthService]},
    {path: 'articles/:articleId', component: ArticleEditComponent, canActivate: [AuthService]},
    singleOrderValues.new,
    singleOrderValues.edit,
    {
        path: 'offers', component: OfferGeneratorComponent, canActivate: [AuthService], children: [
            {
                path: 'load/:orderId', component: LoadOfferComponent, canActivate: [AuthService],
                outlet: 'offer_outlet'
            },
            {
                path: 'tool-orders', component: ToolOrdersComponent, canActivate: [AuthService],
                outlet: 'offer_outlet'
            },
            {
                path: 'ready-orders', component: ReadyOrdersComponent, canActivate: [AuthService],
                outlet: 'offer_outlet'
            },
            {
                path: 'custom-order', component: CustomOrderComponent, canActivate: [AuthService],
                outlet: 'offer_outlet'
            },
            {
                path: 'wheel-select', component: WheelSelectComponent, canActivate: [AuthService],
                outlet: 'offer_outlet'
            },
            {
                path: 'tool-select', component: ToolSelectComponent, canActivate: [AuthService],
                outlet: 'offer_outlet'
            },
            {
                path: 'preview-order', component: ConfirmOrderComponent, canActivate: [AuthService],
                outlet: 'offer_outlet',
                data: {preview: true}
            },
            {
                path: 'confirm-order', component: ConfirmOrderComponent, canActivate: [AuthService],
                outlet: 'offer_outlet'
            }
        ]
    },
    {
        path: 'tool-offers', component: OfferGeneratorComponent, canActivate: [AuthService], children: [
            {
                path: 'select', component: HelmsToolsOrderComponent, canActivate: [AuthService], outlet: 'offer_outlet',
            },
            {
                path: 'preview',
                component: ConfirmOrderComponent,
                canActivate: [AuthService],
                data: {preview: true, mode: 'tools'},
                outlet: 'offer_outlet',
            },
            {
                path: 'confirm',
                component: ConfirmOrderComponent,
                canActivate: [AuthService],
                data: {mode: 'tools'},
                outlet: 'offer_outlet',
            }
        ]
        , data: {
            title: 'Redskabsbestilling',
            steps: [
                {
                    title: 'Vælg Redskaber',
                    url: ['/', 'tool-offers', {outlets: {offer_outlet: ['select']}}]
                },
                {
                    title: 'Oversigt',
                    url: ['/', 'tool-offers', {outlets: {offer_outlet: ['preview']}}]
                },
                {
                    title: 'Bekræft',
                    url: ['/', 'tool-offers', {outlets: {offer_outlet: ['confirm']}}]
                }
            ]
        }
    },
    {
        path: 'settings', component: SettingsComponent, canActivate: [AuthService], children: [
            {
                path: 'products', component: productsComponent(), canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'products/import', component: ProductImportComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'product-categories', component: ProductCategoriesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'product-fields', component: ProductFieldsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'product-field-presets', component: ProductFieldPresetsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'order-line-fields', component: OrderLineFieldsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            }, {
                path: 'order-fields', component: OrderFieldsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'payment-agreements', component: PaymentAgreementsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'contact-fields', component: ContactFieldsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'contact-field-presets', component: ContactFieldPresetsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'contact-types', component: ContactTypesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'price-groups', component: PriceGroupsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'price-adjustments', component: PriceAdjustmentsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'price-adjustments/add', component: PriceAdjustmentEditComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'price-adjustments/:priceAdjustmentId',
                component: PriceAdjustmentEditComponent,
                canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'vat-types', component: VatTypesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'units', component: UnitsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'references', component: ReferencesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'dates', component: DatesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'order-states', component: OrderStatesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'order-types', component: OrderTypesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'branches', component: BranchesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'user', component: UserComponent,
                outlet: 'settings_outlet'
            },
            {
                path: 'users', component: UsersComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'groups', component: GroupsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'audit-log', component: AuditLogsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'attachments', component: AttachmentsComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'articles', component: ArticlesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            },
            {
                path: 'currencies', component: CurrenciesComponent, canActivate: [AuthService],
                outlet: 'settings_outlet'
            }
        ]
    },
    {
        path: 'profile', component: UserComponent,
        canActivate: [AuthService],
    },
    {path: 'custom', children: custom()},
    {
        path: 'production-lines',
        loadChildren: () => import('./helms-production-lines/helms-production-lines.module').then(m => m.HelmsProductionLinesModule),
        canActivate: [AuthService],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
