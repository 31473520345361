import {Component, OnInit} from '@angular/core';
import {Unit} from '../services/unit';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyEditModalComponent} from '../currency-edit-modal/currency-edit-modal.component';
import {CurrenciesService} from '../services/currencies.service';
import {Currency} from '../services/currency';

@Component({
    selector: 'app-currencies',
    templateUrl: './currencies.component.html',
    styleUrls: ['./currencies.component.scss'],
    providers: [CurrenciesService]
})
export class CurrenciesComponent implements OnInit {

    currencies: Currency[];

    loading = false;

    constructor(public currenciesService: CurrenciesService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load(): void {
        this.loading = true;

        this.currenciesService.getCurrencies(1, 10).subscribe(response => {
            this.currencies = response.data;

            this.loading = false;
        });
    }

    add(): void {
        const self = this;

        const modalRef = this.ngbModal.open(CurrencyEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = () => {
            self.load();
        };
    }

    edit(currency: Currency): void {
        const self = this;

        const modalRef = this.ngbModal.open(CurrencyEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.currency = currency;
        modalRef.componentInstance.saved = () => {
            self.load();
        };
    }

    delete(currency: Currency): void {
        if (confirm($localize `Are you sure?\n\nThe unit will be deleted`)) {
            this.loading = true;

            this.currenciesService.deleteCurrency(currency).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
