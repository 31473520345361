import * as dayjs from 'dayjs';
import {Order, OrderJSON} from "../../order";
import {Reservation} from "./reservation";

export class HelmsOrder extends Order {

    reservations?: Reservation[];

    static fromJSON(json: HelmsOrderJSON): HelmsOrder {
        let data = super.fromJSON(json);
        let neworder = new HelmsOrder();

        let extra = {
            reservations: [],
        };

        Object.keys(json).forEach((key, _) => {
            if (key === 'reservations') {
                json[key].forEach((value, _) => {
                    extra[key].push(Reservation.fromJSON(value));
                });
            }
        });

        return Object.assign(neworder, data, extra);
    }
}

interface HelmsOrderJSON extends OrderJSON {
    reservations?: Reservation[];
}
