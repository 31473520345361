import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {BranchesService} from '../../../services/branches.service';
import {OrderStatesService} from '../../../services/order-states.service';
import {DebtorsService} from '../../../services/debtors.service';
import {ReferencesService} from '../../../services/references.service';
import {ProductsService} from '../../../services/products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../../../services/order';
import {Reference} from '../../../services/reference';
import {Subject, Subscription} from 'rxjs';
import {OrderLine} from '../../../services/order-line';
import { debounceTime } from 'rxjs/operators';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-products-search-and-add',
    templateUrl: './products-search-and-add.component.html',
    styleUrls: ['./products-search-and-add.component.scss']
})
export class ProductsSearchAndAddComponent implements OnInit, OnDestroy {

    debounceTime = 500;

    @Output() didAddProductToOrderlines = new EventEmitter<boolean>();

    search = '';

    showProductsSearchResult = false;

    productReferences: Reference[] = [];

    productsSearchSubject: Subject<string> = new Subject<string>();
    productsSearchSubjectReference: Subscription;

    order: Order | null | undefined;
    orderlinesFromProductsSearch: OrderLine[] = [];

    faPlus = faPlus;

    constructor(public ordersService: TenFourOrderService,
                public branchesService: BranchesService,
                public orderStatesService: OrderStatesService,
                public debtorsService: DebtorsService,
                public referencesService: ReferencesService,
                public productsService: ProductsService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router) {
        this.ordersService.currentOrder.subscribe((order) => {
            this.order = order;
        });
    }

    ngOnInit(): void {
        this.referencesService.getReferences().subscribe(response => {
            for (const reference of response.data) {
                if (reference.is_product_reference) {
                    this.productReferences.push(reference);
                }
            }
        });
        this.productsSearchSubjectReference = this.productsSearchSubject.pipe(
            debounceTime(this.debounceTime),
        ).subscribe((val) => {
            this.searchProducts(val);
        });
    }

    searchProducts(val: string): void {
        if (val.length > 0) {
            this.productsService.getProducts(1, 100, true, true, true, true, true, true, {
                byReferenceId: this.productReferences.map(reference => reference.id).join(','),
                byRefrenceValue: val,
                withUnit: true,
                withAdjustedPrices: true,
                withAdjustedCostPrices: true,
                withProductRelations: true
            }).subscribe(response => {
                let orderLines = [];

                for (let product of response.data) {
                    orderLines.push(OrderLine.fromJSON({
                        product_id: product.id,
                        price: product.price,
                        cost_price: product.cost_price,
                        cost_price_adjusted: product.cost_price_adjusted,
                        quantity: 1,
                        product: product,
                        discount_percentage: 0,
                        price_adjusted: product.adjusted_price,
                        price_adjustment_name: product.adjusted_name,
                        price_adjustment_percentage: product.adjusted_percentage
                    }));

                    // Add related products to array with ID of this product as key
                    if (product.product_relation_childrens.length > 0) {
                        for (let productRelation of product.product_relation_childrens) {
                            orderLines.push(OrderLine.fromJSON({
                                product_id: productRelation.product.id,
                                price: productRelation.product.price,
                                cost_price: productRelation.product.cost_price,
                                cost_price_adjusted: productRelation.product.cost_price_adjusted,
                                quantity: 1,
                                product: productRelation.product,
                                discount_percentage: 0,
                                price_adjusted: productRelation.product.adjusted_price,
                                price_adjustment_name: productRelation.product.adjusted_name,
                                price_adjustment_percentage: productRelation.product.adjusted_percentage,
                                is_related_product: true
                            }))
                        }
                    }
                }

                this.orderlinesFromProductsSearch = orderLines;
            });
            this.showProductsSearchResult = true;
        } else {
            this.showProductsSearchResult = false;
            this.orderlinesFromProductsSearch = null;
        }
    }

    productsSearchInputChange(): void {
        this.orderlinesFromProductsSearch = null;
        this.productsSearchSubject.next(this.search);
    }

    resetSearchInput(): void {
        this.search = '';

        this.productsSearchInputChange();
    }

    addOrderlineToOrder(orderLine: OrderLine): void {
        this.order.addOrderLine(OrderLine.fromJSON(orderLine));
        this.ordersService.updateOrder(this.order).subscribe((response) => {
            this.ordersService.setOrder(response.data);
            this.didAddProductToOrderlines.emit(true);
        });
    }

    ngOnDestroy(): void {
        this.productsSearchSubjectReference.unsubscribe();
    }

}
