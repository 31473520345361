import {Component, OnInit} from '@angular/core';
import {PriceAdjustment} from "../services/price-adjustment";
import {PriceAdjustmentsService} from "../services/price-adjustments.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-price-adjustments',
    templateUrl: './price-adjustments.component.html',
    styleUrls: ['./price-adjustments.component.sass'],
    providers: [PriceAdjustmentsService]
})
export class PriceAdjustmentsComponent implements OnInit {

    priceAdjustments: PriceAdjustment[];

    loading = false;

    constructor(public priceAdjustmentsService: PriceAdjustmentsService,
                public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;

        this.priceAdjustmentsService.getPriceAdjustments().subscribe(response => {
            this.priceAdjustments = response.data;

            this.loading = false;
        });
    }

    delete(priceAdjustment: PriceAdjustment) {
        if (confirm($localize `Are you sure?\n\nThe price adjustment will be deleted`)) {
            this.loading = true;

            this.priceAdjustmentsService.deletePriceAdjustment(priceAdjustment).subscribe(result => {
                this.loading = false;

                this.load();
            });
        }
    }
}
