import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Language} from './language';
import {Article} from "./article";
import {Currency} from "./currency";

@Injectable()
// @ts-ignore
export class CurrenciesService {

    protected url = environment.apiBase + 'currencies';
    public cachedCurrencies: Observable<{data: Currency[], count: number, perPage: number}>;

    constructor(public apiService: ApiService) {
    }

    getCurrency(id: string): Observable<{data: Currency, count: number, perPage: number}>  {
        const url = this.url + '/' + id;

        return this.apiService.get<Currency>(url, true, Currency);
    }

    getCurrencies(page: number, limit: number): Observable<{ data: Currency[], count: number, perPage: number }>  {
        let url = this.url;

        const parameters = [];

        parameters.push('page=' + page);

        if (limit) {
            parameters.push('limit=' + limit);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }
        if (!this.cachedCurrencies) {
            this.cachedCurrencies = this.apiService.get<Currency[]>(url, true, Article);
        }
        return this.cachedCurrencies;
    }

    addCurrency(currency: Currency): Observable<{data: Currency, count: number, perPage: number}>  {
        return this.apiService.post<Currency>(this.url, currency, true, Currency);
    }

    updateCurrency(currency: Currency): Observable<{data: Currency, count: number, perPage: number}>   {
        return this.apiService.patch<Currency>(this.url + '/' + currency.id, currency, true, Currency);
    }

    deleteCurrency(currency: Currency): Observable<{data: Currency, count: number, perPage: number}>   {
        return this.apiService.delete<Currency>(this.url + '/' + currency.id, true, Currency);
    }
}
