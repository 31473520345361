import {Component, OnInit} from '@angular/core';
import {Creditor} from "../services/creditor";
import {CreditorsService} from "../services/creditors.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreditorEditModalComponent} from "../creditor-edit-modal/creditor-edit-modal.component";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'app-creditors',
    templateUrl: './creditors.component.html',
    styleUrls: ['./creditors.component.scss'],
    providers: [CreditorsService]
})
export class CreditorsComponent implements OnInit {

    creditors: Creditor[];

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    faPencilAlt = faPencilAlt;
    faTimes = faTimes;

    constructor(
        public authService: AuthService,
        public creditorsService: CreditorsService,
        public ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.load(this.page);
    }

    load(page: number) {
        this.loading = true;

        this.creditorsService.getCreditors(page, this.limit, true, true).subscribe(response => {
            this.creditors = response.data;
            this.count = response.count;
            this.perPage = response.perPage;
            this.loading = false;
        });
    }

    pageChange(page: number) {
        this.load(page);
    }

    add() {
        let self = this;

        const modalRef = this.ngbModal.open(CreditorEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.saved = function () {
            self.load(this.page);
        }
    }

    edit(creditor: Creditor) {
        let self = this;

        const modalRef = this.ngbModal.open(CreditorEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.creditor = creditor;
        modalRef.componentInstance.saved = function () {
            self.load(this.page);
        }
    }

    delete(creditor: Creditor) {
        if (confirm($localize`Are you sure?\n\nThe creditor will be deleted`)) {
            this.loading = true;

            this.creditorsService.deleteCreditor(creditor).subscribe(result => {
                this.loading = false;

                this.load(this.page);
            });
        }
    }
}
