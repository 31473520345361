<ng-container *ngIf="orders">
    <h1 class="heading" i18n>Demo Reservation</h1>
    <app-table [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th><span i18n>Name</span>:</th>
            <th><span i18n>No.</span>:</th>
            <th></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-order [ngForOf]="orders">
            <tr>
                <td>{{ machinesService.getMachineOrderLine(order).product.name }}</td>
                <td>{{ order.getReference(idValuesService.tmtNumberId) }}</td>
                <td>
                    <button class="btn  btn-sm  btn-success" (click)="openModal(content, order)">
                        <fa-icon [icon]="faCalendarCheck"></fa-icon>
                        <span i18n>Reserve</span>
                    </button>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>
</ng-container>

<ng-container *ngIf="orders && count > orders.length">
    <ngb-pagination class="d-flex  justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
</ng-container>

<app-loader *ngIf="!orders"></app-loader>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ machinesService.getMachineOrderLine(selectedOrder).product.name }} ({{ selectedOrder.getReference(idValuesService.tmtNumberId) }})</h4>
        <button type="button" class="btn btn-close" aria-label="Close" (click)="modal.dismiss()">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
    <div class="modal-body helms-demos-form">
        <app-loader *ngIf="!reservations"></app-loader>
        <ng-container *ngIf="reservations">
            <h5 i18n>When do you need the machine?</h5>
            <ngb-datepicker #dp
                            *ngIf="reservations"
                            (dateSelect)="onDateSelection($event)"
                            [displayMonths]="2"
                            [dayTemplate]="t"
                            [markDisabled]="isDateBetweenReservations"
                            outsideDays="hidden"
                            class="mb-3">
            </ngb-datepicker>

            <ng-template #t let-date let-focused="focused">
                <span class="custom-day"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
            </ng-template>

            <app-table>
                <thead>
                <tr>
                    <th><span i18n>Additional equipment</span>:</th>
                </tr>
                </thead>
                <tbody>
                <ng-template ngFor let-orderLine [ngForOf]="selectedOrder.order_lines">
                    <tr *ngIf="orderLine.id !== machinesService.getMachineOrderLine(selectedOrder).id">
                        <td>{{ orderLine.product.name }}</td>
                    </tr>
                </ng-template>
                </tbody>
            </app-table>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn  btn-danger  float-left" (click)="modal.close()" i18n>Cancel</button>
        <button type="button" class="btn  btn-success" (click)="reserve()" [disabled]="disableSubmit" i18n>Reserve</button>
    </div>
</ng-template>
