import * as dayjs from 'dayjs';
import {ContactField} from "./contact-field";
import {ContactType} from "./contact-type";

export class Contact {

    id: string;
    contact_type_id: string;
    name: string;
    nickname: string;
    full_name: string;
    address: string;
    zip: string;
    city: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    contact_type: ContactType;
    contact_fields: ContactField[];
    _joinData: any;

    constructor() {
    }

    static fromJSON(json: ContactJSON | string): Contact {
        if (typeof json === 'string') {
            return JSON.parse(json, Contact.reviver);
        } else {
            let contact = Object.create(Contact.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                contact_type: null,
                contact_fields: []
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'contact_type' && json[key]) {
                    extra[key] = ContactType.fromJSON(json[key]);
                }

                if (key === 'contact_fields') {
                    json[key].forEach((value, _) => {
                        extra[key].push(ContactField.fromJSON(value));
                    });
                }
            });

            return Object.assign(contact, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Contact.fromJSON(value) : value;
    }

    public getContactField(id: string): string | null {
        let index = this.contact_fields.findIndex((element) => {
            return element.id === id;
        });

        if (index >= 0) {
            return this.contact_fields[index]._joinData.value;
        }

        return null;
    }

    public getContactFieldByName(name: string): string | null {
        const target = this.contact_fields.find((c) => {
            return c.name === name;
        });

        if (target){
           return target._joinData.value;
        } else {
            return null;
        }
    }

    public isOrderInvoiceType(): boolean {
        return this.contact_type && this.contact_type.is_order_invoice;
    }

    public isOrderDeliveryType(): boolean {
        return this.contact_type && this.contact_type.is_order_delivery;
    }
}

// A representation of Contact data that can be converted to
// and from JSON without being altered.
interface ContactJSON {
    id?: string;
    contact_type_id?: string;
    name?: string;
    nickname?: string;
    full_name?: string;
    address?: string;
    zip?: string;
    city?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    contact_fields?: ContactField[];
}

export interface Contactable {
    contacts: Contact[];
}
