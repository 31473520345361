import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Reference} from "./reference";

@Injectable()
export class ReferencesService {

    protected url = environment.apiBase + 'references';

    constructor(public apiService: ApiService) {
    }

    getReference(id: string): Observable<{data: Reference, count: number, perPage: number}> {
        return this.apiService.get<Reference>(this.url + '/' + id, true, Reference);
    }

    getReferences(): Observable<{data: Reference[], count: number, perPage: number}> {
        return this.apiService.get<Reference[]>(this.url, true, Reference);
    }

    addReference(reference: Reference): Observable<{data: Reference, count: number, perPage: number}> {
        return this.apiService.post<Reference>(this.url, reference, true, Reference);
    }

    updateReference(reference: Reference): Observable<{data: Reference, count: number, perPage: number}> {
        return this.apiService.patch<Reference>(this.url + '/' + reference.id, reference, true, Reference);
    }

    deleteReference(reference: Reference): Observable<{data: Reference, count: number, perPage: number}> {
        return this.apiService.delete<Reference>(this.url + '/' + reference.id, true, Reference);
    }
}
