import * as dayjs from 'dayjs';
import {GroupPermission} from "./group-permission";
import {User} from "./user";

export class Group {

    id: string;
    name: string;
    default: boolean;
    is_managed: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    group_permissions: GroupPermission[];
    users: User[];

    constructor() {
    }

    static fromJSON(json: GroupJSON | string): Group {
        if (typeof json === 'string') {
            return JSON.parse(json, Group.reviver);
        } else {
            let group = Object.create(Group.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                group_permissions: [],
                users: []
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'group_permissions') {
                    json[key].forEach((value, _) => {
                        extra.group_permissions.push(GroupPermission.fromJSON(value));
                    });
                }

                if (key === 'users') {
                    json[key].forEach((value, _) => {
                        extra.users.push(User.fromJSON(value));
                    });
                }
            });

            return Object.assign(group, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Group.fromJSON(value) : value;
    }

    hasPermission(permission: string): boolean {
        let index = this.group_permissions.findIndex((element) => {
            return element['permission'] === permission;
        });

        return index >= 0;
    }

    addGroupPermission(permission: string): void {
        this.group_permissions.push(GroupPermission.fromJSON({
            permission: permission
        }));
    }
}

// A representation of Group data that can be converted to
// and from JSON without being altered.
interface GroupJSON {
    id?: string;
    name?: string;
    default?: boolean;
    is_managed?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
