<div class="container" *ngIf="!loading">
    <div class="row">
        <div class="col-5">
            <p class="h3" i18n>Groups</p>
            <button (click)="addProductRelationsGroup()" class="btn btn-sm btn-primary" i18n>Add</button>

            <ul class="list-unstyled mt-3">
                <ng-template ngFor let-group [ngForOf]="_product.product_relations_groups" let-i="index">
                    <li>
                        <div class="card">
                            <div class="card-body">
                                <p class="card-title h5">
                                    {{group.name}}
                                    <button (click)="editProductRelationsGroup(group)" class="btn  btn-sm  btn-primary" i18n>Edit</button>
                                </p>
                                <ng-container *ngIf="group.is_dependent">
                                    <p class="card-subtitle mb-2 text-muted" i18n>Dependent</p>
                                </ng-container>
                                <ng-container *ngIf="group.is_mutually_exclusive">
                                    <p class="card-subtitle mb-2 text-muted" i18n>Exclusive</p>
                                </ng-container>
                                <ng-container *ngIf="!group.is_mutually_exclusive && !group.is_dependent">
                                    <p class="card-subtitle mb-2 text-muted" i18n>Grouping</p>
                                </ng-container>
                                <br>
                                <ul class="list-group list-group-flush">
                                    <ng-template ngFor let-productRelation [ngForOf]="group.product_relations_product_relations_groups" let-i="index">
                                        <li class="list-group-item">
                                            <a routerLink="/products/{{ productRelation.product_relations_id }}">{{ productRelation.product_relation.related_product.name }}</a>
                                        </li>
                                    </ng-template>
                                </ul>
                                <button (click)="deleteGroup(group)" class="btn btn-primary btn-sm float-right" i18n>Delete</button>
                            </div>
                        </div>
                    </li>
                </ng-template>

                <div *ngIf="_product.product_relations_groups.length === 0">
                    <hr>

                    <span class="text-muted" i18n>No groups</span>

                    <hr>
                </div>
            </ul>
        </div>

        <!-- Column -->

        <div class="col-7">
            <p class="h3" i18n>Relations</p>
            <button (click)="addProductRelation(true)" class="btn  btn-sm  btn-primary" i18n>Add</button>

            <input type="text" class="form-control mb-2 mt-2" [(ngModel)]="searchQuery" placeholder="Søg på relationer" />

            <hr>

            <app-table *ngIf="_product.product_relation_parents" [autoSizeLastColumn]="true">
                <thead>
                <tr>
                    <th i18n>Product name</th>
                    <th i18n>Note</th>
                    <th i18n>Standard</th>
                    <th i18n>Price</th>
                    <th i18n>Extra</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <ng-template ngFor let-productRelation [ngForOf]="_product.product_relation_childrens | productRelationFilter:'name':searchQuery" let-i="index">
                    <tr>
                        <td><a
                            routerLink="/products/{{ productRelation.product.id }}">{{ productRelation.product.name }}</a>
                            <ng-template ngFor let-productCategory [ngForOf]="productRelation.product.product_categories">
                            <span class="badge  badge-secondary  ml-2">
                                {{ productCategory.name }}
                            </span>
                            </ng-template>
                        </td>
                        <td>{{productRelation.note}}</td>
                        <td>
                            <fa-icon class="ml-1" *ngIf="productRelation.is_standard" [icon]="faGears"
                                     [ngbTooltip]="'Standard'"></fa-icon>
                        </td>
                        <td>
                            <p *ngIf="!productRelation.related_product.prices || productRelation.related_product.prices.length === 0" class="text-muted" i18n>No prices</p>
                            <ng-container *ngIf="productRelation.related_product.prices &&  productRelation.related_product.prices.length > 0"
                                          class="table  table-sm  table-striped  table-hover">
                                <ng-container *ngFor="let price of productRelation.related_product.prices; index as i;">
                                    <div *ngIf="price.price_group_id === linkedPriceGroup.id">
                                        {{ price.price_group.name }}:<br/>
                                        <input type="text" class="form-control  form-control-sm"
                                               [(ngModel)]="price.price" name="name-{{ i }}">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <div *ngIf="productRelation.isFollowProduct()" class="mt-2">
                                <span class="text-muted  mr-1" i18n>Minimum</span>
                                <input type="text" class="form-control  form-control-sm"
                                       style="display: inline; width: 50px;"
                                       name="product-product-relation-child-{{ i }}-follow-requirement-minimum"
                                       [(ngModel)]="productRelation.follow_requirement_minimum">

                                <span class="text-muted  ml-2  mr-1" i18n>Repeat</span>
                                <input type="text" class="form-control  form-control-sm"
                                       style="display: inline; width: 50px;"
                                       name="product-product-relation-child-{{ i }}-follow-requirement-repeat"
                                       [(ngModel)]="productRelation.follow_requirement_repeat">

                                <span class="text-muted  ml-2  mr-1" i18n>Amount</span>
                                <input type="text" class="form-control  form-control-sm"
                                       style="display: inline; width: 50px;"
                                       name="product-product-relation-child-{{ i }}-follow-amount"
                                       [(ngModel)]="productRelation.follow_amount">

                                <button (click)="productRelation.setNotFollowProduct()"
                                        class="btn  btn-sm  btn-danger  float-right"
                                        i18n>Not follow product
                                </button>
                            </div>

                        </td>
                        <td>
                            <a class="text-primary" href="javascript:"
                               (click)="editProductRelation(productRelation)" i18n>Edit</a><br/>
                            <a (click)="removeRelation(_product.product_relation_childrens[i]);" href="javascript:"
                               class="text-danger" i18n>Remove
                            </a>
                        </td>
                    </tr>
                </ng-template>
                </tbody>
            </app-table>


            <div *ngIf="_product.product_relation_childrens.length === 0">
                <span class="text-muted" i18n>No child relations</span>

                <hr>
            </div>
        </div>
    </div>
</div>
