<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container>{{ title }}</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ message }}</p>

        <form>
            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="checkbox" name="checkbox">&nbsp;{{ label }}
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="save()" i18n>OK</button>
    </div>
</div>
