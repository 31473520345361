<div class="pb-3">
    <h1 class="heading">
        <ng-container i18n>Analysis</ng-container>
        <small *ngIf="loading && product" class="text-muted" i18n>Loading...</small>
    </h1>

    <app-loader *ngIf="!product || (loading && product)" [useOverlayLoader]="loading && product ? true : false" i18n>
        Loading...
    </app-loader>


    <app-table *ngIf="product && productFieldPresets" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <ng-container *ngFor="let field of productFieldPresets.product_fields">
                <th [ngClass]="{'text-right': field.type ==='number'}">{{field.name}}</th>
            </ng-container>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngFor="let parentProduct of product.product_relation_parents">
            <tr>
                <ng-container *ngFor="let field of productFieldPresets.product_fields">
                    <td [ngClass]="{'text-right': field.type ==='number'}">
                        {{field.type === 'number' ? (parentProduct.product.getFieldValueForId(field.id)| number:'1.2-2':'da') : parentProduct.product.getFieldValueForId(field.id)}}
                    </td>
                </ng-container>
            </tr>
        </ng-container>
        </tbody>
    </app-table>


    <app-table *ngIf="product && analysisFieldsDetailsPresets">
        <thead>
        <tr>
            <th i18n>Analysis Details</th>
            <ng-container *ngFor="let field of analysisFieldsDetailsPresets.product_fields">
                <th class="text-right">{{field.name | dynamicTranslate}}:</th>
            </ng-container>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngFor="let childrelation of product.product_relation_childrens;  let last=last">
            <tr>
                <td class=""
                    [ngClass]="{'pl-4': childrelation.product.hasCategory({id:'1709cbfa-246f-43ab-810c-8aed0991ecb4'})}">
                    {{childrelation.product.name}}
                </td>
                <ng-container *ngIf="childrelation.product.product_categories.length > 0">
                    <ng-container *ngFor="let category of childrelation.product.product_categories">
                        <ng-container *ngFor="let prest of category.product_field_presets">
                            <ng-container *ngFor="let field of prest.product_fields">
                                <td colspan="4" class="text-right">
                                    {{childrelation.product.getFieldValueForId(field.id)| number:'1.2-2':'da'}}</td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="childrelation.product.product_categories.length ===0">
                    <ng-container *ngFor="let field of analysisFieldsDetailsPresets.product_fields">
                        <td class="text-right"> {{childrelation.product.getFieldValueForId(field.id)| number:'1.2-2':'da'}}</td>
                    </ng-container>
                </ng-container>
            </tr>

            <ng-container *ngIf="last"><!-- last line is the final Hkg result -->
                <tr>
                    <td style="border-top: 1px solid #000000" i18n="@@Hkg for payment">Hkg for payment</td>
                    <ng-container *ngFor="let field of analysisFieldsDetailsPresets.product_fields">
                        <td style="border-top: 1px solid #000000" class="text-right">
                            <!-- @todo this is a bit hardcoded - maybe add this row when importing-->
                            <ng-container *ngIf="field.name ==='Hkg after reduction'">
                                {{childrelation.product.getFieldValueForId(field.id)| number:'1.2-2':'da'}}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </ng-container>
        </tbody>
    </app-table>

    <app-table *ngIf="product" [hidden]="true">
        <thead>
        <tr></tr>
        </thead>

        <tbody>
        <ng-container *ngFor="let field of product.product_fields">
            <tr>
                <td>
                    {{field.name}}
                </td>
                <td>
                    {{field._joinData.value}}
                </td>
            </tr>
        </ng-container>
        </tbody>
    </app-table>

    <div class="row" *ngIf="analysisFieldPresets">

        <div class="col-6">

            <h3></h3>
            <app-table>
                <tbody>
                <ng-container *ngFor="let field of analysisFieldPresets.product_fields">
                    <tr>
                        <td>{{field.name}}</td>
                        <td class="text-right">{{product.getFieldValueForId(field.id)}}</td>
                    </tr>
                </ng-container>
                </tbody>
            </app-table>
        </div>
    </div>
</div>
