import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faCheck, faPen} from '@fortawesome/free-solid-svg-icons';
import {OrdersService} from '../../../services/orders.service';
import {Order} from '../../../services/order';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductsService} from '../../../services/products.service';
import {Date} from '../../../services/date';
import {Product} from '../../../services/product';
import {OrderLine} from "../../../services/order-line";
import {forkJoin, Subject, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {IdValuesService} from "../../../services/themes/helms/id-values.service";
import {DebtorsService} from "../../../services/debtors.service";
import {Debtor} from "../../../services/debtor";
import {OrderPrintModalComponent} from "../../../order-print-modal/order-print-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthService} from "../../../services/auth.service";
import {MachinesService} from "../../../services/themes/helms/machines.service";
import * as dayjs from "dayjs";
import {OrderStatesService} from "../../../services/order-states.service";
import {OrderState} from "../../../services/order-state";

@Component({
    selector: 'app-helms-order-edit',
    templateUrl: './helms-order-edit.component.html',
    styleUrls: ['./helms-order-edit.component.scss'],
    providers: [OrdersService, ProductsService, IdValuesService, DebtorsService, MachinesService, OrderStatesService]
})
export class HelmsOrderEditComponent implements OnInit, OnDestroy {

    public faPen = faPen;
    public faCheck = faCheck;

    public showDetails: boolean;

    @Input() orderId: string;
    @Input() factoryView: boolean;

product: Product;

    order: Order;

    loading = false;
    editing = false;
    isSaving = false;

    public debtors: Debtor[];

    public condition: string;
    public location: string;
    public locationName: any;
    public isDemo: boolean;
    public isHidden: boolean;

    public discountValue: number;
    public discountModelChanged: Subject<number> = new Subject<number>();
    private discountModelChangeSubscription: Subscription;

    public memoText: string;
    public memoModelChanged: Subject<string> = new Subject<string>();
    private memoModelChangeSubscription: Subscription;

    public memoTextExtern: string;
    public memoExternModelChanged: Subject<string> = new Subject<string>();
    private memoExternModelChangeSubscription: Subscription;

    public showRedskabGroup = false;
    public showWheelGroup = true;

    public groupedData: IGroupData[] = [];
    public orderStates: OrderState[] = [];
    showDebug: boolean;

    constructor(public ordersService: OrdersService,
                public productsService: ProductsService,
                public debtorsService: DebtorsService,
                public activatedRoute: ActivatedRoute,
                public idValuesService: IdValuesService,
                public authService: AuthService,
                public ngbModal: NgbModal,
                public orderStatesService: OrderStatesService,
                public machinesService: MachinesService,
                public router: Router) {
    }

    ngOnInit(): void {
        if (!this.orderId) {
            this.activatedRoute.paramMap.subscribe(params => {
                this.orderId = params.get('orderId');
                this.load();
            });
        } else {
            this.load();
        }

        this.discountModelChangeSubscription = this.discountModelChanged.pipe(debounceTime(2000)).subscribe(newValue => {
            this.discountValue = newValue;
            this.save();
        });

        this.memoModelChangeSubscription = this.memoModelChanged.pipe(debounceTime(2000)).subscribe(newText => {
            this.memoText = newText;
            this.save();
        });

        this.memoExternModelChangeSubscription = this.memoExternModelChanged.pipe(debounceTime(2000)).subscribe(newText => {
            this.memoTextExtern = newText;
            this.save();
        });

        this.debtorsService.getDebtors(1, 250, true, false, false, false, false, {}).subscribe(response => {
            this.debtors = response.data;
        });
    }

    onEditToggle(): void {
        this.editing = !this.editing;
    }

    save(): void {
        this.isSaving = true;

        let machineOrderLine = this.machinesService.getMachineOrderLine(this.order);
        if (machineOrderLine) {
            if (this.location) {
                this.locationName = this.debtors.find((item) => {
                    return item.id === this.location;
                })?.name;
            }
        }

        this.order.setFieldValueForId(this.idValuesService.conditionFieldValueId, this.condition);
        this.order.setFieldValueForId(this.idValuesService.locationFieldValueId, this.location);
        this.order.setFieldValueForId(this.idValuesService.locationNameFieldValueId, this.locationName);
        this.order.setFieldValueForId(this.idValuesService.conditionDiscountFieldValueId, this.discountValue);
        this.order.setFieldValueForId(this.idValuesService.memoFieldValueId, this.memoText);
        this.order.setFieldValueForId(this.idValuesService.memoExternFieldValueId, this.memoTextExtern);
        this.order.setFieldValueForId(this.idValuesService.demoFieldValueId, this.isDemo ? '1' : '0');
        this.order.setFieldValueForId(this.idValuesService.hiddenFieldValueId, this.isHidden ? '1' : '0');

        this.ordersService.updateOrder(this.order).subscribe(_ => {
            this.isSaving = false;

            this.load();
        });
    }

    onDemoChange(): void {
        this.isDemo = !this.isDemo;

        this.save();
    }

    onHiddenChange(): void {
        this.isHidden = !this.isHidden;

        this.save();
    }

    onCheckboxChange(product: Product): void {
        let index = this.order.hasOrderLineWithProduct(product);

        if (index) {
            this.order.order_lines.splice(index, 1);
        } else {
            this.order.addOrderLine(OrderLine.fromJSON({
                product_id: product.id,
                quantity: 1,
                price: product.price,
                product: product
            }));
        }

        this.save();
    }

    onDiscountInputChange(value: number): void {
        this.discountModelChanged.next(value);
    }

    onMemoInputChange(value: string): void {
        this.memoModelChanged.next(value);
    }

    onMemoExternInputChange(value: string): void {
        this.memoExternModelChanged.next(value);
    }


    /**
     * Kopieret til helms-orders.component.ts for mass assignment
     * @param orderState
     */
    setState(orderState: OrderState): void {

        let alert:string;
        if (orderState.sorting < this.order.order_state.sorting) {
            alert = 'Er du sikker ? Ordre status rækkefølge går tilbage';
        } else {
            alert = 'Er du sikker ? Ordre status vil blive ændret';
        }

        if (confirm(alert)) {
            let dateId = '';

            switch (orderState.id) {
                case '67c6c963-28d1-4ade-a8e6-7399ea1a6600': // Bestilling
                    dateId = '9309724f-fcbd-4660-bf7a-6409e0e9f95c';
                    break;

                case 'c17d1800-af49-4e8c-8b5f-ad33342d052b': // Hjemkommet
                    dateId = 'a6b972b2-1716-42b9-95c6-344d7a8cd636'
                    break;

                case 'b57c5930-7f13-451f-a842-3b7bf001d682': // Leveret
                    dateId = 'f1c6a3c4-a71e-45c7-a486-3b761fa6a066';
                    break;

                case 'ce8e0faa-6070-44c6-9a00-4a9e0feea197': // Afsluttet
                    dateId = '2581d2f3-ab95-4324-86ee-f6e34bf32b40';
                    break;
            }

            if (dateId != '') {
                this.order.dates.push(Date.fromJSON({
                    'id': dateId,
                    '_joinData': {
                        'time': dayjs()
                    }
                }));
            }

            this.order.order_state_id = orderState.id;

            this.save();
        }
    }

    load(): void {
        this.loading = true;

        this.ordersService.getOrder(this.orderId, true, true, true, false, true, true, true, false, {withOrderType: true}).subscribe(response => {
            this.order = response.data;


            this.condition = this.order.getFieldValueForId(this.idValuesService.conditionFieldValueId);
            this.location = this.order.getFieldValueForId(this.idValuesService.locationFieldValueId);
            this.locationName = this.order.getFieldValueForId(this.idValuesService.locationNameFieldValueId);
            this.discountValue = this.order.getFieldValueForId(this.idValuesService.conditionDiscountFieldValueId);
            this.memoText = this.order.getFieldValueForId(this.idValuesService.memoFieldValueId);
            this.memoTextExtern = this.order.getFieldValueForId(this.idValuesService.memoExternFieldValueId);
            this.isDemo = this.order.getFieldValueForId(this.idValuesService.demoFieldValueId) === '1';
            this.isHidden = this.order.getFieldValueForId(this.idValuesService.hiddenFieldValueId) === '1';

            let machineOrderLine = this.machinesService.getMachineOrderLine(this.order);
            if (machineOrderLine) {

                forkJoin([
                    this.productsService.getProduct(machineOrderLine.product.id, false, false, false, false, false, false, null, false, false, false, false, {withAdjustedPrices: true}),
                    this.productsService.getProducts(1, -1, false, false, true, false, false, false, {
                        withProductRelations: false,
                        withProductStocks: true,
                        withAdjustedPrices: true,
                        withProductFields: true,
                        byParentRelation: machineOrderLine.product.id
                    }),
                    this.orderStatesService.getOrderStates()
                ]).subscribe((results) => {
                    const productResponse = results[0];
                    const relationsResponse = results[1];
                    if (this.order.order_type_id !== null) {
                        this.showRedskabGroup = true;
                    }

                    this.product = productResponse.data;

                    this.parseGroups(relationsResponse.data);
                    this.orderStates = results[2].data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }

        });
    }

    ngOnDestroy(): void {
        this.discountModelChangeSubscription.unsubscribe();
        this.memoModelChangeSubscription.unsubscribe();
        this.memoExternModelChangeSubscription.unsubscribe();
    }

    parseGroups(products: Product[]): void {
        const processed = products
            .reduce((r: Map<string, IGroupData>, e) => {
                const group = e.product_categories[0].name;
                if (!r[group]) {
                    let sort = 0;
                    switch (group) {
                        // @todo implement sorting on categories og match on id/import ids (breaks with translations)
                        case 'Extraudstyr':
                            sort = 2;
                            break;
                        case 'Hjul':
                            sort = 1;
                            break;
                        case 'Redskaber':
                            sort = 3;
                            break;
                    }
                    r[group] = {group, products: [e], sort, discounts_texts: [], discount_amount: 0, total:0, unadjusted_total:0};
                } else {
                    r[group].products.push(e);
                }
                return r;
            }, new Map());
        this.groupedData = Object.values(processed)
            .sort((a, b) => {
                if (a.sort > b.sort) {
                    return 1;
                }
                return -1;
            }).map((group) => {
                //Group discounts and sums per group
                group.products.forEach((product) => {
                    if (this.order.hasOrderLineWithProduct(product)) {
                        let discounts_text = this.order.order_lines[this.order.hasOrderLineWithProduct(product)].price_adjustment_name;
                        if (discounts_text) {
                            const discounts_texts = discounts_text.split('<br>');
                            discounts_texts.forEach((text) => {
                                if (!group.discounts_texts.find((a) => {
                                    return a === text;
                                })) {
                                    group.discounts_texts.push(text);
                                }
                            });
                        }

                        group.discount_amount = group.discount_amount + this.order.order_lines[this.order.hasOrderLineWithProduct(product)].getAdjustedSavings();
                        group.total = group.total + this.order.order_lines[this.order.hasOrderLineWithProduct(product)].getTotal();
                        group.unadjusted_total = group.unadjusted_total + this.order.order_lines[this.order.hasOrderLineWithProduct(product)].getUnAdjustedTotal();
                    }
                });
                return group;
            });
    }

    public printModal(type: string): void {
        const modalRef = this.ngbModal.open(OrderPrintModalComponent, {size: 'lg'});
        modalRef.componentInstance.order = this.order;
        modalRef.componentInstance.type = type;
    }

    deleteOrder(order: Order): void {
        if (confirm($localize`Are you sure?\n\nThe order will be deleted`)) {
            this.loading = true;
            this.ordersService.deleteOrder(order).subscribe((response) => {
                this.router.navigateByUrl('/orders');
            });
        }
    }

    removeDebtor() {
        this.order.removeDebtor();
        this.save();
    }

}

export interface IGroupData {
    group: string;
    order_lines: OrderLine[];
    sort: number;
}
