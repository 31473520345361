<app-table [autoSizeFirstColumn]="true" *ngIf="extraTools">
    <thead>
    <tr>
        <th></th>
        <th>Redskab</th>
        <th class="text-right">Pris I DKK</th>
        <th>Lager</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let product of extraTools">
        <td>
            <div class="QuantitySelector">
                <button class="QuantitySelector__btn  QuantitySelector__btn--remove  btn  btn-primary  button  button--table" (click)="onRemoveClick(product)">-</button>
                <span class="QuantitySelector__count  badge    ml-1  mr-1" [ngClass]="{'badge-secondary': quantityForTool(product) > 0}">{{quantityForTool(product)}}</span>
                <button class="QuantitySelector__btn  QuantitySelector__btn--remove btn  btn-primary  button  button--table" (click)="onAddClick(product)">+</button>
            </div>
        </td>
        <td>
            <button class="icon-button" placement="right" [ngbTooltip]="htmlContent" triggers="manual" #t1="ngbTooltip"
                    (click)="onThumbnailClick($event, t1, product)"
                    *ngIf="product.attachments && product.attachments.length > 0">
                <fa-icon [icon]="faCamera"></fa-icon>
            </button>
            <span [innerHTML]="product.name"></span>
        </td>
        <td class="text-right">{{product.price|currency:'DKK'}}</td>
        <td>
            <ng-container *ngIf="product?.product_stocks.length > 0; else noStock">
                <ng-container *ngFor="let stock of product.product_stocks">
                    {{stock.branch.name}}: {{stock.quantity === null || stock.quantity === '' ? 'Ukendt' : stock.quantity}}
                </ng-container>
            </ng-container>
            <ng-template #noStock>Ukendt</ng-template>
        </td>
        <td>
    </tr>
    </tbody>
</app-table>
<app-loader *ngIf="!extraTools"></app-loader>
<ng-template #htmlContent>
    <img src="{{activeThumbnail}}" />
</ng-template>
