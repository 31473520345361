import * as dayjs from 'dayjs';
import {PriceAdjustment} from "./price-adjustment";
import {PriceGroup} from "./price-group";
import {Product} from "./product";

export class ProductQuantityDiscount {

    id: string;
    product_id: string;
    price_adjustment_id: string;
    price_group_id: string;
    min: number;
    pct: number;
    requires_min_multiple: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    product: Product;
    price_adjustment: PriceAdjustment;
    price_group: PriceGroup;

    constructor() {
    }

    static fromJSON(json: ProductQuantityDiscountJSON | string): ProductQuantityDiscount {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductQuantityDiscount.reviver);
        } else {
            let productQuantityDiscount = Object.create(ProductQuantityDiscount.prototype);

            let extra = {
                product: null,
                price_adjustment: null,
                price_group: null,
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'product' && json[key]) {
                    extra[key] = Product.fromJSON(json[key]);
                }

                if (key === 'price_adjustment' && json[key]) {
                    extra[key] = PriceAdjustment.fromJSON(json[key]);
                }

                if (key === 'price_group' && json[key]) {
                    extra[key] = PriceGroup.fromJSON(json[key]);
                }
            });

            return Object.assign(productQuantityDiscount, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductQuantityDiscount.fromJSON(value) : value;
    }
}

// A representation of ProductQuantityDiscount data that can be converted to
// and from JSON without being altered.
interface ProductQuantityDiscountJSON {
    id?: string;
    product_id?: string;
    price_adjustment_id?: string;
    price_group_id?: string;
    min?: number;
    pct?: number;
    requires_min_multiple?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
