import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

export class Token {

    private token: string;

    constructor() {
    }

    getToken(): string {
        return this.token;
    }

    getUserId(): string {
        return this.getPayload().sub;
    }

    getExpiration(): number {
        return this.getPayload().exp;
    }

    isExpired(): boolean {
        dayjs.extend(utc);

        let now = dayjs.utc();
        let expire = dayjs.utc(this.getExpiration() * 1000);

        return now.isAfter(expire);
    }

    protected getPayload() {
        return JSON.parse(atob(this.token.split('.')[1]))
    }

    static fromJSON(json: TokenJSON|string): Token {
        if (typeof json === 'string') {
            return JSON.parse(json, Token.reviver);
        } else {
            let token = Object.create(Token.prototype);
            return Object.assign(token, json, {});
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Token.fromJSON(value) : value;
    }

    static verifyFormat(token: string) {
        if (!token) {
            return false;
        }

        const parts = token.split('.');

        if (parts.length !== 3) {
            return false;
        }

        return true;
    }
}

// A representation of Token data that can be converted to
// and from JSON without being altered.
interface TokenJSON {
    token: string;
}
