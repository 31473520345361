import {Component, Input, OnInit} from '@angular/core';
import {Order} from "../../../services/order";
import {Debtor} from "../../../services/debtor";
import {Creditor} from "../../../services/creditor";

@Component({
    selector: 'app-dates-and-info',
    templateUrl: './dates-and-info.html',
    styleUrls: ['./dates-and-info.scss']
})
export class DatesAndInfoComponent implements OnInit {

    @Input() order: Order;
    @Input() debtor: Debtor;
    @Input() creditor: Creditor;

    constructor() {
    }

    ngOnInit(): void {
    }

}
