import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IdValuesService {



    /* Interpreted from the ImportLageCommand.php file in the execute() method. */
    public orderId = 'b9c69ebe-3dd9-425e-867d-f9ea2ccdedd8';
    public tmtNumberId = 'a2282eb6-bc0b-4c54-8b28-b626422e4644';

    public storageIdFieldValueId = '134e99d4-aa30-4fec-ae31-09936d366355';
    public discountFieldValueId = '42274c25-cbe5-4b4d-ae14-7a9ff26ad7c4';
    public conditionFieldValueId = 'a7a141dc-ab4f-11ec-b909-0242ac120002';
    public conditionDiscountFieldValueId = '600a5e6a-fb0e-4e92-8b59-dbf880c27a49';
    public demoFieldValueId = 'eb706407-1239-492f-8fcf-405cb5b7d76a';
    public hiddenFieldValueId = '3f1cfc23-61f2-428e-b622-af4f672b8a78';
    public locationFieldValueId = '1df7a373-e3e8-4451-be6e-876ea18e32b6';
    public locationNameFieldValueId = 'c6063187-23c5-4625-a4b8-564d9ba2482f';
    public memoFieldValueId = '8cd5924f-75ab-452f-ac13-9ff256869674';
    public memoExternFieldValueId = 'a3857836-131f-4b6a-8d1e-2f65a39e9c5f';
    public homeFieldValueId = '3cba83ba-2c6d-4e93-9b04-6b4823bc388a';
    public commentsFieldValueId = '35df77bc-535a-4da0-9dbc-c9145c6992f3';
    public adressFieldValueId = '88b103e1-e574-44e8-a0ff-2dd254938c46';
    public offerFieldValueId = '547abcf4-87da-4306-8f11-96267bead659';
    public currencyFieldValueId = 'f71fdddc-2a35-4eca-afef-8781709fb290';
    public dateFieldValueId = 'daa8fdde-a98a-4f3a-81e1-ef9019c46980';
    public storeFieldValueId = '0284656a-dd5e-43aa-905f-2c90e9645cf8';
    public requisitionFieldValueId = '8aaaec4a-7e6c-46a1-9c43-06baf8b2370a';
    public customOrderLineFieldValueId = 'e47f93ec-482f-4e9e-b0cd-aada60db1fb0';
    public linkFieldValueId = '46dd4b4c-b07d-4a1f-b6af-522e87a36180';

    public branchId = '63f4e9cd-953d-4977-8c1b-8a2e86e83e34';
    public orderedOrderStateId = '67c6c963-28d1-4ade-a8e6-7399ea1a6600';
    public offerOrderStateId = '44019a09-dca4-435b-bf53-7e77a59694f2';

    public productMachineCategoryId = 'ddeb6131-7a39-4c8a-bc9a-849151b57141';
    public productDeliveryCategoryId = '6a393b85-7fb0-4ba4-b394-a6b2152cedaf';
    public wheelCategoryId = '86252329-c3cd-4632-aa50-dc5966a7566a';
    public equipmentCategoryId = '0ac87919-fda3-4ed6-8ff6-897b1244111a';
    public toolsCategoryId = '0cd897bc-178b-46cb-a42e-ce36b744545e';

    public productionDateId = '022487e5-0953-4027-8455-6578f7b47334';
    public homeDateId = 'a6b972b2-1716-42b9-95c6-344d7a8cd636';
    public orderedDateId = '9309724f-fcbd-4660-bf7a-6409e0e9f95c';

    public pipelineOrderStateId = '46ff26ba-ecbc-428f-bd56-fe7516f31c03';
    public inventoryOrderStateId = '01190160-e140-4129-b711-13f0c5d7a3f6';

    public tyskNavn = '0d905077-677b-4b0e-bc99-120154e40adf';
    public beskrivelse = '7d4d5a3f-c053-4915-b585-10eb5051fbe6';
    constructor() {
    }
}
