import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../services/auth.service";
import {CreditorsService} from "../services/creditors.service";
import {CreditorsUser} from "../services/creditors-user";

@Component({
    selector: 'app-creditors-user-edit-modal',
    templateUrl: './creditors-user-edit-modal.component.html',
    styleUrls: ['./creditors-user-edit-modal.component.scss'],
    providers: [CreditorsService]
})
export class CreditorsUserEditModalComponent implements OnInit {

    public creditorsUser: CreditorsUser;
    public _creditorsUser: CreditorsUser;

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public creditorsService: CreditorsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this._creditorsUser = this.creditorsUser ? CreditorsUser.fromJSON(this.creditorsUser) : CreditorsUser.fromJSON({});
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;

        this.creditorsService.updateCreditorsUser(this._creditorsUser).subscribe(_ => {
            this.saved();

            this.saving = false;

            this.activeModal.close();
        });
    }
}
