import * as dayjs from 'dayjs';
import {Product} from "../../services/product";
import {ProductionLine} from "./production-line";
import {Order} from "../../services/order";

export class ProductionLineSlot {

    id: string;
    created: dayjs.Dayjs;
    modified: dayjs.Dayjs;
    date: dayjs.Dayjs;
    product: Product;
    order: Order;

    _warning?:boolean;
    _group?:any;

    constructor() {
    }

    static fromJSON(json: ProductionLineSlotInterface | string): ProductionLineSlot {
        if (typeof json === 'string') {
            return JSON.parse(json, ProductionLineSlot.reviver);
        } else {
            let model = new ProductionLineSlot();

            let extra = {
                created: dayjs(json.created),
                modified: dayjs(json.modified),
                date: dayjs(json.date),
                product: null,
                order: null,
            };

            Object.keys(json).forEach((key, index) => {
                if (key === 'product' && json[key]) {
                    extra[key] = Product.fromJSON(json[key]);
                }
                if (key === 'order' && json[key]) {
                    extra[key] = Order.fromJSON(json[key]);
                }
            });

            return Object.assign(model, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? ProductionLineSlot.fromJSON(value) : value;
    }
}

export interface ProductionLineSlotInterface extends ProductionLineSlot {
}
