<button *ngIf="isSubMenu" [className]="'navigation__toggle-button  navigation__toggle-button--sub-level'" (click)="toggleNavbar($event)">
    <span class="navigation__title">{{title}}</span>
    <span [className]="'navigation__icon  navigation__icon--sub-menu' + (navBarOpen ? '  navigation__icon--close-sub-menu' : '  navigation__icon--open-sub-menu')"></span>
</button>

<nav (clickOutside)="closeNavbar(true)" [exclude]="'button'" *ngIf="navData" [className]="'navigation' + (isSubMenu ? '  navigation--sub-menu' : '  navigation--top-menu')">
    <button *ngIf="!isSubMenu" [className]="'navigation__toggle-button  navigation__toggle-button--top-level'" type="button" aria-controls="navigation" aria-label="Toggle navigation" (click)="toggleNavbar($event)">
        <span [className]="'navigation__icon' + (navBarOpen ? '  navigation__icon--close-menu' : '  navigation__icon--open-menu')"></span>
    </button>
    <ul [className]="('navigation__wrapper' + (navBarOpen ? ' navigation__wrapper--open' : '')) + (isSubMenu ? '  navigation__wrapper--sub-menu' : '  navigation__wrapper--top-menu')" id="navigation">
        <ng-container *ngFor="let navItem of navData; index as i;">
            <li *ngIf="checkPermissions(navItem.permission)" [routerLinkActive]="'navigation__item--active'" [className]="'navigation__item' + (navItem.className ? '  ' + navItem.className : '')">

                <ng-container *ngIf="!navItem.children && !navItem.seperator">
    
                    <ng-container *ngIf="!navItem.onClick">
                        <a class="navigation__link" [routerLink]="navItem.url" [queryParams]="navItem.queryParams?navItem.queryParams:{}" (click)="closeNavbar(true)">
                            <fa-icon *ngIf="navItem.icon" [ngClass]="'navigation__link-icon'" [icon]="navItem.icon"></fa-icon>
                            {{navItem.title ? navItem.title : 'Missing Title'}}
                        </a>
                    </ng-container>
    
                    <ng-container *ngIf="navItem.onClick">
                        <button class="navigation__link" (click)="navItem.onClick($event)">
                            <fa-icon *ngIf="navItem.icon" [ngClass]="'navigation__link-icon'" [icon]="navItem.icon"></fa-icon>
                            {{navItem.title ? navItem.title : 'Missing Title'}}
                        </button>
                    </ng-container>
    
                </ng-container>
    
                <ng-container *ngIf="!navItem.url && !navItem.onClick && !navItem.seperator && !navItem.children">
                    <span class="navigation__title">{{navItem.title ? navItem.title : 'Missing Title'}}</span>
                </ng-container>
    
                <ng-container *ngIf="navItem.seperator">
                    <hr />
                </ng-container>
    
                <ng-container *ngIf="navItem.children">
                    <app-navigation [isSubMenu]="true" [navData]="navItem.children" [title]="navItem.title" (shouldClose)="closeNavbar($event)"></app-navigation>
                </ng-container>
    
            </li>
        </ng-container>
    </ul>
</nav>
