import * as dayjs from 'dayjs';

export class PaymentAgreement {

    id: string;
    name: string;
    days: number;
    is_running_month: boolean;
    is_default: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;

    constructor() {
    }

    static fromJSON(json: PaymentAgreementJSON | string): PaymentAgreement {
        if (typeof json === 'string') {
            return JSON.parse(json, PaymentAgreement.reviver);
        } else {
            let paymentAgreement = Object.create(PaymentAgreement.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created)
            }

            return Object.assign(paymentAgreement, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? PaymentAgreement.fromJSON(value) : value;
    }

    public onDaysChange() {
        this.is_running_month = null;
    }

    public onIsRunningMonthChange() {
        this.days = null;
    }
}

// A representation of PaymentAgreement data that can be converted to
// and from JSON without being altered.
interface PaymentAgreementJSON {
    id?: string;
    name?: string;
    days?: number;
    is_running_month?: boolean;
    is_default?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
