import {Component, ElementRef, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {faTimes} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-layout-splitter',
    templateUrl: './layout-splitter.component.html',
    styleUrls: ['./layout-splitter.component.scss']
})
export class LayoutSplitterComponent implements AfterViewInit {

    @ViewChild('secondaryContent') secondaryContent: ElementRef;
    @Input() isSplitScreen: boolean = false;
    @Output() shouldCloseAdditionalContent = new EventEmitter<boolean>();

    faIcon = faTimes;

    public elementHeight: number = 0;

    constructor(private _element:ElementRef) {
    }

    ngAfterViewInit(): void {
        if(this.secondaryContent.nativeElement.children.length > 0) {
            this.isSplitScreen = true;
        }
    }

    closeAdditionalContent(value: boolean) {
        this.shouldCloseAdditionalContent.emit(value);
    }

}
