<div class="orders">
    <h1 class="heading" i18n>Orders</h1>

    <app-loader *ngIf="!orders || (loading && orders)" [useOverlayLoader]="loading && orders ? true : false" i18n>Loading...</app-loader>

    <app-table-helper *ngIf="orders">
            <form>
                <div class="form-row">
                    <div class="col">
                        <input type="text" id="product-queryFilter" class="form-control form-control-sm" i18n-placeholder placeholder="Search" [(ngModel)]="filterQuery.searchQuery" name="queryFilter">
                    </div>
                    <div class="col">
                        <button (click)="load(page)" class="btn  btn-sm  btn-secondary  ml-2" style="transform: translateY(-1px);" [disabled]="loading" i18n>Search</button>
                    </div>
                </div>
            </form>

    </app-table-helper>

    <app-table *ngIf="orders" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th i18n>Order ID</th>
            <th i18n>Year</th>
            <th i18n>Debtor</th>
            <th></th>

<!--            <th i18n>Leveringsdato</th>-->
<!--            <th i18n>Indvejning</th>-->
<!--            <th i18n>Udvejning</th>-->
<!--            <th i18n><span placement="top" ngbTooltip="This can be used to explain a column!" class="tbl__icon">?</span> Mark</th>-->
<!--            <th i18n>Sort</th>-->
<!--            <th i18n>Eksternt bil</th>-->
<!--            <th i18n>Internt Vejenum</th>-->
<!--            <th i18n>Udvejningsv</th>-->
<!--            <th i18n>Hkg. lever</th>-->
<!--            <th i18n>Afregnings</th>-->
<!--            <th i18n>Sti til analyse</th>-->
<!--            <th></th>-->
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-order [ngForOf]="orders">
            <tr>
                <td>{{ order.getIdentificationReference() }}</td>
                <td>{{ order.getReference('c62c0010-c6a9-4b64-bbda-ab5c09f9dc4b') }}</td><!-- @todo hardcoded ?! -->
                <td>{{ order?.debtor?.name }}</td>
                <td>
                    <a routerLink="/orders/{{ order.id }}" i18n>Order details</a>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="orders && count > orders.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10" [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'" [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>
