<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            <ng-container *ngIf="!_orderLine" i18n>Add order line</ng-container>
            <ng-container *ngIf="_orderLine" i18n>Edit order line</ng-container>
        </h5>
        <button (click)="close()" type="button" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <p *ngIf="!products" class="text-muted" i18n>Loading...</p>

            <div *ngIf="products">
                <div class="form-group">
                    <label for="order-line-product_id" i18n>Product</label>
                    <select id="order-line-product_id" class="form-control  form-control-sm" [(ngModel)]="_orderLine.product_id" name="product_id" (change)="productSelected(productSelect.value)" #productSelect [disabled]="loading">
                        <option [ngValue]="null" i18n>-- Select product --</option>
                        <option *ngFor="let product of products" [ngValue]="product.id">{{ product.name }}</option>
                    </select>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="order-line-quantity" i18n>Quantity</label>
                            <input type="number" min="0" id="order-line-quantity" class="form-control" [(ngModel)]="_orderLine.quantity" (ngModelChange)="quantityChanged()" name="quantity" [disabled]="loading">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="order-line-price">
                                Price
                                <span *ngIf="loading" class="text-muted" i18n>Loading...</span>
                            </label>
                            <input type="number" id="order-line-price" class="form-control" [(ngModel)]="_orderLine.price" (ngModelChange)="_orderLine.onPriceChange($event)" name="price" [disabled]="loading || !_orderLine.product_id">

                            <span *ngIf="_orderLine.cost_price && _orderLine.product_id" class="text-muted">
                                <span><ng-container i18n>Cost price</ng-container>: {{ _orderLine.cost_price }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="order-line-discount" i18n>Discount %</label>
                            <input type="number" id="order-line-discount" class="form-control" [(ngModel)]="_orderLine.discount" (ngModelChange)="_orderLine.onDiscountChange($event)" min="0" max="100" name="discount" [disabled]="loading || !_orderLine.product_id">
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div *ngIf="_orderLine.product" class="form-group">
                            <label for="order-line-product-vat-rate" i18n>VAT %</label>
                            <input type="number" id="order-line-product-vat-rate" class="form-control" [(ngModel)]="_orderLine.product.vat_type.rate" name="product-vat-rate" [disabled]="true">

                            <span class="text-muted">
                                <span><ng-container i18n>VAT amount</ng-container>: {{ _orderLine.getVATAmount() }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="order-line-total" i18n>Total</label>
                            <input type="number" id="order-line-total" class="form-control" [value]="_orderLine.getTotal()" name="total" [disabled]="true">
                        </div>
                    </div>
                </div>

                <div *ngIf="_orderLine.product" class="row">
                    <ng-template ngFor let-alternativePriceUnit [ngForOf]="_orderLine.product.alternative_price_units" let-i="index">
                        <div class="col-md-3">
                            <div class="form-group">
                                <span *ngIf="!loading" class="float-right">{{ alternativePriceUnit.getPrice(_orderLine.price) | currency:'DKK':'':null }}</span>

                                <label for="order-line-alternative-price-unit-{{ i }}-amount">
                                    <span *ngIf="_orderLine.alternative_price_unit_id === alternativePriceUnit.id" class="text-primary">&gt;&gt; </span>
                                    {{ alternativePriceUnit.unit }}
                                    <span class="text-muted">({{ alternativePriceUnit.factor }})</span>
                                </label>
                                <input type="number" min="0" id="order-line-alternative-price-unit-{{ i }}-amount" class="form-control" [(ngModel)]="alternativePriceUnit.quantity" (ngModelChange)="_orderLine.onAlternativePriceUnitAmountChanged(alternativePriceUnit)" name="amount-{{ i }}" [disabled]="loading">
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div *ngIf="_orderLine.product?.product_relation_childrens">
                    <ng-template ngFor let-productRelation [ngForOf]="_orderLine.product.product_relation_childrens">
                        <span *ngIf="productRelation.isFollowProduct()" class="badge  badge-secondary">
                            {{ productRelation.product.name }} x {{ productRelation.follow_amount }}
                            (from {{ productRelation.follow_requirement_minimum }} and at every {{ productRelation.follow_requirement_repeat}})
                        </span>
                        <br>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="!products" i18n>Close</button>
        <button type="button" class="btn btn-primary" (click)="add()" [disabled]="!products || !_orderLine.product_id || loading || adding">
            <ng-container *ngIf="!adding" i18n>Add</ng-container>
            <ng-container *ngIf="adding" i18n>Adding...</ng-container>
        </button>
    </div>
</div>
