import * as dayjs from 'dayjs';
import {Reference} from "./reference";
import {Contact, Contactable} from "./contact";
import {User} from "./user";

export class Creditor implements Contactable {

    id: string;
    name: string;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    references: Reference[];
    contacts: Contact[];
    users: User[];

    balance: number;

    constructor() {
    }

    static fromJSON(json: CreditorJSON | string): Creditor {
        if (typeof json === 'string') {
            return JSON.parse(json, Creditor.reviver);
        } else {
            let creditor = Object.create(Creditor.prototype);

            let extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                references: [],
                contacts: [],
                users: []
            }

            Object.keys(json).forEach((key, _) => {
                if (key === 'references') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Reference.fromJSON(value));
                    });
                }

                if (key === 'contacts') {
                    json[key].forEach((value, _) => {
                        extra[key].push(Contact.fromJSON(value));
                    });
                }

                if (key === 'users') {
                    json[key].forEach((value, _) => {
                        extra[key].push(User.fromJSON(value));
                    });
                }
            });

            return Object.assign(creditor, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === "" ? Creditor.fromJSON(value) : value;
    }

    hasReference(id: string): boolean {
        let index = this.references.findIndex((element) => {
            return element['id'] === id;
        });

        return index >= 0;
    }
}

// A representation of Creditor data that can be converted to
// and from JSON without being altered.
interface CreditorJSON {
    id?: string;
    name?: string;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
    references?: Reference[];
    contacts?: Contact[];
    users?: User[];
}

export interface CreditorRelatable {
    addCreditor(creditor: Creditor): void
    removeCreditor(): void
}
