import * as dayjs from 'dayjs';
import {Translation} from './translation';

export class Unit {

    id: string;
    name: string;
    // tslint:disable-next-line:variable-name
    is_default: boolean;
    modified: dayjs.Dayjs;
    created: dayjs.Dayjs;
    // tslint:disable-next-line:variable-name
    _translations: Translation;

    constructor() {
    }

    static fromJSON(json: UnitJSON | string): Unit {
        if (typeof json === 'string') {
            return JSON.parse(json, Unit.reviver);
        } else {
            const unit = Object.create(Unit.prototype);

            const extra = {
                modified: dayjs(json.modified),
                created: dayjs(json.created),
                _translations: null,
            };

            Object.keys(json).forEach((key, _) => {
                if (key === '_translations' && json[key]) {
                    extra[key] = Translation.fromJSON(json[key]);
                }
            });

            return Object.assign(unit, json, extra);
        }
    }

    static reviver(key: string, value: any): any {
        return key === '' ? Unit.fromJSON(value) : value;
    }
}

// A representation of Unit data that can be converted to
// and from JSON without being altered.
interface UnitJSON {
    id?: string;
    name?: string;
    is_default?: boolean;
    modified?: dayjs.Dayjs;
    created?: dayjs.Dayjs;
}
